import axios from "axios";
import { PortalAIPApplicationModel } from "../models/PortalAIPApplication";
import { UserContextComponent } from "./UserContextComponent";
import { DownloadDocumentButton } from "./DownloadDocumentButton";

export interface IValuationProps {
  item: PortalAIPApplicationModel;
  buttonStyle?: "standard" | "pill" | null;
  className?: string;
}

interface IValuationState {
  downloadError: boolean;
}

export class ValuationDownloadButton extends UserContextComponent<IValuationProps, IValuationState> {
  constructor(props: IValuationProps) {
    super(props);
    this.downloadValuation = this.downloadValuation.bind(this);
    this.state = {downloadError: false};
  }

    downloadValuation() {
        const context = this.getContext();

        axios({
            url: `/Mortgages/DownloadValuationByAIP?applicationId=${this.props.item.aipApplicationId}&mortgageId=${context.mortgage?.mortgageId}`,
            method: "GET",
            responseType: "blob"
        }).then(response => {
            if (response.data.size === 0) {
                this.setState({downloadError: true});
            } else {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", 'Valuation.pdf');
                document.body.appendChild(link);
                link.click();
                if (link.parentNode !== null)
                    link.parentNode.removeChild(link);
            }
        });
    }

  render() {

    return (
      <DownloadDocumentButton 
        className={this.props.className}
        title={"Valuation"} 
        expiry={this.props.item.valuationExpiryDate}
        download={this.downloadValuation}
        buttonStyle={this.props.buttonStyle}
        showError={this.state.downloadError} />
    );
  }
}