import React from "react";
import { Stack, Card, Container } from "react-bootstrap";

export class HelpBox extends React.Component<any, any> { 
    render() {
        const { title, text } = this.props;

        return (
          <Container className="pt-2">

            <Card className="p-4">
              <Stack gap={3}>
                <h2>{title}</h2>
                <p>{text}</p>
                </Stack>
                {this.props.children}
            </Card>
            </Container>
          );
    }
}