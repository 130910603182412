import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../pages/Applicant.css';
import { UserContextComponent } from '../UserContextComponent';
import axios from 'axios';


export class CostOfCreditTile extends UserContextComponent<any, any> {

    constructor(props: any) {
        super(props);
        this.download = this.download.bind(this);
    }

    download() {
        const context = this.getContext();

        axios({
            url: `/Mortgages/DownloadCostOfCredit?mortgageId=${context.mortgage?.mortgageId}`,
            method: "GET",
            responseType: "blob"
        }).then(response => {
            if (response.data.size === 0) {
                //this.setState({downloadError: true});
            } else {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", 'CostOfCredit.pdf');
                document.body.appendChild(link);
                link.click();
                if (link.parentNode !== null)
                    link.parentNode.removeChild(link);
                context.mortgage!.isCostOfCreditDownloaded = true;
                this.setState({ refreshToggle: !this.state.refreshToggle });
            }
        });
    }

    render() {
        return (
            <div className={`text-start boxty grid box-teal-outline ${this.props.className ?? ''}`}>
                <h3 className="pb-3">Download Cost of Credit</h3>
                <p>This document shows you the overall costs of your mortgage and will show any additional costs for the deferred start you have requested.</p>
                <p className='mt-3'>Please review this document and let us know if you change your mind about using Deferred start.</p>
                <button className="btn fw big btn-light next-secondary pe-5 mt-3" onClick={this.download}>Download</button>
            </div>
        );
    }
}




