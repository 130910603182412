import { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Form, Row } from 'react-bootstrap';
import axios from 'axios';
import { UserContextComponent } from '../components/UserContextComponent';
import { Navigate, useNavigate } from 'react-router-dom';
import { NotFound } from './NotFound';

export class ImpersonateContact extends UserContextComponent<any, any> {
    constructor(props: any) {
        super(props);
        const searchParams = new URLSearchParams(document.location.search);
        let contactId = searchParams.get('contactId') ?? "";
        this.state = { impersonated: false, contact: contactId, loading: false, autoImpersonate: contactId!="" };
        this.impersonateContact = this.impersonateContact.bind(this);
        this.onChange = this.onChange.bind(this);
        this.checkIsImpersonated = this.checkIsImpersonated.bind(this);
    }

    componentDidMount() {

        // axios.defaults.baseURL = process.env.REACT_APP_API_URL;
        // axios.get('/Contacts/GetCaptchaPublicKey')
        //     .then((response) => {
        //         this.setState({ captchaKey: response.data, loading: false });
        //     });
    }

    onChange(value: string) {
        this.setState({ contact: value });
    }

    impersonateContact() {
        if (this.state.contact === "" || this.state.contact === null) {
            return;
        }
        sessionStorage.setItem("imp", "");

        axios.defaults.baseURL = process.env.REACT_APP_API_URL;
        axios
            .post('/Contacts/impersonate', null, { params: { id: this.state.contact } })
            .then(() => {
                let context = this.getContext();
                context.impersonatedId = this.state.contact;
                //context.load
                context.reloadMortgage();
                this.setState({loading: true});
                setTimeout(this.checkIsImpersonated, 500); 
            }
            );

    }

    checkIsImpersonated() {
        let context = this.getContext();
        if (context.contact?.contactId === context.impersonatedId) {
            context.impersonatedId = this.state.contact;
            this.setState({ impersonated: true, loading: false });
            return;
        }

        setTimeout(this.checkIsImpersonated, 500);
    }

    render() {
        let context = this.getContext();
        if (!this.state.impersonated && (context.contact === null || context.contact.isAdmin !== true)) {
            return <NotFound />
        }

        if (this.state.autoImpersonate) {
            this.impersonateContact();
        }

        if (this.state.loading)
            return <p><em>Loading...</em></p>;

        return (
            <Container className="ps-5">
                <Row className="page-title" style={{ textAlign: "center" }}>
                    <h1>Admin</h1>
                </Row>
                {this.state.impersonated === false &&
                    <Row>
                        <Col className="col-2"></Col>
                        <Col className="col-8">
                            <p><span>Id:</span></p>
                            <Form.Control type="text" defaultValue={this.state.contact} onChange={e => {this.onChange(e.target.value); }} />
                        </Col>
                        <Col className="col-2"></Col>
                    </Row>
                }
                <Row style={{ textAlign: "center", padding: "20px" }}>
                    {this.state.impersonated === false && this.state.autoImpersonate === false &&
                        <p>
                            <button className="btn btn-standard btn-wide200" onClick={this.impersonateContact}>Go</button>
                        </p>
                    }
                    {this.state.autoImpersonate &&
                        <p>
                            <button disabled className="btn btn-standard btn-wide200" >Impersonating...</button>
                        </p>
                    }
                    {this.state.impersonated === true &&
                        <Navigate to="/" />
                        //<p className='subtext'><span style={{ color: 'rgba(51, 204, 153, 1)' }} className="fa-solid fa-check fa-xl"></span> Request sent</p>
                    }
                </Row>
            </Container>
        );
    }
}
