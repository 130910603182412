import { Col, Form, Modal, Row } from "react-bootstrap";
import { IFileItem } from "../DocumentManager";
import { UserContextComponent } from "../UserContextComponent";
import * as Icons from "react-bootstrap";
import { PortalTransactionCommentsModel } from "../../models/PortalTransactionCommentsModel";
import axios from "axios";
import { logger } from "../../helper/Logger";
import DraggableModal from "../DraggableModal";
import FormattedDate from "../FormattedDate";

interface ITransactionCommentProps {
    item:IFileItem;
    mortgageId:string;
}

interface ITransactionCommentState {
    showTransactionComments?: boolean;
    currentTransaction?: PortalTransactionCommentsModel;
    currentComment?: string;
    showReply?: boolean;
    transactions:PortalTransactionCommentsModel[]|null;
}

export class TransactionComments extends UserContextComponent<ITransactionCommentProps, ITransactionCommentState> {

    constructor(props: ITransactionCommentProps) {
        super(props);

        this.saveComment = this.saveComment.bind(this);
        this.loadComments = this.loadComments.bind(this);
        this.state = {transactions: null};
    }

    saveComment() {
        // Send in a null for the pin if not updated. Sending in a pin will trigger the automatic file unlock which clears the Comments on success, and we only want that when a pin changes.
        axios.post('/DocumentTransactions', null, { params: { mortgageId: this.props.mortgageId, transactionId: this.state.currentTransaction?.transactionId, customerComment: this.state.currentComment ?? '' } })
            .then(response => {
                this.setState({ showReply: false });
                this.state.currentTransaction!.customerComment = this.state.currentComment ?? '';
            })
            .catch((e) => {
                logger.log(e);
            });
    }

    loadComments() {
        if (this.props.item.transactionComments) {
            this.setState({transactions: this.props.item.transactionComments});
            return;
        }

        axios.get('/DocumentTransactions', { params: { mortgageId: this.props.mortgageId, mortgageDocumentId: this.props.item.mortgageDocumentId, fileName: this.props.item.name  } })
            .then(response => {
                this.setState({ transactions: response.data ?? [] });
                this.props.item.transactionComments = response.data;
            })
            .catch((e) => {
                logger.log(e);
            });
        
    }

    render():any {
        var item = this.props.item;

        // Lazy load any comments
        if (!this.state.transactions) {
            this.loadComments();
            return <h6>Loading... <Icons.Spinner size="sm" animation="border" role="status" /></h6>;
        } else if (this.state.transactions.length === 0) {
            return <></>;
        }
        var content = <></>;
        if (this.state.showTransactionComments) {
            content = <>
                {this.state.transactions.map(transaction => {
                    return <div className="transaction-comment">
                    <h6><FormattedDate date={transaction.date} monthFormat={'numeric'}/> - {transaction.description}</h6>
                    <h6>{transaction.amount}</h6>
                    <h6><strong>{transaction.comments}</strong>
                    <div className="link mt-3" onClick={() => this.setState({showReply: true, currentComment:transaction.customerComment, currentTransaction:transaction})}><i className="fa-solid fa-reply"></i> reply</div>
                    </h6>
                    </div>
                })}
                <DraggableModal 
                    show={this.state.showReply} 
                    onHide={() => { this.setState({showReply: false}); }} 
                    title={""}
                    body={
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Reply</Form.Label>
                                <Form.Control onMouseDown={(e) => {e.stopPropagation()}} as="textarea" rows={3} defaultValue={this.state.currentComment} onChange={e => this.setState({ currentComment: e.target.value })} autoFocus />
                            </Form.Group>
                        </Form>
                    }
                    footer={
                        <>
                        <button className="btn btn-light" onClick={() => this.setState({showReply: false})}>
                            Close
                        </button>
                        <button className="btn btn-standard" onClick={() => this.saveComment()}>
                            Save
                        </button>
                        </>
                    }
                />
            </>;
        }

        return <>
        <div className="link" onClick={(e) => {this.setState({showTransactionComments: !this.state.showTransactionComments});}}><h6>{this.state.showTransactionComments ? 'Hide transaction queries' : 'Show transaction queries'}</h6></div>
        {content}
        </>;
    }
}