import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../pages/Applicant.css';
import { UserContextComponent } from '../UserContextComponent';
import { Link } from 'react-router-dom';
import { MortgageType } from '../../models/PortalMortgageModel';


export class AppointSolicitorTile extends UserContextComponent<any, any> {

    constructor(props: any) {
        super(props);
    }

    render() {
        var context = this.getContext();

        return (
            <div className="text-start tall boxty grid box-teal-outline m-3">
                <h3 className="pb-3">You need to appoint a Solicitor</h3>
                {context.mortgage!.type === MortgageType.PurchasingaNewHome
                    ? <p>When you go sale agreed, you will need to provide solicitor details for conveyancing.</p>
                    : <p>You will need to provide solicitor details for conveyancing.</p>
                }
                <p className='mt-3'>Don’t delay! Add your solicitor now, or engage <strong>Beam</strong>, our online conveyancing partner.</p>
                <Link to="/solicitor-details" className=" a-s-e"><button className="btn fw big btn-light next-secondary pe-5 mt-3">Add solicitor</button></Link>
            </div>
        );
    }
}




