import { Component } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Tooltip } from "../components/Tooltip";
import { ControlType, DataType, Field } from "../models/PortalMetadata";
import DatePicker from "react-date-picker";
import "./PlusMinusBox.css"

interface IFormControlState {
    isDirty: boolean;
    value: string | number | undefined,
    lookupValues: []
}

interface IFormControlProp {
    field: Field;
    icon?: string;
    fieldOnly?: boolean;
    customLabel?: string;
    fieldUpdated?(): void;
    isRequired?: boolean;
    isInvalid?(value: string | number | undefined): boolean;
    invalidText?: string;
    alignLeft?: boolean | undefined;
    light?: boolean | undefined;
    readOnly?: boolean;
    extraClasses?: string;
}

export class FormControl extends Component<IFormControlProp, IFormControlState> {
    constructor(props: any) {
        super(props);
        this.state = { isDirty: false, value: props.value, lookupValues: [] };

        this.isInvalid = this.isInvalid.bind(this);
    }

    componentDidMount() {
        this.handleIsDirty = this.handleIsDirty.bind(this);
    }

    handleIsDirty(event: any) {
        if (this.props.field.controlType === ControlType.date) {
            this.props.field.dateValue = new Date(event);
            this.setState({ isDirty: true, value: event });
            this.props.field.isDirty = true;
            this.props.field.value = event;
        }
        else {
            const target = event.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;

            if (this.props.field.originalValue !== value) {
                this.setState({ isDirty: true, value: value });
                this.props.field.isDirty = true;
                this.props.field.value = value;
            }
        }
    }

    isInvalid() {
        if (this.props.isInvalid) {
            return this.props.isInvalid(this.props.field.value);
        }
        if (this.props.field.controlType === ControlType.currency && this.props.field.minValue) {
            var v = parseFloat((this.props.field.value || "").toString().replace("€", "").replace(/,/g, ''));
            return v < this.props.field.minValue;
        }
        return false;
    }

    increment() {
        let v = 0;
        if (this.props.field.value == null) {
            v = 0;
        } else {
            v = this.props.field.value as number;
        }
        v++;
        if (this.props.field.handleChange)
            this.props.field.handleChange({ target: { name: this.props.field.name, value: v } });
    }

    decrement() {
        let v = 0;
        if (this.props.field.value == null) {
            v = 0;
        } else {
            v = this.props.field.value as number;
        }
        if (v > 1)
            v--;
        if (this.props.field.handleChange)
            this.props.field.handleChange({ target: { name: this.props.field.name, value: v } });
    }

    render() {
        var extraClasses = this.props.extraClasses ?? "";
        if (this.props.alignLeft) extraClasses += "text-start";
        if (this.props.icon) extraClasses += " pl-5-imp";
        if (this.props.light) extraClasses += " modal-control";
        if (this.props.light && this.props.field.controlType === ControlType.date) extraClasses += " form-date";
        const val = (
            <>
                {this.props.field.controlType === ControlType.text &&
                    <Form.Control id={this.props.field.name} readOnly={this.props.readOnly} className={extraClasses} htmlSize={1} name={this.props.field.name} placeholder={this.props.field.placeholder ?? ""} type="text"
                        value={this.props.field.value} maxLength={this.props.field.maxLength}
                        onChange={e => { this.handleIsDirty(e); if (this.props.field.handleChange) this.props.field.handleChange(e); if (this.props.fieldUpdated) this.props.fieldUpdated(); }}
                        isInvalid={this.isInvalid()} />
                }
                {this.props.field.controlType === ControlType.currency &&
                    <Form.Control id={this.props.field.name} readOnly={this.props.readOnly} className={extraClasses} htmlSize={1} name={this.props.field.name} placeholder={this.props.field.placeholder ?? ""} type="text"
                        value={this.props.field.value} maxLength={this.props.field.maxLength}
                        onChange={e => { this.handleIsDirty(e); if (this.props.field.handleChange) this.props.field.handleChange(e); if (this.props.fieldUpdated) this.props.fieldUpdated(); }} 
                        isInvalid={this.isInvalid()} />
                }
                {this.props.field.controlType === ControlType.textarea &&
                    <Form.Control id={this.props.field.name} readOnly={this.props.readOnly} className={extraClasses} name={this.props.field.name} as="textarea" placeholder={this.props.field.placeholder ?? ""} type="text"
                        value={this.props.field.value} maxLength={this.props.field.maxLength}
                        onChange={e => { this.handleIsDirty(e); if (this.props.field.handleChange) this.props.field.handleChange(e); }} />
                }
                {this.props.field.controlType === ControlType.picklist &&
                    <>
                        {this.props.field.dataType === DataType.boolean &&
                            <Form.Select id={this.props.field.name} className={extraClasses} name={this.props.field.name} placeholder={this.props.field.placeholder ?? ""} value={convertToBool(this.props.field.value)} onChange={e => { this.handleIsDirty(e); if (this.props.field.handleChange) this.props.field.handleChange(e); }}>
                                <option></option>
                                {this.props.field.options !== null && this.props.field.options.map(opt => (
                                    <option key={opt.value} value={opt.value} >{opt.text}</option>
                                ))}
                            </Form.Select>}
                        {this.props.field.dataType !== DataType.boolean &&
                            <Form.Select id={this.props.field.name} className={extraClasses} name={this.props.field.name} placeholder={this.props.field.placeholder ?? ""} value={this.props.field.value} onChange={e => { this.handleIsDirty(e); if (this.props.field.handleChange) this.props.field.handleChange(e); }}>
                                <option></option>
                                {this.props.field.options !== null && this.props.field.options.map(opt => (
                                    <option key={opt.value} value={opt.value} >{opt.text}</option>
                                ))}
                            </Form.Select>}
                    </>
                }
                {this.props.field.controlType === ControlType.lookup &&
                    <Form.Select className={extraClasses} name={this.props.field.name}
                        placeholder={this.props.field.placeholder ?? ""} value={this.props.field.value} onChange={e => { this.handleIsDirty(e); if (this.props.field.handleChange) this.props.field.handleChange(e); }}>
                        <option value={"-"}></option>
                        {this.props.field.lookupValues !== null && this.props.field.lookupValues.map((opt: any) => (
                            <option key={opt.value} value={opt.value}  >{opt.text}</option>
                        ))}
                    </Form.Select>
                }
                {this.props.field.controlType === ControlType.date &&
                    <DatePicker className={extraClasses} name={this.props.field.name} value={this.props.field.dateValue ?? undefined} onChange={
                        (e: Date) => {
                            this.handleIsDirty(e);
                            if (this.props.field.handleChange)
                                this.props.field.handleChange({ target: { name: this.props.field.name, value: e } });
                        }}
                        //isInvalid={this.isInvalid()}
                        minDate={this.props.field.minValue}
                        maxDate={this.props.field.maxValue}
                        clearIcon={null}
                        calendarIcon={null}
                        format="d/M/yyyy"
                    />
                }

                {/* {this.props.field.controlType === ControlType.date && 
                    <DatePickerControl className={extraClasses} name={this.props.field.name} placeholder={this.props.field.placeholder ?? ""} value={this.props.field.dateValue} onChange={
                        (e: ChangeEvent<Element>) => {
                            this.handleIsDirty(e);
                            if (this.props.field.handleChange)
                                this.props.field.handleChange({ target: { name: this.props.field.name, value: e } });
                        }}
                         isInvalid={this.isInvalid()}
                         />
                    } */}
                {this.props.field.controlType === ControlType.number &&
                    <Form.Control id={this.props.field.name} readOnly={this.props.readOnly} className={extraClasses} htmlSize={1} name={this.props.field.name} placeholder={this.props.field.placeholder ?? ""} type="number" value={this.props.field.value}
                        min={this.props.field.minValue} max={this.props.field.maxValue} maxLength={8}
                        onChange={e => { this.handleIsDirty(e); if (this.props.field.handleChange) this.props.field.handleChange(e); }} /> // add validation for number
                }
                {this.props.field.controlType === ControlType.numberWithControls &&
                    <div className="plus-minus-box-container">
                        <div className="plus-minus-box-component" style={{ flexGrow: "0" }}>
                            <i className="far fa-square-minus fa-2xl" onClick={(e) => { this.decrement(); }}></i>
                        </div>
                        <div className="plus-minus-box-component" style={{ flexGrow: "2", textAlign: "center" }}>
                            <Form.Control id={this.props.field.name} className={extraClasses} htmlSize={1} name={this.props.field.name} placeholder={this.props.field.placeholder ?? ""} type="number" value={this.props.field.value}
                                min={this.props.field.minValue} max={this.props.field.maxValue} maxLength={8}
                                onChange={e => { this.handleIsDirty(e); if (this.props.field.handleChange) this.props.field.handleChange(e); }} />
                        </div>
                        <div className="plus-minus-box-component" style={{ flexGrow: "0" }}>
                            <i className="far fa-square-plus fa-2xl" onClick={(e) => { this.increment(); }}></i>
                        </div>
                    </div>
                }
                {this.props.field.controlType === ControlType.decimal &&
                    <Form.Control id={this.props.field.name} readOnly={this.props.readOnly} className={extraClasses} htmlSize={1} name={this.props.field.name} placeholder={this.props.field.placeholder ?? ""} type="number" value={this.props.field.value}
                        min={this.props.field.minValue} max={this.props.field.maxValue}
                        onChange={e => { this.handleIsDirty(e); if (this.props.field.handleChange) this.props.field.handleChange(e); }} /> // add validation for decimal
                }
            </>
        );

        if (this.props.fieldOnly === true) {
            return (
                <>
                    {val}
                    {this.props.invalidText !== null &&
                        <Form.Control.Feedback type="invalid">
                            {this.props.invalidText}
                        </Form.Control.Feedback>
                    }
                </>
            );
        }
        return (
            <Form.Group className="mb-2 text-start" >
                <Tooltip tooltip={this.props.field.tooltip} >
                    <InputGroup className="pill-input" hasValidation style={{ display: "block"}}>
                        {this.props.icon &&
                            <InputGroup.Text>
                                <i style={{ paddingRight: '10px' }} className={`${this.props.icon} control-icon`}></i>{this.props.customLabel ? this.props.customLabel : this.props.field.label}
                                {/* {this.props.isRequired && <span style={{ color: 'red', textIndent: '4px' }}>*</span>} */}
                            </InputGroup.Text>
                        }
                        {!this.props.icon &&
                            <InputGroup.Text>
                                {this.props.customLabel ? this.props.customLabel : this.props.field.label}
                                {/* {this.props.isRequired && <span style={{ color: 'red', textIndent: '4px' }}>*</span>} */}
                            </InputGroup.Text>
                        }
                        {val}
                        {this.props.invalidText !== null &&
                            <Form.Control.Feedback type="invalid"  style={{paddingBottom: 25}}>
                                {this.props.invalidText}
                            </Form.Control.Feedback>
                        }
                    </InputGroup>
                </Tooltip>
            </Form.Group >);
    };
};

export function convertToBool(val: any) {
    return val;
}

export function convertToDate(val: any) {
    if (val === null)
        return undefined;
    else return val.toISOString();
}