import axios from "axios";
import { Col, Container, Modal, Nav, Navbar, Row, Stack} from "react-bootstrap";
import UserContextState, { UserContext } from "./UserContext";
import { Link, Navigate } from "react-router-dom";
import logo from '../doddl-brand-logo.svg';
import { MortgageStatus, MortgageType, YesNoBlank } from "../models/PortalMortgageModel";
import { UserContextComponent } from "./UserContextComponent";
import React from "react";
import { logger } from "../helper/Logger";
import { SharedFieldsCollection } from "../forms/SharedFields";
import { TimelineItemStatus } from "./Timeline";
import { InsuranceQuoteButton } from "./AIP/InsuranceQuoteButton";
import * as Icons from "react-bootstrap";
import { AIPDDLStatus, AIPType, PortalAIPApplicationModel } from "../models/PortalAIPApplication";
import { FormControl } from "./FormControl";
import { PortalContactModel } from "../models/PortalContactModel";
import FormattedDate from "./FormattedDate";

interface IApplicantMenu {
    Applicant:any,
    Personal: any,
    Residential: any,
    Employment: any,
    Financial: any,
    AssetsAndLoans: any
}

interface ISideNavState {
    loading: boolean;
    isCompleted: boolean;
    activeKey: string;
    activeAppKey: string | null;
    applicants: PortalContactModel[];
    show: boolean;
    expanded?:boolean;
    stageSelected?: number;
    refreshToggle?: boolean;
    aipStatus?: any;
    conditions?: any;
    clarifications?: any;
    conditionsLoaded?: boolean;
    redirectToPropertyPage?: boolean;
    savingCloseDate?: boolean;
    sharedFields: SharedFieldsCollection;
    closeDateCollapsed: boolean;
    showSaleAgreedModal: boolean;
    savingSaleAgreed?: boolean;
}

export class SideNav extends UserContextComponent<any, ISideNavState> {
    static contextType = UserContext;

    static menu = {
        Dashboard: {Title: "Dashboard", Key: "/dashboard"}, 
        Profile: {Title: "Apply", Key: "/profile"}, 
        // Property: {Title: "Property Details", Key: "/property"}, 
        AIP: {Title: "Approval", Key: "/aip-application"}, 
        Completion: {Title: "Completion", Key: "/completion"},
        SharedDetails: {Title: "SharedDetails", Key: "/shared-details"}, 
        Documents: {Title: "Documents", Key: "/documents"}, 
        LenderConfirmation: {Title: "Lender Confirmation", Key: "/lender-confirmation"}, 
    };

    static appMenu = {
        Dashboard: {Title: "Dashboard", Key: "/dashboard", ParentKey: this.menu.Profile.Key, Stage: 0, step: 0}, 

        // Profile / Applicant 1 and Applicant 2 menus
        GetMortgageApproval: {Title: "Get mortgage approval", Key: "GetMortgageApproval", ParentKey: this.menu.Profile.Key, Stage: 1, Step: 0},
        // Applicant 1
        Applicant1: {Title: "Applicant1", Key: "/personal1", ParentKey: this.menu.Profile.Key, Stage: 1, step: 0, Icon: "far fa-user"}, 
        Personal1: {Title: "Personal information", Key: "/personal1", ParentKey: this.menu.Profile.Key, Stage: 1, step: 1, Icon:"far fa-address-book"}, 
        Residential1: {Title: "Housing history", Key: "/residential1", ParentKey: this.menu.Profile.Key, Stage: 1, step: 2, Icon:"fas fa-clock-rotate-left"}, 
        Employment1: {Title: "Employment & income", Key: "/employment1", ParentKey: this.menu.Profile.Key, Stage: 1, step: 3, Icon: "far fa-money-bill-1"}, 

        //Financial1: {Title: "Assets, savings & loans", Key: "/financial1", ParentKey: this.menu.Profile.Key, Stage: 1, step: 4, Icon: "fas fa-briefcase"}, 
        Financial1: {Title: "Bank Details", Key: "/financial1", ParentKey: this.menu.Profile.Key, Stage: 1, step: 4, Icon: "fas fa-building-columns"}, 
        AssetsAndLoans1: {Title: "Assets & loans", Key: "/assetsandloans1", ParentKey: this.menu.Profile.Key, Stage: 1, step: 5, Icon: "fas fa-briefcase"}, 

        // Applicant 2
        Applicant2: {Title: "Applicant2", Key: "/personal2", ParentKey: this.menu.Profile.Key, Stage: 2, step: 0, Icon: "far fa-user"},
        Personal2: {Title: "Personal information", Key: "/personal2", ParentKey: this.menu.Profile.Key, Stage: 2, step: 1, Icon:"far fa-address-book"}, 
        Residential2: {Title: "Housing history", Key: "/residential2", ParentKey: this.menu.Profile.Key, Stage: 2, step: 2, Icon:"fas fa-clock-rotate-left"}, 
        Employment2: {Title: "Employment & income", Key: "/employment2", ParentKey: this.menu.Profile.Key, Stage: 2, step: 3, Icon: "far fa-money-bill-1"}, 

        //Financial2: {Title: "Assets, savings & loans", Key: "/financial2", ParentKey: this.menu.Profile.Key, Stage: 2, step: 4, Icon: "fas fa-briefcase"},
        Financial2: {Title: "Bank Details", Key: "/financial2", ParentKey: this.menu.Profile.Key, Stage: 2, step: 4, Icon: "fas fa-building-columns"},
        AssetsAndLoans2: {Title: "Assets & loans", Key: "/assetsandloans2", ParentKey: this.menu.Profile.Key, Stage: 2, step: 5, Icon: "fas fa-briefcase"},

        // Shared profile info
        MortgageDetails: {Title: "Mortgage requirement", Key: "/shared", ParentKey: this.menu.Profile.Key, Stage: 3, step: 0, Icon: "fas fa-coins"},
        Mortgage: {Title: "Mortgage requirement", Key: "/shared", ParentKey: this.menu.Profile.Key, Stage: 3, step: 1, Icon: "fas fa-coins"},
        Documents: {Title: "Your documents", Key: "/documents", ParentKey: this.menu.Profile.Key, Stage: 3, step: 1, Icon: "far fa-folder-open"},
        
        // Profile part 2
        GetReadyForSaleAgreed: {Title: "Get ready for going Sale Agreed!", Key: "GetReadyForSaleAgreed", ParentKey: this.menu.Profile.Key, Stage: 3, Step: 0},
        OtherImportantInfo: {Title: "Other important information required:", Key: "OtherImportantInfoReq", ParentKey: this.menu.Profile.Key, Stage: 3, Step: 0},
        Property: {Title: "Add property details", Key: "/property", ParentKey: this.menu.Profile.Key, Stage: 3, step: 2, Icon: "fas fa-home"},
        Solicitor: {Title: "Appoint your solicitor", Key: "/solicitor-details", ParentKey: this.menu.Profile.Key, Stage: 3, step: 3, Icon: "fas fa-gavel", NoHover: true},
        LifeCover: {Title: "Get life cover", Key: "#LifeCover", ParentKey: this.menu.Profile.Key, Stage: 3, step: 4, Icon: "fas fa-heart-pulse", NoHover: true},
        SaleAgreed: {Title: "Are you Sale Agreed?", Key: "#SaleAgreed", ParentKey: this.menu.Profile.Key, Stage: 3, Step: 5, Icon: "far fa-handshake"},
        LenderConfirmation: {Title: "Lender Confirmation", Key: "/lender-confirmation", ParentKey: this.menu.Profile.Key, Stage: 5, step: 1},

        // API Menus (it shares the "Get mortgage Approval" header and the "Other important info" sub menu)
        Profile: {Title: "Complete application", Key: "/profile", ParentKey: this.menu.Profile.Key, Stage: 0, step: 0}, 
        AIP: {Title: "Get AIP", Key: this.menu.AIP.Key, ParentKey: this.menu.AIP.Key, Stage: 6, step: 1},

        // Completion Menus / Checklist items
        Completion: {Title: this.menu.Completion.Title, Key: this.menu.Completion.Key, ParentKey: this.menu.Completion.Key, Stage: 7, step: 1},
        SaleAgreedCompletions: {Title: "Go sale agreed", Key: "#SaleAgreed", ParentKey: this.menu.Profile.Key, Stage: 3, Step: 5, Icon: "fa fa-child-reaching"},
        ComleteOnYourMortgage: {Title: "Complete on your mortgage", Key: this.menu.Completion.Key, ParentKey: this.menu.Completion.Key, Stage: 7, step: 2}, 
        SecureMortgage: {Title: "Secure mortgage offer", Key: this.menu.Completion.Key, ParentKey: this.menu.Completion.Key, Stage: 7, step: 2}, 
        ConfirmChosenLender: {Title: "Confirm chosen lender", Key: 'ConfirmChosenLender', ParentKey: this.menu.Completion.Key, Stage: 1, step: 1, Icon:"far fa-circle-check"}, 
        GetLetterOfOffer: {Title: "Get letter of offer", Key: 'GetLetterOfOffer', ParentKey: this.menu.Completion.Key, Stage: 1, step: 1, Icon:"far fa-envelope"}, 
        DownloadEsis: {Title: "Download ESIS", Key: 'DownloadEsis', ParentKey: this.menu.Completion.Key, Stage: 1, step: 1, Icon:"fa fa-earth-europe"}, 
        DownloadCostOfCredit: {Title: "Download Cost of Credit", Key: 'DownloadCostOfCredit', ParentKey: this.menu.Completion.Key, Stage: 1, step: 1, Icon:"fa fa-coins"}, 
        CompletionDocs: {Title: "Mortgage completion docs", Key: '/documents' /*'CompletionDocs'*/, ParentKey: this.menu.Completion.Key, Stage: 3, step: 1, Icon:"fa fa-book"}, 
        ConfirmSolicitorHasDeeds: {Title: "Confirm solicitor has title deeds", Key: 'ConfirmSolicitorHasDeeds', ParentKey: this.menu.Completion.Key, Stage: 1, step: 1, Icon:"far fa-bookmark"}, 
        FinalSteps: {Title: "Final steps", Key: 'FinalSteps', ParentKey: this.menu.Completion.Key, Stage: 7, step: 2}, 
        MeetSolicitor: {Title: "Meet solicitor for signing", Key: 'MeetSolicitor', ParentKey: this.menu.Completion.Key, Stage: 1, step: 1, Icon:"far fa-handshake"}, 
        AgreeClosing: { Title: "Current estimated closing", Key: 'AgreeClosing', ParentKey: this.menu.Completion.Key, Stage: 1, step: 1, Icon:"far fa-calendar-plus"}, 
        FundRelease: {Title: "Bank releases funds", Key: 'FundRelease', ParentKey: this.menu.Completion.Key, Stage: 1, step: 1, Icon:"fa fa-flag-checkered"}, 
    };

    constructor(props: any) {
        super(props);
        this.refreshMenuState = this.refreshMenuState.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.titleCol = this.titleCol.bind(this);
        this.fullTitleCol = this.fullTitleCol.bind(this);
        this.percCol = this.percCol.bind(this);
        this.renderOtherItemsOfImportanceMenu = this.renderOtherItemsOfImportanceMenu.bind(this);
        this.renderApplicantMenu = this.renderApplicantMenu.bind(this);
        this.saleAgreedNav = this.saleAgreedNav.bind(this);
        this.confirmTitleDeeds = this.confirmTitleDeeds.bind(this);
        this.metWithSolicitor = this.metWithSolicitor.bind(this);
        this.setClientAdvisedClosingDate = this.setClientAdvisedClosingDate.bind(this);

        let key = window.location.pathname.toLocaleLowerCase();
        
        this.state = { 
            loading: true, 
            applicants: [], 
            isCompleted: false, 
            show: false, 
            activeKey: key, 
            activeAppKey: SideNav.appMenu.Applicant1.Key,
            sharedFields: new SharedFieldsCollection(),
            closeDateCollapsed: true,
            showSaleAgreedModal: false,
        };

    }

    componentDidMount(): void {
        let context = this.getContext();
        context.refreshMenuState = this.refreshMenuState;
        this.state.sharedFields.loadData(context.mortgage, [], true, null);
        this.populateData();
    }

    componentDidUpdate() {
        if (this.state.applicants && this.state.applicants.length > 0)
            this.appMenuSetup();

        let key = window.location.pathname.toLocaleLowerCase();
        //if (key.startsWith("/applicant")) key = "/profile";
        let activeAppKey = this.findAppKey(key);
        if (activeAppKey) {
            if (activeAppKey.ParentKey !== this.state.activeKey || activeAppKey.Key !== this.state.activeAppKey) {
                this.setState({activeKey: activeAppKey.ParentKey, activeAppKey: activeAppKey.Key, stageSelected: activeAppKey.Stage});
            }
        } else if(this.state.activeKey !== key || this.state.activeAppKey !== null) {
            this.setState({activeKey: key, activeAppKey: null})
        }
    }

    findAppKey(key:string) {
        let appMenuAsArray = Object.values(SideNav.appMenu);
        let item = appMenuAsArray.filter((i:any) => i.Key === key);
        if (item.length > 0)
            return item[0];
        return null;
    }

    handleSelect(key:any){
        logger.log(key);
        this.setState({ activeKey: key, activeAppKey: key, expanded: false});
    };

    handleAppSelect(menuItem:any){
        logger.log(menuItem.Key);
        this.setState({ activeKey: menuItem.ParentKey, activeAppKey: menuItem.Key, stageSelected: menuItem.Stage });
    };

    handleStep(stage:number,activeKey:string){
        logger.log(stage.toString() + " " + activeKey);
        this.setState({ activeKey: activeKey, stageSelected: stage });
    };

    appMenuSetup(): void {
        let context = this.getContext();
        const applicants = this.state.applicants;
        let contact1 = context.mortgage?.primaryApplicant ?? applicants[0];
        let contact2 = context.mortgage?.secondaryApplicant ?? applicants[1];
        if (contact1) {
            SideNav.appMenu.Applicant1.Title = contact1.firstName;
        }
        if (contact2) {
            SideNav.appMenu.Applicant2.Title = contact2.firstName;
        }
    }

    refreshMenuState() {
        this.setState({refreshToggle: !this.state.refreshToggle});
    }

    async populateData() {
        const context = this.context as UserContextState;
        if (context.contact === null || !context.contact.mortgageId)
            return;

        axios
            .get("/mortgages/GetMortgageApplicants?mortgageId=" + context.contact.mortgageId)
            .then((response) => {
                this.appMenuSetup();
                this.setState({ applicants: response.data, loading: false });
            })
            .catch((error) => {
                if (error.response.statusCode === 401) {
                    alert("Unauthorized");
                }
            });

        axios
            .get("/AIPApplications/GetAIPApplicationsByMortgageId?mortgageId=" + context.contact.mortgageId)
            .then((response) => {
                let aips: PortalAIPApplicationModel[] = response.data;
                aips.forEach(e => {
                    e.clarifications = e.clarifications.filter(c => !c.completed);
                });
                var status = 0;
                if (aips.filter(a => [AIPDDLStatus.Proceeding, AIPDDLStatus.ApprovedByLender].includes(a.aipDdlStatus)).length > 0) {
                    status = 100;
                } else if (aips.filter(a => [AIPDDLStatus.Clarification, AIPDDLStatus.Submitted].includes(a.aipDdlStatus)).length > 0) {
                    status = 50;
                }

                this.setState({ aipStatus: status });
            });
    }

    async refresher() {
        const context = this.context as UserContextState;
        if (context.contact === null || !context.contact.mortgageId) {
            setTimeout(() => this.refresher(), 500);
        }
        this.populateData();
    }

    circle(progress: string) {
        if (progress === 'notstarted')
            return 'far fa-circle'
        if (progress === 'inprogress')
            return 'fas fa-circle'
        return 'fas fa-circle'
    }

    fullTitleCol(menuItem:any) {
        return <Col className={`pt-1 nav-text nav-title text-start nopointer`}>{menuItem.Icon ? <i className={`${menuItem.Icon} nav-icon-sub`}></i> : <></>} {menuItem.Title}</Col>;
    }

    titleCol(menuItem:any, activeCompletions?:any) {
        var stateClass='';
        var headersList=[
            SideNav.appMenu.GetMortgageApproval.Key, 
            SideNav.appMenu.GetReadyForSaleAgreed.Key, 
            SideNav.appMenu.OtherImportantInfo.Key,
            SideNav.appMenu.ComleteOnYourMortgage.Key,
            SideNav.appMenu.FinalSteps.Key,
        ];
        var noHoversList=[
            SideNav.appMenu.GetMortgageApproval.Key, 
            SideNav.appMenu.GetReadyForSaleAgreed.Key,
            SideNav.appMenu.OtherImportantInfo.Key,
            SideNav.appMenu.LifeCover.Key,
            SideNav.appMenu.SaleAgreed.Key,
            SideNav.appMenu.Property.Key,
            SideNav.appMenu.Solicitor.Key,
        ];

        if (menuItem.Key === this.state.activeAppKey && menuItem.ParentKey === this.state.activeKey && menuItem.Key !== SideNav.appMenu.Completion.Key) {
            // BANDAID - need to figure out a better way to do this
            if (menuItem.ParentKey != SideNav.menu.Profile.Key || menuItem.step != 0) {
                stateClass = 'selected';
            }
        }
        if (activeCompletions === menuItem.Key) {
            stateClass = 'selected';
        }
        if (headersList.includes(menuItem.Key)) {
            stateClass = 'header';
        }
        if (noHoversList.includes(menuItem.Key)) {
            stateClass += ' nohover nopointer';
        }
        if (menuItem.Key === SideNav.appMenu.Completion.Key || menuItem.ParentKey === SideNav.appMenu.Completion.Key) {
            stateClass += ' nohover';
        }
        return <Col className={`pt-1 nav-text text-start ${stateClass}`}>{menuItem.Icon ? <i className={`${menuItem.Icon} nav-icon-sub`}></i> : <></>} {menuItem.Title}</Col>;
    }
    percCol(value:number, useText?:boolean) {
        if (value > 100) {
            value = 100;
        }
        var stateClass='';
        if (value >= 100) {
            stateClass = 'completed';
        } else if (value > 0) {
            stateClass = 'inprogress';
        }
        var text = <>TODO</>;
        if (value >= 100) {
            text = <>DONE</>;
        } else if (value > 0) {
            if (useText) {
                text = <>STARTED</>;
            } else {
                text = <>{Math.round(value)}%</>;
            }
        }
        return <Col className={`nav-perc text-middle selected ${stateClass}`}>{text}</Col>;
    }

    textCol(value:any) {
        return <Col className={`nav-perc text-middle selected completed`}>{value}</Col>;
    }
    
    timelineCol(items: any, key:string, defaultToWaiting?:boolean) {
        var item:any = items.find((x:any) => x.key === key);
        var status = item?.status ?? null;

        let appMenu = SideNav.appMenu;
        if ([appMenu.GetMortgageApproval.Key,appMenu.ComleteOnYourMortgage.Key,appMenu.FinalSteps.Key, appMenu.OtherImportantInfo.Key].includes(key)) {
            return <Col className={`nav-perc-teal`}><>.</></Col>;
        }

        var stateClass='';
        var text = <><i className="fas fa-lock" /></>;
        if (status === TimelineItemStatus.Completed) {
            stateClass = 'completed';
            text = <>DONE</>;
        } else if (status  === TimelineItemStatus.Waiting) {
            stateClass = 'inprogress';
            text = <>WAITING</>
        } else if (status === TimelineItemStatus.ToDo) {
            text = <>TODO</>
        } else if (status === TimelineItemStatus.Inactive && defaultToWaiting) {
            stateClass = 'inprogress';
            text = <>WAITING</>
        }

        // If the status is passed inactive and it's a file download, then always display the download buttons for the item
        if (status !== TimelineItemStatus.Inactive){
            if (key === appMenu.DownloadEsis.Key) {
                text = <button className="btn mini btn-standard" title={"download"} onClick={() => this.downloadEsis()}>download</button>
                text = <div className="pointer" onClick={() => this.downloadEsis()}><i className="fa fa-cloud-download fa-lg" /></div>
            } else if (key === appMenu.DownloadCostOfCredit.Key) {
                text = <button className="btn mini btn-standard" title={"download"} onClick={() => this.downloadCostOfCredit()}>download</button>
                text = <div className="pointer" onClick={() => this.downloadCostOfCredit()}><i className="fa fa-cloud-download fa-lg" /></div>
            }
        }

        return <Col className={`nav-perc text-middle selected ${stateClass}`}>{text}</Col>;
    }
    
    buttonCol(contents?:any, menuItem?:any, activeCompletions?:any) {
        var classes = '';
        if (menuItem && (menuItem.Key === this.state.activeAppKey || activeCompletions === menuItem.Key)) {
            classes = 'selected'
        }
        return <Col className={`nav-btncol ${classes}`}>{contents??<>&nbsp;</>}</Col>;
    }
    
    formatDate(dateIn?: any): string {
        if(!dateIn) return '';
        let date = new Date(dateIn);
        return !date ? '' : date.getDate() + '/' + (date.getMonth() + 1) + '/' + (date.getFullYear());
    };

    confirmedChosenLender():boolean {
        let context = this.getContext();
        let mortgage = context.mortgage!;
        if (mortgage.confirmedChosenLender || mortgage.loanOfferRequested)
            return true;
        return false;
    }
    
    atLoanOfferConditions() {
        var mortgage = this.getContext().mortgage!;
        if (!mortgage.loanOfferRequested) {
            return true;
        }
        return false;
    }

    outstandingConditions() {
        if (this.state.conditions !== null && 
            this.state.conditions != undefined && 
            this.state.conditions.filter((c:any) => c.uploaded !== true && c.completed !== true).length > 0) {
            return true;
        }
        return false;
    }

    hasIncompleteConditions() {
        if (this.state.conditions !== null && 
            this.state.conditions != undefined && 
            this.state.conditions.filter((c:any) => c.completed !== true).length > 0) {
            return true;
        }
        return false;
    }

    atLoanOfferClarifications() {
        var mortgage = this.getContext().mortgage!;
        if (mortgage.status === MortgageStatus.PreLoanOfferClarification) {
            return true;
        }
        return false;
    }

    outstandingClarifications() {
        if (this.state.clarifications !== null && 
            this.state.clarifications !== undefined &&
            this.state.clarifications.filter((c:any) => c.uploaded !== true).length > 0) {
            return true;
        }
        return false;
    }

    waitingForOffer() {
        var mortgage = this.getContext().mortgage!;
        if (!this.atLoanOfferConditions() && !this.atLoanOfferClarifications() && !mortgage.loanOfferReceived) {
            return true;
        }
        return false;
    }

    loanOfferNoConditions() {
        var mortgage = this.getContext().mortgage!;
        if (mortgage.loanOfferReceived && this.state.conditionsLoaded && this.state.conditions?.length === 0) {
            return true;
        }
        return false;
    }

    loanOfferNoOutstandingConditions() {
        var mortgage = this.getContext().mortgage!;
        if (mortgage.loanOfferReceived && !this.outstandingConditions()) {
            return true;
        }
        return false;
    }

    downloadEsis() {
        const context = this.getContext();

        axios({
            url: `/Mortgages/DownloadESIS?mortgageId=${context.mortgage?.mortgageId}`,
            method: "GET",
            responseType: "blob"
        }).then(response => {
            if (response.data.size === 0) {
                //this.setState({downloadError: true});
            } else {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", 'ESIS.pdf');
                document.body.appendChild(link);
                link.click();
                if (link.parentNode !== null)
                    link.parentNode.removeChild(link);
                context.mortgage!.isEsisDownloaded = true;
                this.setState({ refreshToggle: !this.state.refreshToggle });
            }
        });
    }

    downloadCostOfCredit() {
        const context = this.getContext();

        axios({
            url: `/Mortgages/DownloadCostOfCredit?mortgageId=${context.mortgage?.mortgageId}`,
            method: "GET",
            responseType: "blob"
        }).then(response => {
            if (response.data.size === 0) {
                //this.setState({downloadError: true});
            } else {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", 'CostOfCredit.pdf');
                document.body.appendChild(link);
                link.click();
                if (link.parentNode !== null)
                    link.parentNode.removeChild(link);
                context.mortgage!.isCostOfCreditDownloaded = true;
                this.setState({ refreshToggle: !this.state.refreshToggle });
            }
        });
    }

    confirmTitleDeeds() {
        let context = this.getContext();
        context.mortgage!.confirmedTitleDeeds = YesNoBlank.Yes;
        this.setState({ refreshToggle: !this.state.refreshToggle });
        axios.post('/Mortgages/ConfirmTitleDeeds', null, { params: { mortgageId: context.mortgage?.mortgageId } })
            .then(response => {
                
            });
    }

    metWithSolicitor() {
        let context = this.getContext();
        context.mortgage!.metWithSolicitor = true;
        this.setState({ refreshToggle: !this.state.refreshToggle });
        axios.post('/Mortgages/MetWithSolicitor', null, { params: { mortgageId: context.mortgage?.mortgageId } })
            .then(response => {
            });
    }

    getCompletionSteps(): any {
        let context = this.getContext();
        let mortgage = context.mortgage!;
        let secureMortgageStatus = 0;
        let finalStepsStatus = null;

        let loanOfferStatus = TimelineItemStatus.Inactive;
        if (this.confirmedChosenLender()) {
            if (mortgage.loanOfferReceived) {
                loanOfferStatus = TimelineItemStatus.Completed;
            } else if (this.waitingForOffer()) {
                loanOfferStatus = TimelineItemStatus.Waiting;
            } else if (this.atLoanOfferConditions() && this.outstandingConditions()) {
                loanOfferStatus = TimelineItemStatus.ToDo;
            } else {
                loanOfferStatus = TimelineItemStatus.Waiting;
            }
        }

        let downloadESISStatus = TimelineItemStatus.Inactive;
        let downloadCostOfCreditStatus = TimelineItemStatus.Inactive;
        let isEsisAvailable = false;
        if (this.confirmedChosenLender() && mortgage.loanOfferReceived) {
            if (mortgage.isEsisDownloaded) {
                downloadESISStatus = TimelineItemStatus.Completed;
            } else if (mortgage.isEsisAvailable) {
                downloadESISStatus = TimelineItemStatus.ToDo;
            }
            if (mortgage.isCostOfCreditDownloaded) {
                downloadCostOfCreditStatus = TimelineItemStatus.Completed;
            } else if (mortgage.isCostOfCreditAvailable) {
                downloadCostOfCreditStatus = TimelineItemStatus.ToDo;
            }
        }

        let uploadDocsStatus = TimelineItemStatus.Inactive;
        if (loanOfferStatus === TimelineItemStatus.Completed) {
            if (this.outstandingConditions()) {
                uploadDocsStatus = TimelineItemStatus.ToDo;
            } else {
                uploadDocsStatus = TimelineItemStatus.Completed;
            }
        }

        let titleDeedsStatus = TimelineItemStatus.Inactive;
        if (uploadDocsStatus === TimelineItemStatus.Completed) {
            if (mortgage.confirmedTitleDeeds === YesNoBlank.Yes) {
                titleDeedsStatus = TimelineItemStatus.Completed;
            } else {
                titleDeedsStatus = TimelineItemStatus.ToDo;
            }
        } else {
            if (mortgage.confirmedTitleDeeds === YesNoBlank.Yes) {
                titleDeedsStatus = TimelineItemStatus.Completed;
            }
        }

        let appMenu = SideNav.appMenu;
        let items = [];
        //if (anyOutstanding) {
            //onClick={() => { this.setState({ navigateToSolicitor: true }) }}>Enter Solicitor Details
        let solicitor = context.mortgage?.solicitor;
        items.push({key: appMenu.ConfirmChosenLender.Key, status: this.confirmedChosenLender() ? TimelineItemStatus.Completed : TimelineItemStatus.ToDo});
        items.push({key: appMenu.GetLetterOfOffer.Key, status: loanOfferStatus});
        items.push({key: appMenu.DownloadEsis.Key, status: downloadESISStatus});
        items.push({key: appMenu.DownloadCostOfCredit.Key, status: downloadCostOfCreditStatus});
        items.push({key: appMenu.CompletionDocs.Key, status: uploadDocsStatus});
        if (mortgage.type === MortgageType.RemortgagingExistingLoan) {
            items.push({key: appMenu.ConfirmSolicitorHasDeeds.Key, status: titleDeedsStatus});
        }
        if (items.filter((i:any)=> i.status !== TimelineItemStatus.Completed).length === 0) {
            secureMortgageStatus = 100;
        } else if (items.filter((i:any)=> i.status !== TimelineItemStatus.ToDo && i.status !== TimelineItemStatus.Inactive).length > 0) {
            secureMortgageStatus = 100 / items.length * items.filter((i:any)=>i.status !== TimelineItemStatus.Completed).length;
        }
        items.push({key: appMenu.SecureMortgage.Key, status: secureMortgageStatus});

        let solicitorStatus = TimelineItemStatus.Inactive;
        let closingDateStatus = TimelineItemStatus.Inactive;
        let fundReleaseStatus = TimelineItemStatus.Inactive;
        if (mortgage.metWithSolicitor) {
            solicitorStatus = TimelineItemStatus.Completed;
        }
        if (mortgage.loanOfferReceived) {
            finalStepsStatus = 100;
            if (mortgage.metWithSolicitor) {
                if (mortgage.fundsReleaseDate) {
                    closingDateStatus = TimelineItemStatus.Completed;
                    if (new Date(mortgage.fundsReleaseDate) <= new Date()) {
                        fundReleaseStatus = TimelineItemStatus.Completed;
                    } else {
                        fundReleaseStatus = TimelineItemStatus.Waiting;
                    }
                } else if (mortgage.clientAdvisedClosingDate || mortgage.estimatedClosingDate) {
                    closingDateStatus = TimelineItemStatus.Waiting;
                    fundReleaseStatus = TimelineItemStatus.Waiting;
                } else {
                    closingDateStatus = TimelineItemStatus.ToDo;
                    fundReleaseStatus = TimelineItemStatus.ToDo;
                }
            } else {
                closingDateStatus = TimelineItemStatus.ToDo
                solicitorStatus = TimelineItemStatus.ToDo;
                fundReleaseStatus = TimelineItemStatus.ToDo;
            }
        }

        items.push({key: appMenu.FinalSteps.Key, status: finalStepsStatus});
        items.push({key: appMenu.MeetSolicitor.Key, status: solicitorStatus});
        items.push({key: appMenu.AgreeClosing.Key, status: closingDateStatus});
        items.push({key: appMenu.FundRelease.Key, status: fundReleaseStatus});
        return items;
    }
    
    getActiveCompletionStep(items:any[]) {
        let appMenu = SideNav.appMenu;
        // Start at 1 to skip the "Secure mortgage offer" step
        for (let index = 1; index < items.length; index++) {
            const item = items[index];
            if (item.key === appMenu.FinalSteps.Key) {
                return item.key;
            }
            if ([TimelineItemStatus.ToDo,TimelineItemStatus.Waiting].includes(item.status)) {
                return item.key;
            }
        }
        return null;
    }

    foundMyNewHome(): void {
        const context = this.context as UserContextState;
        axios.post('/Mortgages/SetSaleAgreed', null, { params: { mortgageId: context.contact?.mortgageId } })
            .then(response => {
                context.mortgage!.saleAgreed = true;
                this.setState({ redirectToPropertyPage: true, showSaleAgreedModal: false});
            });
    }

    setClientAdvisedClosingDate() {
        this.setState({ savingCloseDate: true });
        let date = new Date(this.state.sharedFields.getFieldByName("clientAdvisedClosingDate").value as string);
        let context = this.getContext();
        context.mortgage!.clientAdvisedClosingDate = date;

        this.setState({ refreshToggle: !this.state.refreshToggle, closeDateCollapsed: true, savingCloseDate: false });
        axios.post('/Mortgages/SetClientAdvisedClosingDate', null, { params: { mortgageId: context.mortgage?.mortgageId, date: date } })
            .then(response => {
                this.setState({ refreshToggle: !this.state.refreshToggle});
            });
    }

    // Used by multiple parts in different areas so pull it out as a function
    saleAgreedNav(showCompletionsText?: boolean) {
        let appMenu = SideNav.appMenu;
        let context = this.getContext();
        if (context.mortgage!.type === MortgageType.RemortgagingExistingLoan) {
            return <></>;
        }
        return <Nav.Item className="applicantnav-submenu2">
                <Nav.Link as={Link} 
                    to={appMenu.SaleAgreed.Key} 
                >
                    <div className="nav-item-div">
                        <Row>
                            {this.titleCol(showCompletionsText ? appMenu.SaleAgreedCompletions : appMenu.SaleAgreed)}
                            {context.mortgage!.saleAgreed
                                ? this.percCol(100, true)
                                : this.buttonCol(<button className='btn btn-light mini' onClick={() => {this.setState({showSaleAgreedModal:true})}}>NO</button>)
                            }
                        </Row>
                    </div>
                </Nav.Link>
            </Nav.Item>;
    }

    renderApplicantMenu(applicant: any, stageSelected: number) {
        const context = this.getContext();
        let mortgage = context.mortgage!;
        var applicantStage;

        var percentageOfCompletion: number = (applicant.personalInfoPercentage + applicant.residentialInfoPercentage + applicant.employmentInfoPercentage + applicant.assetsInfoPercentage) / 4;
        var applicantMenu:IApplicantMenu;
        if (applicant.contactId === mortgage.primaryApplicant.contactId) {
            applicantStage = 1;
            applicantMenu = {
                Applicant: SideNav.appMenu.Applicant1,
                Personal: SideNav.appMenu.Personal1,
                Residential: SideNav.appMenu.Residential1,
                Employment: SideNav.appMenu.Employment1,
                Financial: SideNav.appMenu.Financial1,
                AssetsAndLoans: SideNav.appMenu.AssetsAndLoans1
            }
        } else {
            applicantStage = 2;
            applicantMenu = {
                Applicant: SideNav.appMenu.Applicant2,
                Personal: SideNav.appMenu.Personal2,
                Residential: SideNav.appMenu.Residential2,
                Employment: SideNav.appMenu.Employment2,
                Financial: SideNav.appMenu.Financial2,
                AssetsAndLoans: SideNav.appMenu.AssetsAndLoans2
            }
        }

        return <>
            <Nav.Item className="applicantnav-submenu" title={applicantMenu.Applicant.Title}>
                <Nav.Link as={Link} to={applicantMenu.Applicant.Key} 
                    onClick={(event) => {this.setState({expanded: false}); this.handleAppSelect(applicantMenu.Applicant);}}>
                    <div className="nav-item-div">
                        <Row>
                            {this.titleCol(applicantMenu.Applicant)}
                            {this.percCol(percentageOfCompletion)}
                        </Row>
                    </div>
                </Nav.Link>
            </Nav.Item>
            {stageSelected === applicantStage &&
            <>
                <Nav.Item className="applicantnav-submenu2">
                    <Nav.Link as={Link} to={applicantMenu.Personal.Key} 
                        className={this.state.activeAppKey === applicantMenu.Personal.Key || this.state.activeAppKey === applicantMenu.Applicant.Key ? (applicant.personalInfoPercentage??0) < 100 ? "applicantnav-link-selected" : "applicantnav-link-selected-done" : ""}
                        onClick={(event) => {this.setState({expanded: false}); if (this.props.setStep) this.props.setStep(1); this.handleAppSelect(applicantMenu.Personal);}}
                    >
                        <div className={`nav-item-div ${this.state.activeAppKey === applicantMenu.Personal.Key && 'selected'}`}>
                            <Row>
                                {this.titleCol(applicantMenu.Personal)}
                                {this.percCol(applicant.personalInfoPercentage)}
                            </Row>
                        </div>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item className="applicantnav-submenu2">
                    <Nav.Link as={Link} to={applicantMenu.Residential.Key} className={this.state.activeAppKey === applicantMenu.Residential.Key ? (applicant.residentialInfoPercentage??0) < 100 ? "applicantnav-link-selected" : "applicantnav-link-selected-done" : "applicantnav-link"}
                        onClick={(event) => {this.setState({expanded: false}); if (this.props.setStep) this.props.setStep(2); this.handleAppSelect(applicantMenu.Residential);}}
                    >
                        <div className="nav-item-div">
                            <Row>
                                {this.titleCol(applicantMenu.Residential)}
                                {this.percCol(applicant.residentialInfoPercentage)}
                            </Row>
                        </div>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item className="applicantnav-submenu2">
                    <Nav.Link as={Link} to={applicantMenu.Employment.Key} className={this.state.activeAppKey === applicantMenu.Employment.Key ? (applicant.employmentInfoPercentage??0) < 100 ? "applicantnav-link-selected" : "applicantnav-link-selected-done" : "applicantnav-link"}
                        onClick={(event) => {this.setState({expanded: false}); if (this.props.setStep) this.props.setStep(3); this.handleAppSelect(applicantMenu.Employment);}}
                    >
                        <div className="nav-item-div">
                            <Row>
                                {this.titleCol(applicantMenu.Employment)}
                                {this.percCol(applicant.employmentInfoPercentage)}
                            </Row>
                        </div>                                                    
                    </Nav.Link>
                </Nav.Item>
                {/*<Nav.Item className="applicantnav-submenu2">
                    <Nav.Link as={Link} to={applicantMenu.Financial.Key} className={this.state.activeAppKey === applicantMenu.Financial.Key ? (applicant.assetsInfoPercentage??0) < 100 ? "applicantnav-link-selected" : "applicantnav-link-selected-done" : "applicantnav-link"}
                        onClick={(event) => {this.setState({expanded: false}); if (this.props.setStep) this.props.setStep(4); this.handleAppSelect(applicantMenu.Financial);}}
                    >
                        <div className="nav-item-div">
                            <Row>
                                {this.titleCol(applicantMenu.Financial)}
                                {this.percCol(applicant.assetsInfoPercentage)}
                            </Row>
                        </div>                                                    
                    </Nav.Link>
                </Nav.Item>
            */}
                <Nav.Item className="applicantnav-submenu2">
                    <Nav.Link as={Link} to={applicantMenu.Financial.Key} className={this.state.activeAppKey === applicantMenu.Financial.Key ? (applicant.assetsInfoPercentage??0) < 100 ? "applicantnav-link-selected" : "applicantnav-link-selected-done" : "applicantnav-link"}
                        onClick={(event) => {this.setState({expanded: false}); if (this.props.setStep) this.props.setStep(4); this.handleAppSelect(applicantMenu.Financial);}}
                    >
                        <div className="nav-item-div">
                            <Row>
                                {this.titleCol(applicantMenu.Financial)}
                                {this.percCol(applicant.bankingInfoPercentage)}
                            </Row>
                        </div>                                                    
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item className="applicantnav-submenu2">
                    <Nav.Link as={Link} to={applicantMenu.AssetsAndLoans.Key} className={this.state.activeAppKey === applicantMenu.AssetsAndLoans.Key ? (applicant.assetsInfoPercentage??0) < 100 ? "applicantnav-link-selected" : "applicantnav-link-selected-done" : "applicantnav-link"}
                        onClick={(event) => {this.setState({expanded: false}); if (this.props.setStep) this.props.setStep(4); this.handleAppSelect(applicantMenu.AssetsAndLoans);}}
                    >
                        <div className="nav-item-div">
                            <Row>
                                {this.titleCol(applicantMenu.AssetsAndLoans)}
                                {this.percCol(applicant.assetsInfoPercentage)}
                            </Row>
                        </div>                                                    
                    </Nav.Link>
                </Nav.Item>
            </>
            }
        </>;
    }

    renderOtherItemsOfImportanceMenu() {
        let appMenu = SideNav.appMenu;
        let context = this.getContext();

        var purchaserHeader = <div className="applicantnav-submenu nohover">
                <div>
                    <div className="nav-item-div">
                        <Row>
                            {this.fullTitleCol(appMenu.GetReadyForSaleAgreed)}
                        </Row>
                    </div>
                </div>
            </div>;

        var switcherHeader = <div className="applicantnav-submenu nohover">
            <div>
                <div className="nav-item-div">
                    <Row>
                        {this.fullTitleCol(appMenu.OtherImportantInfo)}
                    </Row>
                </div>
            </div>
            </div>;

        var solicitorNav = <Nav.Item className="applicantnav-submenu2">
                <Nav.Link as={Link} to={appMenu.Solicitor.Key} className={this.state.activeAppKey === appMenu.Solicitor.Key ? (context.mortgage?.solicitorDetailsPercentage ?? 0) < 100 ? "applicantnav-link-selected" : "applicantnav-link-selected-done" : "applicantnav-link"}
                    onClick={(event) => {this.setState({expanded: false}); if (this.props.setStep) this.props.setStep(3); this.handleAppSelect(appMenu.Solicitor);}}
                >
                    <div className="nav-item-div">
                        <Row>
                            {this.titleCol(appMenu.Solicitor)}
                            {this.buttonCol(<div className='btn btn-standard mini'>update</div>, appMenu.Solicitor)}
                        </Row>
                    </div>
                </Nav.Link>
            </Nav.Item>;

        var lifeCoverNav = <Nav.Item className="applicantnav-submenu2">
                <Nav.Link as={Link} 
                    to={appMenu.LifeCover.Key} 
                    className={this.state.activeAppKey === appMenu.LifeCover.Key ? "applicantnav-link-selected" : "applicantnav-link"}>
                    <div className="nav-item-div">
                        <Row>
                            {this.titleCol(appMenu.LifeCover)}
                            {this.buttonCol(<InsuranceQuoteButton mini={true} mortgage={context.mortgage!} alreadyQuoted={context.mortgage!.hasInsuranceQuote ?? false} />)}
                        </Row>
                    </div>
                </Nav.Link>
            </Nav.Item>;

        var propertyNav = <Nav.Item className="applicantnav-submenu2">
                <Nav.Link as={Link} to={appMenu.Property.Key} className={this.state.activeAppKey === appMenu.Property.Key ? (context.mortgage?.propertyDetailsPercentage ?? 0) < 100 ? "applicantnav-link-selected" : "applicantnav-link-selected-done" : "applicantnav-link"}
                    onClick={(event) => {this.setState({expanded: false}); if (this.props.setStep) this.props.setStep(2); this.handleAppSelect(appMenu.Property);}}
                >
                    <div className="nav-item-div">
                        <Row>
                            {this.titleCol(appMenu.Property)}
                            {this.buttonCol(<div className='btn btn-standard mini'>update</div>, appMenu.Property)}
                        </Row>
                    </div>
                </Nav.Link>
            </Nav.Item>;

        return <>
            {context.mortgage?.type === MortgageType.PurchasingaNewHome ? purchaserHeader : switcherHeader}
            {solicitorNav}
            {lifeCoverNav}
            {context.mortgage?.type === MortgageType.PurchasingaNewHome && this.saleAgreedNav()}
            {propertyNav}
        </>;
    }

    render() {
        if (this.state.loading) {
            // For admin users they can access a page without the side bar
            // This causes a state issue, so let's just poll when we detect impersonation
            if (this.state.activeKey === "/_impersonate") {
                this.refresher();
            }
            return <></>;
        }

        if (this.state.redirectToPropertyPage) {
            this.setState({redirectToPropertyPage: false});
            return <Navigate  to="/property" />;
        }

        let menu = SideNav.menu;
        let appMenu = SideNav.appMenu;
        let context = this.getContext();

        const applicants = this.state.applicants;
        if (this.props.authenticated && (applicants === null || applicants === undefined)) {
            return;
        }

        let primaryApplicant = applicants.length > 0 ? applicants[0] : null;
        let secondaryApplicant = applicants.length > 1 ? applicants[1] : null;
        let primary = context.mortgage?.primaryApplicant ?? primaryApplicant;
        let secondary = context.mortgage?.secondaryApplicant ?? secondaryApplicant;


        let stageSelected = 0;
        Object.entries(appMenu).forEach(([key, value]) => { 
            if(this.state.activeAppKey === value.Key) {
                stageSelected = value.Stage;
            }
        });

        var completionSteps = this.getCompletionSteps();
        var activeCompletionStep = this.getActiveCompletionStep(completionSteps);
        var applyLcoked = [MortgageStatus.Closed].includes(context.mortgage!.status);
        var aipLocked = [MortgageStatus.NewLead, MortgageStatus.ClientContacted, MortgageStatus.PortalSetup, MortgageStatus.PortalLogin, MortgageStatus.InformationGathering, MortgageStatus.Closed].includes(context.mortgage!.status);
        var completionsLocked = aipLocked || [MortgageStatus.AIPSubmitted, MortgageStatus.PreAIPClarification].includes(context.mortgage!.status) || context.mortgage!.approvalType === AIPType.HouseHunter;
        
        return (
            <>
                <Navbar className="doddl-nav" expand="lg" expanded={this.state.expanded ?? false}>
                    <Navbar.Toggle onClick={() => this.setState({expanded: !(this.state.expanded ?? false)})} aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Container>
                    <Row className="mb-3">
                        <Stack gap={2} direction='horizontal' className="justify-center">
                            {applyLcoked
                            ? <Link to={'#'} className={`btn btn-nav disabled`}>1. {menu.Profile.Title} <i className="fa fa-lock"></i></Link>
                            : <Link to={menu.Profile.Key} className={`btn btn-nav ${this.state.activeKey === menu.Profile.Key ? 'selected' : ''}`} onClick={(e) => {this.handleSelect(menu.Profile.Key);}}>1. {menu.Profile.Title}</Link>
                            }
                            {aipLocked
                            ? <Link to={'#'} className={`btn btn-nav disabled`} >2. {menu.AIP.Title} <i className="fa fa-lock"></i></Link>
                            : <Link to={menu.AIP.Key} className={`btn btn-nav ${this.state.activeKey === menu.AIP.Key ? 'selected' : ''}`}  onClick={(e) => {this.handleSelect(menu.AIP.Key);}}>2. {menu.AIP.Title}</Link>
                            }
                            {completionsLocked
                            ? <Link to={'#'} className={`btn btn-nav disabled`} >2. {menu.Completion.Title} <i className="fa fa-lock"></i></Link>
                            : <Link to={menu.Completion.Key} className={`btn btn-nav ${this.state.activeKey === menu.Completion.Key ? 'selected' : ''}`}  onClick={(e) => {this.handleSelect(menu.Completion.Key);}}>3. {menu.Completion.Title}</Link>
                            }
                            
                        </Stack>
                    </Row>
                    <Row>
                        <Nav>
                            <div className="profile-group">

                            {this.props.authenticated && applicants.length > 0 &&
                            <>
                                {this.state.activeKey === menu.Profile.Key &&
                                    <>
                                        <div className="applicantnav-submenu nohover">
                                            <div>
                                                <div className="nav-item-div">
                                                    <Row>
                                                        {this.fullTitleCol(appMenu.GetMortgageApproval)}
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                        {this.renderApplicantMenu(primary, stageSelected)}

                                        {/* If we have a secondary render their navigation menu too */}
                                        {context.mortgage?.secondaryApplicant &&
                                            this.renderApplicantMenu(secondary, stageSelected)
                                        }

                                        <Nav.Item className="applicantnav-submenu">
                                            <Nav.Link as={Link} to={appMenu.Mortgage.Key}>
                                                <div className="nav-item-div">
                                                <Row>
                                                    {this.titleCol(appMenu.Mortgage)}
                                                    {this.percCol(context.mortgage?.mortgageDetailsPercentage ?? 0)}
                                                </Row>
                                                </div>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="applicantnav-submenu" title={appMenu.Documents.Title}>
                                            <Nav.Link as={Link} to={appMenu.Documents.Key} 
                                                onClick={(event) => {this.setState({expanded: false}); this.handleAppSelect(appMenu.Documents);}}
                                            >
                                                <div className="nav-item-div">
                                                <Row>
                                                    {this.titleCol(appMenu.Documents)}
                                                    {this.percCol(context.mortgage?.documentsPercentComplete??0, true)}
                                                </Row>
                                                </div>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </>    
                                }
                            </>
                            }
                            {this.props.authenticated && this.state.activeKey === menu.AIP.Key &&
                                <>
                                    <div className="applicantnav-submenu nohover">
                                        <div>
                                            <div className="nav-item-div">
                                                <Row>
                                                    {this.titleCol(appMenu.GetMortgageApproval)}
                                                    {this.timelineCol([], appMenu.GetMortgageApproval.Key)}
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                    <Nav.Item className="applicantnav-submenu">
                                        <Nav.Link as={Link} to={appMenu.Profile.Key}>
                                            <div className="nav-item-div">
                                                <Row>
                                                    {this.titleCol(appMenu.Profile)}
                                                    {this.percCol((this.state.aipStatus > 0 ? 100 : context.mortgage?.documentsPercentComplete??0), true)}
                                                </Row>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="applicantnav-submenu">
                                        <Nav.Link as={Link} to={appMenu.AIP.Key}>
                                            <div className="nav-item-div">
                                                <Row>
                                                    {this.titleCol(appMenu.AIP)}
                                                    {this.percCol(this.state.aipStatus??0, true)}
                                                </Row>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                </>
                            }

                            {this.props.authenticated && [menu.Profile.Key, menu.AIP.Key].includes(this.state.activeKey) &&
                                this.renderOtherItemsOfImportanceMenu()
                            }
                            {this.props.authenticated && this.state.activeKey === menu.Completion.Key &&
                                <>
                                    <div className="applicantnav-submenu nohover">
                                        <div>
                                            <div className="nav-item-div">
                                                <Row>
                                                    {this.titleCol(appMenu.ComleteOnYourMortgage)}
                                                    {this.timelineCol([], appMenu.ComleteOnYourMortgage.Key)}
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                    <>
                                        {this.saleAgreedNav(true)}
                                        <div className="applicantnav-submenu2">
                                            <div className={activeCompletionStep === appMenu.GetLetterOfOffer.Key ? "applicantnav-link-selected" : "applicantnav-link"}>
                                                <div className="nav-item-div nohover">
                                                    <Row>
                                                        {this.titleCol(appMenu.GetLetterOfOffer, activeCompletionStep)}
                                                        {this.timelineCol(completionSteps, appMenu.GetLetterOfOffer.Key, true)}
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="applicantnav-submenu2">
                                            <div className={activeCompletionStep === appMenu.DownloadEsis.Key ? "applicantnav-link-selected" : "applicantnav-link"}>
                                                <div className="nav-item-div nohover">
                                                    <Row>
                                                        {this.titleCol(appMenu.DownloadEsis, activeCompletionStep)}
                                                        {this.timelineCol(completionSteps, appMenu.DownloadEsis.Key, true)}
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                        {context.mortgage?.deferredPayment &&
                                        <div className="applicantnav-submenu2">
                                            <div className={activeCompletionStep === appMenu.DownloadCostOfCredit.Key ? "applicantnav-link-selected" : "applicantnav-link"}>
                                                <div className="nav-item-div nohover">
                                                    <Row>
                                                        {this.titleCol(appMenu.DownloadCostOfCredit, activeCompletionStep)}
                                                        {this.timelineCol(completionSteps, appMenu.DownloadCostOfCredit.Key, true)}
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        {context.mortgage!.type === MortgageType.RemortgagingExistingLoan && 
                                        <div className="applicantnav-submenu2">
                                            <div className={activeCompletionStep === appMenu.ConfirmSolicitorHasDeeds.Key ? "applicantnav-link-selected" : "applicantnav-link"}>
                                                <div className="nav-item-div nohover">
                                                    <Row>
                                                        {this.titleCol(appMenu.ConfirmSolicitorHasDeeds, activeCompletionStep)}
                                                        {context.mortgage!.confirmedTitleDeeds === YesNoBlank.Yes
                                                        ? this.percCol(100)
                                                        : this.buttonCol(<button className="btn mini btn-standard" onClick={this.confirmTitleDeeds}>confirm</button>, appMenu.ConfirmSolicitorHasDeeds, activeCompletionStep)
                                                        }
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        {/* <div className="applicantnav-submenu2">
                                            <div className={activeCompletionStep === appMenu.CompletionDocs.Key ? "applicantnav-link-selected" : "applicantnav-link"}> */}
                                        <Nav.Item className="applicantnav-submenu2">
                                            <Nav.Link 
                                                className={activeCompletionStep === appMenu.CompletionDocs.Key ? "applicantnav-link-selected" : "applicantnav-link"}
                                                as={Link} to={appMenu.CompletionDocs.Key}
                                                >
                                                <div className="nav-item-div nohover">
                                                    <Row>
                                                        {this.titleCol(appMenu.CompletionDocs, activeCompletionStep)}
                                                        {this.timelineCol(completionSteps, appMenu.CompletionDocs.Key, true)}
                                                    </Row>
                                                </div>
                                            {/* </div>
                                        </div> */}
                                        </Nav.Link>
                                        </Nav.Item>
                                    </>

                                    <div className="applicantnav-submenu nohover">
                                        <div>
                                            <div className="nav-item-div">
                                                <Row>
                                                    {this.titleCol(appMenu.FinalSteps, activeCompletionStep)}
                                                    {this.timelineCol(completionSteps, appMenu.FinalSteps.Key)}
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                    <>
                                        <div className="applicantnav-submenu2">
                                            <div className={activeCompletionStep === appMenu.MeetSolicitor.Key ? "applicantnav-link-selected" : "applicantnav-link"}>
                                                <div className="nav-item-div nohover">
                                                    <Row>
                                                        {this.titleCol(appMenu.MeetSolicitor, activeCompletionStep)}
                                                        {context.mortgage!.metWithSolicitor
                                                        ? this.percCol(100)
                                                        : this.buttonCol(<button className="btn mini btn-standard" onClick={this.metWithSolicitor}>confirm</button>, appMenu.MeetSolicitor, activeCompletionStep)
                                                        }
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="applicantnav-submenu2">
                                            <div className={activeCompletionStep === appMenu.AgreeClosing.Key ? "applicantnav-link-selected" : "applicantnav-link"}>
                                                <div className="nav-item-div nohover">
                                                    <Row>
                                                        {this.titleCol(appMenu.AgreeClosing, activeCompletionStep)}
                                                        {context.mortgage!.estimatedClosingDate 
                                                        ?
                                                        this.textCol(<FormattedDate excludeYear={true} monthFormat="short" date={context.mortgage!.estimatedClosingDate} />)
                                                        :
                                                        this.buttonCol(<button className="btn mini btn-standard" onClick={() => {this.setState({closeDateCollapsed: false})}}>update</button>, appMenu.AgreeClosing, activeCompletionStep)
                                                        }
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="applicantnav-submenu2">
                                            <div className={activeCompletionStep === appMenu.FundRelease.Key ? "applicantnav-link-selected" : "applicantnav-link"}>
                                                <div className="nav-item-div nohover">
                                                    <Row>
                                                        {this.titleCol(appMenu.FundRelease, activeCompletionStep)}
                                                        {this.timelineCol(completionSteps, appMenu.FundRelease.Key)}
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                    </>

                                </>
                            }
                            </div>
                        </Nav>
                    </Row>
                    </Container>
                    </Navbar.Collapse>
                </Navbar>
                <div className="brand-div">
                    <Navbar.Brand><Link to="/dashboard"><img src={logo} width="100px" alt="doddl" /></Link></Navbar.Brand>
                </div>
                <Modal centered size="lg" show={this.state.showSaleAgreedModal} onHide={() => {this.setState({showSaleAgreedModal: false})}}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center mb-5 ps-5 pe-5">
                            <h1 className="impact">Are you sale agreed?</h1>
                            <p className="">Let us know when you have gone sale agreed so that we can begin the process of getting your letter of offer from your chosen lender. Are you sale agreed?</p>
                        </div>
                        <Stack direction="horizontal"  gap={3} className="text-center justify-center">
                            <button className="btn medium btn-secondary" onClick={() => {this.setState({savingSaleAgreed: true}); this.foundMyNewHome();}}>{this.state.savingSaleAgreed ? <Icons.Spinner size="sm" animation="border" role="status" /> : 'Yes!'}</button>
                            <button className="btn medium btn-light" onClick={() => {this.setState({showSaleAgreedModal: false})}}>No</button>
                        </Stack>
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
                <Modal fullscreen="lg-down" show={!this.state.closeDateCollapsed} onHide={() => { this.setState({ closeDateCollapsed: true }); }} centered>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col>
                                    <FormControl light icon="far fa-calendar" field={this.state.sharedFields.getFieldByName("clientAdvisedClosingDate")} ></FormControl>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.savingCloseDate &&
                            <Icons.Spinner animation="border" role="status" />
                        }
                        {!this.state.savingCloseDate &&
                            <button className="btn btn-standard btn-wide" onClick={this.setClientAdvisedClosingDate}>
                                Save
                            </button>
                        }
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

}