import React from "react";
import UserContextState, { UserContext } from "./UserContext";
import axios, { AxiosRequestConfig } from "axios";
import { GetTooltips } from "../helper/tooltipHelper";
import { AxiosError, AxiosResponse } from "axios";
import { PortalPage } from "./PortalPage";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FormLookupItem } from "../models/PortalMetadata";
import { logger } from "../helper/Logger";
import { ImpersonateContact } from "../pages/ImpersonateContact";

export class UserProvider extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.reloadMortgage = this.reloadMortgage.bind(this);
        this.loadPillsTooltips = this.loadPillsTooltips.bind(this);
        this.setIsDirty = this.setIsDirty.bind(this);
        this.getMortgageDocuments = this.getMortgageDocuments.bind(this);
        this.loadUserDetails = this.loadUserDetails.bind(this);
        this.loadMortgage = this.loadMortgage.bind(this);
        this.loadMortgageDocuments = this.loadMortgageDocuments.bind(this);
        this.loadPillsTooltips = this.loadPillsTooltips.bind(this);

        this.addDoc = this.addDoc.bind(this);
        this.updateDoc = this.updateDoc.bind(this);
        this.deleteDoc = this.deleteDoc.bind(this);
        this.updateId = this.updateId.bind(this);
        this.loadFiles = this.loadFiles.bind(this);

        this.state = {
            loading: true,
            contact: null,
            userNotFound: false,
            error: false,
            errorDetails: null,
            isDirty: false,
            setIsDirty: (b: boolean) => { this.setState({ isDirty: b }); },
            impersonatedId: null,
            mortgageDocumentList: [],
            reloadMortgage: this.reloadMortgage,
            getMortgageDocuments: this.getMortgageDocuments,
            addDoc: this.addDoc,
            updateDoc: this.updateDoc,
            deleteDoc: this.deleteDoc,
            updateId: this.updateId,
            institutions: [],
            contactNotFound: false,
            settings: null,
            files: null,
        };

        axios.interceptors.response.use(this.onResponse, this.onResponseError);
    }

    setLastLoginTime(contactId: string): void {
        if (sessionStorage.getItem("lastLoginLogged") === null) {
            axios.post('/Contacts/SetLastLoginTime', null, { params: { contactId: contactId } }).then((response) => { sessionStorage.setItem("lastLoginLogged", "1"); });
        }
    }

    onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
        return config;
    }

    callErrorApi(error: AxiosError) {
        setTimeout(() => {
            var form = new FormData();
            form.append('details', JSON.stringify(error) + ' | \r\n\r\nConsole: \r\n' + JSON.stringify(logger.getLogs()));
            axios.post("/Log/Error", form,
                {
                    params: { message: error?.message ?? "Error occured" },
                    maxBodyLength: 2147483647,
                    headers: { "Content-Type": "multipart/form-data" }
                }
            ).catch((e) => {
                logger.error("Error reaching error logger");
                logger.log(e);
            });
            logger.clearLogs();
        }, 500);
    }

    onRequestError = (error: AxiosError): Promise<AxiosError> => {
        logger.error(`[request error] [${JSON.stringify(error)}]`);
        this.callErrorApi(error);
        return Promise.reject(error);
    }

    onResponse = (response: AxiosResponse): AxiosResponse => {

        return response;
    }

    onResponseError = (error: AxiosError): Promise<AxiosError> => {
        if (window.location.href.includes("_impersonate")) {
            // We may get an error if someone without a mortgage tries to impersonate. Just ignore it in this instance only
            return Promise.resolve(error);
        }
        if (error.config.url ==='/Log/Error') {
            // If we get an error attempting to log an error, then we have an API issue. So just stop here
            return Promise.resolve(error);
        }
        logger.error(`${JSON.stringify(error)}`);
        if (error?.response?.status! >= 500 || error?.response?.status === 400) {
            this.callErrorApi(error);
        }
        this.setState({ error: true });

        return Promise.reject(error);
    }

    componentDidMount() {
        if (window.location.pathname === "/error") {
            this.setState({ loading: false });
            return;
        }

        if (this.state.loading) {
            this.loadData(true, null);
        }


    }

    loadData(loadTooltips: boolean, contactId: string | null) {
        this.loadSettings().then((settingsResponse:any) => {
            this.setState({settings: settingsResponse.data});
        });
        this.loadUserDetails(contactId).then((userDetailsResponse: any) => {
            if (userDetailsResponse)
                logger.log("User Details Loaded", userDetailsResponse.data);
            else {
                logger.warn("Error state detected. Null User Details returned");
                this.setState({ error: true });
                return;
            }
            //if not impersonated, update last login time.
            if (this.state.impersonatedId === null)
                this.setLastLoginTime(userDetailsResponse.data.contactId);
            if (userDetailsResponse.data.contactId === "00000000-0000-0000-0000-000000000000") {
                this.setState({ userNotFound: true })
            } else if (userDetailsResponse.data.mortgageId === "00000000-0000-0000-0000-000000000000" || userDetailsResponse.data.mortgageId === null)
                this.setState({ noMortgageError: true, contact: userDetailsResponse.data, loading: false });
            else {
                this.loadMortgage(userDetailsResponse.data).then((mortgageResponse: any) => {
                    logger.log("Mortgage Loaded", mortgageResponse.data);

                    this.loadFiles(mortgageResponse.data.mortgageId);

                    //setTimeout(() => {
                    this.loadMortgageDocuments(userDetailsResponse.data).then((mortgageDocumentsResponse: any) => {

                        logger.log("Mortgage Documents Loaded", mortgageDocumentsResponse.data);

                        this.loadInstitutions().then((institutionsResponse: any) => {
                            let institutions: FormLookupItem[] = institutionsResponse.data.map((item: any) => {
                                return { text: item.name, value: item.institutionId, item: item } as FormLookupItem;
                            });
                            this.setState({
                                contact: userDetailsResponse.data,
                                mortgage: mortgageResponse.data,
                                mortgageDocumentList: mortgageDocumentsResponse.data,
                                loading: false,
                                error: false,
                                institutions: institutions,
                                noMortgageError: false,
                                applicantNo: (userDetailsResponse.data.contactId === mortgageResponse.data.primaryApplicant.contactId ? 1 : 2)
                            });

                        });
                        if (loadTooltips) {
                            this.loadPillsTooltips().then((tooltipsResponse: any) => {
                                logger.log("Tooltips Loaded", mortgageDocumentsResponse.data);
                                this.setState({
                                    tooltips: tooltipsResponse.data,
                                });
                            })
                        }
                    });
                    //}, 10);

                });
            }
        });
    }

    loadFiles(mortgageId: string) {
        if (this.state.loadingFiles) return;
        this.setState({loadingFiles: true});
        let url = `/sp/GetFiles?mortgageId=${mortgageId}`;
        axios.get(url)
            .then((response) => {
                let files = response.data;
                this.setState({ files: files, loadingFiles: false });
            })
            .catch((error) => {
                this.setState({ loadingFiles: false });
            });
    }

    loadUserDetails(contactId: string | null) {
        let url = '/Contacts/GetUserDetails';
        if (contactId)
            url = '/Contacts/GetUserDetailsEx?id=' + contactId;
        return axios.get(url).catch((error) => {
            this.setState({ error: true });
        });
    }

    loadInstitutions() {
        let url = "/Dictionary/GetInstitutions";
        return axios.get(url);
    }

    loadMortgage(contact: any) {
        return axios.get("/Mortgages/GetMortgageByMortgageId?mortgageId=" + contact.mortgageId);
    }

    loadMortgageDocuments(mortgage: any) {
        let url = `/MortgageDocuments/GetAllDocuments?mortgageId=${mortgage.mortgageId}`;
        return axios.get(url);
    }

    loadPillsTooltips() {
        return GetTooltips("pill");
        // .then((response) => {
        //     this.setState({ tooltips: response.data });
        // })
        // .catch((error) => {
        //     this.setState({ error: true });
        // });
    }

    loadSettings() {
        let url = "/settings";
        return axios.get(url);
    }

    impersonateContact(contactId: string) {
        this.loadData(false, contactId);
        this.setState({ impersonatedId: contactId });
    }

    reloadMortgage = () => {
        if (this.state.impersonatedId !== null) {
            this.loadData(false, this.state.impersonatedId);
        } else {
            this.setState({ files: []});
            axios.get("/Mortgages/GetMortgageByMortgageId?mortgageId=" + this.state.contact.mortgageId)
                .then((response) => {
                    this.setState({ mortgage: response.data });
                })
                .catch((error) => {
                });
        }
    }

    setIsDirty(isDirty: boolean) {
        this.setState({ isDirty: isDirty });
    }

    getMortgageDocuments(mortgageId: string) {
        if (!mortgageId)
            mortgageId = this.state.contact.mortgageId;
        let url = `/MortgageDocuments/GetAllDocuments?mortgageId=${mortgageId}`;
        return axios
            .get(url)
            .then((response) => {
                var list = response.data;
                this.setState({ loading: false, mortgageDocumentList: list });
            })
            .catch((error) => {
                if (error.response.statusCode === 401) {
                    alert("Unauthorized");
                }
            });
    }

    addDoc(doc: any) {
        let doclist = this.state.mortgageDocumentList;
        doclist.push(doc);
        this.setState({
            mortgageDocumentList: doclist
        });
        //this.getMortgageDocuments();
    }

    updateDoc(doc: any) {
        let doclist = this.state.mortgageDocumentList;
        let oldDocs = doclist.map((d: any) => { if (d.mortgageDocumentId === doc.mortgageDocumentId) return doc; else return d });

        oldDocs.forEach((d: any) => {
            if (d.mortgageDocumentId === doc.mortgageDocumentId)
                logger.log(d.statusReason);

            if (d.new === true) {
                d.new = false;
            }

        });
        this.setState({
            mortgageDocumentList: oldDocs
        });
        //this.getMortgageDocuments();
    }

    updateId(oldId: string, newId: string) {
        let doclist = this.state.mortgageDocumentList;
        let oldDoc = doclist.find((d: any) => d.mortgageDocumentId === oldId);
        if (!oldDoc) return;
        else oldDoc.mortgageDocumentId = newId;

        let oldDocs = doclist.map((d: any) => { if (d.mortgageDocumentId === oldId) return oldDoc; else return d; });
        this.setState({
            mortgageDocumentList: oldDocs
        });
        //this.getMortgageDocuments();
    }

    deleteDoc(doc: any) {
        let doclist = this.state.mortgageDocumentList;
        doclist = doclist.filter((o: any) => o.mortgageDocumentId !== doc.mortgageDocumentId);
        this.setState({
            mortgageDocumentList: doclist
        });
        //this.getMortgageDocuments();
    }

    render() {

        if (this.state.error === true) {
            return <PortalPage title={<>We are so sorry!</>} description={"Our portal is experiencing some issues. We are aware of this and are working to resolve the problem as soon as possible."}>
                <br />
                <p className="max-w-wide"><Link to="/"><Button onClick={() => { this.setState({ error: false }); }} className='btn btn-standard btn-round-lg circle btn-bordered btn-lg'>Go to home page</Button></Link></p>
            </PortalPage>
        }
        if (this.state.noMortgageError === true && (!this.state.contact.isAdmin || window.location.href.includes("_impersonate") === false)) {
            var imp = sessionStorage.getItem("imp") ?? "";
            if (imp != "" && (window.location.pathname ?? "/") === "/" ) {
                axios.post('/Contacts/impersonate', null, { params: { id: imp } })
                    .then(() => {
                        var context = this.state as UserContextState;
                        context.impersonatedId = imp;
                        context.reloadMortgage();
                    }
                );
                return <></>;
            }
        
            return <PortalPage title={<>Details not found!</>} description={"You do not have a mortgage linked to this email address. If you feel this is an error, please contact us and we will resolve this for you."}>
                <br />
            </PortalPage>;
            
        }

        if (!this.state.loading && this.state.userNotFound === true) {
            return <p><em>There is no user account associated with your e-mail address or you have no active mortgage.</em></p>;
        }

        if (this.state.loading === true)
            return <p><em>Loading...</em></p>;



        var v = this.state as UserContextState;

        return (
            <UserContext.Provider value={v}>
                <>
                    {this.props.children}
                </>
            </UserContext.Provider>
        );
    }
}
