import axios from 'axios';
import './FileUpload.css';
import { PickerInline } from 'filestack-react';
import { Component } from 'react';
import { logger } from '../helper/Logger';

// const YOUR_API_KEY = 'AVPxEgshPQ4SROSFqC9Qez';//uat
const YOUR_API_KEY = 'AOTjRd9OaTTqw9zEmyA8Hz';//uat

interface IState {
    uploading: boolean;
}

export class FileUpload extends Component<any, IState>{

    constructor(props: any) {
        super(props);
        this.state = {uploading: false};
        this.uploadStarted = this.uploadStarted.bind(this);
        this.uploadComplete = this.uploadComplete.bind(this);
    }

    uploadStarted() {
        this.setState({uploading: true});
    }

    uploadComplete() {
        this.setState({uploading: false});
    }

    render() {
        var uploadComplete = this.uploadComplete;
        return <>
        <div className='progresscontainer'>
            {this.state.uploading &&
                <div className='progressfile'>Processing... <i className="fas fa-spinner fa-spin-pulse" style={{padding: 3}}></i></div>
            }
            <PickerInline
            apikey={YOUR_API_KEY}
            onSuccess={(res) => {
                logger.log("success");
                logger.log(res);
            }}
            onUploadDone={(res: any) => {
                logger.log("upload");
                logger.log(res)
                res.filesUploaded.forEach((file: any) => {
                    var form = new FormData();
                    form.append('file', file.originalFile);
                    this.uploadStarted();
                    axios.post("/SP/UploadFile", file.originalFile === undefined ? null : form,
                        {
                            // "content": file.getFileEncodeBase64String(),
                            params: { "contactId": this.props.contactId, "category": this.props.category, "mortgageDocumentId": this.props.mortgageDocumentId, "mortgageId": this.props.mortgageId, "uploadId": file.uploadId, "url": file.url, "fileName": file.filename },
                            maxBodyLength: 2147483647,
                            headers: { "Content-Type": "multipart/form-data" }
                        })
                        .then((response) => {
                            uploadComplete();
                            if (this.props.onUploadComplete) {
                                this.props.onUploadComplete(response.data, file.uploadId);
                            }
                        })
                        .catch((error) => {
                            uploadComplete();
                            logger.log(error);
                        });
                });
            }}
            pickerOptions={{
                accept: ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'],
                fromSources: ["local_file_system", "googledrive", "dropbox", "googlephotos", "onedrive", "onedriveforbusiness"],
                storeTo: { location: "azure", container: "filestack" },
                //onFileUploadStarted: function (a) { uploadStarted(a)},
                onFileUploadFinished: function (a) { logger.log(a); logger.log("done uploading"); },
                maxFiles: 10,
                exposeOriginalFile: true,
                disableThumbnails: true,
                disableTransformer: true,
                onOpen: function(p) { 
                    setTimeout(FileUpload.expandForMobile,100);
                }
            }}
            clientOptions={{ sessionCache: true }}
        />
        </div>
            <br />
        </>
    }

    static expandForMobile() {
        var burgerMenu = document.getElementsByClassName("fsp-mobile-menu");
        if (burgerMenu.length > 0) {
            FileUpload.simulateMouseClick(burgerMenu[0]);
        } else {
            setTimeout(FileUpload.expandForMobile,100);
        }
    }
    static simulateMouseClick(element:any){
        ['mousedown', 'click', 'mouseup'].forEach(mouseEventType =>
            element.dispatchEvent(
            new MouseEvent(mouseEventType, {
                view: window,
                bubbles: true,
                cancelable: true,
                buttons: 1
            })
            )
        );
    }
}

export const SPCategory = Object.freeze({
    Saving: "Savings Statement",
    Current: "Current Account Statement"
});