import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { PortalHelperTextModel } from '../models/PortalHelperTextModel';

export class Tooltip extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    render() {
        if (this.props.tooltip !== null && this.props.tooltip !== undefined) {
            return (
                <OverlayTrigger
                    key="top"
                    delay={{ show: 100, hide: 200 }}
                    placement="top"
                    overlay={
                        <Popover id={`popover-positioned-top`}>
                            {this.props.tooltip?.title && <Popover.Header as="h4">{this.props.tooltip?.title}</Popover.Header>}
                            <Popover.Body>
                                {this.props.tooltip?.content}
                            </Popover.Body>
                        </Popover>
                    }
                >
                    {this.props.children}
                </OverlayTrigger>);
        }
        else
            return (<>{this.props.children}</>);
    }
}

export function getTooltip(texts: PortalHelperTextModel[], fieldname: string) {
    for (let index = 0; index < texts.length; index++) {
        const element = texts[index];
        if (element.fieldName === fieldname)
            return element;
    }
    return null;
}