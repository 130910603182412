import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Container, Stack } from "react-bootstrap";
import './Applicant.css';
import { Navigate } from "react-router-dom";
import { InputPill } from '../components/Pills/InputPill';
import { ApplicantPage } from "./ApplicantPage";
import { ApplicantService } from './ApplicantService';
import { ApplicantBaseComponent } from './ApplicantBaseComponent';
import { AggregatePill, AggregateType, IAggregateListItem, IAggregatePillProps } from '../components/Pills/AggregatePill';
import { AutoCompletePill } from '../components/Pills/AutoCompletePill';
import { getTooltip } from '../components/Tooltip';
import axios from 'axios';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '../helper/authConfig';
import { Children } from '../components/Applicant/Children';
import { HasChildren } from '../models/PortalContactModel';
import { LinkPill } from '../components/Pills/LinkPill';
import { FieldWithHelptext } from '../components/FieldWithHelptext';
import { BothApplicantFieldsWithAggregate } from '../components/BothApplicantFieldsWithAggregate';
import currencyFormatter, { currencyToNumber } from '../helper/currencyFormatter';

export class ApplicantPersonal extends ApplicantBaseComponent {
    applicantService: ApplicantService;

    constructor(props: any) {
        super(props);
        this.prev = this.prev.bind(this);
        this.next = this.next.bind(this);

        this.changeEmail = this.changeEmail.bind(this);
        this.saveChildrenPill = this.saveChildrenPill.bind(this);
    }

    componentDidMount() {
        let context = this.getContext();
        this.applicantService = new ApplicantService(context, this, this.props.applicantNo);
        this.applicantService.getNationalities().then(() => {
            this.applicantService.getCountries().then(() => {
                this.applicantService.getApplicantData();
            });
        });
    }

    // #region Email
    changeEmail(email: string) {
        this.applicantService.handleChange({ target: { name: "emailAddress", value: email } });
        this.applicantService.saveAllFields(false);

        axios.post('/Contacts/ChangeEmail', null, { params: { applicantNo: this.state.applicantNo, mortgageId: this.getContext().mortgage?.mortgageId } })
            .then((response) => {
                const myMSALObj = new PublicClientApplication(msalConfig);
                myMSALObj.logoutRedirect();

            })
            .catch((error) => {
                //if (error.response.statusCode === 401) {
                //    alert("Unauthorized");
                //}
            });

    }
    // #endregion

    // #region children
    saveChildrenPill(items: IAggregateListItem[], fields: any) {
        let context = this.getContext();
        let otherContact: any;
        if (this.state.applicantNo === 1)
            otherContact = context.mortgage?.secondaryApplicant;
        else if (this.state.applicantNo === 2)
            otherContact = context.mortgage?.primaryApplicant;

        if (items.length === 0) {
            //they said no to has children
            if (fields) {
                fields.updateModel(this.state.contact, "hasChildren", 0);
                fields.updateFieldValue("hasChildren", 0);

                fields.updateModel(this.state.contact, "childrenNumber", 0);
                fields.updateFieldValue("childrenNumber", 0);

                fields.updateModel(this.state.contact, "childrenAge", "");
                fields.updateFieldValue("childrenAge", "");

                if (otherContact?.hasChildren === null && otherContact.childrenNumber === null && (otherContact.childrenAge === null || otherContact.childrenAge === "")) {
                    otherContact.hasChildren = 0;
                    otherContact.childrenNumber = 0;
                    otherContact.childrenAge = "";

                    if (this.state.applicantNo === 1)
                        context.mortgage!.secondaryApplicant = otherContact;
                    else if (this.state.applicantNo === 2)
                        context.mortgage!.primaryApplicant = otherContact;
                }
            }
            this.setState({ fields: this.state.fields });
            this.applicantService.saveAllFields(false);
        }
        else {
            if (fields) {
                fields.updateModel(this.state.contact, "hasChildren", fields.getFieldValueByName("hasChildren", this.state.contact));
                fields.updateFieldValue("hasChildren", fields.getFieldValueByName("hasChildren", this.state.contact));

                fields.updateModel(this.state.contact, "childrenNumber", fields.getFieldValueByName("childrenNumber", this.state.contact));
                fields.updateFieldValue("childrenNumber", fields.getFieldValueByName("childrenNumber", this.state.contact));

                let ages: string = "";
                items.forEach((item, i) => { ages += item.values[0].toString(); if (items.length - 1 !== i) ages += "," });
                fields.updateModel(this.state.contact, "childrenAge", ages);
                fields.updateFieldValue("childrenAge", ages);

                if (otherContact?.hasChildren === null && otherContact.childrenNumber === null && (otherContact.childrenAge === null || otherContact.childrenAge === "")) {
                    otherContact.hasChildren = fields.getFieldValueByName("hasChildren", this.state.contact);
                    otherContact.childrenNumber = fields.getFieldValueByName("childrenNumber", this.state.contact);
                    otherContact.childrenAge = ages;

                    if (this.state.applicantNo === 1)
                        context.mortgage!.secondaryApplicant = otherContact;
                    else if (this.state.applicantNo === 2)
                        context.mortgage!.primaryApplicant = otherContact;
                }
            }

            this.setState({ fields: this.state.fields });
            this.applicantService.saveAllFields(false);
        }
    }

    // #endregion

    prev() {
        if (this.ppsInvalidCheck(this.state.fields.getFieldByName("ppsNumber").value as string))
            return;

        this.applicantService.saveAllFields(false);
        this.setState({ redirectTo: "/profile" });
    }

    next() {
        if (this.ppsInvalidCheck(this.state.fields.getFieldByName("ppsNumber").value as string))
            return;

        this.applicantService.saveAllFields(false);
        this.setState({ redirectTo: "/residential" + this.props.applicantNo });
    }

    ppsInvalidCheck(value: string) {
        var regex = /^\d{7}[A-Za-z]{1,2}$/
        if (value && !regex.test(value)) {
            return true;
        }
        return false;
    }

    render() {
        if (this.state.loading) {
            return;
        }

        let context = this.getContext();

        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />;
        }

        if (!this.state.contact)
            return <span>Applicant information not found</span>;

        //email pill
        // let emailItems: IAggregateListItem[] = [];
        // emailItems.push({ key: "Email", values: [this.state.contact.emailAddress] });
        // let emailPill: IAggregatePillProps =
        // {
        //     uniqueKey: "email",
        //     label: "Email",
        //     items: emailItems,
        //     showLabelCount: false,
        //     aggregate1type: AggregateType.firstValue,
        //     aggregate1label: "",
        //     aggregate1currency: false,
        //     aggregate2type: undefined,
        //     aggregate2label: undefined,
        //     aggregate2currency: undefined,
        //     overlay: <Email record={this.state.contact} fields={this.state.fields} changeEmail={this.changeEmail} helperTexts={this.state.helperTexts} ></Email>,
        //     hideIfValue: this.state.emptyOnly,
        //     //setCloseModal: this.setCloseEmailModal,
        //     //setOpenModal: this.setOpenEmailModal,
        //     //itemProp: this.state.email,
        //     tooltip: getTooltip(context.tooltips ?? [], "email")
        // };

        //children
        let ages = [];
        let childrenAges = this.state.fields.getFieldValueByName("childrenAge", this.state.contact);
        if (childrenAges !== null && childrenAges !== undefined && childrenAges !== "")
            ages = childrenAges.split(",");
        let childrenItems: IAggregateListItem[] = [];

        for (var i = 0; i < ages.length; i++) {
            childrenItems.push({ key: i.toString(), values: [parseInt(ages[i])] });
        }

        let childrenPill: IAggregatePillProps = {
            uniqueKey: "Children",
            label: "Children",
            showLabelCount: true,
            items: this.state.contact.hasChildren === null && childrenItems.length === 0 ? null : childrenItems,
            aggregate1type: AggregateType.list,
            aggregate1label: "",
            aggregate1currency: false,
            aggregate2type: undefined,
            aggregate2label: undefined,
            aggregate2currency: undefined,
            overlay: <Children key={JSON.stringify(childrenItems)} record={this.state.contact} items={childrenItems} fields={this.state.fields} save={this.saveChildrenPill} hasAny={this.state.contact.hasChildren === HasChildren.Yes}></Children>,
            hideIfValue: this.state.emptyOnly,
            tooltip: getTooltip(context.tooltips ?? [], "children"),
            isMissing: this.state.contact.hasChildren === null
        };

        var title = this.state.contact.firstName + "'s Personal Information";
        var subTitle = <>Complete your digital application starting with your personal information.<br />Once complete we will review everything and send this application form to you for electronic signature.</>;

        var ptsb = context.mortgage?.preferredLender === "PTSB";
        var f = this.state.fields.getFieldByName("mothersMaidenName");
        f.optional = (ptsb === false);

        var applicantOnePaysText;
        var applicantTwoPaysText;
        if (context.mortgage?.secondaryApplicant !== null) {
            if (context.applicantNo === 1) {
                applicantOnePaysText = "you pay";
                applicantTwoPaysText = context.mortgage?.secondaryApplicant.firstName + " pays";
            } else {
                applicantOnePaysText = context.mortgage?.primaryApplicant.firstName + " pays";
                applicantTwoPaysText = "you pay";
            }
        }

        return (
            <>
                <ApplicantPage applicantNo={this.state.applicantNo} title={title} subTitle={subTitle} next={this.next} prev={this.prev}
                    contact={this.state.contact} handleSubmit={this.applicantService.handleSubmit} showFinishButton={false}
                    emptyOnlyClick={() => this.setState({ emptyOnly: !this.state.emptyOnly })} percentComplete={this.state.contact.personalInfoPercentage}>
                    <Container className="pill-form">
                        <>
                            <Container>
                                <Row className="pill-row">
                                    <Stack gap={1} direction="horizontal" className="pill-stack">
                                        <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("firstName")} ></InputPill>
                                        <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("lastName")} ></InputPill>
                                        <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("sex")} ></InputPill>
                                        <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("maritalStatus")} ></InputPill>

                                        <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("birthDate")} ></InputPill>
                                        <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("placeOfBirth")} ></InputPill>
                                        <AutoCompletePill field={this.state.fields.getFieldByName("nationality")}
                                            handleChange={this.applicantService.handleChange}
                                            hideIfValue={this.state.emptyOnly}
                                            list={this.state.nationalitiesList}
                                        ></AutoCompletePill>
                                        <AutoCompletePill field={this.state.fields.getFieldByName("countryOfBirth")}
                                            handleChange={this.applicantService.handleChange}
                                            hideIfValue={this.state.emptyOnly}
                                            list={this.state.countryList}
                                        ></AutoCompletePill>

                                        <AggregatePill size="lg" {...childrenPill}></AggregatePill>
                                        <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("hasChildcareCost")} ></InputPill>
                                        {this.state.contact.hasChildcareCost && context.mortgage?.secondaryApplicant !== null &&
                                            <LinkPill
                                                hideIfValue={this.state.emptyOnly}
                                                field={this.state.fields.getFieldByName("monthlyChildcareCost")}
                                                valueToDisplay={currencyFormatter(currencyToNumber(this.state.fields.getFieldByName("monthlyChildcareCost").value as string)+currencyToNumber(this.state.fields.getFieldByName("monthlyChildcareCostOther").value as string))}
                                                label='Childcare'
                                                modalSize='lg'
                                                saveOnDone={this.applicantService.saveAllFields}
                                                overlay={<BothApplicantFieldsWithAggregate
                                                    title='Childcare'
                                                    field={this.state.fields.getFieldByName("monthlyChildcareCost")}
                                                    fieldLabel={`How much ${this.state.applicantNo === 1 ? applicantOnePaysText : applicantTwoPaysText}:`} 
                                                    field2={this.state.fields.getFieldByName("monthlyChildcareCostOther")}
                                                    field2Label={`How much ${this.state.applicantNo === 1 ? applicantTwoPaysText : applicantOnePaysText}:`} 
                                                    totalLabel={'Total childcare costs:'} />}
                                            />
                                        }
                                        {this.state.contact.hasChildcareCost && context.mortgage?.secondaryApplicant === null &&
                                            <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("monthlyChildcareCost")} ></InputPill>
                                        }
                                        <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("smoker")} ></InputPill>
                                        <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("mobile")} ></InputPill>
                                        {/*<AggregatePill {...emailPill} ></AggregatePill>*/}

                                        {/* Moved to AppolicantPersonal as per request from Grainne on 10th May 2024 */}
                                        <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("employmentStatus")} ></InputPill>

                                        <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("ppsNumber")} isInvalid={this.ppsInvalidCheck} invalidText="PPS looks invalid" ></InputPill>
                                        {context.mortgage?.preferredLender === "PTSB" &&
                                            <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("mothersMaidenName")} ></InputPill>
                                        }
                                    </Stack>
                                </Row>
                            </Container>
                        </>
                    </Container>

                </ApplicantPage>
            </>
        );
    }
}




