import React from "react";
import { Component } from "react";

import { ButtonSubmitPill } from "./ButtonSubmitPill";

interface ICopyPillProps {
  value: string;
  extraClasses?:string;
  customLabel?:string;
}

interface ICopyPillState {
  copied: boolean;
}


export class CopyPill extends Component<ICopyPillProps, ICopyPillState> {
  
  constructor(props: ICopyPillProps) {
    super(props);
    this.state = {copied: false};
    this.copy = this.copy.bind(this);
  }

  copy(value: string) {
    this.setState({copied: true}); 
    navigator.clipboard.writeText(value);
    setTimeout(() => this.setState({copied: false}), 1500); 
  }

  render() {
    return (
      <>
      <ButtonSubmitPill 
        readonly
        extraClasses={this.props.extraClasses}
        mobileLabel="Copy your link" 
        label="Your Referral link:" 
        buttonLabel={<i className="far fa-copy"></i>} 
        onSubmit={this.copy}
        value={this.props.value}></ButtonSubmitPill>
        <div className={'float-right ' + (this.state.copied ? 'copied' : 'notcopied')}><i className="fas fa-check"></i></div>
        </>
    );
  }
}

