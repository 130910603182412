import React from "react";
import { Component } from "react";
import "./Timeline.css";

export interface TimelineProps {
    items: TimelineItem[];
}

export enum TimelineItemStatus {
    Completed = 1,
    Inactive = 2,
    ToDo = 3,
    Waiting = 4
}

export interface TimelineItem {
    status: TimelineItemStatus
    title: React.ReactNode;
    content: React.ReactNode | null;
    key: string;
}

export class Timeline extends Component<TimelineProps, any> {
    constructor(props: TimelineProps) {
        super(props);
    }

    getColor(item: TimelineItem) {
        if (item.status === TimelineItemStatus.Completed)
            return "var(--teal)";
        if (item.status === TimelineItemStatus.Inactive)
            return "#aaaaaa";
        if (item.status === TimelineItemStatus.ToDo)
            return "var(--teal)";
        if (item.status === TimelineItemStatus.Waiting)
            return "var(--teal)";
    }

    getBackgroundImageStyle(item: TimelineItem) {        
        var color = this.getColor(item);
        return `linear-gradient(${color}, ${color})`;
    }

    render(): React.ReactNode {
        var items = this.props.items.map((item: any, idx: number) => {
            return (
                <div key={`${item.key}`}>
                    <div className={"timeline"}>
                        {/* <div className='main-nav-link nav-link ' style={{ color: this.getColor(item) }}> */}
                        <div className='timeline-icon ' style={{ color: this.getColor(item) }}>
                            {item.status === TimelineItemStatus.Completed &&
                                <i className="fas fa-circle-check fa-lg"></i>
                            }
                            {item.status === TimelineItemStatus.Waiting &&
                                <i className="fa-regular fa-clock fa-lg"></i>
                            }
                            {item.status !== TimelineItemStatus.Waiting && item.status !== TimelineItemStatus.Completed && 
                                <i className="far fa-circle fa-lg"></i>
                            }
                        </div>
                        <div className='timeline-header'>
                            <span className="ih5">{item.title}</span>
                        </div>
                    </div>
                    {item.content && <div className='timeline-content' style={{ backgroundImage: this.getBackgroundImageStyle(item) }}>
                        {item.content}
                    </div>}
                </div>
            );
        });
        return (
            <div style={{ textAlign: 'left' }}> {items}</div >
        );
    }
}