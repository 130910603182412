/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */

function getParameter(p: string | undefined) {
    //logger.log(p);
    return p == undefined ? "" : p;
}

export const b2cPolicies = {   
    names: {
        signUpSignIn: getParameter(process.env.REACT_APP_MSAL_SIGNINSIGNUP_PROFILE),
        forgotPassword: getParameter(process.env.REACT_APP_MSAL_FORGOT_PASSWORD_PROFILE),
        editProfile: getParameter(process.env.REACT_APP_MSAL_EDIT_PROFILE_PROFILE)
        },
    authorities: {
        signUpSignIn: {
            authority: getParameter(process.env.REACT_APP_MSAL_SIGNINSIGNUP_AUTHORITY),
        },
        forgotPassword: {
            authority: getParameter(process.env.REACT_APP_MSAL_FORGOT_PASSWORD_AUTHORITY),
        },
        editProfile: {
            authority: getParameter(process.env.REACT_APP_MSAL_EDIT_PROFILE_AUTHORITY)
        }
    },
    authorityDomain: getParameter(process.env.REACT_APP_MSAL_AUTHORITY_DOMAIN)
}


/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig = {
    auth: {
        clientId: getParameter(process.env.REACT_APP_MSAL_CLIENT_ID), // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority. //'https://login.microsoftonline.com/PUT_YOUR_TENANT_ID_HERE', // Tenant ID of the React.JS App Registration
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: "/", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    /*system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            }
        }
    }*/
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
    apiHello: {
        endpoint: "http://localhost:5000/hello",
        scopes: [getParameter(process.env.REACT_APP_MSAL_SCOPE)], // e.g. api://xxxxxx/access_as_user
    },
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginApiRequest = {
    scopes: [getParameter(process.env.REACT_APP_MSAL_SCOPE)],
};

