import { FieldsCollection } from './FieldsCollection';

export class PropertyFieldsCollection extends FieldsCollection {
    constructor() {
        super();
        this.addTextField("propertyAddress", "Property Address");
        this.addTextField("eircode", "Property Eircode");
        this.addPicklistField("houseType", "House Type",
            [{ text: "Detached", value: 755980000 }, { text: "Semi-d", value: 755980001 },
            { text: "Bungalow", value: 755980002 }, { text: "1Bed", value: 755980003 }, { text: "Terraced", value: 755980004 },
            { text: "Apartment", value: 755980005 }, { text: "Studio", value: 755980006 }]);
        this.addPicklistField("propertyType", "Property Type",
            [{ text: "New Build", value: 755980000 }, { text: "Self Build", value: 755980001 },
            { text: "Second Hand", value: 755980002 }]);
        this.addTextField("yearofConstruction", "Year of construction");
        this.addDecimalField("squareFootageoftheProperty", "Total Square Footage", 0, 10000);
        this.addNumberField("totalNumberofBedrooms", "Number of Bedrooms", 0, 100);
        this.addNumberField("totalNumberofRooms", "Number of Rooms", 0, 100);
        this.addPicklistField("freeholdorLeasehold", "Freehold/Leasehold",
            [{ text: "Freehold", value: 755980000 }, { text: "Leasehold", value: 755980001 }]);
        //this.addBooleanField("registeredInBothYourNames", "Registered In Both");
        this.addPicklistField("berRating", "BER Rating",
            [{ text: "A1", value: 755980000 },
            { text: "A2", value: 755980001 },
            { text: "A3", value: 755980002 },
            { text: "B1", value: 755980003 },
            { text: "B2", value: 755980004 },
            { text: "B3", value: 755980005 },
            { text: "C1 or less", value: 755980006 },
            { text: "Unknown", value: 755980007 },
            { text: "Exempt", value: 755980008 }]);
        this.addBooleanField("requirestructuralrenovations", "Require renovations?");
        this.addCurrencyField("costofTheWork", "Cost of the work", 0, 1000000);
        this.addPicklistField("selfOwnedSite", "Self owned site?", [{ text: "Yes", value: 755980000 }, { text: "No", value: 755980001 }]);
        this.addPicklistField("selfBuildContractType", "Direct Labour/Contract", [{text: "Direct Labour", value: 755980000}, {text: "Contract", value: 755980001}]);
        this.addBooleanField("hasTheBuildObtainedFullPlanningPermission", "Full Permission?");
        this.addCurrencyField("estimatedCostofTheBuild", "Estimated Cost", 0, 10000000);
        this.addTextField("pleaseConfirmIfTheSiteIsBeingGifted", "Gifted/Purchasing");
        this.addBooleanField("equityReleasetoCarryOutHomeImprovements", "Equity Release to Carry Out Home Improvements");
        this.addBooleanField("saleAgreed", "Sale Agreed?");
    }
}