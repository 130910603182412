import { cssColor } from "@fluentui/react";
import { StringifyOptions } from "querystring";
import React from "react";
import { Row, Col, Container, Accordion } from "react-bootstrap";
import { MortgageDocumentStatusCode } from "../models/PortalMortgageDocumentModel";
import { Tooltip } from "./Tooltip";
import axios from "axios";
import { PortalMortgageModel } from "../models/PortalMortgageModel";

interface IDocumentListProps {
    list: IDocumentListItem[];
    columnCount: number;
    title: string;
    legend: string;
}

interface IDocumentListItem {
    key: string;
    text: string;
    class: string;
    percentComplete: number;
    drillthrough: IDocumentListProps;
    needsAction: boolean;
}

interface IDocumentListState {
    parent: IDocumentListProps;
    context: IDocumentListProps;
}

export class DocumentList extends React.Component<any, IDocumentListState> {
    constructor(props: any) {
        super(props);

        let categories: IDocumentListItem[] = [];
        props.categories.forEach((element: string) => {
            let d = props.documents.filter((item: { category: string; }) => { return item.category === element });
            let needsActionDocs = d.filter((item: { statusReason: number; }) => { return item.statusReason === MortgageDocumentStatusCode.NeedsAction });
            categories.push(
                {
                    key: element,
                    text: element,
                    class: '',
                    percentComplete: 0,
                    drillthrough: { columnCount: 1, title: "Outstanding documents in " + element, legend: '', list: d },
                    needsAction: needsActionDocs.length > 0 ? true : false
                }
            );
        });

        let rootProps: IDocumentListProps = { columnCount: 1, title: "Outstanding categories", legend: '', list: categories };

        this.state = { parent: rootProps, context: rootProps };
    }

    getPercentCompleteClass(percentComplete: number) {
        if (percentComplete < 25)
            return "DocumentList-perc-0";
        if (percentComplete < 50)
            return "DocumentList-perc-25";
        if (percentComplete < 75)
            return "DocumentList-perc-50";
        if (percentComplete < 100)
            return "DocumentList-perc-75";
        return "DocumentList-perc-100";
    }

    drillthrough(item: IDocumentListItem) {
        if (item.drillthrough) {
            this.setState({ parent: this.state.parent, context: item.drillthrough });
        }
    }

    home() {
        this.setState({ parent: this.state.parent, context: this.state.parent });
    }

    render() {
        const parent = this.state.parent;
        const context = this.state.context;
        const list = context.list ?? [];
        const columnCount = context.columnCount || 1;
        const colClass = (columnCount == 2 ? "col-5" : "col-10") + " text-start"

        const legendClass = colClass + " text-start";

        return (
            <Container className="documentlist">
                <Row>
                    <Col className={legendClass}>
                        <h4><span>{parent.legend} </span></h4>
                    </Col>
                </Row>
                <div className="tape" />
                <div className="postit">
                    <div onClick={(e) => this.home()}><i className="fas fa-house text-start pointer" style={{float: "left", marginTop: 6}}></i></div>
                    <h3 style={{fontFamily: 'caveat', color: 'black'}}>Document checklist</h3>
                    {list.map((item: any, item_idx: number) => (
                        <Row key={item_idx}>
                            <Col className="col-1 p-0">
                                <div className='postititem'>-</div>
                            </Col>
                            <Col className="col-11 text-start p-0 pr-3">
                                <div onClick={(e) => this.drillthrough(item)} className='postititem'>
                                    <span className={item.statusReason == 755980001 ? 'postititemdone' : ''}>
                                        {item.text ?? item.portalName}
                                    </span>
                                    &nbsp;
                                    <span>
                                        {item.statusReason == MortgageDocumentStatusCode.Approved &&
                                            <i className="fas fa-check postititem-approved"></i>}
                                        {item.needsAction &&
                                            <i className="fas fa-circle-exclamation exclamation"></i>}
                                        {item.statusReason == MortgageDocumentStatusCode.NeedsAction &&
                                            <Tooltip tooltip={{ "fieldname": "", "title": "Needs Action", "content": item.comments }} >
                                                <i className="fas fa-circle-exclamation exclamation"></i>
                                            </Tooltip>}
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    ))}
                </div>
            </Container>
        );
    }
}