export class ProfileItemModel {
    id: number;
    title: string;
    description: string;
    duration: any;
    durationClass: string;
    percent: number;
    progress: string;
    progressClass: string;
    url: string|null;
    initials: string;
    initialsClass: string;
    icon: any;

    constructor(id: number, title: string, description: string, duration: any, durationClass:string, percent:number, progress:string, progressClass:string, url: string|null, initials: any, initialsClass:string, icon:any) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.duration = duration;
        this.durationClass = durationClass;
        this.percent = percent ?? null;
        this.progress = progress;
        this.progressClass = progressClass;
        this.url = url;
        this.initials = initials;
        this.initialsClass = initialsClass;
        this.icon = icon;
    }
}