import axios from "axios";
import { Component } from "react";
import UserContext from "../components/UserContext";
import { ApplicantFieldsCollection } from "../forms/ApplicantFields";
import currencyFormatter, { currencyToNumber } from "../helper/currencyFormatter";
import { GetTooltips } from "../helper/tooltipHelper";
import { DataType, ControlType, FormLookupItem } from "../models/PortalMetadata";
import { ApplicableTo } from "../models/PortalMortgageDocumentModel";
import { PortalContactModel } from "../models/PortalContactModel";

export class ApplicantService {
    userContext: UserContext;
    component: Component;
    applicantNo: number;

    constructor(userContext: UserContext, component: Component, applicantNo: number) {
        this.userContext = userContext;
        this.component = component;
        this.applicantNo = applicantNo;

        this.handleChange = this.handleChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.getfields = this.getfields.bind(this);
        this.saveAllFields = this.saveAllFields.bind(this);

        //this.userContext.getMortgageDocuments(this.getApplicantId());
    }

    public setState(state: any): void {
        this.component.setState(state);
    }

    public getState(): any {
        return this.component.state;
    }

    getfields(contact: any, helperTexts: any) {
        if (contact !== null && helperTexts !== null) {
            let fields = new ApplicantFieldsCollection();
            if (this.getState().occupationList) {
                fields.addLookupValues("occupationId", this.getState().occupationList);
                fields.addLookupValues("prevOccupationId", this.getState().occupationList);
            }
            if (this.getState().nationalitiesList)
                fields.addLookupValues("nationality", this.getState().nationalitiesList);
            if (this.getState().countryList)
                fields.addLookupValues("countryOfBirth", this.getState().countryList);

            fields.loadData(contact, helperTexts, true, this.handleChange, this.handleDateChange);
            this.setState({ fields: fields, loading: false, helperTexts: helperTexts });
        } else {
            this.setState({ loading: false });
        }
        //this.setState({ fields: getApplicantFields(contact, helperTexts, !this.state.usingCard, this.handleChange, this.handleDateChange), loading: false });
    }

    updateDirtyFlag() {
        let dirtyFields: any[] = [];
        for (let fieldName in this.getState().fields.fields) {
            let f = this.getState().fields.fields[fieldName];
            if (f.isDirty)
                dirtyFields.push(f);
        }

        var isDirty = dirtyFields.length > 0;
        this.userContext.setIsDirty(isDirty);
    }

    saveAllFields(redirect: any) {
        // var saveOtherChildcare = false;
        // var saveOtherRent = false;
        var contact = this.getState().contact;
        var otherApplicant: PortalContactModel = (this.userContext.mortgage?.primaryApplicant.contactId === contact.contactId ? this.userContext.mortgage!.secondaryApplicant : this.userContext.mortgage!.primaryApplicant);
        let fields = this.getState().fields.fields;

        let dirtyFields: any[] = [];
        for (let fieldName in fields) {
            let f = fields[fieldName];

            // Patch for null smoker. Hard to apply this to all booleans as React forces you to strongly type properties, unlike JavaScript
            if (f.name === "smoker") {
                if (f.isDirty === false && contact.smoker === null) {
                    this.getState().fields.updateModel(contact, "smoker", 0);
                    this.getState().fields.updateFieldValue("smoker", 0)
                }
            }

            if (f.isDirty) {
                dirtyFields.push(f);

                if (this.userContext.mortgage!.secondaryApplicant !== null) {
                    if (f.name === "monthlyChildcareCost") {
                        this.getState().fields.updateModel(otherApplicant, "monthlyChildcareCostOther", (f.value));
                    }
        
                    if (f.name === "monthlyChildcareCostOther") {
                        this.getState().fields.updateModel(otherApplicant, "monthlyChildcareCost", (f.value));
                    }
        
                    if (f.name === "monthlyRentAmount") {
                        this.getState().fields.updateModel(otherApplicant, "monthlyRentAmountOther", (f.value));
                    }

                    if (f.name === "monthlyRentAmountOther") {
                        this.getState().fields.updateModel(otherApplicant, "monthlyRentAmount", (f.value));
                    }
                }
            }
        }

        if (dirtyFields.length === 0) {
            if (redirect)
                this.setState({ finished: true });
            return;
        }

        let newContact = this.getState().fields.getAsEntity("contactId", contact.contactId, contact);
        newContact.mortgageId = this.userContext.mortgage?.mortgageId;
        axios.post('/contacts/update', newContact)
            .then(response => {
                for (let i = 0; i < dirtyFields.length; i++) {
                    let f = dirtyFields[i];
                    f.originalValue = f.value;
                    f.isDirty = false;
                }

                this.updateDirtyFlag();

                var data = response.data;
                contact.personalInfoPercentage = data.personalInfoPercentage;
                contact.residentialInfoPercentage = data.residentialInfoPercentage;
                contact.employmentInfoPercentage = data.employmentInfoPercentage;

                contact.percentageOfCompletion =
                    (contact.personalInfoPercentage +
                        contact.residentialInfoPercentage +
                        contact.employmentInfoPercentage +
                        contact.assetsInfoPercentage) / 4;

                // BANDAID - THIS IS TO KEEP THE APPLICANT MENU IN SYNC AS IT USES THE CONTEXT
                if (this.getState().applicantNo === 1) {
                    this.userContext.mortgage!.primaryApplicant = contact;
                } else {
                    this.userContext.mortgage!.secondaryApplicant = contact;
                }

                if (redirect)
                    this.setState({ finished: true, fields: this.getState().fields, contact: contact });

                else
                    this.setState({ fields: this.getState().fields, contact: contact });
            });
    }


    saveRateUpdateStatus(newValue: number) {
        var contact = this.getState().contact;
        contact.rateUpdateStatus = newValue;
        this.getState().fields.updateModel(contact, "rateUpdateStatus", newValue);
        this.getState().fields.updateFieldValue("rateUpdateStatus", newValue);
        this.saveAllFields(null);
    }

    handleSubmit(event: any) {
        event.preventDefault();
        this.saveAllFields(true);
    }

    handleDateChange(event: any) {
        this.handleChange(event);
    }

    handleChange(event: any) {
        const target = event.target;
        let value = (target.type === 'checkbox' ? target.checked : target.value); // || (target.textContent || '');
        const name = target.name || target.getAttribute('name');

        if (!name)
            return;

        var contact = this.getState().contact;

        let f = this.getState().fields.getFieldByName(name);

        if (f.dataType === DataType.number && value !== null) {
            value = value.replace(/\D/g, '');
        }

        if (f.dataType === DataType.number && f.maxValue) {
            if (parseInt(value) > f.maxValue) {
                alert(`Maximum value for '${f.label}' is ${f.maxValue}`);
                return;
            }
        } else if (f.dataType === DataType.string && f.maxLength) {
            if (value !== null && value.length > f.maxLength) {
                alert(`Maximum length for '${f.label}' is ${f.maxLength}`);
                return;
            }
        }

        //// if we don't set the value here it sometimes doesn't get set and causes a bug with reverting to the original value
        let fields = this.getState().fields;
        if (fields) {
            if (f.controlType === ControlType.currency) {
                fields.updateModel(contact, name, value?.replaceAll(",", "").replaceAll("€", ""));
                fields.updateFieldValue(name, currencyFormatter(Number(value?.replaceAll(",", "").replaceAll("€", ""))));
            } else {
                fields.updateModel(contact, name, value);
                fields.updateFieldValue(name, value);
            }
        }

        this.setState({ fields: this.getState().fields, contact: contact });
        this.updateDirtyFlag();
    }

    handleTitleChange(event: any, title: string) {
        var contact = this.getState().contact;
        contact['title'] = title;
        this.setState({
            contact: contact
        });
    }

    async getApplicantData() {
        if (this.applicantNo === 1) {
            this.setState({ contact: this.userContext.mortgage?.primaryApplicant, otherApplicant: this.userContext.mortgage?.secondaryApplicant, loading: true });
        } else {
            this.setState({ contact: this.userContext.mortgage?.secondaryApplicant, otherApplicant: this.userContext.mortgage?.primaryApplicant, loading: true });
        }
        this.getTooltips("contact");
    }

    getTooltips(entityname: string) {
        var contact: any;
        if (this.applicantNo === 1) {
            contact = this.userContext.mortgage?.primaryApplicant;
        } else {
            contact = this.userContext.mortgage?.secondaryApplicant;
        }
        GetTooltips(entityname).then((response) => {
            this.setState({ helperTexts: response.data });
            this.getfields(contact, response.data);
        });
    }

    getApplicantId(): string {
        let contactId = this.userContext.contact?.contact1Id;
        if (this.applicantNo !== 1 && this.userContext.contact?.contact2Id !== null) {
            contactId = this.userContext.contact?.contact2Id;
        }
        return contactId!;
    }

    groupBy(arr: any[], key: string) {
        const initialValue = {};
        return arr.reduce((acc, cval) => {
            const myAttribute = cval[key];
            acc[myAttribute] = [...(acc[myAttribute] || []), cval]
            return acc;
        }, initialValue);
    };

    async getMortgageDocuments() {
        if (this.userContext.mortgageDocumentList) {
            let applicableTo = this.applicantNo === 1 ? ApplicableTo.Primary : ApplicableTo.Secondary;
            let docs = this.userContext.mortgageDocumentList.filter(c => c.applicableTo === ApplicableTo.Shared || c.applicableTo === applicableTo || c.isJoint === true);
            docs.sort(
                (a: any, b: any) => {
                    // We want to primarily sort by mortgage document id, but if these are null make sure they appear first
                    // We next want to sort by the order property (if its set) as this allows to change the order before merging
                    if (
                        a.categoryOrder === b.categoryOrder) {
                        // if we have 2 null mortgage documents, or 2 matching mortgage documents, sort by the order attribute
                        if (!a.applicableTo && !b.applicableTo) return 1;
                        if (a.applicableTo < b.applicableTo) return -1;
                        if (a.applicableTo > b.applicableTo) return 1;
                    }

                    // Compare if the document needs to come before or after
                    if (a.categoryOrder < b.categoryOrder) return -1;
                    if (a.categoryOrder > b.categoryOrder) return 1;

                    // If we get here, we've probably hit equal documents and orders... 
                    // shouldn't happen, but in case paul got at the code you never know
                    return 0;
                }
            );
        }
    }

    async getDocumentTypes() {
        let url = "/Dictionary/GetDocumentTypes";
        axios
            .get(url)
            .then((response) => {
                var list = response.data;
                this.setState({ documentTypeList: list, });
            })
            .catch((error) => {
                if (error.response.statusCode === 401) {
                    alert("Unauthorized");
                }
            });
    }

    async getOccupations() {
        return new Promise((resolve, reject) => {
            if (this.getState().occupationList) {
                resolve(this.getState().occupationList);
            }

            let url = "/Dictionary/GetOccupations";
            axios
                .get(url)
                .then((response) => {
                    var list = response.data;
                    var ocpList = list.map((item: any) => {
                        return { text: item.name, value: item.occupationId } as FormLookupItem;
                    });
                    this.setState({ occupationList: ocpList });
                    resolve(ocpList);
                })
                .catch((error) => {
                    if (error.response.statusCode === 401) {
                        alert("Unauthorized");
                    }
                    reject();
                });
        });
    }

    async getNationalities() {
        return new Promise((resolve, reject) => {
            let url = "/Dictionary/GetNationalities";
            axios
                .get(url)
                .then((response) => {
                    var list = response.data;
                    var items = list.map((item: any) => {
                        return { text: item.name, value: item.id, item: item } as FormLookupItem;
                    });
                    this.setState({ nationalitiesList: items });
                    resolve(items);
                })
                .catch((error) => {
                    if (error.response.statusCode === 401) {
                        alert("Unauthorized");
                    }
                    reject();
                });

        });
    }

    async getCountries() {
        return new Promise((resolve, reject) => {
            let url = "/Dictionary/GetCountries";
            axios
                .get(url)
                .then((response) => {
                    var list = response.data;
                    var items = list.map((item: any) => {
                        return { text: item.name, value: item.id, item: item } as FormLookupItem;
                    });
                    this.setState({ countryList: items });
                    resolve(items);
                })
                .catch((error) => {
                    if (error.response.statusCode === 401) {
                        alert("Unauthorized");
                    }
                    reject();
                });

        });
    }

    // getInstitutions() {
    //     let url = "/Dictionary/GetInstitutions";
    //     axios
    //         .get(url)
    //         .then((response) => {
    //             let insList: FormLookupItem[] = response.data.map((item: any) => {
    //                 return { text: item.name, value: item.institutionId } as FormLookupItem;
    //             });
    //             this.setState({ institutionList: insList });
    //         })
    //         .catch((error) => {
    //             if (error.response.statusCode === 401) {
    //                 alert("Unauthorized");
    //             }
    //         });
    // }

    getFinanceTooltips() {
        GetTooltips("mortgageportaldocument").then((response) => {
            this.setState({ dochelperTexts: response.data });
        }).catch((error) => {
            if (error.response.statusCode === 401) {
                alert("Unauthorized");
            }
        });
    }
}