import { Component } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { FormControl } from "./FormControl";
//for google autocomplete
//import { addressAutocomplete } from "./PlaceAutocomplete";
//for data8 autocomplete
import {
  addressAutocomplete,
  addressDrilldown,
  addressRetrieve,
} from "./Data8AutoComplete";
import { Field } from "../models/PortalMetadata";
import "./Address.css";
import { ApplicantFieldsCollection } from "../forms/ApplicantFields";

interface IAddressProps {
  address: string;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  eircode?: string;
  county?: string;
  country?: string;
  city?: string;
  movedInDate?: string;
  mortgageBefore?: string;

  letManual: boolean;
  helperTexts: any[];
  record: any;
  fields: ApplicantFieldsCollection;
  handleChange(event: any): void;
  bubbleUpValidity?(isValid: boolean): void;
  disabled?: boolean;
  customAddressPrompt?: any;
}

interface IAddressState {
  selectedPrediction: string;
  predictions: any[];
  searchValue?: string | null;
  AutoComplete: boolean;
  helperTexts: any[];
  record: any;
  fields: ApplicantFieldsCollection;
  address: string;
  invalidAddress: boolean;
  customError: string;
  sessionId: string;
}

export class Address extends Component<IAddressProps, IAddressState> {
  constructor(props: any) {
    super(props);
    //this.handleAddress = this.handleAddress.bind(this);
    this.toggleManualEntry = this.toggleManualEntry.bind(this);
    this.toggleAddressSearch = this.toggleAddressSearch.bind(this);
    this.setPredictions = this.setPredictions.bind(this);
    this.handlePredictionSelection = this.handlePredictionSelection.bind(this);
    this.selectAddress = this.selectAddress.bind(this);

    let address: any = this.getFieldByName(this.props.address)?.value;
    this.getCompositeAddress = this.getCompositeAddress.bind(this);
    this.bubbleUpValidity = this.bubbleUpValidity.bind(this);
    this.onBlurEvent = this.onBlurEvent.bind(this);
    this.drilldownPredictions = this.drilldownPredictions.bind(this);

    this.state = {
      selectedPrediction: "",
      predictions: [],
      AutoComplete: true,
      helperTexts: [],
      record: props.record,
      fields: props.fields,
      address: address,
      invalidAddress: false,
      sessionId: "",
      customError: "",
    };
  }

  toggleManualEntry() {
    this.setState({ AutoComplete: false });
    if (this.props.eircode) {
      this.bubbleUpValidity(
        (this.getFieldByName(this.props.eircode).value ?? "") !== ""
      );
    } else {
      this.bubbleUpValidity(true);
    }
  }

  toggleAddressSearch() {
    this.setState({ AutoComplete: true });
    this.bubbleUpValidity(!this.state.invalidAddress);
  }

  // handleAddress() {
  //     this.setState({ AutoComplete: !this.state.AutoComplete });
  //     this.bubbleUpValidity(!this.state.invalidAddress);
  // }

  clearSearch(value: string) {
    if (value === "")
      this.setState({ selectedPrediction: "", predictions: [] });
  }

  setSearchValue(searchValue: string) {
    this.setState({ searchValue: searchValue, address: searchValue });
    this.searchPredictions(searchValue);
  }

  bubbleUpValidity(isValid: boolean) {
    if (this.props.bubbleUpValidity) this.props.bubbleUpValidity(isValid);
  }

  async searchPredictions(text: string) {
    this.bubbleUpValidity(false);
    this.setState({ invalidAddress: true });
    if (!text) {
      return [];
    }
    if (text.length < 2) {
      return [];
    }

    // To turn off addressing uncomment this...
    //return [];
    // TURNED OFF

    addressAutocomplete(text, this.state.sessionId)
      .then((predictions) => {
        this.setPredictions(predictions.data.results);
        if (predictions.data.status.success) {
          this.setState({ sessionId: predictions.data.sessionID });
        } else {
          this.setState({ customError: predictions.data.status.errorMessage });
        }

        // if (predictions === null || predictions.length === 0) {
        //     this.setState({ invalidAddress: true });

        // } else {
        //     this.setState({ invalidAddress: false, sessionId: predictions.data.sessionID });
        // }
      })
      .catch((e) => {
        this.setState({
          customError:
            "There was an error trying to retrieve the address. Please enter the address manually",
        });
        //alert(1);
      });
  }

  async drilldownPredictions(value: string) {
    this.bubbleUpValidity(false);
    this.setState({ invalidAddress: true });

    addressDrilldown(value)
      .then((predictions) => {
        this.setPredictions(predictions.data.results);
        this.setState({ sessionId: predictions.data.sessionID });

        // if (predictions === null || predictions.length === 0) {
        //     this.setState({ invalidAddress: true });

        // } else {
        //     this.setState({ invalidAddress: false, sessionId: predictions.data.sessionID });
        // }
      })
      .catch((e) => {
        //alert(1);
      });
  }

  setPredictions(nextPredictions: any) {
    this.setState({ predictions: nextPredictions });
  }

  isEircode(eircode: string) {
    var pattern =
      "\\b(?:(" +
      "a(4[125s]|6[37]|7[5s]|[8b][1-6s]|9[12468b])|" +
      "c1[5s]|" +
      "d([0o][1-9sb]|1[0-8osb]|2[024o]|6w)|" +
      "e(2[15s]|3[24]|4[15s]|[5s]3|91)|" +
      "f(12|2[368b]|3[15s]|4[25s]|[5s][26]|9[1-4])|" +
      "h(1[2468b]|23|[5s][34]|6[25s]|[79]1)|" +
      "k(3[246]|4[5s]|[5s]6|67|7[8b])|" +
      "n(3[79]|[49]1)|" +
      "p(1[247]|2[45s]|3[126]|4[37]|[5s][16]|6[17]|7[25s]|[8b][15s])|" +
      "r(14|21|3[25s]|4[25s]|[5s][16]|9[35s])|" +
      "t(12|23|34|4[5s]|[5s]6)|" +
      "v(1[45s]|23|3[15s]|42|9[2-5s])|" +
      "w(12|23|34|91)|" +
      "x(3[5s]|42|91)|" +
      "y(14|2[15s]|3[45s])" +
      ")\\s?[abcdefhknoprtsvwxy\\d]{4})\\b";

    var reg = new RegExp(pattern, "i");
    //return the first Eircode
    var i = String(eircode).search(reg);
    return i !== -1;
  }

  isCounty(county: string) {
    var counties = [
      "antrim",
      "armagh",
      "cavan",
      "derry",
      "donegal",
      "down",
      "fermanagh",
      "monaghan",
      "tyrone",
      "galway",
      "leitrim",
      "mayo",
      "roscommon",
      "sligo",
      "carlow",
      "dublin",
      "kildare",
      "kilkenny",
      "laois",
      "longford",
      "louth",
      "meath",
      "offaly",
      "westmeath",
      "wexford",
      "wicklow",
      "clare",
      "cork",
      "kerry",
      "limerick",
      "tipperary",
      "waterford",
    ];
    if (counties.find((c) => county.toLowerCase() === c)) return true;
    return false;
  }

  isCountry(country: string) {
    // in this release we are limited to ireland so just lookup that
    return country.toLowerCase() === "ireland";
  }

  getFieldByName(name: string): Field {
    return this.props.fields.getFieldByName(name);
  }

  setFieldValue(fieldName: string, value: string) {
    let fields = this.state.fields;
    if (fields) {
      fields.updateModel(this.props.record, fieldName, value);
      fields.updateFieldValue(fieldName, value);
    }
    this.setState({ fields: this.state.fields, record: this.state.record });
  }

  isNullOrEmpty(s: any): boolean {
    return s === null || s === undefined || s === "";
  }

  getCompositeAddress() {
    let addressLine1 = "",
      addressLine2 = "",
      addressLine3 = "";
    if (this.props.addressLine1)
      addressLine1 = this.getFieldByName(this.props.addressLine1)
        .value as string;
    if (this.props.addressLine2)
      addressLine2 = this.getFieldByName(this.props.addressLine2)
        .value as string;
    if (this.props.addressLine3)
      addressLine3 = this.getFieldByName(this.props.addressLine3)
        .value as string;

    let eircode = "",
      city = "",
      county = "",
      country = "";
    if (this.props.eircode)
      eircode = this.getFieldByName(this.props.eircode).value as string;
    if (this.props.county)
      county = this.getFieldByName(this.props.county).value as string;
    if (this.props.country)
      country = this.getFieldByName(this.props.country).value as string;
    if (this.props.city)
      city = this.getFieldByName(this.props.city).value as string;

    let address = "";

    if (!this.isNullOrEmpty(addressLine1)) {
      address = addressLine1;
    }

    if (!this.isNullOrEmpty(addressLine2)) {
      address += ", " + addressLine2;
    }

    if (!this.isNullOrEmpty(addressLine3)) {
      address += ", " + addressLine3;
    }

    if (!this.isNullOrEmpty(county)) {
      address += ", " + city;
    }

    if (!this.isNullOrEmpty(county)) {
      address += ", " + county;
    }

    if (!this.isNullOrEmpty(eircode)) {
      address += ", " + eircode;
    }

    if (!this.isNullOrEmpty(country)) {
      address += ", " + country;
    }

    this.setFieldValue(this.props.address, address);

    return address;
  }

  addressManuallyUpdated() {
    this.setState({ address: this.getCompositeAddress() });
    if (this.props.eircode)
      this.bubbleUpValidity(
        (this.getFieldByName(this.props.eircode).value ?? "") !== ""
      );
  }

  handlePredictionSelection = (e: any, prediction: any) => {
    e.preventDefault();
    this.bubbleUpValidity(true);
    if (prediction.container) {
      this.drilldownPredictions(prediction.value);
    } else {
      this.selectAddress(prediction);
    }
  };

  selectAddress = (prediction: any) => {
    addressRetrieve(prediction.value).then((address) => {
      var record = this.state.record;
      this.setFieldValue(this.props.address, prediction.label);

      if (this.props.eircode) this.setFieldValue(this.props.eircode, "");
      if (this.props.county) this.setFieldValue(this.props.county, "");
      if (this.props.city) this.setFieldValue(this.props.city, "");
      if (this.props.country) this.setFieldValue(this.props.country, "");
      if (this.props.addressLine1)
        this.setFieldValue(this.props.addressLine1, "");
      if (this.props.addressLine2)
        this.setFieldValue(this.props.addressLine2, "");
      if (this.props.addressLine3)
        this.setFieldValue(this.props.addressLine3, "");

      //var line = 1;
      var lines = address.data.result.address.lines;
      for (let i = 0; i < lines.length; i++) {
        var term = lines[i];
        if (i === 0 && this.props.addressLine1) {
          this.setFieldValue(this.props.addressLine1, term);
        } else if (i === 1 && this.props.addressLine2) {
          this.setFieldValue(this.props.addressLine2, term);
        } else if (i === 2 && this.props.addressLine3) {
          this.setFieldValue(this.props.addressLine3, term);
        } else if (i === 4 && this.props.city) {
          this.setFieldValue(this.props.city, term);
        } else if (i === 5 && this.props.county) {
          this.setFieldValue(this.props.county, term);
        } else if (i === 6 && this.props.eircode) {
          this.setFieldValue(this.props.eircode, term);
        }
      }

      this.getCompositeAddress();

      this.setState({
        record: record,
        fields: this.state.fields,
        address: prediction.label,
        selectedPrediction: address,
        predictions: [],
        searchValue: "",
        invalidAddress: false,
      });

      this.props.handleChange({
        target: { name: this.props.address, value: prediction.label },
      });
    });
  };

  onBlurEvent(e: any) {
    this.clearSearch(e.target.value);
    if (this.props.bubbleUpValidity === undefined) {
      // if we don't have validity check enabled then just grab the address on blur
      this.setFieldValue(this.props.address, e.target.value);
      this.props.handleChange({
        target: { name: this.props.address, value: e.target.value },
      });
    }
  }

  render() {
    return (
      <>
        <div className="address-container">
          {this.state.AutoComplete && (
            <Form.Group className="text-start">
              <Row>
                <Col xs={this.props.letManual ? 9 : 12}>
                  {this.props.customAddressPrompt ?? (
                    <span className="input-group-text">
                      Start typing the address or Eircode:
                    </span>
                  )}
                  <Form.Control
                    disabled={this.props.disabled}
                    placeholder={
                      this.props.disabled
                        ? "Disabled - please enter address manually"
                        : ""
                    }
                    className="modal-control"
                    value={this.state.address}
                    type="text"
                    onChange={(e) => {
                      this.setSearchValue(e.target.value);
                    }}
                    onBlur={this.onBlurEvent}
                  />
                  {this.state.invalidAddress === true &&
                    this.props.bubbleUpValidity !== undefined && (
                      <Form.Control.Feedback
                        type="invalid"
                        style={{ display: "block" }}
                      >
                        {this.state.customError !== ""
                          ? this.state.customError
                          : "The provided address is not valid."}
                      </Form.Control.Feedback>
                    )}
                </Col>
                {this.props.letManual && (
                  <Col xs={3} className="text-end">
                    <br />
                    <br />
                    <div className="doddltext" style={{ marginTop: -10 }}>
                      <div
                        className="btn btn-standard-transparent btn-outline btn-wide"
                        onClick={this.toggleManualEntry}
                      >
                        Enter manually
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
              {this.state.predictions?.length > 0 && (
                <div className="pac-container">
                  <ul className="pac-item">
                    {this.state.predictions?.map((prediction: any | null) => (
                      <li
                        key={prediction?.value}
                        onKeyDown={(e) =>
                          this.handlePredictionSelection(e, prediction)
                        }
                        onClick={(e) =>
                          this.handlePredictionSelection(e, prediction)
                        }
                      >
                        {prediction?.label || "Not found"}
                        {prediction?.container && (
                          <div className="pac-item-count">
                            {prediction?.items} items
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </Form.Group>
          )}
          {!this.state.AutoComplete && (
            <>
              <Row>
                <Col xs={9}>
                  {this.props.addressLine1 && (
                    <FormControl
                      light
                      field={this.getFieldByName(this.props.addressLine1)}
                      fieldUpdated={() => this.addressManuallyUpdated()}
                    ></FormControl>
                  )}
                </Col>
                <Col xs={3} className="text-end">
                  <br />
                  <div className="doddltext" style={{ paddingBottom: 10 }}>
                    <div
                      className="btn btn-standard-transparent btn-outline mb-3"
                      onClick={this.toggleAddressSearch}
                    >
                      Back to address search
                    </div>
                  </div>
                </Col>
              </Row>
              {this.props.addressLine2 && (
                <FormControl
                  light
                  field={this.getFieldByName(this.props.addressLine2)}
                  fieldUpdated={() => this.addressManuallyUpdated()}
                ></FormControl>
              )}
              {this.props.addressLine3 && (
                <FormControl
                  light
                  field={this.getFieldByName(this.props.addressLine3)}
                  fieldUpdated={() => this.addressManuallyUpdated()}
                ></FormControl>
              )}
              {this.props.city && (
                <FormControl
                  light
                  field={this.getFieldByName(this.props.city)}
                  fieldUpdated={() => this.addressManuallyUpdated()}
                ></FormControl>
              )}
              <Row>
                <Col md={12} lg={6}>
                  {this.props.county && (
                    <FormControl
                      light
                      field={this.getFieldByName(this.props.county)}
                      fieldUpdated={() => this.addressManuallyUpdated()}
                    ></FormControl>
                  )}
                </Col>
                <Col md={12} lg={6}>
                  {this.props.eircode && (
                    <>
                      <FormControl
                        light
                        field={this.getFieldByName(this.props.eircode)}
                        fieldUpdated={() => this.addressManuallyUpdated()}
                      ></FormControl>
                      {(this.getFieldByName(this.props.eircode).value ?? "") ===
                        "" && (
                        <Form.Control.Feedback
                          type="invalid"
                          style={{ display: "block", marginLeft: 10 }}
                        >
                          Eircode is required
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Col>
              </Row>
              {this.props.country && (
                <FormControl
                  light
                  field={this.getFieldByName(this.props.country)}
                  fieldUpdated={() => this.addressManuallyUpdated()}
                ></FormControl>
              )}
            </>
          )}
          <Row>
            <Col xs={4}>
              {this.props.movedInDate && (
                <FormControl
                  light
                  field={this.getFieldByName(this.props.movedInDate)}
                ></FormControl>
              )}
            </Col>
            {this.props.mortgageBefore && (
              <Col xs={5}>
                {this.props.mortgageBefore && (
                  <FormControl
                    light
                    field={this.getFieldByName(this.props.mortgageBefore)}
                  ></FormControl>
                )}
              </Col>
            )}
          </Row>
        </div>
      </>
    );
  }
}
