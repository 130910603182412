import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { PortalPage } from "../components/PortalPage";
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

export class ErrorPage extends Component {
    componentDidMount() {
        document.title = "doddl - Error";
    }

    render() {
        return (            
            <PortalPage title={<>We are so sorry!</>} description={"Our portal is experiencing some issues. We are aware of this and are working to resolve the problem as soon as possible."}>
                <br/>
                <p className=' max-w-wide'><Link to="/"><Button className='btn btn-standard'>Go to home page</Button></Link></p>
            </PortalPage>
        );        
    }
}
