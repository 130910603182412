import { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Form, Row } from 'react-bootstrap';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

export class NotValid extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = { sent: false, email: "", token: "", loading: true, captchaKey: null };
        this.requestNewLink = this.requestNewLink.bind(this);
        this.onChange = this.onChange.bind(this);
        this.captchaOnChange = this.captchaOnChange.bind(this);
    }

    componentDidMount() {
        axios.defaults.baseURL = process.env.REACT_APP_API_URL;
        axios.get('/Contacts/GetCaptchaPublicKey')
            .then((response) => {
                this.setState({ captchaKey: response.data, loading: false });
            });
    }

    onChange(value: string) {
        this.setState({ email: value });
    }

    requestNewLink() {
        if (this.state.email === "" || this.state.email === null || this.state.token === "" || this.state.token === null) {
            return;
        }
        axios.defaults.baseURL = process.env.REACT_APP_API_URL;
        axios.post('/Contacts/RequestNewLink', null, { params: { email: this.state.email, token: this.state.token } });
        this.setState({ sent: true });
    }

    captchaOnChange(value: string | null) {
        this.setState({ token: value });
    }

    render() {
        if (this.state.loading)
            return <p><em>Loading...</em></p>;

        return (
            <Container className="">
                <Row className="page-title" style={{ textAlign: "center" }}>
                    <h1>Your security matters</h1>
                    <p className='subtext mt-4 mb-4'>Your invite to the doddl portal has expired, but no need to worry. Simply click below and we’ll send you a new link for you to keep moving with your mortgage.</p>
                </Row>
                {this.state.sent === false &&
                        <>
                        <Row>
                            <Col className="col-2"></Col>
                            <Col className="col-8">
                                <p><span>Please confirm your e-mail address:</span></p>
                                <Form.Control type="email" value={this.state.email} onChange={e => { this.onChange(e.target.value); }} />
                            </Col>
                            <Col className="col-2"></Col>
                        </Row>
                        <Row className='justify-center' style={{ paddingTop: "20px"}}>
                        <Col className="col-2 "></Col>
                            <Col className='col-8'>
                                <ReCAPTCHA style={{ textAlign: "center", display:'inline-block' }} sitekey={this.state.captchaKey} onChange={this.captchaOnChange} />
                            </Col>
                            <Col className="col-2 "></Col>
                        </Row>
                        </>
                }
                <Row style={{ textAlign: "center", padding: "20px" }}>
                    {this.state.sent === false &&
                        <p>
                            <button className="btn xl btn-standard btn-wide200" onClick={this.requestNewLink}>Send new link</button>
                        </p>
                    }
                    {this.state.sent === true &&
                        <p className='subtext'><span style={{ color: 'rgba(51, 204, 153, 1)' }} className="fa-solid fa-check fa-xl"></span> Your request has been sent.</p>
                    }
                </Row>
            </Container>
        );
    }
}
