import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";

interface IProps {
  size?: 's'|'m'|'l';
  className?: string;
}

export class OpenBankingBubbles extends React.Component<IProps, any> {

    constructor(props: any) {
      super(props);
      this.state = { currentValue: props.value };
    }

    render() {
        const size = this.props.size ?? 'm';
        const margin = (size === 's' ? -50 : -85);
        const className = this.props.className??'';
        return (<>
          <div className={className} style={{display:'inline-block'}}>
            <div className={`bubble dark justify-center ${size}`} style={{marginRight:margin}}>
                <i className="fa-solid fa-building-columns"></i>
            </div>
            <div className={`bubble white justify-center ${size}`}>
                <i className="fa-solid fa-arrow-right-arrow-left"></i>
            </div>
            </div>
          </>
        );
    }
}