import React from "react";
import { Button } from "react-bootstrap";
import { FileUploadLegacy } from "../FileUploadLegacy";
import { ChatQuestionModel } from "./ChatQuestionModel";
import "./ChatControl.css"

export interface ChatUploadProps {
    question: ChatQuestionModel;
    confirmUpload(question?: ChatQuestionModel): void;
    goBack(question?: ChatQuestionModel): void;
    //onUploadComplete(data: any, mortgageDocumentId: string | null): void;
    setAnswer(question?: ChatQuestionModel, value?: any): void;
    contactId?: string;
}

export interface ChatUploadState {
    fileUploaded: Boolean;
}

export class ChatUpload extends React.Component<ChatUploadProps, ChatUploadState> {

    constructor(props: ChatUploadProps) {
        super(props);
        this.onUploadComplete = this.onUploadComplete.bind(this);
        this.state = { fileUploaded: false };
    }

    private onUploadComplete(data: any, mortgageDocumentId: string | null): void {

        this.setState({ fileUploaded: true });
        //this.props.onUploadComplete(data, mortgageDocumentId);
    }

    render() {
        return <>
            <div className="chat-drag-drop" style={{ width: '100%', minWidth: '400px' }}>
                <div className="chat-drag-drop" style={{ paddingTop: '5px' }}>
                    <FileUploadLegacy contactId={this.props.contactId} category={this.props.question.documentCategory} mortgageDocumentId={this.props.question.mortgageDocumentId} onUploadComplete={(data: any) => this.onUploadComplete(data, this.props.question.mortgageDocumentId)} />
                </div>
                <Button className="btn-outline-doddl" variant="lignt" style={{ marginRight: '10px' }} onClick={e => { this.props.goBack(this.props.question) }}>Back</Button>
                {this.state.fileUploaded && <Button className="btn-outline-doddl" variant="lignt" style={{ marginRight: '10px' }} onClick={e => { this.props.confirmUpload(this.props.question); }}>I'm done.</Button>}
            </div>
        </>;
    }
}