import { Col, Row } from "react-bootstrap";
import './Documents.css';
import { PortalPage } from "../components/PortalPage";
import axios from 'axios';
import { DocumentList } from '../components/DocumentList';
import { ChatControl } from '../components/Chat/ChatControl';
import { PortalCategoryModel } from '../models/PortalCategoryModel';
import { appInsightsHelper } from '../components/AppInsights';
import { UserContextComponent } from '../components/UserContextComponent';
import { DocumentManager, IFileItem } from "../components/DocumentManager";
import { ApplicantMenu } from "../components/ApplicantMenu";
import { SideNav } from "../components/SideNav";
import { MortgageDocumentStatusCode, PortalMortgageDocumentModel } from "../models/PortalMortgageDocumentModel";

interface IDocumentsProps {
}

interface IDocumentsState {
    loading: boolean;
    documents: any[];
    files: IFileItem[];
    selectedCategory: string | null;
    selectedDocument: string | null;
    mortgageDocumentId: string | null;
    documentsListKey: number;
    isChat: boolean;
    categories: PortalCategoryModel[];
}

export class Documents extends UserContextComponent<IDocumentsProps, IDocumentsState> {
    constructor(props: any) {
        super(props);
        this.state = { loading: true, documents: [], files: [], selectedCategory: null, selectedDocument: null, mortgageDocumentId: null, documentsListKey: 1, isChat: false, categories: [] };
        this.onUploadComplete = this.onUploadComplete.bind(this);
        this.getCategories = this.getCategories.bind(this);
    }

    componentDidMount() {
        appInsightsHelper.trackPageView({ name: "Documents" });
        this.loadData();
        this.getCategories();
    }

    handleCategoryClick(event: any) {
        event.preventDefault();
        const target = event.target;
        this.setState({ selectedCategory: target.getAttribute("data-category"), selectedDocument: null, mortgageDocumentId: null });
    }

    handleDocumentClick(event: any) {
        event.preventDefault();
        const target = event.target;
        this.setState({ selectedDocument: target.getAttribute("data-category"), mortgageDocumentId: target.getAttribute("data-id") });
    }

    private loadData(): void {
        const context = this.getContext();
        var mortgageId = context.contact?.mortgageId;
        let url = `/MortgageDocuments/GetMortgageDocumentsByMortgageId?mortgageId=${mortgageId}`;
        axios.get(url)
            .then((response) => {
                let documents = response.data;
                this.loadFiles(documents);
            })
            .catch((error) => {
                this.setState({ loading: false });
            });
    }

    private loadFiles(documents: any): void {
        const context = this.getContext();
        let url = `/sp/GetFiles?mortgageId=${context.mortgage?.mortgageId}`;
        axios.get(url)
            .then((response) => {
                let files = response.data;
                this.addOpenBankingDocumentComments(documents, files);
            })
            .catch((error) => {
                this.setState({ loading: false });
            });
    }

    private addOpenBankingDocumentComments(documents: any, files: IFileItem[]): void {
        const context = this.getContext();
        let url = `/MortgageDocuments/GetOpenBankingDocumentsWithComments?mortgageId=${context.mortgage?.mortgageId}`;
        axios.get(url)
            .then((response) => {
                let openBankingfiles = response.data;
                files.push(...openBankingfiles);
                this.setState({ loading: false, documents: documents, files: files });
            })
            .catch((error) => {
                this.setState({ loading: false });
            });
    }


    public onUploadComplete(mortgageDocumentId: string): void {
        let document = this.state.documents.find((doc) => { return doc.mortgageDocumentId === mortgageDocumentId });
        if (document != null) {
            document.statusReason = 755980001;
        }
        this.setState({ documentsListKey: this.state.documentsListKey + 1, documents: this.state.documents });
    }

    async getCategories() {
        let context = this.getContext();
        var mortgageId = context.contact?.mortgageId;
        axios
            .get("/MortgageDocuments/GetCategories?mortgageId=" + mortgageId)
            .then((response) => {
                let categories: PortalCategoryModel[] = [];
                for (var i = 0; i < response.data.length; i++) {
                    if (response.data[i].category !== "AIP") {
                        categories.push(response.data[i]);
                    }
                }
                this.setState({ categories: categories });
            })
            .catch((error) => {
                if (error.response.statusCode === 401) {
                    alert("Unauthorized");
                }
            });
    }
    statusClass() {
        let fullPercent = DocumentManager.calculatePercent(this.getContext(), this.state.documents);
        if (fullPercent === 0) return '';
        if (fullPercent >= 100) return 'complete'
        return 'inprogress';
    }
    render() {
        let context = this.getContext();

        if (this.state.categories.length === 1 && this.state.selectedCategory === null) {
            this.setState({ selectedCategory: this.state.categories[0].category, selectedDocument: null, mortgageDocumentId: null });
        }

        let applicants = [context.mortgage?.primaryApplicant, context.mortgage?.secondaryApplicant];
        

        let contents = this.state.loading
            ? <><p><em>Loading...</em></p></>
            : (
                <>
                    <PortalPage className={`bump-right ${this.statusClass()}`} titleClassName="max-w-wide" title={"Your Documents"}>
                        <Row>
                            {this.state.isChat &&
                                <Col className="col-7">
                                    <ChatControl onUploadComplete={this.onUploadComplete} PrimaryApplicant={context.mortgage?.primaryApplicant.firstName} SecondaryApplicant={(context.mortgage?.secondaryApplicant ? context.mortgage?.secondaryApplicant.firstName : "")}></ChatControl>
                                </Col>
                            }
                            {this.state.isChat &&
                                <Col className="col-3">
                                    <DocumentList key={this.state.documentsListKey} categories={this.state.categories} documents={this.state.documents} />
                                </Col>
                            }
                            {!this.state.isChat &&
                                <Col className="max-w-wide col-12">
                                    <DocumentManager key={this.state.documentsListKey} categories={this.state.categories} items={this.state.files} documents={this.state.documents} applicants={applicants} mortgageId={context.mortgage?.mortgageId} />
                                </Col>
                            }
                        </Row>
                        <br />
                    </PortalPage>
                </>
            );
        return contents;
    }
}
