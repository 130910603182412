import React from "react";
import { Component } from "react";
import { ControlType, DataType, Field } from "../../models/PortalMetadata";
import { FormControl } from "../FormControl";
import { Pill } from "./Pill";
import { IPillElementModel } from "./PillElement";
import * as Icons from "react-bootstrap";

interface IButtonSubmitPillProps {
  label?: any;
  value: string;
  mobileLabel?: string;
  buttonLabel: any;
  readonly?: boolean;
  extraClasses?:string;
  placeholder?:string;
  allowSubmit?:boolean | null;
  onSubmit(value:string):void;
}

interface IButtonSubmitPillState {
  allowSubmit?: boolean | null;
  width: number;
}


export class ButtonSubmitPill extends Component<IButtonSubmitPillProps, IButtonSubmitPillState> {

  constructor(props: IButtonSubmitPillProps) {
    super(props);
    this.state = { allowSubmit: props.allowSubmit, width: window.innerWidth };
    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
    this.isMobileOrTablet = this.isMobileOrTablet.bind(this);
    window.addEventListener('resize', this.handleWindowSizeChange);

  }

  componentDidUpdate() {  
    if (this.props.allowSubmit !== this.state.allowSubmit) {
      this.setState({allowSubmit: this.props.allowSubmit});
    }
  }

  handleWindowSizeChange() {
    this.setState({width: window.innerWidth});
  }

  isMobileOrTablet() {
    return this.state.width <= 1500; 
  }

  componentDidMount() {
  }

  render() {
    var field: Field = {
      name: "",
      label: "",
      dataType: DataType.string,
      controlType: ControlType.text,
      originalValue: this.props.value,
      value: this.props.value,
      dateValue: null,
      isDirty: false,
      options: null,
      lookupValues: null,
      api: null,
      isPill: false,
      minValue: undefined,
      maxValue: undefined,
      maxLength: undefined,
      optional: true,
      placeholder: this.props.placeholder
    };

    var allowSubmit = true;
    if (this.state.allowSubmit !== null && this.state.allowSubmit !== undefined) {
      allowSubmit = this.state.allowSubmit!;
    }

    var components: IPillElementModel[] = [];
    if (this.isMobileOrTablet()) {
      components.push({
        leftBorder: true,
        rightBorder: false,
        content: 
        <>
          <div className={this.props.extraClasses} style={{whiteSpace: 'nowrap'}}>{this.props.mobileLabel ?? this.props.label}</div>
          <span className={this.props.extraClasses} style={{ visibility: "hidden", height: "1px", paddingRight: 5, whiteSpace: 'nowrap' }}>{this.props.mobileLabel ?? this.props.label}</span>
        </>
      });

    } else {
      if (this.props.label) {
        components.push({
          leftBorder: true,
          rightBorder: false,
          content: 
          <>
            <div className={'buttonpilllabel ' + this.props.extraClasses} style={{whiteSpace: 'nowrap'}}>{this.props.label}</div>
            <span className={'buttonpilllabel ' + this.props.extraClasses} style={{ visibility: "hidden", height: "1px", paddingRight: 5, whiteSpace: 'nowrap' }}>{this.props.label}</span>
          </>
        });
      }
  
      components.push({
        leftBorder: false,
        rightBorder: false,
        content:
        <>
          <FormControl
            readOnly={this.props.readonly}
            fieldOnly={true}
            field={field}
            extraClasses={this.props.extraClasses}
            ></FormControl>
            <span style={{ visibility: "hidden", height: "1px", paddingRight: 5, whiteSpace: 'nowrap' }}>{this.props.placeholder?.substring(0,30)}</span>
            <span style={{ visibility: "hidden", height: "1px", paddingRight: 5, whiteSpace: 'nowrap' }}>{this.props.value?.substring(0,30)}</span>
          </>
      });
  
      }
    components.push({
      leftBorder: false,
      rightBorder: true,
      backgroundColor: 'var(--sage)',
      content:
        <button className="btn btn-pillbutton" onClick={() => { if (!allowSubmit) return; this.props.onSubmit((field.value ?? "") as string)}}>{allowSubmit ? this.props.buttonLabel :  <Icons.Spinner animation="border" role="status" style={{color: "white"}} />}</button>
    });

    return (
      <Pill disableCollapse={true} components={components} empty={false} extraClass="no-min-width thick-white-border"></Pill>
    );
  }
}

