import { Component } from "react";

export interface IAggregatePillOverlayProps {
    closePill?(): void;
}

export class AggregatePillOverlay<T extends IAggregatePillOverlayProps, S> extends Component<T, S> {
    constructor(props: any) {
        super(props);
    }
}