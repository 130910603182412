import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Container, Stack } from "react-bootstrap";
import './Applicant.css';
import { Navigate } from "react-router-dom";
import { InputPill } from '../components/Pills/InputPill';
import { LinkPill } from "../components/Pills/LinkPill";
import { Address } from "../components/Address";
import { ApplicantPage } from "./ApplicantPage";
import { ApplicantService } from './ApplicantService';
import { ApplicantBaseComponent } from './ApplicantBaseComponent';
import { FieldWithHelptext } from '../components/FieldWithHelptext';
import currencyFormatter, { currencyToNumber } from '../helper/currencyFormatter';
import { BothApplicantFieldsWithAggregate } from '../components/BothApplicantFieldsWithAggregate';
import { MortgageSubType } from '../models/PortalMortgageModel';

export class ApplicantResidential extends ApplicantBaseComponent {
    applicantService: ApplicantService;

    constructor(props: any) {
        super(props);
        this.prev = this.prev.bind(this);
        this.next = this.next.bind(this);
    }

    componentDidMount() {
        let context = this.getContext();
        this.applicantService = new ApplicantService(context, this, this.props.applicantNo);
        this.applicantService.getApplicantData();
    }

    prev() {
        this.applicantService.saveAllFields(false);
        this.setState({ redirectTo: "/personal" + this.props.applicantNo });
    }

    next() {
        this.applicantService.saveAllFields(false);
        this.setState({ redirectTo: "/employment" + this.props.applicantNo });
    }

    ppsInvalidCheck(value: string) {
        var regex = /^\d{7}[A-Za-z]{1,2}$/
        if (value && !regex.test(value)) {
            return true;
        }
        return false;
    }

    render() {
        if (this.state.loading) {
            return;
        }

        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />;
        }

        if (!this.state.contact)
            return <span>Applicant information not found</span>;

        let context = this.getContext();

        //address
        let threeYearsAgo = new Date();
        threeYearsAgo.setFullYear(threeYearsAgo.getFullYear() - 3);
        let movedInDate = this.state.contact.address1MovedIn !== null ? new Date(this.state.contact.address1MovedIn) : null;
        let movedInLessThanThreeYearsAgo = movedInDate !== null && movedInDate.toISOString() > threeYearsAgo.toISOString();

        var title = this.state.contact.firstName + "'s Residential Information";
        var subTitle = <>Banks need to know if you currently rent or own your own home, and your monthly rent or mortgage payments.</>;

        var applicantOnePaysText;
        var applicantTwoPaysText;
        if (context.mortgage?.secondaryApplicant !== null) {
            if (context.applicantNo === 1) {
                applicantOnePaysText = "you pay";
                applicantTwoPaysText = context.mortgage?.secondaryApplicant.firstName + " pays";
            } else {
                applicantOnePaysText = context.mortgage?.primaryApplicant.firstName + " pays";
                applicantTwoPaysText = "you pay";
            }
        }

        return (
            <>
                <ApplicantPage applicantNo={this.state.applicantNo} title={title} subTitle={subTitle} next={this.next} prev={this.prev}
                    contact={this.state.contact} handleSubmit={this.applicantService.handleSubmit} showFinishButton={false}
                    emptyOnlyClick={() => this.setState({ emptyOnly: !this.state.emptyOnly })} percentComplete={this.state.contact.residentialInfoPercentage}>
                    <Container className="pill-form">
                        <>

                            <Container>
                                <Row className="pill-row">
                                    <Stack gap={1} direction="horizontal" className="pill-stack">
                                        <LinkPill
                                            hideIfValue={this.state.emptyOnly}
                                            extraClass={this.state.contact.address1MovedIn === null || this.state.contact.eircode === null ? "pill-missing" : undefined}
                                            field={this.state.fields.getFieldByName("address1")}
                                            canSave={this.state.address1IsValid}
                                            saveOnDone={this.applicantService.saveAllFields}
                                            overlay={<Address bubbleUpValidity={(isValid:boolean) => this.setState({address1IsValid: isValid})} helperTexts={this.state.helperTexts} record={this.state.contact} fields={this.state.fields}
                                                handleChange={this.applicantService.handleChange} address="address1" addressLine1="address1Line1" addressLine2="address1Line2"
                                                addressLine3="address1Line3" country="address1Country" county="address1County" city="address1City" eircode="address1Eircode" movedInDate="address1MovedIn" mortgageBefore="previouslyHaveMortgageLoan" letManual={true} 
                                                customAddressPrompt={
                                                    <>
                                                        <div className="input-group-text pb-0">Start typing your address or Eircode, please ensure that the Eircode address matches your 'proof of address'.</div>
                                                        <div className="input-group-text pt-0">If your address is wrong in the Eircode Finder, you should <strong>&nbsp;manually enter the address</strong></div>
                                                    </>
                                                }/>}
                                        />
                                        {movedInLessThanThreeYearsAgo &&
                                            <LinkPill
                                                hideIfValue={this.state.emptyOnly}
                                                field={this.state.fields.getFieldByName("address2")}
                                                canSave={this.state.address2IsValid}
                                                saveOnDone={this.applicantService.saveAllFields}
                                                overlay={<Address bubbleUpValidity={(isValid:boolean) => this.setState({address2IsValid: isValid})} helperTexts={this.state.helperTexts} record={this.state.contact} fields={this.state.fields}
                                                    handleChange={this.applicantService.handleChange} address="address2" addressLine1="address2Line1" addressLine2="address2Line2"
                                                    addressLine3="address2Line3" country="address2Country" county="address2County" city="address2City" eircode="address2Eircode" movedInDate="address2MovedIn" letManual={true} />}
                                            />}
                                        <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("ownerOrRenter")} ></InputPill>
                                        {(this.state.contact.ownerOrRenter === "778390001" || this.state.contact.ownerOrRenter === 778390001 || context.mortgage?.subType === MortgageSubType.SecondTimeBuyer) && <>
                                            <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("doYouPayYourRentThroughYourBankAccount")} ></InputPill>
                                            <LinkPill
                                                hideIfValue={this.state.emptyOnly}
                                                field={this.state.fields.getFieldByName("monthlyRentAmount")}
                                                valueToDisplay={context.mortgage?.secondaryApplicant === null ?
                                                    this.state.fields.getFieldByName("monthlyRentAmount").value as string
                                                    :
                                                    currencyFormatter(currencyToNumber(this.state.fields.getFieldByName("monthlyRentAmount").value as string)+currencyToNumber(this.state.fields.getFieldByName("monthlyRentAmountOther").value as string))
                                                }
                                                label='Mortgage/Rent'
                                                modalSize='lg'
                                                saveOnDone={this.applicantService.saveAllFields}
                                                overlay={context.mortgage?.secondaryApplicant === null ?
                                                <FieldWithHelptext field={this.state.fields.getFieldByName("monthlyRentAmount")} />
                                                :
                                                <BothApplicantFieldsWithAggregate
                                                    title='Mortgage/Rent'
                                                    field={this.state.fields.getFieldByName("monthlyRentAmount")}
                                                    fieldLabel={`How much ${this.state.applicantNo === 1 ? applicantOnePaysText : applicantTwoPaysText}:`} 
                                                    field2={this.state.fields.getFieldByName("monthlyRentAmountOther")}
                                                    field2Label={`How much ${this.state.applicantNo === 1 ? applicantTwoPaysText : applicantOnePaysText}:`} 
                                                    totalLabel={'Total Mortgage/Rent:'} />
                                                }
                                            />
                                        </>
                                        }
                                        {movedInLessThanThreeYearsAgo &&
                                            <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("livedOutsideofIreland")} ></InputPill>
                                        }
                                    </Stack>
                                </Row>
                            </Container>
                        </>
                    </Container>

                </ApplicantPage>
            </>
        );
    }
}




