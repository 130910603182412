import './Documents.css';
import { UserContext } from '../components/UserContext';
import UserContextState from '../components/UserContext';
import axios from 'axios';
import { MortgageType, PortalMortgageModel } from '../models/PortalMortgageModel';
import Property from './Property';
import Shared from './Shared';
import { PortalPropertyModel } from '../models/PortalPropertyModel';
import { SolicitorDetails } from './SolicitorDetails';
import { appInsightsHelper } from '../components/AppInsights';
import { UserContextComponent } from '../components/UserContextComponent';
import { Navigate } from 'react-router-dom';

interface ISharedDetailsProps {
}

interface ISharedDetailsState {
    loading: boolean;
    mortgage: PortalMortgageModel | null;
    property: PortalPropertyModel | null;
    step: number;
    redirectOnNext?: boolean | null;
}

export class SharedDetails extends UserContextComponent<ISharedDetailsProps, ISharedDetailsState> {
    static contextType = UserContext;

    constructor(props: any) {
        super(props);
        this.prev = this.prev.bind(this);
        this.next = this.next.bind(this);
        this.setStep = this.setStep.bind(this);
        this.state = { loading: false, mortgage: null, property: null, step: 2 };
    }

    componentDidMount() {
        appInsightsHelper.trackPageView({ name: "Shared Detail" });
        //this.populateData();
    }

    prev() {
        var step = this.state.step;

        if (step === 1) {
            this.setState({
                redirectOnNext: true
            });
        } else {
            if (step > 1)
                step--;

            this.setState({
                step: step
            });
        }
    }

    next() {
        var step = this.state.step;

        if (step === 3) {
            this.setState({ redirectOnNext: true });
            return;
        }

        if (step < 3)
            step++;

        this.setState({
            step: step
        });

    }

    setStep(step: number): void {
        this.setState({ step: step });
    }

    render() {
        if (this.state.redirectOnNext) {
            return (<Navigate to={"/profile"} />)
        }
        let context = this.getContext();

        if (this.state.loading || context.mortgage === null)
            return (<><p><em>Loading...</em></p></>);

        let switcherOrSaleAgreedPurchaser = context.mortgage?.type === MortgageType.RemortgagingExistingLoan || context.mortgage?.saleAgreed;

        return (
            <>
            {!switcherOrSaleAgreedPurchaser &&
                <>
                {this.state.step === 1 &&
                    <Shared next={this.next} prev={this.prev} step="1" steps="2" />
                }
                {this.state.step === 2 &&
                    <Property bumpRight={true} prev={this.prev} step="2" steps="2" hideNav={true} />
                }
                </>
            }
            {switcherOrSaleAgreedPurchaser &&
                <>
                {this.state.step === 1 &&
                    <Shared next={this.next} prev={this.prev} step="1" steps="3" />
                }
                {this.state.step === 2 &&
                    <Property bumpRight={true} next={this.next} prev={this.prev} step="2" steps="3" hideNav={true} />
                }
                {this.state.step === 3 &&
                    <SolicitorDetails bumpRight={true} next={this.next} prev={this.prev} step="3" steps="3" />
                }
                </>
            }
            </>
        );

    }

    async populateData() {
        const context = this.context as UserContextState;
        if (context.contact === null)
            return;

        axios.get("/mortgages/GetMortgageByMortgageId?mortgageId=" + context.contact.mortgageId)
            .then((response) => {
                this.setState({ mortgage: response.data });
                this.getProperty();
                //this.getTooltips("contact");
            })
            .catch((error) => {
                if (error.response.statusCode === 401) {
                    alert("Unauthorized");
                }
            });
    }

    async getProperty() {
        const context = this.context as UserContextState;
        if (context.contact === null)
            return;

        let url = `/property/getProperty?mortgageId=${context.contact.mortgageId}`;
        axios
            .get(url)
            .then((response) => {
                this.setState({ property: response.data, loading: false });
            })
            .catch((error) => {
                if (error.response.statusCode === 401) {
                    alert("Unauthorized");
                }
            });
    }
}