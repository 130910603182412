import React, { Component } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { HelpBox } from "../components/HelpBox";
import './Documents.css';
import { PortalPage } from "../components/PortalPage";
import { appInsightsHelper } from '../components/AppInsights';

interface ILenderAdviceProps {
}

interface ILenderAdviceState {
    loading: boolean;
}

export class LenderAdvice extends Component<ILenderAdviceProps, ILenderAdviceState> {
    constructor(props: any) {
        super(props);
        this.state = { loading: false };
    }
    componentDidMount() {
        appInsightsHelper.trackPageView({ name: "Lender Advice" });
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : (
                <PortalPage title={"Lender Advice"} description={""}>
                        <Row>
                            <Col className="col-1"></Col>
                            <Col className="col-6">
                                <div className="list-group">
                                    <div className=" list-group-item list-group-item-action flex-column align-items-start">
                                        <Container>
                                            <Row>
                                                <Col className="col-1">
                                                    
                                                </Col>
                                                <Col className="col-11 text-start">
                                                    
                                                </Col>
                                            </Row>
                                            <Row><Col>&nbsp;</Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </div>
                            </Col>
                            <Col className="col-1"></Col>
                            <Col className="col-3">
                                    <HelpBox title={"What's next?"} text={"You should have received your lender declaration form by email to be completed in docusign!"}></HelpBox>
                            </Col>
                        </Row>
                        <br />
                        <br />
                        <br />
                        <br />
                </PortalPage>
                );
        return contents;
    }
}
