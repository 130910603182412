import { createContext } from "react";
import { PortalHelperTextModel } from "../models/PortalHelperTextModel";
import { FormLookupItem } from "../models/PortalMetadata";
import { PortalMortgageDocumentModel } from "../models/PortalMortgageDocumentModel";
import { PortalMortgageModel } from "../models/PortalMortgageModel";
import { PortalUserModel } from '../models/PortalUserModel'
import { PortalSettingsModel } from "../models/PortalSettingsModel";
import { IFileItem } from "./DocumentManager";

interface UserContextState {
    contact: PortalUserModel | null;
    mortgage: PortalMortgageModel | null;
    tooltips: PortalHelperTextModel[] | null;
    reloadMortgage(): void;
    isDirty: boolean;
    setIsDirty(dirty: boolean): void;
    impersonateContact(contactId: string): void;
    impersonatedId: string | null;
    refreshMenuState(): void;
    mortgageDocumentList: PortalMortgageDocumentModel[] | null;
    getMortgageDocuments(): void;
    addDoc(doc: any): void;
    updateDoc(doc: any): void;
    deleteDoc(doc: any): void;
    updateId(oldId: string, newId: string): void;
    //loading: boolean;
    institutions: FormLookupItem[];
    applicantNo: number | null;
    settings: PortalSettingsModel | null;
    loadingFiles: boolean;
    files: IFileItem[] | null;
}

export const UserContext = createContext<UserContextState | null>(
    {
        contact: null,
        mortgage: null,
        tooltips: null,
        reloadMortgage: () => { },
        isDirty: false,
        setIsDirty: (dirty: boolean) => { },
        impersonateContact: (contactId: string) => { },
        impersonatedId: null,
        refreshMenuState: () => { },
        mortgageDocumentList: [],
        getMortgageDocuments: () => { },
        addDoc: (doc: any) => { },
        updateDoc: (doc: any) => { },
        deleteDoc: (doc: any) => { },
        updateId: (oldId: string, newId: string) => { },
        institutions: [],
        applicantNo: null,
        settings: null,
        loadingFiles: false,
        files: []
    });

export default UserContextState;
