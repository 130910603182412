import React, { ReactNode } from "react";
import { Button } from "react-bootstrap";
import "./ChatControl.css"
import { ChatMessage, MessageDirection } from "./ChatMessage";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import axios from "axios";
import { ChatQuestionModel, ChatQuestionType } from "./ChatQuestionModel";
import { ChatMessageModel } from "./ChatMessageModel";
import { QuestionManager } from "./QuestionManager";
import delay from "../../helper/delay";
import { ChatButtonList } from "./ChatButtonList";
import { ChatUpload } from "./ChatUpload";
import { UserContextComponent } from "../UserContextComponent";

export interface ChatControlState {
  messages: ChatMessageModel[],
  uploadedDocuments: string[]
}

export interface ChatControlProps {
  PrimaryApplicant: string | undefined;
  SecondaryApplicant: string | undefined;
  onUploadComplete(mortgageDocumentId: string): void;
}

export class ChatControl extends UserContextComponent<ChatControlProps, ChatControlState> {
  private messagesEnd: any;
  private questionManager: QuestionManager;

  constructor(props: any) {
    super(props);
    this.setAnswer = this.setAnswer.bind(this);
    this.addAnswer = this.addAnswer.bind(this);
    this.addQuestion = this.addQuestion.bind(this);
    this.goBack = this.goBack.bind(this);
    //this.onUploadComplete = this.onUploadComplete.bind(this);
    this.confirmUpload = this.confirmUpload.bind(this);

    this.messagesEnd = React.createRef<HTMLDivElement>();
    this.state = { messages: [], uploadedDocuments: [] };
  }

  private getQuestions(mortgageId?: string): Promise<any> {
    let url = `/Chat/GetChatQuestionsByMortgageId?mortgageId=${mortgageId}`;
    return axios.get(url);
  }

  componentDidMount(): void {
    const context = this.getContext();
    this.getQuestions(context.contact?.mortgageId).then((response) => {
      this.questionManager = new QuestionManager(response.data);
      delay(300).then(() => {
        this.getNextQuestion();
      });
    });
  }

  componentDidUpdate(): void {
    this.scrollToBottom();
  }

  private scrollToBottom(): void {
    (this.messagesEnd.current as any)?.scrollIntoView({ behavior: "smooth" });
  }

  private getInitials(): string {
    const context = this.getContext();
    const fn = context.contact?.firstName.substring(0, 1) || "";
    const ln = context.contact?.lastName.substring(0, 1) || "";
    return fn + ln;
  }

  private goBack(question: ChatQuestionModel): void {
    this.addAnswer({ name: "Go back" });

    let prevQuestion = this.questionManager.getParentQuestion(question);
    if (prevQuestion != null) {
      prevQuestion = this.questionManager.getParentQuestion(prevQuestion);
    }
    if (prevQuestion != null)
      this.addQuestion(prevQuestion);
  }

  private addQuestion(question: ChatQuestionModel): void {
    let m: ChatMessageModel = {
      order: (this.state.messages.length + 1).toString(),
      direction: MessageDirection.Outgoing,
      initials: this.getInitials(),
      message: question.text,
      question: question,
    };

    this.appendMessage(m);

    if (question?.questionType !== ChatQuestionType.Statement) {
      this.addChildQuestions(question);
    }
  }

  private isBackButtonEnabled(question: ChatQuestionModel): boolean {
    let addBackButton: boolean = false;

    let prevQuestion = this.questionManager.getParentQuestion(question);
    if (prevQuestion != null) {
      prevQuestion = this.questionManager.getParentQuestion(prevQuestion);
    }
    if (prevQuestion != null && prevQuestion.questionType !== ChatQuestionType.Statement) {
      addBackButton = true;
    }
    return addBackButton;
  }

  private updateApplicantName(questions: ChatQuestionModel[]) {
    questions.forEach((q) => {
      if (q.text === "Primary Applicant" || q.text === "[Primary Applicant]") {
        if (this.props.PrimaryApplicant)
          q.text = this.props.PrimaryApplicant;
      }
      if (q.text === "Secondary Applicant" || q.text === "[Secondary Applicant]") {
        if (this.props.SecondaryApplicant)
          q.text = this.props.SecondaryApplicant;
      }
    });
  }

  private addChildQuestions(question: ChatQuestionModel): void {
    let addBackButton = this.isBackButtonEnabled(question);

    let html: ReactNode = <></>;
    let questions = this.questionManager.getChildQuestions(question);
    if (questions?.length > 0 && (questions[0].questionType == ChatQuestionType.Button || questions[0].questionType == ChatQuestionType.DocumentCategory)) {
      this.updateApplicantName(questions);
      html = <ChatButtonList questions={questions} setAnswer={this.setAnswer}></ChatButtonList>

      if (addBackButton)
        html = <><Button className="btn-outline-doddl" variant="lignt" onClick={e => { this.goBack(question) }}>Go back</Button>{html}</>
    }
    else if (question.questionType == ChatQuestionType.Upload) {
      const context = this.getContext();

      if (question.helpText != null) {
        let helpTextMessage: ChatMessageModel = {
          order: (this.state.messages.length + 1).toString(),
          direction: MessageDirection.Outgoing,
          initials: this.getInitials(),
          question: question,
          message: question.helpText
        };

        this.appendMessage(helpTextMessage);
      }
      html = <ChatUpload question={question} setAnswer={this.setAnswer} contactId={context.contact?.contactId} confirmUpload={this.confirmUpload} goBack={this.goBack}></ChatUpload>
      // onUploadComplete={this.onUploadComplete}
    } else {
      return;
    }

    let m: ChatMessageModel = {
      order: (this.state.messages.length + 1).toString(),
      direction: MessageDirection.Incoming,
      initials: this.getInitials(),
      question: question,
      message: <>{html}</>
    };

    delay(300).then(() => {
      this.appendMessage(m);
    });
  }

  private removeLastMessage(): void {
    let messages = this.state.messages;
    messages.pop();
    this.setState({ messages: messages });
  }

  private appendMessage(message: ChatMessageModel): void {
    let messages = this.state.messages;
    messages.push(message);
    this.setState({ messages: messages });
  }

  private confirmUpload(uploadQuestion: ChatQuestionModel): void {
    const context = this.getContext();
    axios.post('/MortgageDocuments/MarkMortgageDocumentAsUploaded', null, { params: { mortgageDocumentId: uploadQuestion.mortgageDocumentId, mortgageId: context.mortgage?.mortgageId } })
      .then(response => {
        if (uploadQuestion.mortgageDocumentId != null)
          this.props.onUploadComplete(uploadQuestion.mortgageDocumentId);
      });

    this.removeLastMessage();

    this.addAnswer({ name: "I'm finished with this upload" });

    this.questionManager.removeQuestion(uploadQuestion);

    let documentTypeQuestion = this.questionManager.getParentQuestion(uploadQuestion);
    if (documentTypeQuestion != null) {
      this.questionManager.removeQuestion(documentTypeQuestion);

      let documentTypeQuestionTitle = this.questionManager.getParentQuestion(documentTypeQuestion);
      if (documentTypeQuestionTitle != null) {
        if (!this.questionManager.hasChildQuestions(documentTypeQuestionTitle)) {
          this.questionManager.removeQuestion(documentTypeQuestionTitle);
        } else {
          this.addQuestion(documentTypeQuestionTitle);
          return;
        }

        let applicantQuestion = this.questionManager.getParentQuestion(documentTypeQuestionTitle);
        if (applicantQuestion != null) {
          let applicantQuestionTitle = this.questionManager.getParentQuestion(applicantQuestion);
          if (!this.questionManager.hasChildQuestions(applicantQuestion)) {
            this.questionManager.removeQuestion(applicantQuestion);

            if (applicantQuestionTitle != null) {
              if (!this.questionManager.hasChildQuestions(applicantQuestionTitle)) {
                this.questionManager.removeQuestion(applicantQuestionTitle);
              }
            }
          }
          if (applicantQuestionTitle != null) {
            let documentCategoryQuestion = this.questionManager.getParentQuestion(applicantQuestionTitle);
            if (documentCategoryQuestion != null) {

              if (!this.questionManager.hasChildQuestions(documentCategoryQuestion)) {
                this.questionManager.removeQuestion(documentCategoryQuestion);
              }
              let documentCategoryQuestionTitle = this.questionManager.getParentQuestion(documentCategoryQuestion);
              if (documentCategoryQuestionTitle != null) {

                if (!this.questionManager.hasChildQuestions(documentCategoryQuestionTitle)) {
                  let lastMessage: ChatMessageModel = {
                    order: (this.state.messages.length + 1).toString(),
                    message: "Our team are currently reviewing your documentation and an advisor will be back to you soon!",
                    direction: MessageDirection.Outgoing,
                    question: null,
                  };
                  this.appendMessage(lastMessage);
                  return;
                }
                this.addQuestion(documentCategoryQuestionTitle);
              }
            }
          }
        }
      }
    }
  }

  private addAnswer(answer: any): void {
    this.removeLastMessage();

    let m: ChatMessageModel = {
      order: (this.state.messages.length + 1).toString(),
      direction: MessageDirection.Incoming,
      initials: this.getInitials(),
      message: answer.name,
      question: null,
      addclass: "chosenanswer"
    };

    this.appendMessage(m);
  }

  setAnswer(question?: ChatQuestionModel, value?: any) {
    if (this.questionManager.currentQuestion !== null) {

      this.addAnswer(value);
      if (question != null) {
        this.questionManager.currentQuestion = question;
        this.forceUpdate();
        delay(300).then(() => {
          this.getNextQuestion();
        });
      }
    }
  }

  // onUploadComplete(data: any, mortgageDocumentId: string | null) {
  //   if (mortgageDocumentId) {
  //     this.props.onUploadComplete(mortgageDocumentId);
  //   }
  // }

  private getNextQuestion(): void {
    let q = this.questionManager.getNextQuestion();
    if (q != null) {
      this.addQuestion(q);
      this.forceUpdate();

      if (q.questionType == ChatQuestionType.Statement) {
        delay(300).then(() => {
          this.getNextQuestion();
        });
      }
    }
  }

  render() {
    return (
      <section style={{ backgroundColor: "#eee" }}>
        <div className="card" id="chat2">
          <div className="card-body" style={{ position: 'relative', height: 600, overflowY: 'auto' }}>
            <TransitionGroup component="div">
              {this.state.messages.map((message: ChatMessageModel) => (
                <CSSTransition key={message.order} timeout={700} classNames="chat-message">
                  <ChatMessage {...message} >{message.message}</ChatMessage>
                </CSSTransition>
              ))}
            </TransitionGroup>
            <div ref={this.messagesEnd}>
            </div>
            <div id="mpty" style={{ height: 70 }}></div>
          </div>
        </div>
      </section>
    );
  }
}