import { PublicClientApplication } from '@azure/msal-browser'
import { useEffect, useState } from 'react'
import { Button, Container, Modal, Row } from 'react-bootstrap'
import { useIdleTimer } from 'react-idle-timer'
import { msalConfig } from '../../helper/authConfig'

const timeout = 60 * 1000 * 20;
const promptBeforeIdle = 60 * 1000 * 2;

export default function LogoutOnIdle() {
    const [remaining, setRemaining] = useState<number>(timeout);
    const [open, setOpen] = useState<boolean>(false);

    const onIdle = () => {
        setOpen(false);
        signOut();
    };

    const onActive = () => {
        setOpen(false);
    };

    const onPrompt = () => {
        setOpen(true);
    };

    const signOut = () => {
        const myMSALObj = new PublicClientApplication(msalConfig);
        myMSALObj.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    };

    const handleClose = () => {
        if (getRemainingTime() <= 0) {
            signOut();
            return;
        }
        setOpen(false);
        activate();
    };

    const handleLogout = () => {
        setOpen(false);
        signOut();
    };

    const { getRemainingTime, activate } = useIdleTimer({
        onIdle,
        onActive,
        onPrompt,
        timeout,
        promptBeforeIdle,
        throttle: 500
    });

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000));
        }, 500)

        return () => {
            clearInterval(interval);
        }
    });

    const remainingSeconds = remaining > 1 ? 'seconds' : 'second';

    return (
        <>
            <Modal className='text-center' size="lg" show={open} onHide={handleClose} centered>
                <Modal.Body>
                    <Container className='text-center' >
                        <Row>
                            <h3 className="impact">Your session is about to time out!</h3>
                        </Row>
                        <Row>
                            <p>You are about to be automatically signed out. What would you like to do?</p>
                            <p>(Automatic log out in {remaining} {remainingSeconds})</p>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className='text-center justify-center'>
                    <Button className='btn btn-standard-transparent big btn-outline' onClick={handleLogout}>
                        Logout
                    </Button>
                    <Button className='btn btn-standard big' onClick={handleClose}>
                        Stay
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

