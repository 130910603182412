import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Container, Stack, Col } from "react-bootstrap";
import '../pages/Applicant.css';
import { InputPill } from './Pills/InputPill';
import axios from 'axios';
import { UserContextComponent } from './UserContextComponent';
import { ControlType, DataType, Field } from '../models/PortalMetadata';
import * as Icons from "react-bootstrap";
import { Applicant2SetupFieldsCollection } from '../forms/Applicant2SetupFields';
import { FieldsCollection } from '../forms/FieldsCollection';

interface IApplicantSetupState {
    saving:boolean;
    fields: FieldsCollection;
    error: string;
}

export class ApplicantSetup extends UserContextComponent<any, IApplicantSetupState> {

    constructor(props: any) {
        super(props);
        this.save = this.save.bind(this);
        this.handleChange = this.handleChange.bind(this);
        var fields = new Applicant2SetupFieldsCollection();
        for (var f in fields.fields) {
            fields.fields[f].handleChange = this.handleChange;
        }

        this.state = {
            saving: false,
            error: "",
            fields: fields
        };
    }

    componentDidMount() {
        let context = this.getContext();
    }

    allowedSave () {
        var firstName = this.state.fields.getFieldByName("setupFirstName").value ?? "";
        var lastName = this.state.fields.getFieldByName("setupLastName").value ?? "";
        var email = this.state.fields.getFieldByName("setupEmailAddress").value ?? "";
        return firstName !== "" && lastName !== "" && email !== "";
    }

    validateEmail = (email:string) => {
        const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        return emailRegex.test(email);
    };

    save() {
        let context = this.getContext();

        if (!this.allowedSave()) {
            this.setState({error: "All the fields are required to create the secondary applicant"});
            return;
        }

        var emailField = this.state.fields.getFieldByName("setupEmailAddress");
        var email = (emailField.value as string).trim();
        if (!this.validateEmail(email)) {
            this.setState({error: "Please enter a valid email address for the secondary applicant"});
            return;
        }

        if (email.trim().toLowerCase() === context.contact?.emailAddress.trim().toLowerCase()) {
            this.setState({error: "You cannot use your own email address for the secondary applicant"});
            return;
        }

        this.setState({saving: true});
        var firstName = this.state.fields.getFieldByName("setupFirstName");
        var lastName = this.state.fields.getFieldByName("setupLastName");
        var newContact:any = {mortgageId: context.mortgage?.mortgageId, firstName: firstName.value, lastName: lastName.value, emailAddress: email};
        axios.post('/Contacts/SecondaryApplicantSetup', newContact)
            .then((response) => {
                //this.props.finished();
                window.location.reload();
                this.setState({saving: false});
            });

    }

    handleChange(event: any) {
        const target = event.target;
        let value = target.value;
        const name = target.name || target.getAttribute('name');

        if (!name)
            return;

        let f = this.state.fields.getFieldByName(name);

        if (f.dataType === DataType.string && f.maxLength) {
            if (value !== null && value.length > f.maxLength) {
                alert(`Maximum length for '${f.label}' is ${f.maxLength}`);
                return;
            }
        }
        f.value = value;

        this.setState({ fields: this.state.fields });
    }

    render() {
        let context = this.getContext();

        return (
            <>
                <Container className="pill-form">
                    <>
                        <Container>
                            <Row>
                                <h1 className='m pb-3'>Add your co-applicant:</h1>
                            </Row>
                            <Row>
                                <Stack gap={1} direction="horizontal" className="pill-stack">
                                    <InputPill extraClasses="pill-missing-fw" hideIfValue={false} field={this.state.fields.getFieldByName("setupFirstName")} ></InputPill>
                                    <InputPill extraClasses="pill-missing-fw" hideIfValue={false} field={this.state.fields.getFieldByName("setupLastName")} ></InputPill>
                                    <InputPill extraClasses="pill-missing-fw email-pill" hideIfValue={false} field={this.state.fields.getFieldByName("setupEmailAddress")} ></InputPill>
                                </Stack>
                            </Row>
                            {this.state.error !== "" &&
                            <Row>
                                <p className='error'>{this.state.error}</p>
                            </Row>
                            }
                        </Container>
                        <Container>
                            <Row className="pt-5 text-end justify-end">
                                <Col xs={10}></Col>
                                <Col>
                                {this.state.saving &&
                                    <button type="button" className="btn btn-dark next"><Icons.Spinner size="sm" animation="border" role="status" /></button>
                                }
                                {!this.state.saving &&
                                    <button type="button" className="btn btn-dark next" style={{paddingTop:10, paddingBottom:10}} onClick={this.save}>Continue</button>
                                }
                                </Col>
                            </Row>
                        </Container>
                    </>
                </Container>
            </>
        );
    }
}




