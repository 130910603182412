import { PortalHelperTextModel } from "./PortalHelperTextModel";

export interface Field {
    name: string,
    label: string,
    dataType: DataType,
    controlType: ControlType,
    originalValue: string | number | undefined,
    value: string | number | undefined,
    dateValue: Date | null,
    isDirty: boolean,
    tooltip?: PortalHelperTextModel | null,
    options: FormOption[] | null, //list for optionsets
    lookupValues: FormLookupItem[] | null, //list for lookups //the same with optionsets now
    api: string | null,//to get lookup options
    handleChange?(e: any): void;
    //handleDateChange?(event: any): void;
    isPill: boolean | false;
    placeholder?: string | null;
    parts?: string[];
    minValue: any | undefined;
    maxValue: any | undefined;
    maxLength: number | undefined;
    optional: boolean;
}

// Need to see if we can implement as a class to have methods like getValue and a better handleChange
// export class Field {
//     name: string;
//     label: string;
//     dataType: DataType;
//     controlType: ControlType;
//     originalValue: string | number | undefined;
//     value: string | number | undefined;
//     dateValue: Date | null;
//     isDirty: boolean;
//     tooltip?: PortalHelperTextModel | null;
//     options: FormOption[] | null; //list for optionsets
//     lookupValues: FormLookupItem[] | null; //list for lookups //the same with optionsets now
//     api: string | null;//to get lookup options
//     handleChange?(e: React.ChangeEvent): void;
//     handleDateChange?(event:any):void;
//     isPill: boolean | false;
//     placeholder?: string | null;
//     parts?: string[];

//     getFieldValue(doc: any) {
//         if (this.dataType === DataType.boolean) {
//             return convertToBool(doc[this.name] ? doc[this.name] : undefined);
//         }
//         return doc[this.name] ? doc[this.name] : undefined;
//     }
// }


export interface FormOption {
    text: string,
    value: number
}

export interface FormLookupItem {
    text: string,
    value: string,
    item: any
}

export interface FormLookup {
    name: string,
    values: FormLookupItem[]
}

export enum DataType {
    //guid = "guid",
    entityReference = "entityRef",
    string = "string",
    number = "number",
    date = "date",
    boolean = "boolean"
}

export enum ControlType {
    text = "text",
    textarea = "textarea",
    date = "date",
    picklist = "picklist",
    lookup = "lookup",
    number = "number",
    numberWithControls = "numberWithControls",
    decimal = "decimal",
    currency = "currency"
}
