import React from 'react';

class FormattedDate extends React.Component<any, any> {

    convertStringToDate(dateString:any) {
        var dayPart = 0;
        var monthPart = 1;
        var yearPart = 2;
        var splitBy = '/';
        if (dateString[4] === '-') {
            yearPart = 0;
            dayPart = 2;
            splitBy = '-';
        }
        const parts = dateString.split(splitBy); // Split the string by '/' or '-'
        const day = parseInt(parts[dayPart], 10); // Parse day component as integer
        const month = parseInt(parts[monthPart], 10) - 1; // Parse month component as integer (subtract 1 as month indices are zero-based)
        const year = parseInt(parts[yearPart], 10); // Parse year component as integer
      
        return new Date(year, month, day); // Create a new Date object
    }

    render() {
        if (!this.props.date) return "";
        var date:Date;
        // In case we get an actual date instead of a string...
        if (typeof this.props.date.getMonth === 'function') {
            date = this.props.date;
        } else {
            date = this.convertStringToDate(this.props.date);
        }

        var options:any = { day: 'numeric', month: this.props.monthFormat ?? 'long' };
        if (!this.props.excludeYear) {
            options.year = 'numeric';
        }

        const formattedDate = date.toLocaleDateString(undefined, options);

        return <>{formattedDate}</>;
    }
}

export default FormattedDate;