import { PortalContactModel } from "./PortalContactModel";

export enum PortalStatus {
    New = 755980000,
    Profile = 755980001,
    Documentation = 755980002,
    DocumentReview = 755980003,
    LenderAdvice = 755980004,
    AIPClarifications = 755980005,
    AIPSubmitted = 755980009,
    AIPApproved = 755980010,
    SaleAgreed = 755980006,
    PreLoan = 755980007,
    Loan = 755980008
}

export enum MortgageSubType {
    FirstTimeBuyer = 755980002,
    Switching = 755980005,
    InvestmentProperty = 755980006,
    HolidayHome = 755980007,
    EquityReleaseTopup = 755980001,
    EquityReleaseWithWorks = 755980000,
    EquityReleaseNoWorks = 755980004,
    SecondTimeBuyer = 755980003,
}

export enum MortgageType
{
	PurchasingaNewHome = 755980000,
	RemortgagingExistingLoan = 755980001,
}

export enum PropertyType {
    NewBuild = 755980000,
    SelfBuild = 755980001,
    SecondHand = 755980002
}

export enum SelfOwnedSite
{
    Yes = 755980000,
    No = 755980001,
}

export enum MortgageStatus {
    NewLead = 755980000,
    ClientContacted = 755980001,
    PortalSetup = 755980014,
    PortalLogin = 755980017,
    InformationGathering = 755980002,
    AIPSubmitted = 755980003,
    PreAIPClarification = 755980011,
    AIPApproved = 755980004,
    LoanOfferRequested = 755980005,
    PreLoanOfferClarification = 755980012,
    LoanOfferReceived = 755980006,
    Completions = 755980007,
    Closed = 755980008,
    NotProceeding = 755980009,
    OnHold = 755980010,
    BacklogLead = 755980013,
}

export enum YesNoBlank
{
    No = 755980001,
    Yes = 755980000,
} 

export enum ArrangeSolicitor
{
    Requested = 755980000,
    Complete = 755980001,
}

export interface PortalMortgageModel {
    mortgageId: string;
    status: number;
    type: number | null;
    subType: number | null;
    subTypeFormatted: string | null;
    saleAgreed: boolean;
    applicationType: string;
    primaryApplicant: PortalContactModel;
    secondaryApplicant: PortalContactModel;
    solicitor: PortalContactModel;
    propertyValue: number;
    propertyValueFormatted: string;
    purchasePrice: number;
    purchasePriceFormatted: string;
    grossVariableIncome: number | null;
    grossVariableIncomeFormatted: string;
    grossBasicIncome: number | null;
    grossBasicIncomeFormatted: string;
    mortgageAmountProposed: number | null;
    mortgageAmountProposedFormatted: string;
    preferredProduct: string;
    preferredLenders: string[];
    preferredLender: string;
    berRating: number | null;
    berRatingFormatted: string | null;
    portalStatus: number | null;
    percentageOfCompletion: number | null;
    chosenLender: string;
    chosenLenderFullName: string;
    chosenLenderLogo: string | null;
    chosenLenderProduct: string;
    loanOfferExpiryDate: string | null;
    consentToBeReady: boolean | null;
    lenderSpecialConditions: string | null;
    mortgageDetailsPercentage: number;
    propertyDetailsPercentage: number;
    solicitorDetailsPercentage: number;
    documentsPercentComplete: number;
    portalFirstLogin?: Date;
    noOfApplications: number;
    noOfSubmittedApplications: number;
    topUpAmount: number | null;
    mortgageTerm: number | null;
    termAdditionalMonths: number | null;
    fixedTermYearsProposed: number | null;
    propertyAddress: string | null;
    offeredInterestRate: number | null;
    confirmedChosenLender: boolean | null;
    loanOfferRequested: string | null;
    loanOfferReceived: string | null;
    estimatedLoanOfferIssueDate: string | null;
    confirmedTitleDeeds: number | null;
    estimatedClosingDate?: Date | null;
    clientAdvisedClosingDate?: Date | null;
    metWithSolicitor: boolean | null,
    closedDate: Date | null;
    fundsReleaseDate: Date | null;
    arrangeSolicitor: number | null;
    isLoanOfferAvailable: boolean | null;
    isValuationAvailable: boolean | null;
    isEsisAvailable: boolean | null;
    isEsisDownloaded: boolean | null;
    firstRepaymentDate: Date | null;
    hasInsuranceQuote: boolean | null;
    approvalType: number | null;
    useLegacyUploader: boolean | null;
    useLetsBeam: boolean | null;
    deferredPayment: boolean | null;
    isCostOfCreditAvailable: boolean;
    isCostOfCreditDownloaded: boolean | null;
}