import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication } from "@azure/msal-browser";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import { msalConfig } from "./helper/authConfig";
import { Footer } from './components/Footer';
import RequestInterceptor from './components/RequestInterceptor';
import Unauthenticated from './components/Unauthenticated';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const msalInstance = new PublicClientApplication(msalConfig);

root.render(
  <>
    {/* <React.StrictMode>*/}
    <MsalProvider instance={msalInstance}>
      <UnauthenticatedTemplate>
        <Unauthenticated />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <App />
      </AuthenticatedTemplate>
    </MsalProvider>
    {/* <Footer /> */}
    {/* </React.StrictMode> */}
  </>
);

/**/
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(logger.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
