import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../pages/Applicant.css';
import { UserContextComponent } from '../UserContextComponent';
import { Link } from 'react-router-dom';
import * as Icons from "react-bootstrap";
import axios from 'axios';
import { MortgageType } from '../../models/PortalMortgageModel';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { InsuranceValidator, ProfileCheckResponse } from '../AIP/InsuranceQuoteButton';

interface ILifeCoverTileProps {
    padding?: boolean;
    onDone?: any;
}

interface ILifeCoverTileState {
    quoteLoader: boolean;
    quoted: boolean;
    missingDetails: boolean;
    missingDetailsResponse?: ProfileCheckResponse;
}

export class LifeCoverTile extends UserContextComponent<ILifeCoverTileProps, ILifeCoverTileState> {

    constructor(props: any) {
        super(props);
        this.requestAQuote = this.requestAQuote.bind(this);
        this.state = {quoted: false, quoteLoader: false, missingDetails: false};
    }
    
    requestAQuote() {
        const context = this.getContext();
        var missingDetailsResponse = InsuranceValidator.profileComplete(context.mortgage!); 
        if (missingDetailsResponse.missingDetails) {
          this.setState({missingDetails: true, missingDetailsResponse: missingDetailsResponse});
          return;
        }
        
        this.setState({quoteLoader: true});
    
        axios.post('/AIPApplications/RequestAQuote', null, { params: { mortgageId: context.mortgage!.mortgageId } })
          .then(response => {
            this.setState({quoteLoader: false, quoted: true});
            this.props.onDone();
          });
    }

    render() {
        var context = this.getContext();
        var padding = this.props.padding ?? true;
        
        return (<>
            <div className={`text-start boxty tall grid box-teal-outline ${padding ? 'm-3' : ''}`}>
                <h3 className="pb-3">Get Life <br />Cover</h3>
                <p className='mb-3'>This is required for all mortgages.</p>
                {context.mortgage!.type === MortgageType.PurchasingaNewHome 
                    ? <p>If you have not already been in contact with our life assurance specialists at doddl then now is the time.</p>
                    : <p>We work with all insurers and our team can find the best cover to meet your needs </p>
                }
                {this.state.quoteLoader &&
                    <div className="btn fw big btn-light mt-3 a-s-e"><Icons.Spinner size="sm" animation="border" role="status" /></div>
                }
                {this.state.quoted &&
                    <div className="btn fw big btn-light mt-3 a-s-e">Quote generated</div>
                }
                {!this.state.quoteLoader && !this.state.quoted &&
                    <button className="btn fw big btn-light next-secondary pe-5 mt-3 a-s-e" onClick={(e) => { this.requestAQuote(); }}>Get a quote</button>
                }
            </div>
        <Modal centered size="lg" fullscreen="lg-down"  show={this.state.missingDetails} onHide={() => {this.setState({missingDetails: false})}}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <div className="text-center mb-5 ps-5 pe-5">
                    <h1 className="impact">We need more details</h1>
                    <p className="">The following details are needed before we can generate a quote</p>
                </div>
                <Container>
                  <Row className="justify-center">
                      <>
                      {this.state.missingDetailsResponse && this.state.missingDetailsResponse.missingApplicant1Fields.length > 0 &&
                      <Col xs={6}>
                        <div className="boxty box-sage auto-margin">
                          <div className="box-block-80 text-start">
                            <h2>{context.mortgage?.primaryApplicant.firstName} </h2>
                            {this.state.missingDetailsResponse?.missingApplicant1Fields.map((item:string, idx: number) => {
                              return <p key={`app1-missing-${idx}`}><i className="fa-solid fa-circle-chevron-right"></i> {item}</p>;
                            })}
                          </div>
                          <Link to={'/personal1'} className="btn fw big btn-secondary next-secondary pe-5 mt-3 a-s-e" onClick={(e) => { this.setState({missingDetails: false}) }}>Go to profile</Link>
                        </div>
                      </Col>
                      }
                      {(this.state.missingDetailsResponse && (this.state.missingDetailsResponse.missingApplicant2 || this.state.missingDetailsResponse.missingApplicant2Fields.length > 0)) &&
                      <Col xs={6}>
                        <div className="boxty box-sage auto-margin">
                          <div className="box-block-80 text-start">
                            <h2>{context.mortgage?.secondaryApplicant.firstName ?? 'Missing Applicant'} </h2>
                            {this.state.missingDetailsResponse?.missingApplicant2Fields.map((item:string, idx: number) => {
                              return <p key={`app2-missing-${idx}`}><i className="fa-solid fa-circle-chevron-right"></i> {item}</p>;
                            })}
                            </div>
                            <Link to={'/personal2'} className="btn fw big btn-secondary next-secondary pe-5 mt-3 a-s-e" onClick={(e) => { this.setState({missingDetails: false}) }}>Go to profile</Link>
                        </div>
                      </Col>
                      }
                      </>
                  </Row>
                </Container>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
    </Modal>
    </>);
    }
}




