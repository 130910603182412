import { Component } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { ApplicantFieldsCollection } from "../../forms/ApplicantFields";
import { FormControl } from "../FormControl";
import { AggregatePillOverlay, IAggregatePillOverlayProps } from "../Pills/AggregatePillOverlay";
import { UserContext } from "../UserContext";
import "./Profit.css"

export interface IProfitProps extends IAggregatePillOverlayProps {
    fields: ApplicantFieldsCollection,
    record: any,
}
interface IProfitState {
    fields: ApplicantFieldsCollection,
    record: any
}

export class Profit extends AggregatePillOverlay<IProfitProps, IProfitState> {
    static contextType = UserContext;

    constructor(props: any) {
        super(props);
        this.state = {
            fields: this.props.fields,
            record: this.props.record
        };
    }

    componentDidMount() {
        this.setState({
            fields: this.props.fields,
            record: this.props.record
        });
    }

    close(): void {
        if (this.props.closePill)
            this.props.closePill();
    }

    render() {
        return (
            <>
                <div>
                    <Modal.Body>
                        <Container className="profit-container">
                            <Row>
                                <Col>
                                    <FormControl field={this.state.fields.getFieldByName("grossProfit")} ></FormControl>
                                </Col>

                                <Col>
                                    <FormControl field={this.state.fields.getFieldByName("previousYearProfit")} ></FormControl>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-standard btn-wide" onClick={() => { this.close(); }}>Done</Button>
                    </Modal.Footer>
                </div>
            </>
        );
    }
}