import axios from "axios";
import React from "react";
import { Modal, Stack } from "react-bootstrap";
import * as Icons from "react-bootstrap";
import { UserContextComponent } from "./UserContextComponent";
import { InputPill } from "./Pills/InputPill";
import { CopyPill } from "./Pills/CopyPill";
import { ControlType, DataType, Field } from "../models/PortalMetadata";
import { PortalReferralModel } from "../models/PortalReferralModel";
import { logger } from "../helper/Logger";

export interface IReferralBoxProps {
    horizontal?: boolean | null;
    style: any;
}

export interface IReferralBoxState {
    showReferral: boolean;
    sending: boolean;
    showEmailDialog: boolean;
}

export class ReferralBox extends UserContextComponent<IReferralBoxProps, IReferralBoxState> {
    constructor(props: IReferralBoxProps) {
        super(props);

        this.state = { showReferral: false, sending: false, showEmailDialog: false };
        this.createReferrals = this.createReferrals.bind(this);
    }

    componentDidMount(): void {
    }

    createReferrals(email1:string, email2:string) {
        var emails = (email1 ?? "").trim();
        if ((email2 ?? "") !== "") {
            emails += (";" + email2.trim());
        }
        
        if (emails !== "") {
            this.setState({sending: true})

            const context = this.getContext();
            const contact = context.contact!;
            var referral:PortalReferralModel = {
                name: contact.contactCode!,
                emailAddresses: emails,
                contactId: contact.contactId,
                mortgageId: context.mortgage?.mortgageId!
            }
            axios
                .post('/Referrals/Create', referral)
                .then(() => { 
                    logger.log('Referral created'); 
                    this.setState({sending: false, showEmailDialog: false})
                })
                .catch((e) => {
                    logger.log(e);
                    this.setState({sending: false, showEmailDialog: false})
                });
        }
    }

    render() {
        const context = this.getContext();
        var email1: Field = {
            name: "",
            label: "",
            dataType: DataType.string,
            controlType: ControlType.text,
            originalValue: "",
            value: "",
            dateValue: null,
            isDirty: false,
            options: null,
            lookupValues: null,
            api: null,
            isPill: false,
            minValue: undefined,
            maxValue: undefined,
            maxLength: undefined,
            optional: true,
            placeholder: "example@doddl.ie"
        };
        var email2: Field = {
            name: "",
            label: "",
            dataType: DataType.string,
            controlType: ControlType.text,
            originalValue: "",
            value: "",
            dateValue: null,
            isDirty: false,
            options: null,
            lookupValues: null,
            api: null,
            isPill: false,
            minValue: undefined,
            maxValue: undefined,
            maxLength: undefined,
            optional: true,
            placeholder: "example@doddl.ie"
        };
        
        return (
        <div key={'invite-' + context.mortgage!.mortgageId} className={"boxty wide box-teal"} style={this.props.style}>

            <p className={`text-white`}>Are you happy with our service</p>
            <h1 className="pointer text-white" onClick={() => this.setState({showReferral: !this.state.showReferral})}>Refer your friends & <strong>win!</strong></h1>
            <br />
            <Stack gap={2} direction="horizontal" className="text-center justify-center">
                    <CopyPill extraClasses="text-white" value={`https://doddl.ie/mortgage-online-application/?referrer=${context.contact?.contactCode}`}></CopyPill>
                    {/* <div style={{paddingBottom: 4, marginRight:7, marginTop:-3}}>
                    <button className="btn btn-standard btn-outline-white" onClick={() => this.setState({showEmailDialog: true})}><i className="far fa-envelope"></i></button>
                    </div> */}
                    <div style={{paddingBottom: 4, marginTop:-3}}>
                    <button className="btn btn-standard btn-outline-white" onClick={() => window.open(`https://wa.me/?text=Here is your doddl referral link: https://doddl.ie/mortgage-online-application/?referrer=${context.contact?.contactCode}`, "_blank")}><i className="fab fa-whatsapp"></i></button>
                    </div>
            </Stack>
            <br />
            <Modal size="lg" show={this.state.showEmailDialog} onHide={() => {this.setState({showEmailDialog: false})}}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="boxty text-center">
                        <p className="text-gray">Are you happy with our service?</p>
                        <h2 className="impact">Refer Friends and <span className="doddltext">Win!</span></h2>
                        <p className="text-gray">Simply enter your friends’ email addresses here:</p>
                        <div className="justify-center" style={{display: 'inline-block'}}>
                            <InputPill field={email1} hideIfValue={false} disableCollapse usePlaceHolderForMinWidth extraClasses="referral-email-pill"></InputPill> 
                            <InputPill field={email2} hideIfValue={false} disableCollapse usePlaceHolderForMinWidth extraClasses="referral-email-pill"></InputPill> 
                            <div className="text-end" style={{paddingRight: 8}}>
                                <button className="btn btn-standard btn-wide" onClick={() => {this.createReferrals(email1.value as string, email2.value as string);}}>{this.state.sending ? <Icons.Spinner size="sm" animation="border" role="status" /> : 'SEND'}</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
        );
    }
}