import React from "react";
import { Component } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { ControlType, Field } from "../../models/PortalMetadata";
import { Tooltip } from "../Tooltip";
import "./AutoPill.css";

interface IAutoCompletePillProps {
    field: Field;
    hideIfValue: boolean | null;
    handleChange(event: any): any;
    list: any[],
    //onDone(mortgageDocumentId: string): void;
}

interface IAutoCompletePillState {
    hideIfValue: boolean | null;
    value: any[]
    hasFocus: boolean;
}


export class AutoCompletePill extends Component<IAutoCompletePillProps, IAutoCompletePillState> {
    constructor(props: IAutoCompletePillProps) {
        super(props);
        this.toggleFocus = this.toggleFocus.bind(this);
        this.state = {
            hideIfValue: false, 
            value: (this.props.list.filter(c => c.value == this.props.field.value)),
            hasFocus: false,
        };
    }

    componentDidUpdate() {
        if (this.state.hideIfValue !== this.props.hideIfValue)
            this.setState({ hideIfValue: this.props.hideIfValue });
    }

    componentDidMount() {
        if (this.state.hideIfValue !== this.props.hideIfValue)
            this.setState({ hideIfValue: this.props.hideIfValue });
    }

    getPillClass() {
        return "notstarted";
    }

    focus(field: Field) {
        // if (field.controlType == ControlType.text || field.controlType == ControlType.textarea || field.controlType == ControlType.number || field.controlType == ControlType.decimal
        //     || field.controlType == ControlType.currency) {
            //let inputs = document.getElementsByName(field.name);
            let input = document.getElementById(field.name+"pill2");
            if (input != null) {
                input.focus();
            }
        // }
    }

    setValue(selected: any[]) {
        let val: any;
        if (selected.length == 0) {
            val = "";
        }
        else {
            val = selected[0].value;
        }
        this.props.handleChange({ target: { value: val, name: this.props.field.name } });
    }

    isFocused():boolean {
        let focused = false;
        let inputs = document.getElementsByName(this.props.field.name);
        if (inputs != null && inputs[0] && inputs[0].id === document.activeElement!.id) {
          focused = true;
        }
        return focused;
    }

    toggleFocus() {
        var newFocus = !this.state.hasFocus;
        this.setState({hasFocus: newFocus});
        // if(this.props.onClick) {
        //     this.props.onClick();
        // }
    }
    
    render() {
        if (this.state.hideIfValue && (this.props.field.value || this.props.field.optional) && !this.isFocused())
            return "";

        var f = this.props.field;
        let text = f.controlType === ControlType.date ? "" : f.value;
        if (f.controlType === ControlType.lookup) {
            let item = f.lookupValues?.find(x => { return x.value == f.value });
            if (item)
                text = item.text;
        }

        var pillMissingClass:string = (!this.props.field.optional && !this.props.field.value ? "pill-missing" : "");
        var pill:any;
        if (!this.props.field.value && !this.state.hasFocus) {
            pill = <div className="pill-container" onClick={this.toggleFocus}>
                <div className="pill-container">
                    <div key={"pill1"} className={"pill-component " + pillMissingClass}
                                style={{
                                    borderLeftStyle: 'solid',
                                    borderRightStyle: 'solid',
                                    backgroundColor: "#FF000002",
                                }}>{<Tooltip tooltip={this.props.field.tooltip}><div onClick={() => this.focus(this.props.field)}>{this.props.field.label}</div></Tooltip>}</div>
                </div></div>;
        } else {
            pill = 
            <div className="pill-container">
            <div className="pill-container">
                <div key="pill1" className={"pill-component " + pillMissingClass}
                    style={{
                        borderLeftStyle: 'solid',
                        borderRightStyle: "solid",
                    }}>
                    {<Tooltip tooltip={this.props.field.tooltip}><div onClick={() => this.focus(this.props.field)}>{this.props.field.label}</div></Tooltip>}
                </div>
                <div id={this.props.field.name+"pill2"} key="pill2" className={"autopill-component " + (!this.props.field.optional && !this.props.field.value ? "pill-missing" : "")}
                    style={{
                        borderLeftStyle: 'none',
                        borderRightStyle: 'solid',
                        textAlign: "left"

                    }}>{<>
                        <Typeahead
                            id={this.props.field.name}
                            className="autopill-typeahead"
                            onChange={(selected) => {
                                this.setState({ value: selected });
                                this.setValue(selected);

                            }}
                            options={this.props.list}
                            selected={this.state.value}
                            labelKey="text"
                            allowNew={false}
                            multiple={false}
                            highlightOnlyResult={false}
                            align="left"
                            
                            // style={{width: spanWidth}}
                        />
                        <span id={this.props.field.name + "-span"} style={{ visibility: "hidden", height: "1px", whiteSpace:"nowrap" }}>{text}</span>
                    </>}
                </div>
            </div></div>
        }
        setTimeout(() => {
            if (this.state.hasFocus) {
                this.focus(this.props.field);
            }
        }, 200);

        return (pill);
    }
}