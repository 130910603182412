import React from "react";
import { UserContextComponent } from "../UserContextComponent";
import { OptInOut, PortalContactModel } from "../../models/PortalContactModel";
import { ApplicantService } from "../../pages/ApplicantService";
import { Toggle } from "@fluentui/react";

export interface IRateCheckerProps {
}

export interface IRateCheckerState {
    contact: PortalContactModel | null;
}

export class RateCheckerTile extends UserContextComponent<IRateCheckerProps, IRateCheckerState> {
    applicantService: ApplicantService;
    constructor(props: IRateCheckerProps) {
        super(props);
        this.saveRateUpdated = this.saveRateUpdated.bind(this);
        this.state = { contact: null };
    }

    componentDidMount(): void {
        const context = this.getContext();
        this.applicantService = new ApplicantService(context, this, context.applicantNo!);
        this.applicantService.getApplicantData();

        let applicant:PortalContactModel = (context.applicantNo === 1 ? context.mortgage?.primaryApplicant : context.mortgage?.secondaryApplicant)!;
        this.setState({contact: applicant})
    }

    saveRateUpdated(e:any, checked?:boolean) {
        this.applicantService.saveRateUpdateStatus(checked ? OptInOut.OptIn : OptInOut.OptOut);
        this.setState({contact: this.state.contact})
    }

    render() {
        const context = this.getContext();
        let mortgage = context.mortgage!;

        return (
            <div key={'bestrate-' + mortgage.mortgageId} className={"text-start boxty tall box-teal-outline "} style={{paddingBottom: 25}}>
                <>
                    <p className={`text-gray`}>Stay on the best rate!</p>
                    <h1 className={`text-gray`}>Rate Checker</h1>
                    <p className={'mt-2'} style={{marginBottom: 23}}>Right now, you’re on a <strong>{mortgage.fixedTermYearsProposed ? mortgage.fixedTermYearsProposed + " year fixed" : "variable rate"} at {mortgage.offeredInterestRate}% APR</strong> - but don’t worry, we’ll contact you 6 months before that and set out the most competitive rates available to you</p>
                    <Toggle className={`mt-3`} onText="ON" offText="OFF" checked={(this.state.contact?.rateUpdateStatus === OptInOut.OptIn)} onChange={this.saveRateUpdated}></Toggle>
                </>
            </div>
            );
    }
}