import { Component } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Menu } from './components/Menu';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Profile } from './pages/Profile';
import { MortgageDetails } from './pages/MortgageDetails';
import Property from './pages/Property';
import './styles.css';
import { Documents } from './pages/Documents';
import { DocumentReview } from './pages/DocumentReview';
import { NotFound } from './pages/NotFound';
import { ErrorPage } from './pages/Error';
import { LenderAdvice } from './pages/LenderAdvice';
import { UserProvider } from './components/UserProvider';
import { AIPApplication } from './pages/AIPApplication';
import { SideNav } from './components/SideNav';
import Shared from './pages/Shared';
import { HomePage } from './pages/HomePage';
import { SolicitorDetails } from './pages/SolicitorDetails';
import { SharedDetails } from './pages/SharedDetails';
import { NotValid } from './pages/NotValid';
import RequestInterceptor from './components/RequestInterceptor';
import { Dashboard } from './pages/Dashboard';
import { LoanCompletion } from './pages/LoanCompletion';
import { LenderConfirmation } from './pages/LenderConfirmation';
import LogoutOnIdle from './components/Idle/LogoutOnIdle';
import { ImpersonateContact } from './pages/ImpersonateContact';
import { ApplicantResidential } from './pages/ApplicantResidential';
import { ApplicantEmployment } from './pages/ApplicantEmployment';
import { ApplicantPersonal } from './pages/ApplicantPersonal';
import { ApplicantFinancial } from './pages/ApplicantFinancial';
import { logger } from './helper/Logger';
import { ApplicantAssetsAndLoans } from './pages/ApplicantAssetsAndLoans';

class App extends Component {
    componentDidMount() {
    }

    componentWillUnmount() {
    }

    unhandledrejection(event: any) {
        logger.log(event.reason);
    }

    render() {
        logger.log("App was loaded");
          return (
            <div className="App">
                <div>
                    <Menu></Menu>
                </div>

                <Router>
                    <RequestInterceptor>
                        <UserProvider>
                            {/* <IdleTimerModal /> */}
                            <LogoutOnIdle></LogoutOnIdle>
                            <div className="doddl-bg">
                                <SideNav authenticated={true}></SideNav>
                                <Routes>
                                    <Route path="/" element={<HomePage />}>
                                    </Route>                                   
                                    <Route path="/dashboard" element={<Dashboard />}>
                                    </Route>
                                    <Route path="/mortgage" element={<MortgageDetails />}>
                                    </Route>
                                    <Route path="/profile" element={<Profile />}>
                                    </Route>

                                    {/* <Route path="/applicant1" element={<Applicant key={1} applicantNo={1}></Applicant>}>
                                    </Route> */}
                                    <Route path="/applicant1" element={<ApplicantPersonal key={10} applicantNo={1}></ApplicantPersonal>}>
                                    </Route>
                                    <Route path="/personal1" element={<ApplicantPersonal key={11} applicantNo={1}></ApplicantPersonal>}>
                                    </Route>
                                    <Route path="/residential1" element={<ApplicantResidential key={12} applicantNo={1}></ApplicantResidential>}>
                                    </Route>
                                    <Route path="/employment1" element={<ApplicantEmployment key={13} applicantNo={1}></ApplicantEmployment>}>
                                    </Route>
                                    <Route path="/financial1" element={<ApplicantFinancial key={14} applicantNo={1}></ApplicantFinancial>}>
                                    </Route>
                                    <Route path="/assetsandloans1" element={<ApplicantAssetsAndLoans key={15} applicantNo={1}></ApplicantAssetsAndLoans>}>
                                    </Route>

                                    {/* <Route path="/applicant2" element={<Applicant key={2} applicantNo={2}></Applicant>}>
                                    </Route> */}
                                    <Route path="/applicant2" element={<ApplicantPersonal key={20} applicantNo={2}></ApplicantPersonal>}>
                                    </Route>
                                    <Route path="/personal2" element={<ApplicantPersonal key={21} applicantNo={2}></ApplicantPersonal>}>
                                    </Route>
                                    <Route path="/residential2" element={<ApplicantResidential key={22} applicantNo={2}></ApplicantResidential>}>
                                    </Route>
                                    <Route path="/employment2" element={<ApplicantEmployment key={23} applicantNo={2}></ApplicantEmployment>}>
                                    </Route>
                                    <Route path="/financial2" element={<ApplicantFinancial key={24} applicantNo={2}></ApplicantFinancial>}>
                                    </Route>
                                    <Route path="/assetsandloans2" element={<ApplicantAssetsAndLoans key={25} applicantNo={2}></ApplicantAssetsAndLoans>}>
                                    </Route>

                                    <Route path="/documents" element={<Documents />}>
                                    </Route>
                                    {/* <Route path="/open-banking" element={<OpenBanking />}>
                                    </Route> */}
                                    <Route path="/document-review" element={<DocumentReview />}>
                                    </Route>
                                    <Route path="/lender-advice" element={<LenderAdvice />}>
                                    </Route>
                                    <Route path="/aip-application" element={<AIPApplication />}>
                                    </Route>
                                    <Route path="/property" element={<Property />}>
                                    </Route>
                                    <Route path="/solicitor-details" element={<SolicitorDetails />}>
                                    </Route>
                                    <Route path="/shared" element={<Shared />}>
                                    </Route>
                                    <Route path="/shared-details" element={<SharedDetails />}>
                                    </Route>

                                    <Route path="/completion" element={<LoanCompletion />}>
                                    </Route>

                                    <Route path="/lender-confirmation" element={<LenderConfirmation />}>
                                    </Route>

                                    <Route path="/not-valid" element={<NotValid />}></Route>

                                    <Route path="/error" element={<ErrorPage />} />

                                    <Route path="/_impersonate" element={<ImpersonateContact />} />

                                    <Route path="*" element={<NotFound />} />

                                </Routes>
                            </div>
                        </UserProvider>
                    </RequestInterceptor>
                </Router>
            </div >
        );
    }
}

export default App;