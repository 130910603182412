import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const appInsightsHelper = {
    appInsights: null,
    init() {
        this.appInsights = new ApplicationInsights( {
            config: {
                connectionString: "InstrumentationKey=d0bbf4ba-5013-4bbb-9f36-cee4b27bd8fb;IngestionEndpoint=https://northeurope-2.in.applicationinsights.azure.com/;LiveEndpoint=https://northeurope.livediagnostics.monitor.azure.com/",
                enableAutoRouteTracking: true,
                extensions: [ reactPlugin ]
            }
        } );
        this.appInsights.loadAppInsights();
    },
    trackPageView( data ) {
        if ( this.appInsights != null ) {
            this.appInsights.trackPageView( data );
        }
    }
}

export { reactPlugin, appInsightsHelper };

let p = process.env.REACT_APP_ENABLE_APPLICATION_INSIGHTS;
if(p !== undefined && p === "1") {
    appInsightsHelper.init();    
}
