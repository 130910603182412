export enum LivingStatus {
    Owner = 778390000,
    PrivateRented = 778390001,
    LivingwithRelatives = 778390002
}

export enum MaritalStatus {
    Single = 1,
    Married = 2,
    Divorced = 3,
    Widowed = 4
}

export enum Nationality {
    Irish = 778390000,
    English = 778390001,
    Welsh = 778390002,
    Scottish = 778390003,
    German = 778390004,
    American = 778390005
}

export enum Gender {
    Male = 1,
    Female = 2
}

//export enum CountryOfBirth {
//    Ireland = 778390000,
//    England = 778390001,
//    Scotland = 778390002,
//    Wales = 778390003,
//    Germany = 778390004,
//    USA = 778390005
//}

export enum EmploymentStatus {
    Employed = 778390000,
    SelfEmployed = 778390001,
    HomeMaker = 778390002,
    Retired = 778390003,
    NotEmployed = 778390004
}

export enum HasChildren {
    No = 0,
    Yes = 1,
}

export enum MissedPayments {
    No = 755980001,
    Yes = 755980000,
}

export enum BankrupcyOrCourt {
    No = 755980001,
    Yes = 755980000,
}

export enum OptInOut {
    OptOut = 755980000,
    OptIn = 755980001,
}

export interface PortalContactModel {
    contactId: string;
    firstName: string;
    middleName: string;
    lastName: string;
    emailAddress: string;
    emailAddress2: string;
    telephone: string;
    mobile: string;
    fullName: string;
    smoker: number | null;
    occupation: string;
    birthDate: Date | null;
    title: string;
    maidenName: string;
    ppsNumber: string;
    countryOfBirth: string | null;
    placeOfBirth: string;
    previousAddressEircode: string;
    previousAddress: string;
    maritalStatus: number | null;
    sex: number | null;
    nationality: number | null;
    ownerOrRenter: number | null;
    currentAddressYears: number | null;
    currentAddressMonths: number | null;
    age: number | null;

    //new fields
    hasChildren: number | null;
    hasChildcareCost: string | null;
    monthlyChildcareCost: number | null;
    monthlyChildcareCostOther: number | null;
    requireWorkPermit: string | null;
    visaType: string | null;
    visaExpiryDate: Date | null;
    livedOutsideofIreland: number | null;

    address1: string;
    address1Eircode: string;
    address1Line1: string;
    address1Line2: string;
    address1Line3: string;
    address1City: string;
    address1County: string;
    address1Country: string;
    address1MovedIn: Date | null;

    address2: string;
    address2Eircode: string;
    address2Line1: string | null;
    address2Line2: string;
    address2Line3: string;
    address2City: string;
    address2County: string;
    address2Country: string;
    address2MovedIn: Date | null;

    //tab2
    monthlyRentAmount: number | null;
    monthlyRentAmountOther: number | null;
    previouslyHaveMortgageLoan: string | null;
    previouslyHaveMortgageLoan2: string | null;

    doYouPayYourRentThroughYourBankAccount: string | null;

    //tab3
    employmentStatus: number | null;
    //paye
    lengthOfTimeEmployerYears: number | null;
    lengthOfTimeEmployerMonths: number | null;
    lengthOfTimePrevEmployerYears: number | null;
    lengthOfTimePrevEmployerMonths: number | null;
    grossSalary: number | null;
    otherCurrencyIncome: string | null;
    commission: number | null;
    overtime: number | null;
    bonus: number | null;
    otherIncome: number | null;
    //self emp
    companyName: string;
    accountantsName: string | null;

    //children
    childrenAge: string | null;
    childrenNumber: number | null;

    employersName: string | null;
    employersAddress1: string | null;
    employersAddress2: string | null;

    prevEmployersName: string | null;
    prevOccupation: string | null;
    prevEmployersAddress1: string | null;
    prevEmployersAddress2: string | null;

    missedPayments: number | null;
    bankrupcyOrCourt: number | null;
    jobType: number | null;

    personalInfoPercentage: number | null;
    residentialInfoPercentage: number | null;
    employmentInfoPercentage: number | null;
    assetsInfoPercentage: number | null;
    bankingInfoPercentage: number | null;
    percentageOfCompletion: number;
   
    businessAddress1: string | null;
    businessAddress2: string | null;
    natureOfBusiness: string | null;
    businessEstablishedDate: Date | null;
    shareholdingPercentage: number | null;
    grossProfit: number | null;
    previousYearProfit: number | null;
    metFaceToFace: boolean | null;

    salaryFrequency: number | null;
    usCitizen: boolean | null;
    contactCode: string | null;
    rateUpdateStatus: number | null;
}