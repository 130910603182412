import { FieldsCollection } from "../forms/FieldsCollection";
import { UserContextComponent } from "../components/UserContextComponent";
import { IAggregateItemProps } from "../components/Pills/AggregatePill";
import { FormLookupItem } from "../models/PortalMetadata";
import UserContextState, { UserContext } from "../components/UserContext";
import { IFileItem } from "../components/DocumentManager";

export interface IApplicantState {
    loading: boolean;
    usingCard: boolean;
    contact: any;
    otherContact: any;
    step: number;
    selectedPrediction: string;
    predictions: any[];
    searchValue?: string | null;
    AutoComplete: boolean;
    helperTexts: any[];
    mortgageDocumentList: any[];
    documentTypeList: any[];
    occupationList: any[];
    nationalitiesList: any[];
    countryList: any[];
    applicantNo: any;
    fields: FieldsCollection;
    emptyOnly: boolean | null;
    finished?: boolean | null;
    redirectTo?: string | null;
    dochelperTexts: any[];
    address1IsValid: boolean;
    address1Error?: string;
    address2IsValid: boolean;
    address2Error?: string;
    refreshToggle:boolean;
    files: IFileItem[];
    bankingRender?: 'openbanking' | 'manual' | null;
    openBankingChecker?: boolean;
}

export class ApplicantBaseComponent extends UserContextComponent<any, IApplicantState> {   
    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            usingCard: false,
            contact: null,
            otherContact: null,
            step: 1,
            selectedPrediction: "",
            predictions: [],
            AutoComplete: true,
            helperTexts: [],
            mortgageDocumentList: [],
            documentTypeList: [],
            occupationList: [],
            nationalitiesList: [],
            countryList: [],
            applicantNo: this.props.applicantNo,
            fields: new FieldsCollection(),
            emptyOnly: false,
            dochelperTexts: [],
            address1IsValid: true,
            address2IsValid: true,
            files: [],
            refreshToggle: false
        };
    }
}
