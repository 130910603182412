import { PortalCategoryModel } from "./PortalCategoryModel";

export enum SpecialConditionsType
{
    PreAIPClarification = 755980004,
    AIPCondition = 755980003,
    PreLoanofferClarification = 755980005,
    LoanofferCustomercondition = 755980000,
    LegalTeam = 755980001,
    LegalPack = 755980002,
}

export interface PortalSpecialConditionsModel {
    mortgageDocumentId: string;
    specialConditionsId: string;
    name: string;
    description: string;
    type?: SpecialConditionsType;
    uploadComplete?: boolean;
    completed?: boolean;
    lender: string;
    category: PortalCategoryModel;
    uploaded?: boolean; 
}