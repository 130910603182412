import { Col, Container, Row } from "react-bootstrap";
import { FormControl } from "./FormControl";
import { AggregatePillOverlay, IAggregatePillOverlayProps } from "./Pills/AggregatePillOverlay";
import { UserContext } from "./UserContext";
import { ControlType, DataType, Field } from "../models/PortalMetadata";
import currencyFormatter, { currencyToNumber } from "../helper/currencyFormatter";

export interface IBothApplicantFieldsWithAggregateProps extends IAggregatePillOverlayProps {
    field: Field,
    fieldLabel: string,
    field2: Field,
    field2Label: string,
    totalLabel: string,
    title: string,
}
interface IBothApplicantFieldsWithAggregateState {
    field: Field,
    field2: Field,
}

export class BothApplicantFieldsWithAggregate extends AggregatePillOverlay<IBothApplicantFieldsWithAggregateProps, IBothApplicantFieldsWithAggregateState> {
    static contextType = UserContext;

    constructor(props: any) {
        super(props);
        this.state = {
            field: this.props.field,
            field2: this.props.field2
        };
    }

    componentDidMount() {
        this.setState({
            field: this.props.field,
            field2: this.props.field2
        });
    }

    close(): void {
        if (this.props.closePill)
            this.props.closePill();
    }

    render() {
        var value1 = currencyToNumber(this.state.field.value as string);
        var value2 = currencyToNumber(this.state.field2.value as string);

        var totalField: Field = {
            value: currencyFormatter(value1+value2),
            name: "",
            label: this.props.totalLabel,
            dataType: DataType.number,
            controlType: ControlType.currency,
            originalValue: undefined,
            dateValue: null,
            isDirty: false,
            options: null,
            lookupValues: null,
            api: null,
            isPill: false,
            minValue: undefined,
            maxValue: undefined,
            maxLength: undefined,
            optional: false
        };

        return (
            <Container className="profit-container">
                <Row>
                    <Col>
                        <FormControl light field={this.state.field} customLabel={this.props.fieldLabel}></FormControl>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormControl light field={this.state.field2} customLabel={this.props.field2Label}></FormControl>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormControl light readOnly field={totalField}></FormControl>
                    </Col>
                </Row>
            </Container>
        );
    }
}