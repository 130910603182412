import { Component } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { ApplicantFieldsCollection } from "../../forms/ApplicantFields";
import { FormControl } from "../FormControl";
import { IAggregatePillOverlayProps, AggregatePillOverlay } from "../Pills/AggregatePillOverlay";
import { UserContext } from "../UserContext";
import "./Profit.css"

export interface IVariableIncomeProps extends IAggregatePillOverlayProps {
    fields: ApplicantFieldsCollection,
    record: any,
}
interface IVariableIncomeState {
    fields: ApplicantFieldsCollection,
    record: any,
    hasAny: boolean | null
}

export class VariableIncome extends AggregatePillOverlay<IVariableIncomeProps, IVariableIncomeState> {
    static contextType = UserContext;

    constructor(props: any) {
        super(props);

        this.state = {
            fields: this.props.fields,
            record: this.props.record,
            hasAny: this.props.fields.getFieldByName("hasVariableIncome").value === 755980000 ? true : false
        };

        this.hasAny = this.hasAny.bind(this);
    }

    componentDidMount() {
        this.setState({
            fields: this.props.fields,
            record: this.props.record
        });
    }

    close(): void {
        if (this.props.closePill)
            this.props.closePill();
    }

    hasAny(hasAny: boolean) {
        if (!hasAny) {
            let fields = this.state.fields;
            if (fields) {
                fields.updateModel(this.props.record, "hasVariableIncome", 755980001);
                fields.updateFieldValue("hasVariableIncome", 755980001);
            }
            this.setState({ fields: this.state.fields });
            this.close();
        }
        else {
            let fields = this.state.fields;
            if (fields) {
                fields.updateModel(this.props.record, "hasVariableIncome", 755980000);
                fields.updateFieldValue("hasVariableIncome", 755980000);
            }
            this.setState({ fields: this.state.fields });
            this.setState({ hasAny: hasAny });
        }
    }

    render() {
        return (
            <>
                <div>
                    <Modal.Body>
                        <Container className="profit-container text-center">
                            {(this.state.hasAny === null || this.state.hasAny === false) &&
                                <div>
                                    <h3>Do you any variable income?
                                    </h3>
                                    <br />
                                    <Button className="btn btn-standard btn-outline btn-wide" onClick={e => this.hasAny(true)}>Yes</Button> &nbsp;
                                    <Button className="btn btn-standard btn-outline btn-wide" onClick={e => this.hasAny(false)}>No</Button>
                                </div>
                            }
                            {this.state.hasAny === true &&
                                <>
                                    <Row>
                                        <Col>
                                            <FormControl field={this.state.fields.getFieldByName("commission")} ></FormControl>
                                        </Col>
                                        <Col>
                                            <FormControl field={this.state.fields.getFieldByName("overtime")} ></FormControl>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormControl field={this.state.fields.getFieldByName("bonus")} ></FormControl>
                                        </Col>
                                        <Col>
                                            <FormControl field={this.state.fields.getFieldByName("otherIncome")} ></FormControl>
                                        </Col>
                                    </Row>
                                </>
                            }
                        </Container>
                    </Modal.Body>
                    {this.state.hasAny === true &&
                        <Modal.Footer>
                            <Button className="btn btn-standard btn-wide" onClick={() => { this.close(); }}>Done</Button>
                        </Modal.Footer>
                    }
                </div >
            </>
        );
    }
}