import React from 'react';
import './Documents.css';
import { MortgageType, YesNoBlank } from '../models/PortalMortgageModel';
import { Navigate } from 'react-router-dom';
import { UserContextComponent } from '../components/UserContextComponent';
import axios from 'axios';
import { Row, Col, Accordion, Card, Container, Modal, Stack } from 'react-bootstrap';
import { PortalPage } from '../components/PortalPage';
import UserContextState from '../components/UserContext';
import { PortalAIPApplicationModel, AIPStatus, AIPDDLStatus, AIPType } from '../models/PortalAIPApplication';
import { PortalClarificationModel } from '../models/PortalClarificationModel';
import "./AIPApplication.css";
import { MortgageDocumentStatusCode, PortalDocumentType, PortalMortgageDocumentModel } from '../models/PortalMortgageDocumentModel';
import { IFileItem } from '../components/DocumentManager';
import { DocumentPill } from '../components/Pills/DocumentPill';
import { PortalSpecialConditionsModel } from '../models/PortalSpecialConditionsModel';
import { ValuationDownloadButton } from '../components/ValuationDownloadButton';
import { AIPDownloadButton } from '../components/AIPDownloadButton';
import * as Icons from "react-bootstrap";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { AppointSolicitorTile } from '../components/Tiles/AppointSolicitorTile';
import { LifeCoverTile } from '../components/Tiles/LifeCoverTile';

interface IAIPApplicationState {
    loading: boolean;
    applications: PortalAIPApplicationModel[];
    redirectToPropertyPage: boolean;
    redirectToCompletions: boolean;
    documents: PortalMortgageDocumentModel[];
    files: IFileItem[];
    cta1Collapsed: boolean;
    cta2Collapsed: boolean;
    cta3Collapsed: boolean;
    chosenLender?: string;
    showSaleAgreedModal: boolean;
    savingSaleAgreed: boolean;

}

export class AIPApplication extends UserContextComponent<any, IAIPApplicationState> {
    constructor(props: any) {
        super(props);
        this.state = { loading: true, savingSaleAgreed: false, showSaleAgreedModal: false, applications: [], redirectToPropertyPage: false, redirectToCompletions: false, documents: [], files: [], cta1Collapsed: true, cta2Collapsed: true, cta3Collapsed: true };

        this.onSpecialConditionsDeleteComplete = this.onSpecialConditionsDeleteComplete.bind(this);
        this.onSpecialConditionsUploadComplete = this.onSpecialConditionsUploadComplete.bind(this);
        this.confirmSpecialConditionsUpload = this.confirmSpecialConditionsUpload.bind(this);
        this.foundMyNewHome = this.foundMyNewHome.bind(this);

        this.onDocumentDone = this.onDocumentDone.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    getSpecialConditionsById(id: string): any {
        for (let i = 0; i < this.state.applications.length; i++) {
            let app = this.state.applications[i];
            let condition = app.specialConditions.find((c: any) => {
                return c.specialConditionsId === id;
            });
            if (condition != null) {
                return condition;
            }
        }
        return null;
    }

    onSpecialConditionsUploadComplete(url: string, id: string) {
        let condition = this.getSpecialConditionsById(id);
        if (condition != null) {
            condition.uploadComplete = true;
            condition.url = url;
        }
        this.setState({ applications: this.state.applications });
    }

    confirmSpecialConditionsUpload(id: string): void {
        const context = this.getContext();

        let condition = this.getSpecialConditionsById(id);
        if (condition != null) {
            axios.post('/SpecialConditions/CompleteSpecialConditions', null, { params: { specialConditionsId: id, url: condition.url, mortgageId: context.mortgage?.mortgageId } })
                .then(response => {
                    for (let i = 0; i < this.state.applications.length; i++) {
                        let app = this.state.applications[i];
                        let condition = app.specialConditions.find((c: any) => {
                            return c.specialConditionsId === id;
                        });
                        if (condition != null) {
                            condition.uploaded = true;
                        }
                    }
                    this.setState({ applications: this.state.applications });
                });
        }
    }

    onSpecialConditionsDeleteComplete(id: string) {
        let condition = this.getSpecialConditionsById(id);
        if (condition != null) {
            condition.uploadComplete = false;
        }
        this.setState({ applications: this.state.applications });
    }

    foundMyNewHome(): void {
        const context = this.context as UserContextState;
        axios.post('/Mortgages/SetSaleAgreed', null, { params: { mortgageId: context.contact?.mortgageId } })
            .then(response => {
                this.setState({ redirectToPropertyPage: true });
            });
    }

    getStatusClass(app: PortalAIPApplicationModel) {
        if (app.status === AIPStatus.Submitted || (app.status === AIPStatus.Clarifications && app.clarifications.length === 0))
            return "box-sage";
        if (app.status === AIPStatus.Approved)
            return "box-chartreuse";
        if (app.status === AIPStatus.Rejected )
            return "box-error";
        if (app.status === AIPStatus.Clarifications && app.clarifications.length > 0) {
            if (app.clarifications.filter(x => !x.uploaded).length > 0)
                return "box-error";
        }
        if (app.status === AIPStatus.Expired)
            return "box-error";

            return "box-teal-outline";
    }

    private getClarificationById(id: string): any {
        for (let i = 0; i < this.state.applications.length; i++) {
            let app = this.state.applications[i];
            let clarification = app.clarifications.find((c: any) => {
                return c.clarificationId === id;
            });
            if (clarification != null) {
                return clarification;
            }
        }
        return null;
    }

    onUploadComplete(url: string, clarificationId: string) {
        let clarification = this.getClarificationById(clarificationId);
        if (clarification != null) {
            clarification.uploadComplete = true;
            clarification.url = url;
        }
        this.setState({ applications: this.state.applications });
    }

    confirmUpload(id: string): void {
        const context = this.getContext();
        let condition = this.getClarificationById(id);
        if (condition != null) {
            axios.post('/AIPApplications/CompleteClarification', null, { params: { clarificationId: id, url: condition.url, mortgageId: context.mortgage?.mortgageId } })
                .then(response => {
                    for (let i = 0; i < this.state.applications.length; i++) {
                        let app = this.state.applications[i];
                        let clarification = app.clarifications.find((c: any) => {
                            return c.clarificationId === id;
                        });
                        if (clarification != null) {
                            clarification.uploaded = true;
                        }
                    }
                    this.setState({ applications: this.state.applications });
                });
        }
    }

    onDeleteComplete(id: string) {
        let clarification = this.getClarificationById(id);
        if (clarification != null) {
            clarification.uploadComplete = false;
        }
        this.setState({ applications: this.state.applications });
    }

    onDocumentDone(mortgageDocumentId: string, status: MortgageDocumentStatusCode, clarificationId: string) {
        this.confirmUpload(clarificationId);

        let docs = this.state.documents;
        var doc: PortalMortgageDocumentModel | undefined = docs.find(i => i.mortgageDocumentId === mortgageDocumentId)
        if (doc) {
            doc.statusReason = status;
            this.setState({ documents: docs, applications: this.state.applications });
        } else {
            this.setState({ applications: this.state.applications });
        }
    }


    proceedWithLender(item: PortalAIPApplicationModel) {
        let context = this.getContext();
        axios.post('/Mortgages/ProceedWithLender', null, { params: { mortgageId: context.contact?.mortgageId, lender: item.lenderChoice, applicationId: item.aipApplicationId } })
            .then(response => {
                context.mortgage!.chosenLender = item.lenderChoice;
                context.mortgage!.chosenLenderFullName = item.lender;
                item.aipDdlStatus = AIPDDLStatus.Proceeding;
                this.setState({ chosenLender: item.lenderChoice, redirectToCompletions: true });
            });
    }

    renderApproved(item: PortalAIPApplicationModel) {
    let context = this.getContext();
        return (
            <div>
                <p className="pb-0 mb-0">{item.amount}</p>
                <p className="aip-status aip-status-approved"><strong>GRANTED</strong></p>
                <i className="fas fa-circle-check aip-approved-text-lg"/>
                <p className="aip-status aip-status-approved"><strong>Approval in Principle</strong></p>
                {item.expirationDate &&
                <p className="tight">Valid until {item.expirationDate}</p>
                }

                {item.isFileAvailable &&
                    <AIPDownloadButton item={item} buttonStyle="standard" />
                }
                {item.isValuationAvailable &&
                    <ValuationDownloadButton item={item} buttonStyle="standard" />
                }
                {item.specialConditions !== null && item.specialConditions.length > 0 &&
                    <div>
                        <br />
                        <Accordion>
                            <Accordion.Item eventKey='1'>
                                <Accordion.Header className='small'>
                                    <div className='text-center' style={{ width: '100%' }}><strong>{item.specialConditions.length} loan offer condition{item.specialConditions.length > 1 ? 's' : ''}</strong></div>
                                </Accordion.Header>
                                <Accordion.Body className='special-conditions'>
                                    {item.specialConditions !== null && item.specialConditions.map((sc: PortalSpecialConditionsModel, idx: number) => (
                                        // <li><div key={"aip-condition" + sc.specialConditionsId} className='aip-special-condition' style={{ display: "inline-block", width: '100%' }} aria-label={item.lender + " - " + sc.name}>{sc.name}</div></li>
                                        <li key={"aip-condition" + sc.specialConditionsId} className='aip-special-condition' aria-label={item.lender + " - " + sc.name}>{sc.name}</li>
                                    ))
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                }
                <button className='btn btn-standard btn-proceed' onClick={() => window.Intercom('showNewMessage')}>Speak to my Advisor</button>
            </div>
        );
    }

    renderClarifications(item: PortalAIPApplicationModel) {
        let context = this.getContext();
        return (
            <>
                <p className="pb-0 mb-0">{item.amount}</p>
                <p className="pt-0 mt-0">Submitted on <span>{item.submissionDate}</span></p>
                {item.isValuationAvailable &&
                    <>
                    <ValuationDownloadButton item={item} buttonStyle="standard" />
                    <br />
                    </>
                }
                {item.clarifications.filter(x => !x.uploaded).length > 0 &&
                    <>
                        <i className="fa-solid fa-triangle-exclamation pill-big aip-clarifications-text-lg"></i>
                        <p className="aip-status aip-clarifications-text">Additional docs required</p>
                        <p className="tight">Please provide the following:</p>
                        <br />
                    </>
                }
                {item.clarifications.filter(x => !x.uploaded).length === 0 &&
                    <>
                    <p className="aip-status aip-submitted-text">Additional docs required</p>
                    <p className="tight">Please provide the following:</p>
                    <br />
                    </>
                }
                {item.clarifications !== null && item.clarifications.map((sc: PortalClarificationModel, idx: number) => (
                    <div key={"pill-paragraph" + sc.mortgageDocumentId} style={{ marginTop: '10px' }}>
                        <DocumentPill
                            key={"pill-" + sc.mortgageDocumentId}
                            status={sc.uploaded ? MortgageDocumentStatusCode.Uploaded : MortgageDocumentStatusCode.Active}
                            category={"AIP"}
                            name={sc.title}
                            portalName={""}
                            helpText={sc.description}
                            files={this.state.files.filter(x => x.mortgageDocumentId === sc.mortgageDocumentId)}
                            contactId={context.contact?.contactId!}
                            mortgageDocumentId={sc.mortgageDocumentId}
                            onDone={(mortgageDocumentId: string, status: MortgageDocumentStatusCode) => { this.onDocumentDone(mortgageDocumentId, status, sc.clarificationId); }}
                            mortgageId={context.contact?.mortgageId}
                            documentType={PortalDocumentType.Document}
                            metFaceToFace={false}
                            onUploadComplete={(url: any) => { this.onUploadComplete(url, sc.clarificationId); }}
                            comments={""}
                        />
                    </div>
                ))}
            </>);
    }

    renderSubmitted(item: PortalAIPApplicationModel) {
        // Commented out the response date as per #854 - at the time they weren't meeting any SLAs so this was causing problems
        return (
            <>
                <p className="pb-0 mb-0">{item.amount}</p>
                <p className="pt-0 mt-0">Submitted on <span>{item.submissionDate}</span></p>
                {item.isValuationAvailable &&
                    <>
                    <ValuationDownloadButton item={item} buttonStyle="standard" />
                    <br />
                    </>
                }
                <i className="far fa-clock aip-submitted-text-lg"></i>
                <p className="aip-status">Awaiting response</p>
                {/* <p className='tight'>Est: {item.estResponseDate}</p> */}
            </>
        );
    }

    renderNew(item: PortalAIPApplicationModel) {
        return (
            <>
                <p>For <span>{item.amount}</span></p>
                <p className="aip-status">Preparing submission</p>
                {item.isValuationAvailable &&
                    <ValuationDownloadButton item={item} buttonStyle="standard" />
                }
            </>
        );
    }

    renderExpired(item: PortalAIPApplicationModel) {
        return (
            <>
                <p>For <span>{item.amount}</span> Submitted on: <span>{item.submissionDate}</span></p>
                <i className="fa-solid fa-triangle-exclamation pill-big aip-clarifications-text-lg"></i>
                <p className="aip-status aip-clarifications-text">EXPIRED</p>
                <p className='aip-status aip-clarifications-text'><em>Expired on {item.expirationDate}</em></p>
            </>
        );
    }

    renderRejected(item: PortalAIPApplicationModel) {
        return (
            <>
                <p>For <span>{item.amount}</span> Submitted on: <span>{item.submissionDate}</span></p>
                <i className="fa-solid fa-triangle-exclamation pill-big aip-clarifications-text-lg"></i>
                <p className="aip-status aip-clarifications-text">REJECTED</p>
                <p className='aip-status aip-clarifications-text'><em>Rejected on {item.responseDate}</em></p>
            </>
        );
    }

    renderFooter() {
        let context = this.getContext();

        if (context.mortgage === null)
            return "";

        if (context.mortgage.type === MortgageType.PurchasingaNewHome) {

        } else {

        }

        return (
            <Row style={{ marginTop: '20px', height: '100%', bottom: 0 }}>
                <Col className="col-12">
                    <Row>
                        <span className="subtext pt-3">{context.mortgage.primaryApplicant.firstName}, to <strong>get ahead of the process</strong> here are some additional items you might now want to consider</span>
                    </Row>
                </Col>
            </Row>
        );
    }

    private confirmedTitleDeeds() {
        let context = this.getContext();
        axios.post('/Mortgages/ConfirmTitleDeeds', null, { params: { mortgageId: context.contact?.mortgageId } })
            .then(response => {
                if (context.mortgage) {
                    this.setState({ loading: true });
                    context.reloadMortgage();
                    this.setState({ loading: false });
                }
            });
    }

    private loadFiles(): void {
        const context = this.getContext();
        let url = `/sp/GetFiles?mortgageId=${context.mortgage?.mortgageId}`;
        axios.get(url)
            .then((response) => {
                let files = response.data;
                this.setState({ loading: false, files: files });
            })
            .catch((error) => {
                this.setState({ loading: false });
            });
    }

    async populateData() {
        const context = this.context as UserContextState;
        if (context.contact === null)
            return;

        axios.get("/AIPApplications/GetAIPApplicationsByMortgageId?mortgageId=" + context.contact.mortgageId)
            .then((response) => {
                let aips: PortalAIPApplicationModel[] = response.data;
                aips.forEach(e => {
                    e.clarifications = e.clarifications.filter(c => !c.completed);
                });
                this.setState({ applications: aips.filter(a => a.aipDdlStatus !== AIPDDLStatus.Withdrawn).sort((a, b) => a.order > b.order ? 1 : -1) });
                this.loadFiles();
            });
    }
    render() {
        let context = this.getContext();

        if (this.state.loading || context.mortgage === null)
            return (<><p><em>Loading...</em></p></>);

        if (this.state.redirectToPropertyPage) {
            return <Navigate to="/property" />
        }

        if (this.state.redirectToCompletions) {
            return <Navigate to="/completion" />
        }

        let names = this.getApplicantNames();
        let aipStatus = null;
        let awaitingOthers = false;
        let awaitingOtherDocs = false;
        var title = "Awaiting mortgage approval";
        var description = `Hi ${names}, we’ve submitted your application to the following ${this.state.applications.length > 1 ? 'lenders' : 'lender'}.`;
        var subtitle = <>Your mortgage advisor is regularly reviewing status and chasing to get approval for you.</>;

        // If we have active full AIPs hide the househunter aips
        var applicationsToDisplay = this.state.applications.filter(app => app.type === AIPType.FullApplication);
        var activeFullApplications = this.state.applications.filter(app => 
            app.type === AIPType.FullApplication && 
            app.aipDdlStatus !== AIPDDLStatus.Expired &&
            app.aipDdlStatus !== AIPDDLStatus.New &&
            app.aipDdlStatus !== AIPDDLStatus.RejectedByLender &&
            app.aipDdlStatus !== AIPDDLStatus.Withdrawn
            );
        if (!activeFullApplications || activeFullApplications.length === 0 || !applicationsToDisplay || applicationsToDisplay.length === 0) {
            applicationsToDisplay = this.state.applications;
        }
        
        applicationsToDisplay = applicationsToDisplay.filter(a => a.aipDdlStatus !== AIPDDLStatus.RejectedByLender);
        
        let shortestSLA = 1000;
        for (let i = 0; i < applicationsToDisplay.length; i++) {
            let app = applicationsToDisplay[i];
            if (app.sla != null && app.sla < shortestSLA) {
                shortestSLA = app.sla;
            }
            if (app.status === AIPStatus.Approved) {
                aipStatus = AIPStatus.Approved;
            } else if (app.status === AIPStatus.Clarifications) {
                awaitingOtherDocs = true;
                if (aipStatus !== AIPStatus.Approved) {
                    aipStatus = AIPStatus.Clarifications;
                }
            } else if (app.status === AIPStatus.Submitted) {
                awaitingOthers = true;
                if (aipStatus === null) {
                    aipStatus = AIPStatus.Submitted;
                }
            }
        }

        if (aipStatus === AIPStatus.Clarifications) {
            if (this.state.applications.length > 1) {
                description = `Hi ${names}, nearly there! A lender has requested additional documents, please upload below.`;
                subtitle = <>We are still working on getting confirmation from other lenders.</>;
            } else {
                description = `Hi ${names}, nearly there! The lender has requested additional documents, please upload below.`;
                subtitle = <></>;
            }
        }
        if (aipStatus === AIPStatus.Approved) {
            title = "You have mortgage approval!";
            description = `Congratulations ${names}. View your AIP details and document below.`;
            if (awaitingOtherDocs) {
                subtitle = <>Another lender has requested additional docs if you'd like approval from them too.</>;
            } else if (awaitingOthers) {
                subtitle = <>We are still working on getting confirmation from other lenders.</>;
            } else {
                subtitle = <></>;
            }
        }
        if (aipStatus === null) {
            title = "Preparing Submissions";
            description = `Hi ${names}, we’re preparing your application for the following ${this.state.applications.length > 1 ? 'lenders' : 'lender'}.`;
            subtitle = <></>;
        }
        
        // Lets build up the array of divs for the masonry grid to display
        var items:any[] = applicationsToDisplay.map((item) => (
            
                <div key={item.aipApplicationId} className={"boxty m-3-notmobile tall " + this.getStatusClass(item)}>
                    {item.lenderLogo
                        ? <img className="mb-2" style={{width: "100%"}} src={item.lenderLogo}></img>
                        : <h1>{item.lender}</h1>
                    }
                    {item.status === AIPStatus.New &&
                        this.renderNew(item)
                    }
                    {item.status === AIPStatus.Expired &&
                        this.renderExpired(item)
                    }
                    {item.status === AIPStatus.Rejected &&
                        this.renderRejected(item)
                    }
                    {(item.status === AIPStatus.Submitted || (item.status === AIPStatus.Clarifications && item.clarifications.length === 0)) &&
                        this.renderSubmitted(item)
                    }
                    {item.status === AIPStatus.Approved &&
                        this.renderApproved(item)
                    }
                    {item.status === AIPStatus.Clarifications && item.clarifications.length > 0 &&
                        this.renderClarifications(item)
                    }
                </div>

            ));

        if (!context.mortgage!.solicitor) {
            items.push(<AppointSolicitorTile />);
        }

        if (!context.mortgage.hasInsuranceQuote) {
            items.push(<LifeCoverTile />);
        }

        return (
            <>
                <PortalPage className="bump-right-sm" title={title} description={description} subtitle={subtitle}>
                    <Container>
                    {!context.mortgage!.saleAgreed && context.mortgage?.type === MortgageType.PurchasingaNewHome &&
                        <>
                        <Row className='mb-3'>
                            <Col></Col>
                            <Col>
                                <button className='btn bigger btn-dark' onClick={() => { this.setState({ showSaleAgreedModal: true }) }}>Are you Sale Agreed?</button>
                            </Col>
                            <Col></Col>
                        </Row>
                        <br />
                        </>
                    }
                    <div className="App">
                        {items.length < 3
                        ?
                        <Row style={{justifyContent: 'center'}}>
                            {items.map((item, idx) => <Col lg={4} key={"aip-item-"+idx}>{item}</Col>)}
                        </Row>
                        :
                        <ResponsiveMasonry columnsCountBreakPoints={{ 900: 1, 1024: 2, 1280: 3 }}>
                            <Masonry>{items}</Masonry>
                        </ResponsiveMasonry>
                        }
                    </div>
                    </Container>
                </PortalPage>
                <Modal centered size="lg" show={this.state.showSaleAgreedModal} onHide={() => {this.setState({showSaleAgreedModal: false})}}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center mb-5 ps-5 pe-5">
                            <h1 className="impact">Are you sale agreed?</h1>
                            <p className="">Let us know when you have gone sale agreed so that we can begin the process of getting your letter of offer from your chosen lender. Are you sale agreed?</p>
                        </div>
                        <Stack direction="horizontal"  gap={3} className="text-center justify-center">
                            <button className="btn medium btn-secondary" onClick={() => {this.setState({savingSaleAgreed: true}); this.foundMyNewHome();}}>{this.state.savingSaleAgreed ? <Icons.Spinner size="sm" animation="border" role="status" /> : 'Yes!'}</button>
                            <button className="btn medium btn-light" onClick={() => {this.setState({showSaleAgreedModal: false})}}>No</button>
                        </Stack>
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}
