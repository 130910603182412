import { useMsal } from "@azure/msal-react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { loginApiRequest } from "../helper/authConfig";
import { ErrorPage } from "../pages/Error";
import { GoToLoginPage } from "../pages/GoToLoginPage";
import { NotValid } from "../pages/NotValid";
import { Login } from "../pages/Login";
import React from "react";
import { UserContext } from "./UserContext";

export default function Unauthenticated() {
    const userContext = React.useContext(UserContext);
    const { instance } = useMsal();

    const handleLogin = () => {
        if (window.location.href.indexOf("AADB2C90208") > 0) {
            window.location.href = "/not-valid";
        } else if (window.location.pathname !== "/error" && window.location.pathname !== "/not-valid" && window.location.pathname !== "/SignOutComplete") {
            
            if (window.location.href.includes("_impersonate")) {
                sessionStorage.setItem("imp", window.location.href.split("=").pop() ?? "");
            }
            
            instance.loginRedirect(loginApiRequest)
                .catch((e) => {});
        }
    };

    if (window.location.href.endsWith("login") === false)
        handleLogin();
    
    return <>
        <Router>
            <Routes>
                <Route path="/" element={<GoToLoginPage />}></Route>
                <Route path="/not-valid" element={<NotValid />}></Route>
                <Route path="/error" element={<ErrorPage />} />
                <Route path="*" element={<GoToLoginPage />}></Route>
                {/* <Route path="/login" element={<Login />}></Route> */}
                {/* <Route path="*" element={<NotFound />} /> */}                
            </Routes>
        </Router>
    </>;
};