import React from "react";
import { Stack, Card, Container, Row, Col } from "react-bootstrap";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";
import "./ProgressTile.css";

export enum ProgressTileSize {
  Small = 1,
  Medium = 2,
  Large = 3,
  Document = 4,
  XSmall = 5
}

export class ProgressTile extends React.Component<any, any> {
  progressTilePercentage: string;
  progressTileInitials: string;
  progressTileHeader: string;

  constructor(props: any) {
    super(props);
    this.state = { percent: this.props.percent ?? 0 };

    if (this.props.progressTileSize == ProgressTileSize.XSmall) {
      this.progressTilePercentage = "progress-tile-percentage-xsmall";
      this.progressTileInitials = "progress-tile-initials-small";
      this.progressTileHeader = "progress-tile-header-small";
    } else if (this.props.progressTileSize == ProgressTileSize.Small) {
      this.progressTilePercentage = "progress-tile-percentage-small";
      this.progressTileInitials = "progress-tile-initials-small";
      this.progressTileHeader = "progress-tile-header-small";
    } else if (this.props.progressTileSize == ProgressTileSize.Medium) {
      this.progressTilePercentage = "progress-tile-percentage-medium";
      this.progressTileInitials = "progress-tile-initials-medium";
      this.progressTileHeader = "progress-tile-header-medium";
    } else if (this.props.progressTileSize == ProgressTileSize.Large) {
      this.progressTilePercentage = this.state.percent >= 100 ? "progress-tile-percentage-large-done" : "progress-tile-percentage-large";
      //this.progressTilePercentage = "shadow-tile";
      this.progressTileInitials = "progress-tile-initials-large";
      this.progressTileHeader = "progress-tile-header-large";
    } else if (this.props.progressTileSize == ProgressTileSize.Document) {
      this.progressTilePercentage = "progress-tile-percentage-document";
      this.progressTileInitials = "progress-tile-initials-document";
      this.progressTileHeader = "progress-tile-header-document";
    }
  }

  componentDidUpdate() {
    if (this.state.percent != this.props.percent) {
      setTimeout(() => { this.setState({ percent: this.props.percent }) }, 500);
    }
  }

  componentDidMount() {
    if (this.state.percent != this.props.percent) {
      setTimeout(() => { this.setState({ percent: this.props.percent }) }, 500);
    }
  }

  getTilePercentageStyle() {
    if (this.props.progressTileSize == ProgressTileSize.XSmall) {
      return "progress-tile-percentage-xsmall";
    } else if (this.props.progressTileSize == ProgressTileSize.Small) {
      return "progress-tile-percentage-small";
    } else if (this.props.progressTileSize == ProgressTileSize.Medium) {
      return "progress-tile-percentage-medium";
    } else if (this.props.progressTileSize == ProgressTileSize.Large) {
      return  this.state.percent >= 100 ? "progress-tile-percentage-large-done" : (this.state.percent === 0 ? "progress-tile-percentage-large-notstarted" : "progress-tile-percentage-large");
    }
    return  "progress-tile-percentage-document";
  }

  getColor(error?: boolean | null) {
    if ((this.props.progressTileSize == ProgressTileSize.XSmall || this.props.progressTileSize == ProgressTileSize.Small) && !error)
      return this.state.percent < 100 ? 'var(--sage)' : 'var(--chartreuse)';
    return error ? "'#DE4300" : (this.state.percent === 0 ? "var(--teal)" : this.state.percent < 100 ? "var(--sage)" : "var(--chartreuse)");
  }

  getTextColor(error?: boolean | null) {
    if (this.props.progressTileSize == ProgressTileSize.Small && this.props.percent === 0)
      return 'var(--teal)';
    if (this.props.progressTileSize == ProgressTileSize.Small && this.props.percent !== 100)
      return 'var(--teal)';
    return error ? "'#DE4300" : ((this.props.percent === 0 && (this.props.alwaysShowAsStarted??false) === false) ? "white" : this.props.percent < 100 ? "var(--teal)" : "var(--teal)");
  }

  getBackColor() {
    if (this.props.progressTileSize == ProgressTileSize.XSmall)
      return 'var(--limestone)';
    if (this.props.progressTileSize == ProgressTileSize.Small && this.props.percent !== 100)
      return 'transparent';
    return this.props.percent === 0 ? 'var(--teal)' : (this.props.percent >= 100 ? 'var(--chartreuse)' : 'var(--sage)');
  }

  Percentage(color?: string | null) {
    let strokeWidth = (this.props.progressTileSize === ProgressTileSize.XSmall ? 18 : (this.props.progressTileSize === ProgressTileSize.Large ? 7 : 10));
    
    return (
      <div className={`${this.getTilePercentageStyle()} ${this.props.className}`}>
        {
          this.props.step && 
          <div className={`percentage-step ${this.state.percent || this.props.alwaysShowAsStarted ? (this.state.percent === 100 ? " progresscomplete-bg" : " inprogress-bg") : ""}`}>{this.props.step!.toString()}</div>
        }
        
      </div>);
  }

  render() {
    const { text, title, error } = this.props;
    let color = this.getColor(error);
    let textcolor = this.getTextColor(error);
//nav-header
    return (
      <Stack style={{ alignItems: "center" }} onClick={this.props.onClick} className={`pointer progresstile  text-start ${this.props.selected ? "categoryselected" : ""}`}>
          <div>
            <div className={`${this.getTilePercentageStyle()} ${this.props.className}`}>
            <Row>
            <Col xs={7} className="pt-col">
              <Stack style={{color: textcolor}}>
                <h1 className={`${this.props.percent === 0 ? 'notstarted' : ''}`}>{this.props.percent > 100 ? 100 : this.props.percent}%</h1>
                <p>{this.props.title}</p>
                <p className={`progresscomplete ${this.props.percent === 0 ? 'notstarted' : (this.props.percent < 100 ? 'inprogress' : '')}`}>{this.props.duration}</p>
              </Stack>
            </Col>
            <Col xs={5} className="pt-col" style={{marginLeft: -10}}>
              {this.props.icon}
            </Col>
          </Row>
            {
              this.props.step && 
              <div className={`percentage-step ${this.state.percent || this.props.alwaysShowAsStarted ? (this.state.percent === 100 ? " progresscomplete-bg" : " inprogress-bg") : ""}`}>{this.props.step!.toString()}</div>
            }            
          </div>
        </div>
      </Stack>
    );
  }
}