import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { IPillElementModel } from "./PillElement";
import { Pill } from "./Pill";
import currencyFormatter from "../../helper/currencyFormatter";
import { PortalHelperTextModel } from "../../models/PortalHelperTextModel";
import { IAggregatePillOverlayProps } from "./AggregatePillOverlay";

export enum AggregateType {
    count,
    sum, // sum each field individually
    list,
    firstValue,
    sumAll, // sum across all the fields
    status, // show a status
}

export enum AggregateStatus {
    todo,
    inprogress,
    done,
    error
}

export interface IAggregateItemProps {
    key: string;
    isCollapsed: boolean;
    hasAny: boolean | null;
}

export interface IAggregateListItem {
    key: string;
    values: any[];
}

export interface IAggregatePillProps {
    uniqueKey: string;
    label: string;
    showLabelCount?: boolean;
    items: IAggregateListItem[] | null;
    aggregate1type: AggregateType;
    aggregate1label: string;
    aggregate1currency: boolean | undefined;
    aggregate1status?: AggregateStatus;
    aggregate2type?: AggregateType;
    aggregate2label?: string;
    aggregate2currency?: boolean | undefined;
    confirmationMessageFirst?: string;
    overlay: React.ReactNode;
    hideIfValue: boolean | null;
    firstCellColored?: boolean,
    size?: "lg" | "sm" | "xl" | undefined,
    className?: string | undefined,
    tooltip?: PortalHelperTextModel | undefined | null,
    isMissing?: boolean | undefined,
    contentClassName?: string | undefined,
    headerClassName?: string | undefined,
    autoOpen?: boolean;
    closePill?(): void;
}

interface IAggregatePillState {
    hideIfValue: boolean | null;
    items: IAggregateListItem[] | null;
    collapsed: boolean;
    autoOpen: boolean;
}


export class AggregatePill extends Component<IAggregatePillProps, IAggregatePillState> {
    constructor(props: IAggregatePillProps) {
        super(props);
        //this.state = { hideIfValue: false, collapsed: this.props.itemProp?.isCollapsed ?? true, items: props.items };
        this.state = { hideIfValue: false, collapsed: true, items: props.items, autoOpen: this.props.autoOpen??false };
        this.collapse = this.collapse.bind(this);
        this.hide = this.hide.bind(this);
        this.show = this.show.bind(this);
        this.getStatusColour = this.getStatusColour.bind(this);
    }

    componentDidUpdate() {
        if (this.state.hideIfValue !== this.props.hideIfValue)
            this.setState({ hideIfValue: this.props.hideIfValue });

        //     debugger;
        // if (this.props.itemProp?.isCollapsed !== null && this.state.collapsed !== (this.props.itemProp?.isCollapsed ?? true))
        // {
        //     let v = this.props.itemProp?.isCollapsed ?? true;
        //     if(this.state.collapsed !== v)
        //         this.setState({ collapsed: v });
        // }
             
    }

    componentDidMount() {
        if (this.state.hideIfValue !== this.props.hideIfValue)
            this.setState({ hideIfValue: this.props.hideIfValue });
    }

    private collapse() {
        this.setState({ collapsed: true });
    }

    asNum(v:any) {
        let r = 0;
        if (v !== "" && v !== '' && v !== null && v !== undefined)
            r = parseFloat(v);
        
        return r;
    }

    getAggregate(valueToAggregate: number, type: AggregateType) {
        let items = this.props.items;
        if(items === null)
            return "";

        if (type === AggregateType.count) {
            return items.length.toString();
        }
        else if (type === AggregateType.sum) {
            return items.reduce((partialSum: any, a: IAggregateListItem) => this.asNum(partialSum) + (this.asNum(a.values[valueToAggregate])), 0).toString();
        }
        else if (type === AggregateType.sumAll) {
            return items.reduce((partialSum: any, a: IAggregateListItem) => this.asNum(partialSum) + (a.values.reduce((pSum2: any, a2: any) => this.asNum(pSum2) + this.asNum(a2))), 0).toString();
        }
        else if (type === AggregateType.list) {
            return items.map((e: IAggregateListItem) => { return e.values[valueToAggregate] }).join(", ");
        }
        else if (type === AggregateType.firstValue) {
            return items.length > 0 ? items[0].values[valueToAggregate] : "";
        }
        return "";
    }
    hide() {
        this.collapse();
        //this.props.setCloseModal(this.props.uniqueKey);
    }
    show() {
        this.setState({ autoOpen: false, collapsed: false });
        //this.props.setOpenModal(this.props.uniqueKey);
    }

    getPillClass() {
        // if (this.props.status === MortgageDocumentStatusCode.Active)
        //   return this.props.files?.length > 0 ? "inprogress" : "notstarted";
        // if (this.props.status === MortgageDocumentStatusCode.Uploaded)
        //   return "done";
        // if (this.props.status === MortgageDocumentStatusCode.NeedsAction)
        //   return "error"
        return "notstarted";
    }

    getStatusColour() {
        if (this.props.aggregate1status == AggregateStatus.done)
            return 'var(--chertreuse)';
        if (this.props.aggregate1status == AggregateStatus.inprogress || this.props.aggregate1status == AggregateStatus.error)
            return 'var(--sage)';
        if (this.props.aggregate1status == AggregateStatus.todo)
            return 'var(--limestone)';
        return undefined;
    }

    render() {
        var value1ToDisplay = this.getAggregate(0, this.props.aggregate1type);
        if (this.props.aggregate1currency)
            value1ToDisplay = currencyFormatter(parseInt(value1ToDisplay));

        var value2ToDisplay = this.props.aggregate2type ? this.getAggregate(1, this.props.aggregate2type) : "";
        if (this.props.aggregate2currency)
            value2ToDisplay = currencyFormatter(parseInt(value2ToDisplay));

        var countToDisplay = this.getAggregate(0, AggregateType.count);

        if (this.state.hideIfValue && this.props.items !== null && !this.props.isMissing) 
            return "";

        var components: IPillElementModel[] = [];
        components.push({
            leftBorder: true,
            rightBorder: true,
            backgroundColor: this.props.firstCellColored === true ? "#eee" : undefined,
            content: <div>{this.props.label} {this.props.showLabelCount && countToDisplay > 0 && <> : {countToDisplay}</>
            }</div >
        });

        components.push({
            leftBorder: false,
            rightBorder: true,
            backgroundColor: this.getStatusColour(),
            fontSize: this.getStatusColour() ? 14 : undefined,
            content: <>{(this.props.items !== null && this.props.items.length === 0) && "None"}{this.props.items !== null && this.props.items.length > 0 && <>{this.props.aggregate1label} {value1ToDisplay}</>}</>
        });

        if (this.props.aggregate2label && this.props.items !== null && this.props.items.length > 0) {
            components.push({
                leftBorder: false,
                rightBorder: true,
                content: <>{this.props.aggregate2label} {value2ToDisplay == 'null' ? "-" : value2ToDisplay}</>
            });
        }
        
        var cloned = React.cloneElement(this.props.overlay as React.ReactElement<IAggregatePillOverlayProps>, {
            closePill: this.collapse
          });

        if (this.state.autoOpen) {
            this.show();
        }

        return (
            <>
                <Pill extraClass={`${this.props.className??''} ${this.props.isMissing === true ? "pill-missing" : ""}`} onClick={() => this.show()} components={components} tooltip={this.props.tooltip ?? undefined}></Pill>

                <Modal contentClassName={this.props.contentClassName} fullscreen="lg-down"  size={this.props.size ?? "xl"} show={!this.state.collapsed} onHide={() => this.hide()} centered>
                    <Modal.Header className={this.props.headerClassName} closeButton></Modal.Header>
                    
                    {/* {this.props.overlay} */}
                    {cloned}
                </Modal>
            </>
        );
    }
}