import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Modal } from "react-bootstrap";
import '../pages/Applicant.css';

import { UserContextComponent } from './UserContextComponent';

import { ApplicantSetup } from './ApplicantSetup';


interface IApplicantSetupDialogProps {
    show: boolean;
    centered: boolean | undefined;
    finished: any;
}

export class ApplicantSetupDialog extends UserContextComponent<IApplicantSetupDialogProps, any> {

    constructor(props: any) {
        super(props);
    }

    render() {
        let context = this.getContext();

        return (
            <Modal size="lg" fullscreen="lg-down" show={this.props.show} centered>
                <div className="modal-content" style={{backgroundColor:'var(--chartreuse)', borderWidth:0}}>
                <Modal.Body style={{background:'transparent'}}>
                    <Container className="text-center justify-center">
                        <div className="page-title pb-2">
                            <h1 className="pb-2">Welcome to the portal, {context.contact?.firstName}!</h1>
                            <p className="pb-2">Please share the basic details of your co-applicant so that we can add them to your mortgage. All fields are mandatory to get you up and running.</p>
                            <hr/>
                        </div>
                        <div className="hr"></div>
                        <ApplicantSetup finished={this.props.finished} />
                    </Container>
                </Modal.Body>
                </div>
            </Modal>
        );
    }
}




