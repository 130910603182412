import React from "react";
import UserContextState, { UserContext } from "./UserContext";

export class Intercom extends React.Component<any, any> {
    static contextType = UserContext;
    
    render() {
        const context = this.context as UserContextState;
        
        if (!context.contact?.emailAddress) return <>{this.props.children}</>;

        let intercomScript = document.createElement("script");
        let script = document.createElement("script");

        intercomScript.innerHTML = '(function () { var w = window; var ic = w.Intercom; if (typeof ic === "function") { ic(\'reattach_activator\'); ic(\'update\', w.intercomSettings); } else { var d = document; var i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; var l = function () { var s = d.createElement(\'script\'); s.type = \'text/javascript\'; s.async = true; s.src = \'https://widget.intercom.io/widget/qxvr34fd\'; var x = d.getElementsByTagName(\'script\')[0]; x.parentNode.insertBefore(s, x); }; if (document.readyState === \'complete\') { l(); } else if (w.attachEvent) { w.attachEvent(\'onload\', l); } else { w.addEventListener(\'load\', l, false); } } })(); ';
        intercomScript.setAttribute("type", "text/javascript");

        script.innerHTML = 'window.intercomSettings = { api_base: "https://api-iam.intercom.io",app_id: "qxvr34fd",name: "' + context.contact?.fullName + '", email: "' + context.contact?.emailAddress + '"}; ';
        script.setAttribute("type", "text/javascript");

        document.head.appendChild(script);
        document.head.appendChild(intercomScript);

        return (<>
            {this.props.children}
            </>
        );
    }
}