import React from "react";
import { Stack } from "react-bootstrap";

export class StayInformedTile extends React.Component {

    render() {
        return (
            <div key={'stayinformedtile'} className={"text-start boxty tall box-teal-outline"}>
                <>
                    <p className={`text-gray`}>Stay informed</p>
                    <h1>Always here for you!</h1>
                    <Stack gap={2} style={{paddingBottom: 1}}>
                        <p className="impact mt-2">Have any questions?</p>
                    <button className="btn btn-gray-outline btn-fw text-start ps-3" onClick={() => window.Intercom('showNewMessage')}>
                        Chat to an expert<span style={{fontSize: 17}}><i className="fas fa-user" style={{marginLeft: 15, marginRight: 15}} /></span>
                    </button>
                    <div className="fakebtn-gray-outline btn-fw text-start ps-3" onClick={() => window.open('https://www.facebook.com/doddl.ie/', '_blank')}>
                        
                        <span className="text-end nowrap" style={{fontSize:18}}>
                            <span style={{fontSize: 15}}>Follow our socials </span>
                            <span>
                                <i onClick={() => window.open('https://www.instagram.com/doddl_ie/', '_blank')} className="fab fa-instagram social" style={{marginLeft: 4}} />
                                <i onClick={() => window.open('https://www.facebook.com/doddl.ie/', '_blank')} className="fab fa-facebook social" style={{marginLeft:7}} />
                                <i onClick={() => window.open('https://ie.linkedin.com/company/doddl/', '_blank')} className="fab fa-linkedin social" style={{marginLeft:7, marginRight: 21}} />
                            </span>

                        </span>
                    </div>
                    </Stack>
                </>
            </div>
            );
    }
}