import { Component } from "react";
import { IPillProps, IPillElementModel } from "./PillElement";
import "./Pill.css";
import { Tooltip } from "../Tooltip";
import React from "react";

export class Pill extends Component<IPillProps, any> {
    constructor(props: IPillProps) {
        super(props);
        this.state = {hasFocus: false, expanded: false};
        this.toggleFocus = this.toggleFocus.bind(this);
    }

    toggleFocus() {
        var newFocus = !this.state.hasFocus;
        this.setState({hasFocus: newFocus, expanded: true});
        if(this.props.onClick) {
            this.props.onClick();
        }
    }

    render() {
        var pill:any;
        var extraClass = (this.props.extraClass ?? "");
        let displayExpanded:boolean = (this.props.disableCollapse || this.state.expanded);
        let labelOnly:any = ((this.props.empty || extraClass.includes("pill-missing")) && !this.state.hasFocus);
        if (!displayExpanded && labelOnly) {
            pill = 
                <div className="pill-container" onClick={this.toggleFocus} >
                    <div key={"pill-0"} className={"pill-component " + this.props.extraClass}
                                style={{
                                    borderLeftStyle: 'solid',
                                    borderRightStyle: 'solid',
                                    backgroundColor: "#FF000002",
                                    marginTop: -1
                                }}>{this.props.components[0].content}</div>
                </div>;
        } else {
            pill = 
                <div className="pill-container" onClick={this.props.onClick} onBlur={this.toggleFocus}>
                    {
                        this.props.components.map((component: IPillElementModel, idx: number) => {
                            return <div key={"pill-" + idx} className={"pill-component " + this.props.extraClass}
                                style={{
                                    borderLeftStyle: component.leftBorder ? 'solid' : 'none',
                                    borderRightStyle: component.rightBorder ? 'solid' : 'none',
                                    backgroundColor: component.backgroundColor,
                                    fontSize: component.fontSize ?? 16,
                                }}>{component.content}</div>;
                        })
                    }
                </div>;
            setTimeout(() => {
                    if (this.state.hasFocus) {
                        if(this.props.components[0].focus) {
                            this.props.components[0].focus();
                        }
                    }
                }, 200);
        }

        return (
            <>
                {this.props.tooltip &&
                    <Tooltip tooltip={this.props.tooltip}>
                        <div className="pill-container" onClick={this.props.onClick} onBlur={this.toggleFocus}>
                            {pill}
                            </div>
                    </Tooltip>}
                {!this.props.tooltip &&
                    <div className="pill-container" onClick={this.props.onClick} onBlur={this.toggleFocus}>
                        {pill}
                    </div>}
            </>
        );
    }
}
