import axios from "axios";
import { MortgageType, PortalMortgageModel, YesNoBlank } from "../models/PortalMortgageModel";
import { ProfileItemModel } from "../models/ProfileItemModel";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, Navigate } from "react-router-dom"
import { Col, Container, Modal, Row, Stack } from "react-bootstrap";
import { PortalPage } from "../components/PortalPage";
import { UserContext } from "../components/UserContext";
import UserContextState from "../components/UserContext";
import { appInsightsHelper } from "../components/AppInsights";
import { ProgressTile, ProgressTileSize } from "../components/ProgressTile";
import { UserContextComponent } from "../components/UserContextComponent";
import React from "react";
import { SolicitorButton } from "../components/AIP/SolicitorButton";
import { InsuranceQuoteButton } from "../components/AIP/InsuranceQuoteButton";
import { CTATiles } from "../components/CTATiles";
import { ApplicantSetup } from "../components/ApplicantSetup";
import { ApplicantSetupDialog } from "../components/ApplicantSetupDialog";
import { AppointSolicitorTile } from "../components/Tiles/AppointSolicitorTile";
import { LifeCoverTile } from "../components/Tiles/LifeCoverTile";

interface IProfileProps {
    splashScreen?: boolean;
}

interface IProfileState {
    //mortgage: PortalMortgageModel | null;
    loading: boolean;
    isCompleted: boolean;
    navigateTo: string;
    missingSecondary: boolean;
}

export class Profile extends UserContextComponent<IProfileProps, IProfileState> {
    static contextType = UserContext;
    static displayName = Profile.name;

    constructor(props: any) {
        super(props);
        this.state = { loading: true, isCompleted: false, navigateTo: "", missingSecondary: false };
        this.formatNumbers = this.formatNumbers.bind(this);
        this.navigateToIncompletePage = this.navigateToIncompletePage.bind(this);
    }

    componentDidMount() {
        appInsightsHelper.trackPageView({ name: "Profile" });

        const context = this.context as UserContextState;
        var mortgage = context.mortgage;

        if (context.mortgage?.applicationType !== "Single" && !context.mortgage?.secondaryApplicant && this.state.missingSecondary === false) {
            this.setState({ loading: false, missingSecondary:true});
        } else {
            this.setState({ loading: false });
        }
        
        //check percentages and redirect to completed if every of them 100%
        if (mortgage != null)
            if (mortgage.primaryApplicant.percentageOfCompletion === 100 &&
                (mortgage.secondaryApplicant === null || mortgage.secondaryApplicant.percentageOfCompletion === 100) &&
                mortgage.percentageOfCompletion === 100)
                this.setState({ isCompleted: true });
    }

    navigateToIncompletePage() {
        const context = this.getContext();
        let mortgage = context.mortgage;
        if (mortgage === null)
            return;

        if (mortgage.primaryApplicant.percentageOfCompletion < 100) {
            this.setState({ navigateTo: "/personal1" });
        } else if (mortgage.applicationType != "Single" && mortgage.secondaryApplicant.percentageOfCompletion < 100) {
            this.setState({ navigateTo: "/personal2" });
        } else if (mortgage.mortgageDetailsPercentage < 100) {
            this.setState({ navigateTo: "/shared" });
        } else if (mortgage.documentsPercentComplete < 100) {
            this.setState({ navigateTo: "/documents" });
        }
    }

    getIconColor(percent:number) {
        if (percent===0) return 'var(--sage)';
        if (percent>=100) return 'var(--teal)';
        return 'var(--chartreuse)'
    }

    onApplicantSetupFinished() {
        this.setState({missingSecondary: false});
    }

    render() {
        if (this.state.navigateTo !== "")
            return <Navigate to={this.state.navigateTo}></Navigate>

        if (this.state.loading) return <p><em>Loading...</em></p>;

        const context = this.getContext();
        let mortgage: PortalMortgageModel | null = context.mortgage;
        
        if (mortgage === null)
            return;

        let switcherOrSaleAgreedPurchaser = context.mortgage?.type === MortgageType.RemortgagingExistingLoan || context.mortgage?.saleAgreed;

        // Calculate all the different percentages for the progress tiles
        let mortgageDetailsPerc = 0;
        if (switcherOrSaleAgreedPurchaser)
            mortgageDetailsPerc = (context.mortgage!.mortgageDetailsPercentage + context.mortgage!.propertyDetailsPercentage)/2;
        else
            mortgageDetailsPerc = (context.mortgage!.mortgageDetailsPercentage);

        let a1TimeLeft = Math.round(16 - 16 * mortgage.primaryApplicant.percentageOfCompletion / 100);
        let mdTimeLeft = Math.round(8 - 8 * mortgageDetailsPerc / 100);
        let docTimeLeft = Math.round(20 - 20 * mortgage.documentsPercentComplete / 100);

        // Create the list of profile tiles to display
        var items: ProfileItemModel[] = [];
        items.push(Profile.createProfileItem(1,
            <><span className="nowrap"><strong>{mortgage.primaryApplicant.firstName}</strong></span><br/><span className="nowrap">{mortgage.primaryApplicant.lastName}</span></>,
            "Let's confirm the details we have for you " + mortgage.primaryApplicant.firstName + ", and we'll capture a few additional details, such as nationality, etc.",
            mortgage.primaryApplicant.percentageOfCompletion,
            <span className="nowrap"><i className="far fa-clock pe-2" />{a1TimeLeft} mins</span>,
            "/personal1",
            mortgage.primaryApplicant.firstName.substring(0, 1).toUpperCase() + mortgage.primaryApplicant.lastName.substring(0, 1).toUpperCase(),
            <i className="far fa-user" style={{fontSize: 120, color: this.getIconColor(mortgage.primaryApplicant.percentageOfCompletion)}} />
        ));
        if (mortgage.applicationType != "Single" && mortgage.secondaryApplicant) {
            let a2TimeLeft = Math.round(16 - 16 * mortgage.secondaryApplicant.percentageOfCompletion / 100);
            items.push(Profile.createProfileItem(2,
                <><span className="nowrap"><strong>{mortgage.secondaryApplicant.firstName}</strong></span><br/><span className="nowrap">{mortgage.secondaryApplicant.lastName}</span></>,
                "Let's confirm the details we have for you " + mortgage.secondaryApplicant.firstName + ", and we'll capture a few additional details, such as nationality, etc.",
                mortgage.secondaryApplicant.percentageOfCompletion,
                <span className="nowrap"><i className="far fa-clock pe-2" />{a2TimeLeft} mins</span>,
                "/personal2",
                mortgage.secondaryApplicant.firstName.substring(0, 1).toUpperCase() + mortgage.secondaryApplicant.lastName.substring(0, 1).toUpperCase(),
                <i className="far fa-user" style={{fontSize: 120, color: this.getIconColor(mortgage.secondaryApplicant.percentageOfCompletion)}} />
            ));
        }
        items.push(Profile.createProfileItem(4,
            <><strong>Mortgage</strong><br/>Details</>,
            "First up, we'll ask a few basic questions about your joint situation, income, and outgoings",
            mortgageDetailsPerc,
            <span className="nowrap"><i className="far fa-clock pe-2" />{mdTimeLeft} mins</span>,
            "/shared",
            <i className='fa fa-clipboard-list' />,
            <i className="fa fa-house" style={{fontSize: 120, color: this.getIconColor(mortgageDetailsPerc)}} />
        ));

        items.push(Profile.createProfileItem(5,
            <>Your<br/><strong>Documents</strong></>,
            "Details about your documents",
            mortgage.documentsPercentComplete,
            <span className="nowrap"><i className="far fa-clock pe-2" />{docTimeLeft} mins</span>,
            "/documents",
            <i className='far fa-folder-open' />,
            <i className="far fa-folder-open" style={{fontSize: 120, color: this.getIconColor(mortgage.documentsPercentComplete)}} />
        ));

        // Map the progress tiles to a list of links
        var result = items.map((item) =>
            <Link key={item.id} to={item.url!} className="list-group-item list-group-item-action boxless">
                <Stack className="all-content-center">
                    <ProgressTile 
                        progressTileSize={ProgressTileSize.Large} 
                        key={"ApplicantTile1-" + item.id.toString()} 
                        text={item.initials} 
                        title={item.title}
                        percent={item.percent}
                        duration={item.duration}
                        icon={item.icon}
                    />
                </Stack>
            </Link>
        );

        // Calculate the title to display depending on who's logged in
        let contactId: string = context.contact!.contactId;
        let titleName = mortgage.secondaryApplicant?.firstName;
        let otherName = <> and <span className="doddltext">{mortgage.primaryApplicant.firstName}</span></>;
        if (mortgage.primaryApplicant.contactId === contactId) {
            titleName = mortgage.primaryApplicant.firstName;
            if (mortgage.secondaryApplicant == null)
                otherName = <></>
            else
                otherName = <> and <span className="doddltext">{mortgage.secondaryApplicant?.firstName}</span></>
        }

        return (
            <>
            {!this.props.splashScreen &&
                    <PortalPage alignTitleLeft={true} className="bump-right-profile" title={<span><span className="doddltext">Hi</span> {titleName}{otherName},</span>} description={<p>Let's complete your digital application form.<br/>What do you want to tackle next?</p>}>
                    <Row className="text-start max-w-940">
                        {result.map((item, idx) => { return <Col key={"item-" + idx} md={6} xl={3}>{item}</Col>; })}
                    </Row>

                    <Row className="text-start max-w-wide">
                        <Col style={{ paddingLeft: 40, paddingTop:40 }}>
                            <p className="text-start">Avoid delays later by getting these other requirements in order as soon as you can too.</p>
                        </Col>
                    </Row>

                    {/* <CTATiles mortgage={mortgage} /> */}
                    <Row>
                        <Col xs={4}>
                            <AppointSolicitorTile />
                        </Col>
                        <Col xs={4}>
                            <LifeCoverTile />
                        </Col>
                        <Col/>
                    </Row>
                    <ApplicantSetupDialog show={this.state.missingSecondary === true} centered finished={this.onApplicantSetupFinished} />

                </PortalPage >
            }
            {this.props.splashScreen &&
                <Row className="justify-center">
                    {result.map((item, idx) => { return <Col key={"item-" + idx} md={6} xl={3}>{item}</Col>; })}
                </Row>
            }
            </>
        );

    }

    async populateData() {
        const context = this.context as UserContextState;
        var mortgageId = context.contact?.mortgageId;
        axios
            .get("/mortgages/GetMortgageByMortgageId?mortgageId=" + mortgageId)
            .then((response) => {
                this.setState({ loading: false });
                //check percentages and redirect to completed if every of them 100%
                if (response.data != null)
                    if (response.data.primaryApplicant.percentageOfCompletion === 100 &&
                        response.data.secondaryApplicant.percentageOfCompletion === 100 &&
                        response.data.percentageOfCompletion === 100)
                        this.setState({ isCompleted: true });
            })
            .catch((error) => {
                if (error.response.statusCode === 401) {
                    alert("Unauthorized");
                }
            });
    }

    formatNumbers(value: any) {
        var prefix = "€";
        if (value === null)
            return prefix + "0";
        else {
            if (value >= 1000000)
                return prefix + Math.round(value / 1000000).toString() + "M";
            else if (value >= 1000)
                return prefix + Math.round(value / 1000).toString() + "K";
            else
                return prefix + value.toString();
        }
        return "";
    }

    static createProfileItem(id: number, title: any, description: string, percent: number, duration: any, url: string, initials: any, icon: any) {

        let durationText = percent >= 100 ? <i className="far fa-thumbs-up" /> : duration;
        let durationClass = percent >= 100 ? "progresscomplete" : "lightcolor";
        let progress = percent >= 100 ? "All done!" : Math.round(percent) + "% complete";
        let progressClass = percent >= 100 ? "progresscomplete" : percent === 0 ? "progresswarning" : "inprogress";
        let initialsClass = "percentage-initials " + (percent === 0 ? "notstarted" : percent === 100 ? "progresscomplete" : "inprogress");


        return new ProfileItemModel(id,
            title,
            description,
            durationText,
            durationClass,
            percent,
            progress,
            progressClass,
            url,
            initials,
            initialsClass,
            icon
        );
    }
}
