import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginApiRequest } from "../helper/authConfig";

export const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginRedirect(loginApiRequest).catch((e) => {
        });
    };
    return <button className='btn btn-standard-transparent btn-round-lg' onClick={handleLogin}>Sign In</button>;
};