import React from 'react';
import { PlaidLinkPropTypes, usePlaidLink } from 'react-plaid-link';


export const PlaidLinkCustom: React.FC<PlaidLinkPropTypes> = props => {
  const { children, style, className, ...config } = props;
  const { error, open } = usePlaidLink({ ...config });

  return (
    <button
      disabled={Boolean(error)}
      type="button"
      className={className}
      style={{
        ...style,
      }}
      onClick={() => open()}
    >
      {children}
    </button>
  );
};

PlaidLinkCustom.displayName = 'PlaidLinkCustom';