/** Signature of a logging function */
export interface LogFn {
    (message?: any, ...optionalParams: any[]): void;
  }
  
  /** Basic logger interface */
  export interface Logger {
    getLogs(): any[];
    clearLogs(): void;
    log: LogFn;
    warn: LogFn;
    error: LogFn;
  }
  
  /** Log levels */
  export type LogLevel = 'log' | 'warn' | 'error';
  
  const NO_OP: LogFn = (message?: any, ...optionalParams: any[]) => {};
  
  /** Logger which outputs to the browser console */
  export class ConsoleLogger implements Logger {
    logs: any[]=[];
    readonly log: LogFn = (message?: any, ...optionalParams: any[]) => {
      this.logs.push({m:message,d:optionalParams});
      this.console_log(message, ...optionalParams);
    };
    readonly warn: LogFn = (message?: any, ...optionalParams: any[]) => {
      this.logs.push({m:message,d:optionalParams});
      this.console_warn(message, ...optionalParams);
    };
    readonly error: LogFn = (message?: any, ...optionalParams: any[]) => {
      this.logs.push({m:message,d:optionalParams});
      this.console_error(message, ...optionalParams);
    };

    readonly console_log: LogFn;
    readonly console_warn: LogFn;
    readonly console_error: LogFn;
  
    constructor(options?: { level? : LogLevel }) {
      const { level } = options || {};
  
      this.console_error = console.error.bind(console);
  
      if (level === 'error') {
        this.warn = NO_OP;
        this.log = NO_OP;
  
        return;
      }
  
      this.console_warn = console.warn.bind(console);
  
      if (level === 'warn') {
        this.log = NO_OP;
  
        return;
      }
  
      this.console_log = console.log.bind(console);
    }
    getLogs():any[] {
      return this.logs;
    };
    clearLogs() {
      this.logs=[];
    }
  }
  
  export const APP_ENV = process.env.NODE_ENV === 'production' ? 'production' : 'development';
  export const LOG_LEVEL: LogLevel = APP_ENV === 'production' ? 'warn' : 'log';
  export const logger = new ConsoleLogger({ level: LOG_LEVEL });