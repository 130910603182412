import { ChatQuestionModel } from "./ChatQuestionModel";


export class QuestionManager {
  questions: ChatQuestionModel[];
  currentQuestion: ChatQuestionModel | null;

  constructor(questions: ChatQuestionModel[]) {
    this.questions = questions;
    this.currentQuestion = null;
  }

  public getParentQuestion(question: ChatQuestionModel): ChatQuestionModel | undefined {
    return this.questions.find((q) => { return q.chatQuestionId === question.parentQuestionId; });
  }

  public removeQuestion(question: ChatQuestionModel): void {
    let idx = this.questions.indexOf(question);
    if (idx > -1) {
      this.questions.splice(idx, 1);
    }
  }

  public hasChildQuestions(question: ChatQuestionModel): Boolean {
    let children = this.questions.find((q) => { return q.parentQuestionId === question.chatQuestionId; });
    return children != undefined;
  }

  public getChildQuestions(question: ChatQuestionModel): ChatQuestionModel[] {
    let children = this.questions.filter(x => x.parentQuestionId === question.chatQuestionId);
    return children;
  }

  public getFirstQuestion(): ChatQuestionModel | undefined {
    let q = this.questions.find(x => x.parentQuestionId === null);
    return q;
  }
 
  public getNextQuestion(): ChatQuestionModel | undefined {
    if (this.currentQuestion == null) {
      let q = this.getFirstQuestion();
      if (q != null) {
        this.currentQuestion = q;
        return q;
      }
    }
    else {
      let questions = this.getChildQuestions(this.currentQuestion);
      if (questions.length > 0) {
        this.currentQuestion = questions[0];
        return questions[0];
      }
    }
  }
}
