import React from "react";
import { Button } from "react-bootstrap";
import { ChatQuestionModel } from "./ChatQuestionModel";

export interface ChatButtonListProps {
    questions: ChatQuestionModel[];
    setAnswer(question?: ChatQuestionModel, value?: any): void;
}

export interface ChatButtonListState {
    primaryQuestions: ChatQuestionModel[];
    secondaryQuestions: ChatQuestionModel[];
    hasMoreQuestions: Boolean;
    moreButtonVisible: Boolean;
}

export class ChatButtonList extends React.Component<ChatButtonListProps, ChatButtonListState> {

    constructor(props: ChatButtonListProps) {
        super(props);

        this.showMore = this.showMore.bind(this);

        if (props.questions.length > 3) {
            let primaryQuestions = props.questions.filter((q, i) => { return i < 2 })
            let secondaryQuestions = props.questions.filter((q, i) => { return i >= 2})
            this.state = { primaryQuestions: primaryQuestions, secondaryQuestions: secondaryQuestions, hasMoreQuestions: true, moreButtonVisible: true };
        }
        else {
            this.state = { primaryQuestions: props.questions, secondaryQuestions: [], hasMoreQuestions: false, moreButtonVisible: false };
        }
    }

    private showMore() {
        this.setState({ moreButtonVisible: false });
    }

    render() {
        let primaryQuestions = this.state.primaryQuestions.map((q) => {
            return <Button key={q.chatQuestionId} className="btn-outline-doddl" variant="lignt" onClick={e => this.props.setAnswer(q, { name: q.text, value: q.chatQuestionId })}>{q.text}</Button>
        });
        let secondaryQuestions = this.state.secondaryQuestions.map((q) => {
            return <Button key={q.chatQuestionId} className="btn-outline-doddl" variant="lignt" onClick={e => this.props.setAnswer(q, { name: q.text, value: q.chatQuestionId })}>{q.text}</Button>
        });
        if (this.state.moreButtonVisible) {
            return <>
                {primaryQuestions}
                {this.state.moreButtonVisible && <Button className="btn-outline-doddl" variant="lignt" onClick={e => { this.showMore(); }}>...</Button>}
            </>;
        } else {
            return <>
                {primaryQuestions}
                {secondaryQuestions}
            </>;
        }
    }
}