import { Button, Col, Row } from "react-bootstrap";
import { PortalMortgageModel } from "../models/PortalMortgageModel";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from "react-router-dom";
import { PortalPage } from "../components/PortalPage";
import { appInsightsHelper } from "../components/AppInsights";
import { UserContextComponent } from "../components/UserContextComponent";

export class MortgageDetails extends UserContextComponent<any, any> {
    static displayName = MortgageDetails.name;

    componentDidMount() {
        appInsightsHelper.trackPageView({ name: "Mortgage Details" });
    }

    private getFullNames(mortgage: PortalMortgageModel) {
        return mortgage.secondaryApplicant == null ?
            mortgage.primaryApplicant.fullName : mortgage.primaryApplicant.fullName + " & " + mortgage.secondaryApplicant.fullName;
    }

    private getSmokers(mortgage: PortalMortgageModel) {
        var smokers: string[] = [];
        smokers.push(mortgage.primaryApplicant.smoker ? 'Y' : 'N');

        if (mortgage.secondaryApplicant != null)
            smokers.push(mortgage.secondaryApplicant.smoker ? 'Y' : 'N');

        return smokers.join('/');
    }

    private getAgeAndOccupation(mortgage: PortalMortgageModel) {
        var age = mortgage.secondaryApplicant == null ?
            "Age (" + mortgage.primaryApplicant.age + "), " : "Ages (" + mortgage.primaryApplicant.age + ", " + mortgage.secondaryApplicant.age + ")";

        var occupations: string[] = [];

        if (mortgage.primaryApplicant != null && mortgage.primaryApplicant.occupation != null)
            occupations.push(mortgage.primaryApplicant.occupation);
        if (mortgage.secondaryApplicant != null && mortgage.secondaryApplicant.occupation != null)
            occupations.push(mortgage.secondaryApplicant.occupation);
        var occupation = occupations.join(',');
        return occupation == null || occupation === '' ? age : age + "," + occupation;
    }

    renderTable() {
        let context = this.getContext();
        let mortgage = context.mortgage;
        if (mortgage == null)
            return;
        var names = this.getApplicantNames();
        var fullNames = this.getFullNames(mortgage);
        var ageAndOccupation = this.getAgeAndOccupation(mortgage);
        var smokers = this.getSmokers(mortgage);

        return (
            <PortalPage title={"What we have covered so far"} description={<>Hi {names}, shown below are the details we have already captured about you:</>}>
                <Row>
                    <Col className="col-md-2">
                    </Col>
                    <Col className="col-md-4 text-start">
                        <ul className="fa-ul">
                            <li><i className="far fa-check-circle doddltext pe-3"></i>{mortgage.subTypeFormatted}</li>
                            <li><i className="far fa-check-circle doddltext pe-3"></i>{fullNames}</li>
                            <li><i className="far fa-check-circle doddltext pe-3"></i>{ageAndOccupation}</li>
                            <li><i className="far fa-check-circle doddltext pe-3"></i>Joint Fixed Income: {mortgage.grossBasicIncomeFormatted}</li>
                            <li><i className="far fa-check-circle doddltext pe-3"></i>Variable Income: {mortgage.grossVariableIncomeFormatted}</li>
                            <li><i className="far fa-check-circle doddltext pe-3"></i>Smokers: {smokers}</li>
                        </ul>
                    </Col>
                    <Col className="col-md-5 text-start">
                        <ul className="fa-ul">
                            <li><i className="far fa-check-circle doddltext pe-3"></i>Approximate Value: {mortgage.propertyValueFormatted}</li>
                            <li><i className="far fa-check-circle doddltext pe-3"></i>Mortgage Required: {mortgage.mortgageAmountProposedFormatted}</li>
                            <li><i className="far fa-check-circle doddltext pe-3"></i>Sale Agreed: {mortgage.saleAgreed ? 'Yes' : 'No'}</li>
                            <li><i className="far fa-check-circle doddltext pe-3"></i>BER: {mortgage.berRatingFormatted}</li>
                            <li><i className="far fa-check-circle doddltext pe-3"></i>Preferred Lender: {mortgage.preferredLenders == null ? "" : mortgage.preferredLenders.join(',')}</li>
                            <li><i className="far fa-check-circle doddltext pe-3"></i>Preferred Product: {mortgage.preferredProduct}</li>
                        </ul>
                    </Col>
                    <Col className="col-md-1">
                    </Col>
                </Row>
                <br />
                <br />
                <br />
                <br />
                <Row>
                    <Col className="col-md-2"></Col>
                    <Col className="col-md-8 text-start">
                        <p>We now will gather a few more details about you to appear on the application form - this should take about 15 minutes if you have the information to hand but you can always save and come back later if you need to.</p>
                    </Col>
                    <Col className="col-md-2"></Col>
                </Row>

                <br />
                <br />
                <Link to="/profile"><Button className='btn btn-standard btn-round-lg circle btn-bordered btn-lg'>Complete your Profile</Button></Link>
            </PortalPage>
        );
    }

    render() {
        return this.renderTable();
    }
}
