import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { PortalPage } from "../components/PortalPage";
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

export class NotFound extends Component {
    render() {
        return (
            <PortalPage title={<>Error 404</>} description={"Requested page hasn't been found."}>
                <br/>
                <p><Link to="/"><Button className='btn btn-standard btn-round-lg circle btn-bordered btn-lg'>Go to home page</Button></Link></p>
            </PortalPage>
        );        
    }
}
