import { Component } from 'react';
import { UserContext } from './UserContext';
import UserContextState from './UserContext';
import { ApplicableTo } from '../models/PortalMortgageDocumentModel';


export class UserContextComponent<P, S> extends Component<P, S> {
    static contextType = UserContext;

    public getContext(): UserContextState {
        const context = this.context as UserContextState;
        return context;
    }

    componentDidUpdate() {
        let context = this.getContext();
        if (context.refreshMenuState) {
            context.refreshMenuState();
        }
    }

    public getApplicantNames(): string {
        let context = this.getContext();
        let mortgage = context.mortgage;
        if (mortgage == null)
            return "";
        return mortgage.secondaryApplicant == null ? mortgage.primaryApplicant.firstName : mortgage.primaryApplicant.firstName + " and " + mortgage.secondaryApplicant.firstName;
    }
    
    public getApplicableTo(): ApplicableTo {
        let context = this.getContext();
        return context.applicantNo===1 ? ApplicableTo.Primary : ApplicableTo.Secondary;
    }
}