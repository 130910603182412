import UserContextState, { UserContext } from '../UserContext';
import { IAggregateListItem } from "../Pills/AggregatePill";
import { Button, Col, Container, Modal, Row, Stack } from "react-bootstrap";
import { Finance } from "./Finance";
import * as Icons from "react-bootstrap";
import { FormLookupItem } from "../../models/PortalMetadata";
import { ApplicableTo, HasAny, MortgageDocumentStatusCode, PortalMortgageDocumentModel } from "../../models/PortalMortgageDocumentModel";
import { AggregatePillOverlay, IAggregatePillOverlayProps } from "../Pills/AggregatePillOverlay";
import { ApplicantService } from '../../pages/ApplicantService';
import axios from 'axios';
import { logger } from '../../helper/Logger';

export interface IFinanceManagerProps extends IAggregatePillOverlayProps {
    uniqueKey: string;
    items: IAggregateListItem[] | null,
    values: any[],
    //addAnother: any;
    //save: any;
    //deleteDocument: any;
    label: any;
    hasAny: boolean | null;
    institutionList: FormLookupItem[],
    helperTexts: any[],
    applicantNo: number;
    contact: any;
    hideAdd?: boolean;
    setContact(contact: any): void;
}

interface IFinanceManagerState {
    items: IAggregateListItem[] | null,
    values: any[],
    emptyOnly: boolean,
    helperTexts: [],
    //hasAny: boolean | null,
    loading: boolean,
    requiredField: string
}

export class FinanceManager extends AggregatePillOverlay<IFinanceManagerProps, IFinanceManagerState> {
    static contextType = UserContext;
    applicantService: ApplicantService;
    deleting: boolean;

    constructor(props: any) {
        super(props);
        this.deleting = false;

        this.state = {
            items: this.props.items,
            values: this.props.values,
            emptyOnly: true,
            helperTexts: [],
            //hasAny: this.props.hasAny,
            //hasAny: this.hasActiveItems(),
            loading: false,
            requiredField: ""
        };

        this.deleteDocumentEx = this.deleteDocumentEx.bind(this);
        this.checkAccountNumber = this.checkAccountNumber.bind(this);

        this.addNewDocument = this.addNewDocument.bind(this);
        this.updateDocument = this.updateDocument.bind(this);
        this.deactivateDocument = this.deactivateDocument.bind(this);
        this.saveDocuments = this.saveDocuments.bind(this);
        this.getFilteredDocs = this.getFilteredDocs.bind(this);
        this.saveAsDeactivatedDocument = this.saveAsDeactivatedDocument.bind(this);
    }

    public getContext(): UserContextState {
        const context = this.context as UserContextState;
        return context;
    }

    componentDidMount() {
        if (this.state.values !== this.props.values)
            this.setState({ values: this.props.values, items: this.props.items });

        let context = this.getContext();
        this.applicantService = new ApplicantService(context, this, this.props.applicantNo);
    }

    close(): void {
        if (this.props.closePill)
            this.props.closePill();
    }

    hasAny(hasAny: boolean) {
        if (!hasAny) {
            this.saveDocuments(this.props.uniqueKey, false, this.state.items!, this.state.values);
            //this.setState({ hasAny: false });
            this.close();
        }
        else {
            //this.setState({ hasAny: true });
            this.addNewDocument(null, this.props.label); //
        }
    }

    checkAccountNumber(value: string) {
        if (value && value.length !== 4) {
            return true;
        }
        return false;
    }

    save() {
        let key = this.props.uniqueKey;
        let nameRequired = key === "OTHERASSETS";
        let balanceRequired = key === "MORTGAGEACCOUNT" || key === "LOAN";
        let institutionRequired = key === "MORTGAGEACCOUNT" || key === "LOAN" || key === "SAVING" || key === "CREDITCARD" || key === "STATEMENT";
        let addressRequired = key === "PROPERTYASSETS";
        let accountNumberRequired = key === "STATEMENT" || key === "SAVING" || key === "CREDITCARD" || key === "FOREIGNACCOUNT";
        let institutionNameRequired = key === "STOCK" || key === "FOREIGNACCOUNT";
        let institutionNameOnOtherRequired = key === "LOAN";
        let loanTypeRequired = key === "LOAN";

        for (var i = 0; i < this.state.values.length; i++) {
            var item = this.state.values[i];
            let otherIsSelected = this.props.institutionList.find(x => x.value == item.institutionId)?.text.toLowerCase() === "other";
            if (balanceRequired && (item.currentBalance === "" || item.currentBalance <= 0)) {
                this.setState({ requiredField: "Balance" });
                return;
            }

            if (nameRequired && (item.name === "" || item.name === null)) {
                this.setState({ requiredField: "Name" });
                return;
            }

            if (addressRequired && (item.name === "" || item.name === null)) {
                this.setState({ requiredField: "Address" });
                return;
            }

            if (institutionRequired && (item.institutionId === "" || item.institutionId === null)) {
                this.setState({ requiredField: "Institution" });
                return;
            }

            if (institutionNameOnOtherRequired && otherIsSelected && ( item.otherInstitutionName === "" || item.otherInstitutionName === null)) {
                this.setState({ requiredField: "Institution Name" });
                return;
            }

            if (institutionNameRequired && (item.otherInstitutionName === "" || item.otherInstitutionName === null)) {
                this.setState({ requiredField: "Institution" });
                return;
            }

            if (accountNumberRequired && (item.accountNumber === "" || item.accountNumber === null)) {
                this.setState({ requiredField: "Account Number" });
                return;
            }

            if (accountNumberRequired && this.checkAccountNumber(item.accountNumber)) {
                this.setState({ requiredField: "4 digit account number" });
                return;
            }

            if (loanTypeRequired && (item.loanType === "" || item.loanType === null)) {
                this.setState({ requiredField: "Loan Type" });
                return;
            }

            if (!nameRequired && !addressRequired)
                item.name = "";
        };

        this.setState({ loading: true });
        this.saveDocuments(this.props.uniqueKey, this.hasActiveItems(), this.state.items!, this.state.values);
        this.setState({ loading: false });

        this.close();
    }

    hasActiveItems(): boolean {
        var activeItems = this.props.values?.filter((item) => { return item.statusReason !== MortgageDocumentStatusCode.Inactive });
        return activeItems?.length > 0;
    }

    deleteDocumentEx(e: any, item: any, values: any[]) {
        if (!this.deleting) {
            this.deleting = true;
            this.deleteDocument(e, item, values);

            if (!this.hasActiveItems() || this.props.hideAdd)
                this.close();
        }
    }

    // #region doc functions
    getFilteredDocs(context?: UserContextState | null) {
        if (!context) context = this.getContext();
        if (context.mortgageDocumentList != null) {
            let applicableTo = this.props.applicantNo === 1 ? ApplicableTo.Primary : ApplicableTo.Secondary;
            let docs = context.mortgageDocumentList.filter(c => c.applicableTo === ApplicableTo.Shared || c.applicableTo === applicableTo || c.isJoint === true);
            docs.sort(
                (a: any, b: any) => {
                    // put new to the bottom
                    if (a.new && !b.new) return -1;
                    if (b.new && !a.new) return 1;
                    // We want to primarily sort by mortgage document id, but if these are null make sure they appear first
                    // We next want to sort by the order property (if its set) as this allows to change the order before merging
                    if (
                        a.categoryOrder === b.categoryOrder) {
                        // if we have 2 null mortgage documents, or 2 matching mortgage documents, sort by the order attribute
                        if (!a.applicableTo && !b.applicableTo) return 1;
                        if (a.applicableTo < b.applicableTo) return -1;
                        if (a.applicableTo > b.applicableTo) return 1;
                    }

                    // Compare if the document needs to come before or after
                    if (a.categoryOrder < b.categoryOrder) return -1;
                    if (a.categoryOrder > b.categoryOrder) return 1;

                    // If we get here, we've probably hit equal documents and orders... 
                    // shouldn't happen, but in case paul got at the code you never know
                    return 0;
                }
            );
            return docs;
        }
        return [];
    }

    addNewDocument(doc: any, label: string) {
        let isNew = true;
        let doclist = this.getFilteredDocs() as any[];// this.state.mortgageDocumentList;
        if (doc === null) {
            //they said yes newly
            let inactiveDoc = doclist.find(e => e.document === label);
            if (inactiveDoc !== null) {
                doc = inactiveDoc;
                doc.hasAny = HasAny.Yes;
                doc.statusReason = MortgageDocumentStatusCode.Active;
                isNew = false;
                logger.log("addNewDocument");
                this.getContext().updateDoc(doc);
            }
        }
        if (isNew) {
            let id = this.applicantService.getApplicantId();
            doclist.push({
                name: "", //doc.name,
                document: doc.document,
                documentId: doc.documentId,
                documentType: doc.documentType,
                new: isNew,
                mortgageId: doclist[0].mortgageId,
                applicableTo: this.props.applicantNo === 1 ? ApplicableTo.Primary : ApplicableTo.Secondary, //doclist[0].applicableTo,// changed this one for "is joint" too
                category: doc.category,
                categoryUniqueCode: doc.categoryUniqueCode,
                mortgageDocumentId: isNew ? Math.random().toString() : doc.mortgageDocumentId,
                currentBalance: 0,
                limit: 0,
                termLeft: 0,
                termPeriod: null,
                hasAny: HasAny.Yes,
                isJoint: false,
                contactId: id,
                statusReason: MortgageDocumentStatusCode.Active,
                categoryOrder: doc.categoryOrder,
                termRemaining: null
            });
            this.getContext().addDoc(doclist.at(doclist.length - 1));
        }
    }

    // cancelDocument = (e: any, doc: any) => {
    //     e.preventDefault()
    //     let doclist = this.getFilteredDocs() as any[]; //this.state.mortgageDocumentList;
    //     doclist = doclist.filter((o: any) => o.mortgageDocumentId !== doc.mortgageDocumentId);
    //     this.setState({
    //         mortgageDocumentList: doclist
    //     });
    // }

    saveDocuments(key: string, hasAny: boolean | null, items: IAggregateListItem[], values: any[]) {
        let id = this.applicantService.getApplicantId();

        if (values.length === 0) {
            hasAny = false;

            // BANDAID - Bit of a bandaid until I figure out a better way to do this... 
            // basically this code tries to get the original mortgageDocumentList (including inactives)
            // and then find the "property" that matches this saveDocuments category key
            // Then it will save that inactive document as being marked as "hasAny = no" 
            var catGrouped = this.applicantService.groupBy(this.getFilteredDocs() as any[], "categoryUniqueCode");//this.state.mortgageDocumentList
            Object.entries(catGrouped).forEach(([catKey, value]) => {
                if (catKey === key) {
                    let inActive = (value as PortalMortgageDocumentModel[]).filter(e => e.statusReason === MortgageDocumentStatusCode.Inactive);
                    if (inActive.length > 0) {
                        var val: any = inActive[0];
                        val.contactId = id;
                        val.hasAny = HasAny.No;
                        this.updateDocument(true, val);
                    }
                }
            });
        }

        values.forEach((val, i) => {
            val.contactId = id;
            if (hasAny === true)
                val.hasAny = HasAny.Yes;
            else if (hasAny === false)
                val.hasAny = HasAny.No;
            else
                val.hasAny = null;

            var isLatest = i === values.length - 1 ? true : false;
            if (hasAny === false) {
                this.deactivateDocument(isLatest, val);
            }
            else if (val.new || val.mortgageDocumentId.indexOf(".") > -1) {
                val.hasAny = HasAny.Yes;
                this.saveDocument(isLatest, val);
            }
            else {
                this.updateDocument(isLatest, val);
            }
        });
    }

    saveDocument = (isLatest: boolean, doc: any) => {
        let context = this.getContext();
        let id = this.applicantService.getApplicantId();
        var newMortgageDoc = {
            documentId: doc.documentId,
            institutionId: doc.institutionId,
            mortgageId: doc.mortgageId,
            applicableTo: this.props.applicantNo === 1 ? ApplicableTo.Primary : ApplicableTo.Secondary,
            name: doc.name,
            category: doc.category,
            categoryId: doc.categoryId,
            document: doc.document,
            institution: doc.institutionId != null ? context.institutions.find(i => i.value === doc.institutionId)?.text : doc.otherInstitutionName,
            currentBalance: doc.currentBalance ?? 0,
            documentType: doc.documentType,
            limit: doc.limit ?? 0,
            termLeft: doc.termLeft ?? 0,
            termPeriod: doc.termPeriod ?? null,
            termRemaining: doc.termRemaining ?? null,
            categoryUniqueCode: doc.categoryUniqueCode,
            accountNumber: doc.accountNumber ?? "",
            planOnClearingTheBalance: doc.planOnClearingTheBalance,
            recurringYearlyValue: doc.recurringYearlyValue,
            recurringMonthlyValue: doc.recurringMonthlyValue,
            contactId: id,
            hasAny: HasAny.Yes,
            statusReason: MortgageDocumentStatusCode.Active,
            isJoint: doc.isJoint,
            otherInstitutionName: doc.otherInstitutionName
        };

        axios.post('/MortgageDocuments/CreateDocument', newMortgageDoc)
            .then(response => {
                let resp = response.data;

                var contact = this.props.contact;
                contact.assetsInfoPercentage = resp.assetsPercentage;
                contact.bankingInfoPercentage = resp.bankingPercentage;
                contact.percentageOfCompletion =
                    (contact.personalInfoPercentage +
                        contact.residentialInfoPercentage +
                        contact.employmentInfoPercentage +
                        contact.bankingInfoPercentage +
                        contact.assetsInfoPercentage) / 5;

                // BANDAID - THIS IS TO KEEP THE APPLICANT MENU IN SYNC AS IT USES THE CONTEXT
                let context = this.getContext();
                if (this.props.applicantNo === 1) {
                    context.mortgage!.primaryApplicant = contact;
                } else {
                    context.mortgage!.secondaryApplicant = contact;
                }
                context.updateDoc(newMortgageDoc);
                context.updateId(doc.mortgageDocumentId, resp.mortgageDocumentId);

                if (isLatest) {
                    this.props.setContact(contact);
                }
            });
    }

    saveAsDeactivatedDocument = (isLatest: boolean, doc: any) => {
        let id = this.applicantService.getApplicantId();
        let newMortgageDoc: any = {
            documentId: doc.documentId,
            institutionId: null,
            mortgageId: doc.mortgageId,
            applicableTo: this.props.applicantNo === 1 ? ApplicableTo.Primary : ApplicableTo.Secondary,
            name: null,
            category: doc.category,
            categoryId: doc.categoryId,
            document: doc.document,
            //institution: doc.institution,
            currentBalance: null,
            documentType: doc.documentType,
            limit: null,
            termLeft: null,
            termPeriod: null,
            categoryUniqueCode: doc.categoryUniqueCode,
            accountNumber: "",
            planOnClearingTheBalance: null,
            recurringYearlyValue: null,
            recurringMonthlyValue: null,
            contactId: id,
            hasAny: HasAny.No,
            isJoint: doc.isJoint,
            statusReason: MortgageDocumentStatusCode.Inactive,
            termRemaining: null
        };

        axios.post('/MortgageDocuments/CreateAsDeactivated', newMortgageDoc)
            .then(response => {
                let resp = response.data;

                doc.hasAny = HasAny.No;
                doc.statusReason = MortgageDocumentStatusCode.Inactive;
                doc.institutionId = null;
                doc.name = null;
                doc.currentBalance = null;
                doc.limit = null;
                doc.termLeft = null;
                doc.termPeriod = null;
                doc.planOnClearingTheBalance = null;
                doc.recurringYearlyValue = null;
                doc.recurringMonthlyValue = null;
                doc.accountNumber = "";
                doc.termRemaining = null;
                this.getContext().updateDoc(doc);
                this.getContext().updateId(doc.mortgageDocumentId, resp.MortgageDocumentId);

                //update percentage
                var contact = this.props.contact;
                contact.assetsInfoPercentage = resp.assetsPercentage;
                contact.bankingInfoPercentage = resp.bankingPercentage;
                contact.percentageOfCompletion =
                    (contact.personalInfoPercentage +
                        contact.residentialInfoPercentage +
                        contact.employmentInfoPercentage +
                        contact.bankingInfoPercentage +
                        contact.assetsInfoPercentage) / 5;

                // BANDAID - THIS IS TO KEEP THE APPLICANT MENU IN SYNC AS IT USES THE CONTEXT
                let context = this.getContext();
                if (this.props.applicantNo === 1) {
                    context.mortgage!.primaryApplicant = contact;
                } else {
                    context.mortgage!.secondaryApplicant = contact;
                }
                this.props.setContact(contact);
                //this.applicantService.updateFinanceCollapsed(doc.categoryUniqueCode, true);
                //this.getContext().getMortgageDocuments();
                //this.applicantService.getMortgageDocuments();
            });
    }

    updateDocument = (isLatest: boolean, doc: any) => {
        doc.currentBalance = (!doc.currentBalance || doc.currentBalance?.toString().trim() === "") ? 0 : doc.currentBalance;
        doc.applicableTo = this.props.applicantNo === 1 ? ApplicableTo.Primary : ApplicableTo.Secondary;
        let context = this.getContext();
        doc.institution = doc.institutionId != null ? context.institutions.find(i => i.value === doc.institutionId)?.text : doc.otherInstitutionName;
        axios.post('/MortgageDocuments/UpdateDocument', doc)
            .then(response => {
                var contact = this.props.contact;

                // There's a rogue order of events someplace blowing up the asset calculation.
                // To protect against this we may get a -1 back. Handle that situation
                if (response.data != -1) {
                    var percentages = response.data;
                    contact.assetsInfoPercentage = percentages.assetsAndLoans;
                    contact.bankingInfoPercentage = percentages.banking; 
                    contact.percentageOfCompletion =
                        (contact.personalInfoPercentage +
                            contact.residentialInfoPercentage +
                            contact.employmentInfoPercentage +
                            contact.assetsInfoPercentage + 
                            contact.bankingInfoPercentage) / 5;
                }
                
                // BANDAID - THIS IS TO KEEP THE APPLICANT MENU IN SYNC AS IT USES THE CONTEXT
                let context = this.getContext();
                if (this.props.applicantNo === 1) {
                    context.mortgage!.primaryApplicant = contact;
                } else {
                    context.mortgage!.secondaryApplicant = contact;
                }
                this.props.setContact(contact);
                this.getContext().updateDoc(doc);
                //this.getContext().getMortgageDocuments();
                //this.applicantService.getMortgageDocuments();

                if (isLatest) {
                    logger.log("updateDocument");
                }
            });
    }

    deactivateDocument = (isLatest: boolean, doc: any) => {
        doc.contactId = this.applicantService.getApplicantId();
        doc.hasAny = HasAny.No;
        doc.statusReason = MortgageDocumentStatusCode.Inactive;
        //this.getContext().updateDoc(doc);
        //this.applicantService.getMortgageDocuments();
        axios.post('/MortgageDocuments/DeactivateDocument', doc)
            .then(response => {
                var contact = this.props.contact;
                var percentages = response.data;
                contact.assetsInfoPercentage = percentages.assetsAndLoans;
                contact.bankingInfoPercentage = percentages.banking; 
                contact.percentageOfCompletion =
                    (contact.personalInfoPercentage +
                        contact.residentialInfoPercentage +
                        contact.employmentInfoPercentage +
                        contact.assetsInfoPercentage +
                        contact.bankingInfoPercentage) / 5;

                // BANDAID - THIS IS TO KEEP THE APPLICANT MENU IN SYNC AS IT USES THE CONTEXT
                let context = this.getContext();
                if (this.props.applicantNo === 1) {
                    context.mortgage!.primaryApplicant = contact;
                } else {
                    context.mortgage!.secondaryApplicant = contact;
                }

                if (isLatest) {
                    logger.log("deactivateDocument");
                    doc.hasAny = HasAny.No;
                    doc.statusReason = MortgageDocumentStatusCode.Inactive;
                    doc.institutionId = null;
                    doc.name = null;
                    doc.currentBalance = null;
                    doc.limit = null;
                    doc.termLeft = null;
                    doc.termPeriod = null;
                    doc.termRemaining = null;
                    doc.planOnClearingTheBalance = null;
                    doc.recurringYearlyValue = null;
                    doc.recurringMonthlyValue = null;
                    doc.accountNumber = "";
                    this.getContext().updateDoc(doc);
                    //this.getContext().getMortgageDocuments();
                    //this.applicantService.getMortgageDocuments();
                }
                this.props.setContact(contact);
            });
    }

    deleteDocument = (e: any, doc: any, values: any[]) => {
        let docs = this.getFilteredDocs().filter((x: any) => x.statusReason !== MortgageDocumentStatusCode.Inactive).filter((x: any) => { return x.categoryUniqueCode === doc.categoryUniqueCode; });// this.state.mortgageDocumentList
        logger.log("docs length:" + docs.length);
        if (docs.length === 1) {
            if (doc.new) {
                logger.log("saveAsDeactivatedDocument");
                this.saveAsDeactivatedDocument(true, doc);
            }
            else {
                logger.log("deactivateDocument");
                this.deactivateDocument(true, doc);
            }
        }
        else {
            if (doc.new || doc.mortgageDocumentId.indexOf(".") > -1) {
                logger.log("cancelDocument");
                //this.cancelDocument(e, doc);
                this.getContext().deleteDoc(doc);
            }
            else {
                logger.log("DeleteDocument");
                axios.post('/MortgageDocuments/DeleteDocument', null, { params: { mortgageDocumentId: doc.mortgageDocumentId } })
                    .then(response => {
                        this.getContext().deleteDoc(doc);
                    });
            }
        }
    }
    // #endregion

    render() {
        let label = this.props.label;
        if (label.substring(this.props.label.length - 1, this.props.label.length) === "s") { //last char == 's'
            label = label.substring(0, this.props.label.length - 1);
        }

        return (
            <>
                <Modal className='text-center' size="lg" show={this.state.requiredField !== ""} onHide={() => { this.setState({ requiredField: "" }) }} centered>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <Container className='text-center' >
                            <Row>
                                <h3 className="impact">Field '{this.state.requiredField}' is required!</h3>
                            </Row>
                            {/* <Row>
                                <p></p>
                            </Row> */}
                        </Container>
                    </Modal.Body>
                    <Modal.Footer className='text-center justify-center'>
                        <Button className='btn btn-standard' onClick={() => { this.setState({ requiredField: "" }) }}>
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>

                <div className="finance-modal">
                    <Modal.Body>
                        {(this.props.hasAny === null || this.props.hasAny === false) &&
                            <Container className="finance-document text-center">
                                <h3>Do you have any {this.props.label}?</h3>
                                <br />
                                <Button className="btn btn-standard btn-outline btn-wide" onClick={e => this.hasAny(true)}>Yes</Button>&nbsp;
                                <Button className="btn btn-standard btn-outline btn-wide" onClick={e => this.hasAny(false)}>No</Button>
                            </Container>
                        }
                        {this.props.hasAny === true && this.state.values.map((item: any, i) =>
                            <Container key={item.key} className="finance-document text-center pb-4">
                                {i === 0 &&
                                    <Row>
                                        <h3 className="impact">{this.props.label}</h3>
                                    </Row>
                                }
                                <Row>
                                    <Col className="col-11"><Finance key={item} item={item} helperTexts={this.props.helperTexts} institutionList={this.props.institutionList} checkAccountNumber={this.checkAccountNumber} /></Col>
                                    <Col className="col-1"><div style={{ cursor: "pointer", top: "40%", position: "relative", fontSize: 25, color: "rgb(153,153,153)" }} className="far fa-trash-can" onClick={e => this.deleteDocumentEx(e, item, this.state.values)}></div></Col>
                                </Row>
                                {i === this.state.values.length - 1 &&
                                    <>
                                        {!this.props.hideAdd &&
                                        <Row className="text-center pt-5">
                                            <Stack direction="horizontal" className="justify-center">
                                                <div className="doddltext h4" style={{ cursor: "pointer" }} onClick={() => { this.addNewDocument(this.props.values.length > 0 ? this.props.values[0] : null, this.props.label) }}>
                                                    <i className="fas fa-square-plus" style={{ verticalAlign: "middle" }}></i> <span className="h5 doddltext ps-2">Add another</span>
                                                </div>
                                            </Stack>
                                        </Row>
                                        }
                                        <Row className="text-center pt-3">
                                            {!this.state.loading &&
                                                <Stack className="justify-center" direction="horizontal" gap={3}>
                                                    <Button className="btn btn-standard big btn-wide" onClick={() => { this.close() }}>Close</Button>
                                                    <Button className="btn btn-standard big btn-wide" onClick={() => { this.save() }}>Done</Button>
                                                </Stack>
                                            }
                                            {this.state.loading && <Icons.Spinner animation="border" role="status" />}
                                        </Row>
                                    </>
                                }
                            </Container>
                        )
                        }
                    </Modal.Body>
                </div>
            </>
        );
    }
}