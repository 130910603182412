import React from "react";
import { Navigate } from "react-router-dom";
import { UserContextComponent } from "./UserContextComponent";
import UserContextState from "./UserContext";

interface IState {
    impersonate: boolean;
    toggleRefresh: boolean;
}

export class ImpersonateContactButton extends UserContextComponent<any,IState> {

    constructor(props:any) {
        super(props);
        this.state = {impersonate: false, toggleRefresh: false};
    }
    
    render() {
        //let context = this.getContext();
        const context = this.context as UserContextState;
        if (!context.contact) {
            setTimeout(() => this.setState({toggleRefresh: !this.state.toggleRefresh}), 1000);
            return <></>;
        }

        if (!context.contact?.isAdmin) return <></>;
        if (this.state.impersonate) return <Navigate to="/_impersonate" />;
        return <a className="ic-button" onClick={() => this.setState({impersonate: true})}><i className="fa fa-user-ninja"></i> </a>;
    }
};