import React from "react";
import { Nav, NavDropdown, NavItem, Navbar } from 'react-bootstrap';
import { AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";
import { SignInButton } from "./SignIn";
import { SignOutButton } from "./SignOut";
import { ImpersonateContactButton } from "./ImpersonateContactButton";

export const Menu = () => {

    return (
        <div className="login-container">
            <UnauthenticatedTemplate>
                <NavItem>
                    <SignInButton />
                </NavItem>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <Navbar>
                    <NavDropdown title={<i className="fa fa-user fa-lg"></i>}>
                        <NavItem className="mr-34" >
                            <SignOutButton />
                        </NavItem>
                    </NavDropdown>
                </Navbar>
            </AuthenticatedTemplate>
        </div>
);
};