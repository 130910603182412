import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Col, Row, Container, Stack, ButtonGroup } from "react-bootstrap";
import "./Applicant.css";
import { PortalPage } from "../components/PortalPage";
import { GetTooltips } from "../helper/tooltipHelper";
import UserContextState, { UserContext } from "../components/UserContext";
import { InputPill } from "../components/Pills/InputPill";
import { Navigate } from "react-router-dom";
import { appInsightsHelper } from "../components/AppInsights";
import { PropertyFieldsCollection } from "../forms/PropertyFields";
import { ControlType } from "../models/PortalMetadata";
import currencyFormatter from "../helper/currencyFormatter";
import { UserContextComponent } from "../components/UserContextComponent";
import {
  MortgageSubType,
  MortgageType,
  PropertyType,
  SelfOwnedSite,
} from "../models/PortalMortgageModel";
import { Address } from "../components/Address";
import { LinkPill } from "../components/Pills/LinkPill";

export default class Property extends UserContextComponent<any, any> {
  static contextType = UserContext;

  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      mortgage: null,
      step: 1,
      helperTexts: [],
      redirectTo: null,
      fields: null,
      addressIsValid: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.prev = this.prev.bind(this);
    this.next = this.next.bind(this);
    this.saveAllFields = this.saveAllFields.bind(this);
  }

  componentDidMount() {
    appInsightsHelper.trackPageView({ name: "Property" });
    let context = this.getContext();
    this.setState({ mortgage: context.mortgage });
    this.getTooltips("mortgage");
  }

  getTooltips(entityname: string) {
    GetTooltips(entityname)
      .then((response) => {
        let context = this.getContext();
        let helperTexts = response.data;

        if (context.mortgage !== null) {
          let fields = new PropertyFieldsCollection();
          fields.loadData(
            context.mortgage,
            helperTexts,
            true,
            this.handleChange
          );
          this.setState({
            fields: fields,
            loading: false,
            helperTexts: helperTexts,
          });
        }
      })
      .catch((error) => {
        if (error.response.statusCode === 401) {
          alert("Unauthorized");
        }
      });
  }

  getFieldCount(): number {
    let context = this.getContext();
    let saleAgreed =
      this.state.fields.getFieldByName("saleAgreed").value === "1";
    let purchaser = context.mortgage?.type === MortgageType.PurchasingaNewHome;
    let fieldCount = 2;
    if (purchaser && !saleAgreed) {
      fieldCount = 1;
    } else {
      if (this.state.mortgage.type === MortgageType.RemortgagingExistingLoan) {
        if (
          [
            MortgageSubType.EquityReleaseTopup,
            MortgageSubType.EquityReleaseWithWorks,
          ].includes(this.state.mortgage.subType)
        ) {
          if (this.state.mortgage.equityReleasetoCarryOutHomeImprovements) {
            fieldCount++;
          }
        }
      } else {
        if (this.state.mortgage.propertyType === PropertyType.SelfBuild) {
          if (this.state.mortgage.selfOwnedSite === SelfOwnedSite.Yes)
            fieldCount += 4;
          fieldCount += 5;
        } else if (
          this.state.mortgage.propertyType === PropertyType.SecondHand
        ) {
          if (this.state.mortgage.requirestructuralrenovations) fieldCount += 3;
          else fieldCount += 2;
        }
      }
    }
    return fieldCount;
  }
  getFieldsDone(): number {
    let fieldsDone = 0;
    let context = this.getContext();
    let saleAgreed =
      this.state.fields.getFieldByName("saleAgreed").value === "1";
    let purchaser = context.mortgage?.type === MortgageType.PurchasingaNewHome;
    if (purchaser && !saleAgreed) {
      fieldsDone = 1;
    } else {
      if (this.state.fields.getFieldByName("propertyAddress").value)
        fieldsDone++;
      if (this.state.fields.getFieldByName("totalNumberofBedrooms").value > 0)
        fieldsDone++;
      //if (this.state.fields.getFieldByName("registeredInBothYourNames").value !== null) fieldsDone++;

      if (this.state.mortgage.type === MortgageType.RemortgagingExistingLoan) {
        if (
          [
            MortgageSubType.EquityReleaseTopup,
            MortgageSubType.EquityReleaseWithWorks,
          ].includes(this.state.mortgage.subType)
        ) {
          if (
            this.state.fields.getFieldByName(
              "equityReleasetoCarryOutHomeImprovements"
            ).value
          ) {
            if (this.state.fields.getFieldByName("costofTheWork").value)
              fieldsDone++;
          }
        }
      } else {
        if (this.state.fields.getFieldByName("propertyType").value)
          fieldsDone++;
        if (this.state.mortgage.propertyType === PropertyType.SelfBuild) {
          if (this.state.fields.getFieldByName("selfOwnedSite").value)
            fieldsDone++;
          if (this.state.fields.getFieldByName("selfBuildContractType").value)
            fieldsDone++;
          if (
            this.state.fields.getFieldByName(
              "hasTheBuildObtainedFullPlanningPermission"
            ).value !== null
          )
            fieldsDone++;
          if (
            this.state.fields.getFieldByName("estimatedCostofTheBuild").value &&
            parseFloat(
              this.state.fields
                .getFieldByName("estimatedCostofTheBuild")
                .value.replaceAll("€", "")
            ) > 0
          )
            fieldsDone++;
          if (this.state.mortgage.selfOwnedSite === SelfOwnedSite.No) {
            if (
              this.state.fields.getFieldByName(
                "pleaseConfirmIfTheSiteIsBeingGifted"
              ).value
            )
              fieldsDone++;
          }
        } else if (
          this.state.mortgage.propertyType === PropertyType.SecondHand
        ) {
          if (this.state.fields.getFieldByName("berRating").value) fieldsDone++;
          if (
            this.state.fields.getFieldByName("requireStructuralRenovations")
              .value !== null
          )
            fieldsDone++;
          if (this.state.mortgage.requirestructuralrenovations) {
            if (this.state.fields.getFieldByName("costofTheWork").value)
              fieldsDone++;
          }
        }
      }
    }
    return fieldsDone;
  }

  saveAllFields(redirectTo: string | null) {
    let dirtyFields: any[] = [];
    for (let fieldName in this.state.fields.fields) {
      let f = this.state.fields.fields[fieldName];
      if (f.isDirty) dirtyFields.push(f);
    }

    // BANDAID - THIS IS TO KEEP THE APPLICANT MENU IN SYNC AS IT USES THE CONTEXT
    let context = this.getContext();
    context.mortgage!.propertyDetailsPercentage =
      (this.getFieldsDone() * 100) / this.getFieldCount();
    context.refreshMenuState();

    if (dirtyFields.length === 0) {
      if (redirectTo) {
        this.setState({ redirectTo: redirectTo });
      }
      return;
    }
    let newMortgage = this.state.fields.getAsEntity(
      "mortgageId",
      this.state.mortgage.mortgageId,
      this.state.mortgage
    );
    //let newMortgage = mapFieldsToEntity(dirtyFields, "mortgageId", this.state.mortgage.mortgageId);

    axios.post("/property/update", newMortgage).then((response) => {
      for (let i = 0; i < dirtyFields.length; i++) {
        let f = dirtyFields[i];
        f.originalValue = f.value;
        f.isDirty = false;
      }

      this.setState({ redirectTo: redirectTo });
    });
  }

  handleChange(event: any) {
    const target = event.target;
    const value =
      (target.type === "checkbox" ? target.checked : target.value) ||
      target.textContent ||
      "";
    const name = target.name || target.getAttribute("name");

    var mortgage = this.state.mortgage;

    if (!name) return;

    let f = this.state.fields.getFieldByName(name);

    //// if we don't set the value here it sometimes doesn't get set and causes a bug with reverting to the original value
    let fields = this.state.fields;
    if (fields) {
      if (f.controlType === ControlType.currency) {
        fields.updateModel(
          this.state.mortgage,
          name,
          value?.replaceAll(",", "").replaceAll("€", "")
        );
        fields.updateFieldValue(
          name,
          currencyFormatter(
            Number(value?.replaceAll(",", "").replaceAll("€", ""))
          )
        );
      } else {
        fields.updateModel(this.state.mortgage, name, value);
        fields.updateFieldValue(name, value);
      }
    }
    this.setState({ fields: this.state.fields, mortgage: mortgage });
  }

  handleSubmit(event: any) {
    event.preventDefault();
    if (this.props.next) {
      this.saveAllFields(null);
      this.props.next();
    } else {
      this.saveAllFields("/profile");
    }
    //this.saveAllFields(true);
  }

  prev() {
    if (this.props.prev) {
      this.saveAllFields(null);
      this.props.prev();
    } else {
      this.saveAllFields("/shared");
    }
  }

  next() {
    if (this.props.next) {
      this.saveAllFields(null);
      this.props.next();
    } else {
      this.saveAllFields("/solicitor-details");
    }
  }

  render() {
    let context = this.getContext();

    if (this.state.loading) {
      return (
        <>
          <p>
            <em>Loading...</em>
          </p>
        </>
      );
    }

    if (this.state.redirectTo) {
      //return <Navigate to="/profile" />
      // For now we always redirect to the "next/prev page" in this flow
      return <Navigate to={this.state.redirectTo} />;
    }

    let saleAgreed =
      this.state.fields.getFieldByName("saleAgreed").value === "1";

    return (
      // <PortalPage title={<>Details about <span className="doddltext">{this.state.contact.firstName}</span></>} description={"Tell us about yourself so that together we can find the most suitable mortgage"}>
      <>
        <PortalPage className="bump-right" title={<>Property Details</>}>
          <Row>
            <Col className="col-12">
              <Form onSubmit={this.handleSubmit}>
                <Row className="sub-title-box">
                  <>
                    {context.mortgage?.type ===
                      MortgageType.RemortgagingExistingLoan && (
                      <p className="subtext">Tell us about your home.</p>
                    )}
                    {context.mortgage?.type ===
                      MortgageType.PurchasingaNewHome &&
                      saleAgreed && (
                        <p className="subtext">
                          Congratulations on finding your new home! Please enter
                          the property address below ensuring that the address
                          of the property you are purchasing fully matches with
                          the address on your booking deposit receipt.
                        </p>
                      )}
                    {context.mortgage?.type ===
                      MortgageType.PurchasingaNewHome &&
                      !(saleAgreed ?? false) && (
                        <p className="subtext">
                          Once you've gone Sale Agreed, provide all the details
                          of your new home here.
                        </p>
                      )}
                  </>
                </Row>
                <Container className="pill-form">
                  <Row className="pill-row">
                    <Stack
                      gap={1}
                      direction="horizontal"
                      className="pill-stack"
                    >
                      {context.mortgage?.type ===
                        MortgageType.PurchasingaNewHome && (
                        <InputPill
                          hideIfValue={this.state.emptyOnly}
                          field={this.state.fields.getFieldByName("saleAgreed")}
                        ></InputPill>
                      )}
                      {/* only show the below if they are a switcher or they've gone sale agreed */}
                      {(context.mortgage?.type !==
                        MortgageType.PurchasingaNewHome ||
                        (saleAgreed ?? false)) && (
                        <>
                          <LinkPill
                            hideIfValue={this.state.emptyOnly}
                            field={this.state.fields.getFieldByName(
                              "propertyAddress"
                            )}
                            saveOnDone={this.saveAllFields}
                            overlay={
                              <Address
                                helperTexts={this.state.helperTexts}
                                record={this.state.mortgage}
                                fields={this.state.fields}
                                handleChange={this.handleChange}
                                address="propertyAddress"
                                eircode="eircode"
                                letManual={false}
                                customAddressPrompt={
                                  context.mortgage?.type ===
                                  MortgageType.PurchasingaNewHome
                                    ? "Please ensure that the address of the property you are purchasing fully matches with the address on your booking deposit receipt."
                                    : "Please enter your full property address."
                                }
                              />
                            }
                          />
                          {context.mortgage?.type ===
                            MortgageType.PurchasingaNewHome && (
                            //if its not switcher
                            <InputPill
                              hideIfValue={this.state.emptyOnly}
                              field={this.state.fields.getFieldByName(
                                "propertyType"
                              )}
                            ></InputPill>
                          )}
                          <InputPill
                            hideIfValue={this.state.emptyOnly}
                            field={this.state.fields.getFieldByName(
                              "totalNumberofBedrooms"
                            )}
                          ></InputPill>

                          {this.state.mortgage.subType !== 755980001 &&
                            this.state.mortgage.subType !== "755980001" && (
                              //show them if its not switcher with equity
                              <>
                                {this.state.mortgage.propertyType ===
                                  PropertyType.SecondHand && (
                                  //second hand
                                  <>
                                    <InputPill
                                      hideIfValue={this.state.emptyOnly}
                                      field={this.state.fields.getFieldByName(
                                        "requirestructuralrenovations"
                                      )}
                                    ></InputPill>
                                    <InputPill
                                      hideIfValue={this.state.emptyOnly}
                                      field={this.state.fields.getFieldByName(
                                        "berRating"
                                      )}
                                    ></InputPill>
                                  </>
                                )}

                                {this.state.mortgage
                                  .requirestructuralrenovations === true &&
                                  this.state.mortgage.propertyType !==
                                    PropertyType.NewBuild && (
                                    //Require structural renovations
                                    <InputPill
                                      hideIfValue={this.state.emptyOnly}
                                      field={this.state.fields.getFieldByName(
                                        "costofTheWork"
                                      )}
                                    ></InputPill>
                                  )}

                                {this.state.mortgage.propertyType ===
                                  PropertyType.SelfBuild && (
                                  //self build
                                  <>
                                    <InputPill
                                      hideIfValue={this.state.emptyOnly}
                                      field={this.state.fields.getFieldByName(
                                        "selfOwnedSite"
                                      )}
                                    ></InputPill>
                                    {this.state.mortgage.selfOwnedSite ===
                                      SelfOwnedSite.No && (
                                      //not build by themselves
                                      <InputPill
                                        hideIfValue={this.state.emptyOnly}
                                        field={this.state.fields.getFieldByName(
                                          "pleaseConfirmIfTheSiteIsBeingGifted"
                                        )}
                                      ></InputPill>
                                    )}
                                    <InputPill
                                      hideIfValue={this.state.emptyOnly}
                                      field={this.state.fields.getFieldByName(
                                        "selfBuildContractType"
                                      )}
                                    ></InputPill>
                                    <InputPill
                                      hideIfValue={this.state.emptyOnly}
                                      field={this.state.fields.getFieldByName(
                                        "hasTheBuildObtainedFullPlanningPermission"
                                      )}
                                    ></InputPill>
                                    <InputPill
                                      hideIfValue={this.state.emptyOnly}
                                      field={this.state.fields.getFieldByName(
                                        "estimatedCostofTheBuild"
                                      )}
                                    ></InputPill>
                                  </>
                                )}
                              </>
                            )}
                          {this.state.mortgage.type ===
                            MortgageType.RemortgagingExistingLoan &&
                            [
                              MortgageSubType.EquityReleaseTopup,
                              MortgageSubType.EquityReleaseWithWorks,
                            ].includes(this.state.mortgage.subType) && (
                              //switcher with equity
                              <>
                                <InputPill
                                  hideIfValue={this.state.emptyOnly}
                                  field={this.state.fields.getFieldByName(
                                    "equityReleasetoCarryOutHomeImprovements"
                                  )}
                                ></InputPill>

                                {this.state.mortgage
                                  .equityReleasetoCarryOutHomeImprovements ===
                                  true && (
                                  //switcher with equity
                                  //equityReleasetoCarryOutHomeImprovements yes
                                  <InputPill
                                    hideIfValue={this.state.emptyOnly}
                                    field={this.state.fields.getFieldByName(
                                      "costofTheWork"
                                    )}
                                  ></InputPill>
                                )}
                              </>
                            )}
                        </>
                      )}
                    </Stack>
                  </Row>
                </Container>
                <Container>
                  <Row>
                    <Col className="toggle-box">
                      <Form.Check
                        type="switch"
                        id="empty-fields-switch"
                        label="Empty Fields Only"
                        reverse
                        onClick={() =>
                          this.setState({ emptyOnly: !this.state.emptyOnly })
                        }
                      />
                    </Col>
                  </Row>
                  <Stack
                    direction="horizontal"
                    gap={3}
                    className="text-center justify-center"
                  >
                    <ButtonGroup>
                      <button
                        type="button"
                        className="btn btn-standard-transparent btn-outline btn-wide"
                        onClick={this.prev}
                      >
                        Back
                      </button>
                      <button
                        type="button"
                        className="btn btn-dark btn-wide"
                        onClick={this.next}
                      >
                        Next
                      </button>
                    </ButtonGroup>
                  </Stack>
                </Container>
              </Form>
            </Col>
          </Row>
        </PortalPage>
      </>
    );
  }

  async getMortgage() {
    const context = this.context as UserContextState;
    let id = context.contact?.mortgageId;
    let url = `/property/getProperty?mortgageId=${id}`;
    axios
      .get(url)
      .then((response) => {
        var mortgage = response.data;
        this.setState({ mortgage: mortgage, loading: true });
        this.getTooltips("mortgage");
      })
      .catch((error) => {
        if (error.response.statusCode === 401) {
          alert("Unauthorized");
        }
      });
  }
}
