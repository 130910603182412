import { Component } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { FormControl } from "./FormControl";
import "./Address.css";
import { ApplicantFieldsCollection } from "../forms/ApplicantFields";
import "./Employment.css";
import { FormLookupItem } from "../models/PortalMetadata";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import React from "react";
import { LinkPill } from "./Pills/LinkPill";
import { Address } from "./Address";

interface IEmploymentProps {
    employersName: string;
    occupation: string;
    address1: string;
    address2: string;
    lengthYears: string;
    lengthMonths: string;

    helperTexts: any[];
    record: any;
    fields: ApplicantFieldsCollection;
    handleChange(event: any): void;
    occupationList: FormLookupItem[]
}

interface IEmploymentState {
    helperTexts: any[];
    record: any;
    fields: ApplicantFieldsCollection;
    occupation: string;
    selectedOccupation: any[];
    addressIsValid: boolean;
}

export class Employment extends Component<IEmploymentProps, IEmploymentState> {
    constructor(props: any) {
        super(props);

        let ocVal = this.props.fields.getFieldByName(this.props.occupation)?.value;

        this.state = {
            helperTexts: [],
            record: props.record,
            fields: props.fields,
            occupation: "",
            selectedOccupation: (this.props.occupationList.filter(c => c.value == ocVal)),
            addressIsValid: true,
        }
    }

    setOccupationValue(selected: any[]) {
        let val: any;
        if (selected.length == 0) {
            this.setFieldValue(this.props.occupation, "");
            val = "";
        }
        else {
            this.setFieldValue(this.props.occupation, selected[0].value);
            val = selected[0].value;
        }

        this.props.handleChange({ target: { value: val, name: this.props.occupation } });
    }

    setFieldValue(fieldName: string, value: string) {
        let fields = this.state.fields;
        if (fields) {
            fields.updateModel(this.state.record, fieldName, value);
            fields.updateFieldValue(fieldName, value);
        }
        this.setState({ fields: this.state.fields, record: this.state.record });
    }

    render() {

        return (
            <Container className="employment-container">
                <Row>
                    <Col>
                        <FormControl icon="fas fa-house" field={this.props.fields.getFieldByName(this.props.employersName)}></FormControl>
                    </Col>
                </Row>
                <Address helperTexts={this.state.helperTexts} record={this.state.record} fields={this.state.fields}
                    handleChange={this.props.handleChange} address={this.props.address1} letManual={false} />
                        {/* <FormControl icon="fa-regular fa-address-book" field={this.props.fields.getFieldByName(this.props.address1)}></FormControl> */}
                    {/* <Col>
                        <FormControl field={this.props.fields.getFieldByName(this.props.address2)} ></FormControl>
                    </Col> */}
                <Row>
                    <Col>
                        <span className="input-group-text">Occupation</span>
                        <Typeahead
                            id="occupationid"
                            onChange={(selected) => {

                                this.setState({ selectedOccupation: selected });
                                this.setOccupationValue(selected);

                            }}
                            options={this.props.occupationList}
                            selected={this.state.selectedOccupation}
                            labelKey="text"
                            allowNew={false}
                            multiple={false}

                        />
                        {/*<Form.Control value={this.state.occupation} type="text"*/}
                        {/*    onChange={e => { this.setSearchValue(e.target.value); this.props.handleChange(e); }}*/}
                        {/*    onBlur={e => this.clearSearch(e.target.value)} />*/}
                        {/*<FormControl icon="fas fa-user-doctor" field={this.props.fields.getFieldByName(this.props.occupation)}></FormControl>*/}
                    </Col>
                    <Col>
                        <FormControl icon="far fa-calendar" field={this.props.fields.getFieldByName(this.props.lengthYears)} ></FormControl>
                    </Col>
                    <Col>
                        <FormControl icon="far fa-calendar" field={this.props.fields.getFieldByName(this.props.lengthMonths)} ></FormControl>
                    </Col>
                </Row>
            </Container>
        );
    }
}