import axios from "axios";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import * as Icons from "react-bootstrap";
import FormattedDate from "./FormattedDate";

export interface IDownloadDocumentButtonProps {
  title: string;
  documentTitle?: string;
  download(): void;
  expiry?: string | null | undefined;
  buttonStyle?: "standard" | "pill" | null;
  showError: boolean;
  className?: string;
}

interface IDownloadDocumentButtonState {
  
}

export class DownloadDocumentButton extends React.Component<IDownloadDocumentButtonProps, IDownloadDocumentButtonState> {
  constructor(props: IDownloadDocumentButtonProps) {
    super(props);
  }

  render() {

    return (
      <>
      <div>
      {this.props.buttonStyle === "standard" &&
      <div>
        <div className={`aip-download-button text-start ${this.props.className ?? ''}`}
            onClick={(e: any) => { this.props.download(); }}>
              <span className="std">Download <span>{this.props.documentTitle ?? this.props.title}</span></span>
            <span style={{ textIndent: '4px', padding: '12px', paddingRight: '10px'}} className=" fa-solid fa-circle-down fa-xl float-right"></span>
        </div>
      </div>
      }
      {(this.props.buttonStyle ?? "pill") === "pill" &&
      <button className={`mt-3 ps-3 btn btn-standard btn-fw nowrap text-start ${this.props.className ?? ''}`} onClick={(e: any) => { this.props.download(); }}>
          <span className="std">Download <strong>{this.props.documentTitle ?? this.props.title}</strong></span>
          <span style={{ textIndent: '4px', paddingRight: '10px' }} className=" float-right"><i className="fa-solid fa-circle-down fa-xl "></i></span>
      </button>
      }
      {this.props.expiry &&
        <p className="s mt-1 mb-0">{this.props.title} expiry <FormattedDate date={this.props.expiry}/></p>
      }
      {this.props.showError &&
          <>
          <p style={{ width: '100%', color: '#333333', paddingTop: 5 }}>We ran into a problem downloading your {this.props.documentTitle ?? this.props.title}. </p>
          <button className='btn btn-standard-transparent btn-outline btn-fw mt-2 mb-4' onClick={() => window.Intercom('showNewMessage')}>Speak to my Advisor</button>
          </>
      }
      </div>
    </>
    );
  }
}