import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Intercom } from "./Intercom";
import { ImpersonateContactButton } from "./ImpersonateContactButton";

export class PortalPage extends React.Component<any, any> {

    render() {
        return (
            <Intercom>
                <Container className={`portal-page menu-pad ${this.props.className??''}`}>
                <div className={`portal-page-container `}>
                        <div className={`page-title text-md-left max-w-wide ${this.props.titleClassName ??''}`}>
                            <h1 className={"d-none d-lg-block d-xl-block" + (this.props.lightTitle ? " light" : "") + (this.props.alignTitleLeft ? " text-start" : " justify-center")}>{this.props.title}</h1>
                            <h1 className="d-sm-block d-md-block d-lg-none d-xl-none">{this.props.mobileTitle ?? this.props.title}</h1>
                            <Row className="">
                                {/* <Col lg={1} className="d-none d-lg-block d-xl-block"></Col> */}
                                <Col xs={12} className={"subtext"}>
                                    <span className={"subtext"+ (this.props.alignTitleLeft ? " text-start" : " justify-center") }>{this.props.description}</span>
                                    {this.props.subtitle &&
                                        <div className="doddl-grey subtitle">{this.props.subtitle}</div>
                                    }
                                </Col>
                                <Col className="col-2"></Col>
                            </Row>
                        </div>
                        {this.props.children}
                        <ImpersonateContactButton />
                        </div>
                </Container>
            </Intercom>
        );
    }
}