import { getTooltip } from "../components/Tooltip";
import currencyFormatter from "../helper/currencyFormatter";
import { ControlType, DataType, Field, FormLookupItem } from "../models/PortalMetadata";

export class FieldsCollection {
    fields: Field[] = [];

    addLookupValues(name: string, values: FormLookupItem[]) {
        let f = this.getFieldByName(name);
        if (f) {
            f.lookupValues = values;
        }
    }

    addTextAsNumberField(name: string, label: string, maxLength: number = 100, optional: boolean = false): Field {
        var f = this.addField(name, label, DataType.string, ControlType.number, optional);
        f.maxLength = maxLength;
        return f;
    }

    addTextField(name: string, label: string, maxLength: number = 100, optional: boolean = false): Field {
        var f = this.addField(name, label, DataType.string, ControlType.text, optional);
        f.maxLength = maxLength;
        return f;
    }

    addBooleanField(name: string, label: string, optional: boolean = false): Field {
        let f = this.addField(name, label, DataType.boolean, ControlType.picklist, optional);
        f.options = [{ text: "No", value: 0 }, { text: "Yes", value: 1 }];
        return f;
    }

    addMultiTextField(name: string, label: string, optional: boolean = false): Field {
        var f = this.addField(name, label, DataType.string, ControlType.textarea, optional);
        f.maxLength = 500;
        return f;
    }

    addLookupField(name: string, label: string, optional: boolean = false): Field {
        let f = this.addField(name, label, DataType.entityReference, ControlType.lookup, optional);
        f.api = "";
        return f;
    };

    addNumberField(name: string, label: string, minValue: number, maxValue: number, optional: boolean = false): Field {
        let f = this.addField(name, label, DataType.number, ControlType.number, optional);
        f.minValue = minValue;
        f.maxValue = maxValue;
        return f;
    }

    addNumberWithControlsField(name: string, label: string, minValue: number, maxValue: number, optional: boolean = false): Field {
        let f = this.addField(name, label, DataType.number, ControlType.numberWithControls, optional);
        f.minValue = minValue;
        f.maxValue = maxValue;
        return f;
    }

    addDecimalField(name: string, label: string, minValue: number, maxValue: number, optional: boolean = false): Field {
        let f = this.addField(name, label, DataType.number, ControlType.decimal, optional);
        f.minValue = minValue;
        f.maxValue = maxValue;
        return f;
    }

    addCurrencyField(name: string, label: string, minValue: number, maxValue: number, optional: boolean = false): Field {
        let f = this.addField(name, label, DataType.number, ControlType.currency, optional);
        f.minValue = minValue;
        f.maxValue = maxValue;
        return f;
    }

    addPicklistField(name: string, label: string, options: any[], optional: boolean = false): Field {
        let f = this.addField(name, label, DataType.number, ControlType.picklist, optional);
        f.options = options;
        return f;
    }

    addDateField(name: string, label: string, minValue: Date | null = null, maxValue: Date | null = null, optional: boolean = false): Field {
        let f = this.addField(name, label, DataType.date, ControlType.date, optional);
        if (minValue) {
            f.minValue = minValue;
        } else {
            f.minValue = new Date(1900, 1, 1);
        }
        if (maxValue) f.maxValue = maxValue;
        return f;
    }

    addField(name: string, label: string, dataType: DataType, controlType: ControlType, optional: boolean = false): Field {
        let f = {
            name: name,
            label: label,
            dataType: dataType,
            controlType: controlType,
            tooltip: null,
            originalValue: "",
            value: "",
            isPill: true,
            isDirty: false,
            api: null,
            lookupValues: null,
            options: null,
            dateValue: null,
            optional: optional,
        } as Field;
        this.fields.push(f);
        return f;
    }

    isNumber(value: any): boolean {
        if (value === null || value === undefined || value === "")
            return false;

        if (value.toString().trim() === "")
            return false;

        if (isNaN(Number(value)))
            return false;

        return true;
    }

    getAsEntity(idAttribute: string, idValue: string, record: any) {
        var newEntity: any = {};
        newEntity[idAttribute] = idValue;
        let dirtyFields: any[] = [];
        for (let i = 0; i < this.fields.length; i++) {
            let f = this.fields[i];
            if (f.isDirty) {
                dirtyFields.push(f);
                newEntity[f.name] = this.getFieldValueForEntity(f, record);
            }
        }
        newEntity["updatedFields"] = dirtyFields.map(item => { return item.name; }).join(",");
        return newEntity;
    }

    getFieldValue(f: Field, data: any) {
        if (f.dataType === DataType.boolean) {
            return data[f.name] === true ? "1" : "0";
        }
        else if (f.dataType === DataType.date) {
            return data[f.name] ? new Date(data[f.name]) : null;
        }
        else if (f.controlType === ControlType.currency) {
            return this.isNumber(data[f.name]) ? currencyFormatter(parseInt(data[f.name])) : "";
        }
        else if (f.dataType === DataType.number) {
            return this.isNumber(data[f.name]) ? parseInt(data[f.name]) : "";
        }
        return data[f.name] ? data[f.name] : undefined;
    }

    getFieldValueForEntity(f: Field, data: any) {
        if (f.dataType === DataType.boolean) {
            return data[f.name];
        }
        else if (f.dataType === DataType.date) {
            return data[f.name] ? new Date(data[f.name]) : null;
        }
        else if (f.controlType === ControlType.currency) {
            return this.isNumber(data[f.name]?.replace(",", "")) ? parseInt(data[f.name]?.replace(",", "")) : null;
        }
        else if (f.dataType === DataType.number) {
            return this.isNumber(data[f.name]) ? parseInt(data[f.name]) : null;
        }
        return data[f.name] ? data[f.name] : undefined;
    }

    updateFieldValue(name: string, value: any) {
        let f = this.getFieldByName(name);
        f.value = value;
        f.isDirty = f.value !== f.originalValue;
        if (f.controlType === ControlType.lookup && value === null) {
            f.value = "-";
        }
    }

    updateModel(model: any, name: string, value: any) {
        let f = this.getFieldByName(name);

        if (f.controlType === ControlType.currency && value != null) {
            model[name] = value.replace("€", "").replaceAll(",", "").replaceAll(" ", "");
        } else if (f.dataType === DataType.number) {
            if (this.isNumber(value))
                model[name] = parseInt(value);
            else
                model[name] = null;
        }
        else if (f.dataType === DataType.boolean && value != null) {
            model[name] = value === "1" ? true : false;
        }
        else if (f.dataType === DataType.date && value != null)
            model[name] = new Date(value);
        else
            model[name] = value;
    }

    public loadData(data: any, tooltips: any[], isPill: boolean | true, handleChange: any, handleDateChange?: any) {
        this.fields.forEach(f => {
            f.originalValue = this.getFieldValue(f, data);
            f.value = this.getFieldValue(f, data);
            f.tooltip = getTooltip(tooltips, f.name);
            f.isPill = isPill;
            if (f.dataType === DataType.date) {
                f.handleChange = handleDateChange;
                f.dateValue = this.getFieldValue(f, data);
            }
            else
                f.handleChange = handleChange;
        });
    }

    public getFieldByName(name: string): Field {
        let f = this.fields.find((field) => { return field.name === name });
        if (f === undefined)
            return this.addTextField(name, `Field '${name}' not found`);
        return f;
    }

    public getFieldValueByName(f: string, data: any) {
        let field = this.getFieldByName(f);
        return this.getFieldValue(field, data);
    }
}