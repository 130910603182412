import { Form, Col, Row, Container, Stack, ButtonGroup } from "react-bootstrap";
import { PortalPage } from "../components/PortalPage";
import { UserContextComponent } from "../components/UserContextComponent";
import { ApplicantMobileMenu } from "./ApplicantMobileMenu";

interface IApplicantPageProps {
    applicantNo: number;
    title: string;
    subTitle: any;
    titleCta?: any;
    next(): void;
    prev(): void;
    contact: any;
    handleSubmit(event: any): void;
    emptyOnlyClick?(): void;
    showFinishButton: boolean;
    children: JSX.Element;
    percentComplete: number;
}

export class ApplicantPage extends UserContextComponent<IApplicantPageProps, any> {
    constructor(props: IApplicantPageProps) {
        super(props);
    }

    statusClass() {
        if (this.props.percentComplete === 0) return '';
        if (this.props.percentComplete >= 100) return 'complete'
        return 'inprogress';
    }

    render() {
        return (
            <>
                <PortalPage className={`bump-right ${this.statusClass()}`} title={this.props.title} mobileTitle={this.props.contact.firstName}>
                    <Row className=" max-w-wide">
                        <Col className="col-12">
                            <Form onSubmit={this.props.handleSubmit}>
                                <Row className={`sub-title-box ${this.props.titleCta ? 'pb-2' : ''}`}>
                                    <div className="subtext">{this.props.subTitle}</div>
                                </Row>
                                {this.props.titleCta ?? <></>}
                                {this.props.percentComplete !== 100 && this.props.emptyOnlyClick &&
                                <Row>
                                    <Col className="toggle-box">
                                        <Form.Check
                                            type="switch"
                                            id="empty-fields-switch"
                                            label="Incomplete fields only"
                                            reverse
                                            onClick={this.props.emptyOnlyClick} />
                                    </Col>
                                </Row>
                                }
                                {this.props.children}
                                <br />
                                <br />
                                <Container>
                                    <Stack direction="horizontal" gap={3} className="text-center justify-center">
                                        <ButtonGroup>
                                        <button type="button" className="btn btn-standard-transparent btn-outline btn-wide" onClick={this.props.prev}>Previous</button>
                                        {this.props.showFinishButton === false &&
                                        <button type="button" className="btn btn-dark btn-wide" onClick={this.props.next}>Next</button>
                                        }
                                        {this.props.showFinishButton === true &&
                                            <button type="button" className="btn btn-dark btn-wide" onClick={this.props.handleSubmit}>Finish</button>
                                        }
                                        </ButtonGroup>
                                    </Stack>
                                </Container>
                                <br />
                                <br />
                            </Form>
                        </Col>
                    </Row>
                </PortalPage>
            </>
        );
    }
}
