export enum ChatQuestionType {
    Statement = 755980000,
    Question = 755980001,
    Button = 755980002,
    DocumentCategory = 755980003,
    OpenBanking = 755980004,
    Upload = 755980005
}

export interface ChatQuestionModel {
    chatQuestionId: string;
    parentQuestionId: string | null;
    sequence: number;
    text: string;
    questionType: ChatQuestionType | null;
    documentCategoryId: string | null;
    documentCategory: string | null;
    helpText: string | null;
    mortgageDocumentId: string | null;
}