
import React from 'react';
import { Modal } from 'react-bootstrap';
import Draggable from 'react-draggable';
import "./DraggableModal.css"
 
const DraggableModal = ({ show, onHide, title, body, footer }) => {
    if (!show) return <></>;
    return (
        <Draggable handle={".modal-body"}>
            <div className='custom-modal'
                dialogClassName="draggable-modal">
                <div className='dragg-it'>
                    <Modal.Header>
                        <h3>{title}</h3>
                    </Modal.Header>
                    <Modal.Body>{body}</Modal.Body>
                    <Modal.Footer>{footer}</Modal.Footer>
                </div>
            </div>

        </Draggable>
    );
};
 
export default DraggableModal;