import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { PortalHelperTextModel } from "../../models/PortalHelperTextModel";
import { ControlType, Field } from "../../models/PortalMetadata";
import { Pill } from "./Pill";
import { IPillElementModel } from "./PillElement";

interface ILinkPillProps {
  field: Field;
  valueToDisplay?: string | null;
  overlay: React.ReactNode;
  hideIfValue: boolean | null;
  label?: string;
  tooltip?: PortalHelperTextModel | undefined | null;
  subText?: string;
  extraClass?: string;
  canSave?: boolean;
  modalSize?: "xl" | "lg" | "sm";
  saveOnDone?(redirect: any): void;
}

interface ILinkPillState {
  hideIfValue: boolean | null;
  collapsed: boolean;
  canSave: boolean;
}

export class LinkPill extends Component<ILinkPillProps, ILinkPillState> {
  constructor(props: ILinkPillProps) {
    super(props);
    this.state = {
      hideIfValue: false,
      collapsed: true,
      canSave: this.props.canSave === true || this.props.canSave === undefined,
    };
    this.collapse = this.collapse.bind(this);
  }

  componentDidUpdate() {
    if (this.state.hideIfValue !== this.props.hideIfValue)
      this.setState({ hideIfValue: this.props.hideIfValue });
    if (
      this.props.canSave !== undefined &&
      this.state.canSave !== this.props.canSave
    )
      this.setState({ canSave: this.props.canSave });
  }

  componentDidMount() {
    if (this.state.hideIfValue !== this.props.hideIfValue)
      this.setState({ hideIfValue: this.props.hideIfValue });
  }

  private collapse() {
    if (this.props.saveOnDone) {
      this.props.saveOnDone(false);
    }
    this.setState({ collapsed: true });
  }

  getPillClass() {
    return "notstarted";
  }

  render() {
    if (
      this.state.hideIfValue &&
      (this.props.field.value || this.props.field.optional)
    )
      return "";

    var components: IPillElementModel[] = [];
    components.push({
      leftBorder: true,
      rightBorder: true,
      content: <div>{this.props.field.label}</div>,
    });

    components.push({
      leftBorder: false,
      rightBorder: true,
      content: (
        <>
          {this.props.valueToDisplay ??
            (this.props.field.controlType === ControlType.date
              ? ""
              : this.props.field.value)}
        </>
      ),
    });

    let extraClass: string = !(
      this.props.valueToDisplay ??
      (this.props.field.controlType === ControlType.date
        ? ""
        : this.props.field.value)
    )
      ? "pill-missing"
      : "";
    if (this.props.extraClass) extraClass = this.props.extraClass;

    return (
      <>
        <Pill
          extraClass={extraClass}
          onClick={() => this.setState({ collapsed: false })}
          components={components}
          tooltip={this.props.tooltip ?? undefined}
        ></Pill>
        <Modal
          fullscreen="lg-down"
          size={this.props.modalSize ?? "xl"}
          show={!this.state.collapsed}
          onHide={() => {
            this.collapse();
          }}
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h3 className="impact text-center">
              {this.props.label ?? this.props.field.label}
            </h3>
            {this.props.overlay}
          </Modal.Body>
          <Modal.Footer>
            <button
              disabled={!this.state.canSave}
              className="btn btn-standard btn-wide"
              onClick={() => {
                this.collapse();
              }}
            >
              Done
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
