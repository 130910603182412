import { FieldsCollection } from './FieldsCollection';

export class SolicitorFieldsCollection extends FieldsCollection {
    constructor() {
        super();
        this.addTextField("fullName", "Solicitor Contact Name", 200);
        this.addTextField("mobile", "Solicitor Phone", 50);
        this.addTextField("emailAddress2", "Solicitor E-mail", 50);
        this.addTextField("address1Line1", "Solicitor Address", 250);
        this.addTextField("companyName", "Solicitor Name");
    }
}