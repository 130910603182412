import { PortalClarificationModel } from "./PortalClarificationModel";
import { PortalSpecialConditionsModel } from "./PortalSpecialConditionsModel";

export enum AIPStatus
{ 
    Submitted = 1,
    Clarifications = 2,
    Approved = 3,
    Expired = 4,
    Withdrawn = 5,
    New = 6,
    Rejected = 7,
}

export enum AIPDDLStatus
{
    ApprovedByLender = 755980000,
    RejectedByLender = 755980001,
    Withdrawn = 755980002,
    Proceeding = 755980003,
    Expired = 755980004,
    Clarification = 755980007,
    Submitted = 755980005,
    New = 755980006
}

export enum AIPType
{
    FullApplication = 755980000,
    HouseHunter = 755980001,
}

export interface PortalAIPApplicationModel {
    aipApplicationId: string;
    submissionDate: string;
    responseDate: string;
    amount: string;
    expirationDate: string;
    approvalDate: string;
    estResponseDate: string;
    lender: string;
    lenderLogo: string | null;
    lenderChoice: string;
    sla?: number;
    isFileAvailable: boolean;
    isValuationAvailable: boolean;
    status: number;
    specialConditions: PortalSpecialConditionsModel[],
    clarifications: PortalClarificationModel[];
    specialOffers: string;
    confirmed: boolean;
    confirming: boolean;
    aipDdlStatus: number;
    order: number;
    valuationExpiryDate: string;
    type?: AIPType;
}