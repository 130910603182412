import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Container, Modal, Row, Stack } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { ApplicantPage } from "./ApplicantPage";
import { ApplicantService } from "./ApplicantService";
import { ApplicantBaseComponent } from "./ApplicantBaseComponent";
import {
  AggregatePill,
  AggregateStatus,
  AggregateType,
  IAggregateListItem,
  IAggregatePillProps,
} from "../components/Pills/AggregatePill";
import { getTooltip } from "../components/Tooltip";
import {
  HasAny,
  MortgageDocumentStatusCode,
  ApplicableTo,
  PortalMortgageDocumentModel,
  PortalDocumentType,
  TransactionSource,
} from "../models/PortalMortgageDocumentModel";
import { FinanceManager } from "../components/Finance/FinanceManager";
import UserContextState from "../components/UserContext";
import { MortgageSubType } from "../models/PortalMortgageModel";
import { Profile } from "./Profile";
import { OpenBanking } from "./OpenBanking";
import axios from "axios";
import { AIPType } from "../models/PortalAIPApplication";
import { FinancePill } from "../components/Pills/FinancePill";
import { DocumentManager } from "../components/DocumentManager";
import { PortalPage } from "../components/PortalPage";
import { OpenBankingBubbles } from "../components/OpenBankingBubbles";

export class ApplicantFinancial extends ApplicantBaseComponent {
  applicantService: ApplicantService;

  constructor(props: any) {
    super(props);
    this.prev = this.prev.bind(this);
    this.next = this.next.bind(this);
    this.getFilteredDocs = this.getFilteredDocs.bind(this);
    this.onDocumentDone = this.onDocumentDone.bind(this);
    this.waitForFiles = this.waitForFiles.bind(this);
    this.addOpenBankingChecker = this.addOpenBankingChecker.bind(this);
    this.openBankingChecker = this.openBankingChecker.bind(this);
    this.openBankingSuccess = this.openBankingSuccess.bind(this);
  }

  componentDidMount() {
    let context = this.getContext();
    this.applicantService = new ApplicantService(
      context,
      this,
      this.props.applicantNo
    );
    this.applicantService.getApplicantData();
    this.applicantService.getMortgageDocuments();
    this.applicantService.getDocumentTypes();
    this.applicantService.getFinanceTooltips();
    this.waitForFiles();
  }

  onDocumentDone(
    mortgageDocumentId: string,
    status: MortgageDocumentStatusCode
  ) {
    let context = this.getContext();
    var doc: PortalMortgageDocumentModel | undefined =
      context.mortgageDocumentList?.find(
        (i) => i.mortgageDocumentId === mortgageDocumentId
      );
    if (doc) {
      doc.statusReason = status;
      context.mortgage!.documentsPercentComplete =
        DocumentManager.calculatePercent(context, this.props.documents);
      this.setState({ refreshToggle: !this.state.refreshToggle });
    }
  }

  prev() {
    this.applicantService.saveAllFields(false);
    this.setState({ redirectTo: "/employment" + this.props.applicantNo });
  }

  next() {
    this.applicantService.saveAllFields(false);
    this.setState({ redirectTo: "/assetsandloans" + this.props.applicantNo });
  }

  hasActiveWithMissingData(
    key: string,
    activeDocuments: PortalMortgageDocumentModel[]
  ): boolean {
    if (key === "PROPERTYASSETS" || key === "OTHERASSETS") {
      return (
        activeDocuments.filter(
          (e) =>
            isNullOrEmpty(e.name) ||
            isNullOrEmpty(e.currentBalance) ||
            e.currentBalance === 0
        ).length > 0
      );
    } else if (key === "LOAN") {
      return (
        activeDocuments.filter(
          (e) =>
            isNullOrEmpty(e.currentBalance) ||
            isNullOrEmpty(e.institutionId) ||
            isNullOrEmpty(e.accountNumber) ||
            e.currentBalance === 0
        ).length > 0
      );
    } else if (key === "MORTGAGEACCOUNT") {
      return (
        activeDocuments.filter(
          (e) =>
            isNullOrEmpty(e.currentBalance) ||
            isNullOrEmpty(e.institutionId) ||
            isNullOrEmpty(e.accountNumber) ||
            e.currentBalance === 0
        ).length > 0
      );
    } else if (key === "STATEMENT") {
      return (
        activeDocuments.filter((e) => isNullOrEmpty(e.accountNumber)).length > 0
      );
    } else {
      return (
        activeDocuments.filter(
          (e) => isNullOrEmpty(e.currentBalance) || e.currentBalance === 0
        ).length > 0
      );
    }
  }

  hasMissingData(key: string, value: PortalMortgageDocumentModel[]): boolean {
    var activeDocuments = value.filter(
      (e) => e.statusReason !== MortgageDocumentStatusCode.Inactive
    );
    var inActiveDocuments = value.filter(
      (e) => e.statusReason === MortgageDocumentStatusCode.Inactive
    );
    let hasInactiveWithHasAnyEqualToNo =
      inActiveDocuments.filter((e) => e.hasAny === HasAny.No).length > 0;
    let activeWithMissingData = this.hasActiveWithMissingData(
      key,
      activeDocuments
    );

    if (activeWithMissingData === true) return true;

    if (activeDocuments.length > 0 || hasInactiveWithHasAnyEqualToNo)
      return false;

    return true;
  }

  getFilteredDocs(context?: UserContextState | null, category?: string) {
    if (!context) context = this.getContext();
    if (context.mortgageDocumentList != null) {
      let applicableTo =
        this.props.applicantNo === 1
          ? ApplicableTo.Primary
          : ApplicableTo.Secondary;
      let categories = ["STATEMENT", "SAVING"];
      if (category) {
        categories = [category];
      }
      let docs = context.mortgageDocumentList.filter(
        (c) =>
          categories.includes(c.categoryUniqueCode ?? "") &&
          (c.applicableTo === ApplicableTo.Shared ||
            c.applicableTo === applicableTo ||
            c.isJoint === true)
      );

      docs.sort((a: any, b: any) => {
        // We want to primarily sort by mortgage document id, but if these are null make sure they appear first
        // We next want to sort by the order property (if its set) as this allows to change the order before merging
        if (a.categoryOrder === b.categoryOrder) {
          // if we have 2 null mortgage documents, or 2 matching mortgage documents, sort by the order attribute
          if (!a.applicableTo && !b.applicableTo) return 1;
          if (a.applicableTo < b.applicableTo) return -1;
          if (a.applicableTo > b.applicableTo) return 1;
        }

        // Compare if the document needs to come before or after
        if (a.categoryOrder < b.categoryOrder) return -1;
        if (a.categoryOrder > b.categoryOrder) return 1;

        // If we get here, we've probably hit equal documents and orders...
        // shouldn't happen, but in case paul got at the code you never know
        return 0;
      });
      return docs;
    }
    return [];
  }

  addNewDocument(doc: any, label: string, documentCode: string) {
    let isNew = true;
    let doclist = this.getFilteredDocs() as any[];
    const context = this.getContext();
    if (doc === null) {
      //they said yes newly
      let inactiveDoc = doclist.find(
        (e) => e.categoryUniqueCode === documentCode
      );
      if (inactiveDoc) {
        doc = inactiveDoc;
        doc.hasAny = HasAny.Yes;
        doc.statusReason = MortgageDocumentStatusCode.Active;
        doc.autoOpen = true;
        isNew = false;
        this.getContext().updateDoc(doc);
      } else {
        doc = {
          document: documentCode,
          documentId: null, // let the API resolve this for us
          documentType: PortalDocumentType.Document,
          category: "Banking",
          categoryUniqueCode: documentCode,
          categoryOrder: 1,
        };
      }
    }
    if (isNew) {
      let id = this.applicantService.getApplicantId();
      let myId = Math.random().toString();
      doclist.push({
        name: "", //doc.name,
        document: doc.document,
        documentId: doc.documentId,
        documentType: doc.documentType,
        new: isNew,
        autoOpen: true,
        mortgageId: context.mortgage?.mortgageId,
        applicableTo:
          this.props.applicantNo === 1
            ? ApplicableTo.Primary
            : ApplicableTo.Secondary, //doclist[0].applicableTo,// changed this one for "is joint" too
        category: doc.category,
        categoryUniqueCode: doc.categoryUniqueCode,
        mortgageDocumentId: myId,
        currentBalance: 0,
        limit: 0,
        termLeft: 0,
        termPeriod: null,
        hasAny: HasAny.Yes,
        isJoint: false,
        contactId: id,
        statusReason: MortgageDocumentStatusCode.Active,
        categoryOrder: doc.categoryOrder,
        termRemaining: null,
        key: myId,
      });
      this.getContext().addDoc(doclist.at(doclist.length - 1));
    }
    this.setState({ refreshToggle: !this.state.refreshToggle });
  }

  getStatusText(doc: PortalMortgageDocumentModel) {
    var currentStatus = doc.statusReason ?? 0;
    if (currentStatus === MortgageDocumentStatusCode.Approved) {
      return "Statements Complete";
    } else if (doc.statusReason === MortgageDocumentStatusCode.Uploaded) {
      return "Statements Uploaded";
    } else if (doc.statusReason === MortgageDocumentStatusCode.InProgress) {
      return "Statements Needed";
    } else if (doc.statusReason === MortgageDocumentStatusCode.NeedsAction) {
      return "Needs Action";
    }
    return "Waiting";
  }

  refreshMortgageDocuments() {
    let context = this.getContext();
    let url = `/MortgageDocuments/GetAllDocuments?mortgageId=${
      context.mortgage!.mortgageId
    }`;
    return axios.get(url).then((mortgageDocumentsResponse) => {
      context.mortgageDocumentList = mortgageDocumentsResponse.data;
      this.setState({
        refreshToggle: !this.state.refreshToggle,
        openBankingChecker: false,
      });
    });
  }

  private waitForFiles(): void {
    const context = this.getContext();
    if (!context.files || context.loadingFiles == true) {
      if (!this.state.loading) {
        this.setState({ loading: true });
      }
      setTimeout(this.waitForFiles, 1000);
      return;
    }
    this.setState({ loading: false, files: context.files });
  }

  addOpenBankingChecker(requestId?: string) {
    this.setState({ openBankingChecker: true });
    // Give the first check about 5 seconds to run...
    setTimeout(() => {
      this.openBankingChecker(requestId);
    }, 5000);
  }

  openBankingChecker(requestId?: string) {
    const context = this.getContext();
    let applicableTo =
      this.props.applicantNo === 1
        ? ApplicableTo.Primary
        : ApplicableTo.Secondary;
    axios
      .get("/OpenBanking/Processing?requestId=" + requestId)
      .then((response) => {
        var processing = response.data;
        if (processing) {
          setTimeout(() => {
            this.openBankingChecker(requestId);
          }, 1000);
        } else {
          this.refreshMortgageDocuments();
        }
      })
      .catch((error) => {
        this.setState({ openBankingChecker: false });
      });
  }

  openBankingSuccess(mortgageDocumentId?: string, requestId?: string) {
    this.addOpenBankingChecker(requestId);
    this.setState({ bankingRender: "openbanking" });
  }

  renderInitial() {
    let context = this.getContext();
    return (
      <>
        <div className="boxty short wide box-teal-outline mb-3 mt-3">
          <div style={{ marginTop: -45 }}>
            <OpenBankingBubbles />
          </div>
          <Stack
            direction="horizontal"
            gap={3}
            className="justify-center mt-4 mb-4"
          >
            <div className="aib-tile" />
            <div className="boi-tile" />
            <div className="n26-tile" />
            <div className="ptsb-tile" />
            <div className="revolut-tile" />
          </Stack>

          <Row className="mb-4">
            <h3>Access your statements securely with Open Banking</h3>
            <p>
              Connect with your bank securely, automatically pulling in all
              required statements and dates into your doddl account -
              eliminating the need to download and upload files separately.
            </p>
          </Row>
          <Row className="mb-5">
            <Stack
              direction="horizontal"
              className="justify-center desktop-only"
              gap={4}
            >
              <div>
                <button
                  type="button"
                  className="btn btn-dark xl nowrap"
                  onClick={() => this.setState({ bankingRender: "manual" })}
                >
                  Manually Upload Statements
                </button>
              </div>
              <div>
                <OpenBanking
                  className="btn xl btn-secondary bold nowrap"
                  label={
                    <>
                      <strong>Access Open</strong> Banking
                    </>
                  }
                  applicableTo={
                    this.props.applicantNo === 1
                      ? ApplicableTo.Primary
                      : ApplicableTo.Secondary
                  }
                  onSuccess={this.openBankingSuccess}
                />
              </div>
            </Stack>
            <Stack direction="vertical" className="justify-center mobile-only">
              <button
                type="button"
                className="btn btn-dark xl nowrap mb-3"
                onClick={() => this.setState({ bankingRender: "manual" })}
              >
                Manually Upload Statements
              </button>
              <OpenBanking
                className="btn xl btn-secondary bold nowrap"
                label={
                  <>
                    <strong>Access Open</strong> Banking
                  </>
                }
                applicableTo={
                  this.props.applicantNo === 1
                    ? ApplicableTo.Primary
                    : ApplicableTo.Secondary
                }
                onSuccess={this.openBankingSuccess}
              />
            </Stack>
          </Row>
        </div>
        <div style={{ marginTop: -40 }}>
          <a className="btn btn-light bigger" href="#ob-benefits">
            Benefits of Open Banking
          </a>
        </div>
        <div id="ob-benefits" className="mt-5">
          <h2 className="light mb-4">
            The easiest & fastest way to securely upload your account statements
            into your mortgage account.
          </h2>
          <p className="mt-3 l">
            No more downloading, uploading or chasing missing statements.
          </p>
          <div className="desktop-only">
            <Stack className="justify-center mt-5">
              <div className="ob-step2" />
              <div className="ob-step1" />
              <div className="ob-step3" />
            </Stack>
            <Stack direction="horizontal" className="justify-center mt-5">
              <div className="ob-step1-text">
                <h1>Secure</h1>
                <p className="nowrap">
                  Securely login with your existing
                  <br />
                  online banking credentials
                </p>
              </div>
              <div className="ob-step2-text">
                <h1>Easy</h1>
                <p className="nowrap">
                  Choose which accounts you’d
                  <br />
                  like to provide statements from.
                </p>
              </div>
              <div className="ob-step3-text">
                <h1>Faster</h1>
                <p className="nowrap">
                  We’ll receive the full 6 months of
                  <br />
                  statements needed.
                </p>
              </div>
            </Stack>
            <Row className="mt-5 mb-5 pb-5">
              <Stack direction="horizontal" className="justify-center" gap={4}>
                <div>
                  <button
                    type="button"
                    className="btn btn-light xl"
                    onClick={() => this.setState({ bankingRender: "manual" })}
                  >
                    Upload Statements Manually
                  </button>
                </div>
                <div>
                  <OpenBanking
                    className="btn xl btn-secondary bold"
                    label="Access Open Banking"
                    applicableTo={
                      this.props.applicantNo === 1
                        ? ApplicableTo.Primary
                        : ApplicableTo.Secondary
                    }
                    onSuccess={this.openBankingSuccess}
                  />
                </div>
              </Stack>
            </Row>
          </div>
          <div className="mt-5 mobile-only">
            <Stack className="justify-center mt-5">
              <div className="ob-step1" />
              <div className="ob-step1-text">
                <h1>Secure</h1>
                <p className="nowrap">
                  Securely login with your existing
                  <br />
                  online banking credentials
                </p>
              </div>
              <div className="ob-step2 mt-2" />
              <div className="ob-step2-text">
                <h1>Easy</h1>
                <p className="nowrap">
                  Choose which accounts you’d
                  <br />
                  like to provide statements from.
                </p>
              </div>
              <div className="ob-step3" />
              <div className="ob-step3-text">
                <h1>Faster</h1>
                <p className="nowrap">
                  We’ll receive the full 6 months of
                  <br />
                  statements needed.
                </p>
              </div>
            </Stack>
            <Row className="mt-5 mb-5 pb-5">
              <Stack direction="vertical" className="justify-center">
                <button
                  type="button"
                  className="btn btn-light xl mb-3"
                  onClick={() => this.setState({ bankingRender: "manual" })}
                >
                  Upload Statements Manually
                </button>
                <OpenBanking
                  className="btn xl btn-secondary bold"
                  label="Access Open Banking"
                  applicableTo={
                    this.props.applicantNo === 1
                      ? ApplicableTo.Primary
                      : ApplicableTo.Secondary
                  }
                  onSuccess={this.openBankingSuccess}
                />
              </Stack>
            </Row>
          </div>
        </div>
      </>
    );
  }

  renderPills(initialRenderState?: "openbanking" | "manual" | undefined) {
    let context = this.getContext();
    let currentAccountDocs = this.getFilteredDocs(context, "STATEMENT").filter(
      (e) => e.statusReason !== MortgageDocumentStatusCode.Inactive
    );
    let savingsAccountDocs = this.getFilteredDocs(context, "SAVING").filter(
      (e) => e.statusReason !== MortgageDocumentStatusCode.Inactive
    );
    var openBankingDocs =
      currentAccountDocs.filter(
        (x) => x.transactionSource === TransactionSource.OpenBanking
      ).length +
      savingsAccountDocs.filter(
        (x) => x.transactionSource === TransactionSource.OpenBanking
      ).length;
    var renderState: "openbanking" | "manual" =
      initialRenderState ??
      this.state.bankingRender ??
      (openBankingDocs > 0 ? "openbanking" : "manual");

    return (
      <Container className="pill-form justify-center ps-2">
        <>
          <Container>
            <Row className="pill-row">
              <Col lg={12} xl={6} className="ps-1">
                <Stack gap={2} className="justify-center">
                  <p className="impact">Current Accounts</p>
                  {currentAccountDocs.map((item: any, idx) => (
                    <FinancePill
                      document={item}
                      key={`financepill-current-${
                        item.key ?? item.mortgageDocumentId
                      }`}
                      files={this.state.files.filter(
                        (f) => f.mortgageDocumentId === item.mortgageDocumentId
                      )}
                      contactId={this.applicantService.getApplicantId()}
                      applicantNo={this.props.applicantNo}
                      onDone={this.onDocumentDone}
                      mortgageId={context.mortgage?.mortgageId}
                      helperTexts={this.state.dochelperTexts}
                      setContact={(contact) => {
                        this.setState({ contact: contact });
                      }}
                      refreshParent={this.refreshMortgageDocuments}
                    />
                  ))}
                  {this.state.openBankingChecker && (
                    <Container className={`finance-pill-container w-360`}>
                      <div className={`finance-pill w-360 nowrap`}>
                        <Row>
                          <Col xs={9} className="text-start pt-col">
                            <div>
                              <p className="s">
                                Adding institution, one moment please
                              </p>
                            </div>
                          </Col>
                          <Col xs={3} className="pt-col">
                            <div style={{ marginTop: -4 }}>
                              <Row>
                                <i className="far fa-clock pill-big inprogress"></i>
                              </Row>
                              <Row>
                                <p className="tiny pt-1">CONNECTING</p>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Container>
                  )}
                  {renderState === "manual" && (
                    <button
                      key="add-ca-button"
                      type="button"
                      className="btn big btn-light w-360 xl rad-s"
                      onClick={() => {
                        this.addNewDocument(
                          currentAccountDocs.length == 0
                            ? null
                            : currentAccountDocs[0],
                          "Current",
                          "STATEMENT"
                        );
                      }}
                    >
                      <span className=" float-left">
                        <i className="fas fa-plus-circle fa-xl" />
                      </span>{" "}
                      Add a Current Account
                    </button>
                  )}
                </Stack>
              </Col>
              {/*{!(context.mortgage?.approvalType === AIPType.HouseHunter) && (*/}
                <Col lg={12} xl={6} className="lg-margin-top-2 ps-1">
                  <Stack gap={2} className="justify-center">
                    <p className="impact">Savings Accounts</p>
                    {savingsAccountDocs.map((item: any, idx) => (
                      <FinancePill
                        document={item}
                        key={`financepill-savings-${
                          item.key ?? item.mortgageDocumentId
                        }`}
                        files={this.state.files.filter(
                          (f) =>
                            f.mortgageDocumentId === item.mortgageDocumentId
                        )}
                        contactId={this.applicantService.getApplicantId()}
                        applicantNo={this.props.applicantNo}
                        onDone={this.onDocumentDone}
                        mortgageId={context.mortgage?.mortgageId}
                        helperTexts={this.state.dochelperTexts}
                        setContact={(contact) => {
                          this.setState({ contact: contact });
                        }}
                        refreshParent={this.refreshMortgageDocuments}
                      />
                    ))}
                    {renderState === "manual" && (
                      <button
                        key="add-sa-button"
                        type="button"
                        className="btn big btn-light w-360 xl rad-s"
                        onClick={() => {
                          this.addNewDocument(
                            savingsAccountDocs.length == 0
                              ? null
                              : savingsAccountDocs[0],
                            "Savings",
                            "SAVING"
                          );
                        }}
                      >
                        <span className=" float-left">
                          <i className="fas fa-plus-circle fa-xl" />
                        </span>{" "}
                        Add a Savings Account
                      </button>
                    )}
                  </Stack>
                </Col>
              {/*)}*/}
            </Row>
            {renderState === "openbanking" && (
              <Stack className="justify-center mt-5">
                <OpenBanking
                  className="btn xl btn-secondary w-360 rad-s"
                  label={
                    <>
                      <OpenBankingBubbles
                        size="s"
                        className="float-left ms-3"
                      />{" "}
                      <strong>Add another</strong> account
                    </>
                  }
                  applicableTo={
                    this.props.applicantNo === 1
                      ? ApplicableTo.Primary
                      : ApplicableTo.Secondary
                  }
                  onSuccess={(mDocId?: string, requestId?: string) => {
                    this.addOpenBankingChecker(requestId);
                  }}
                />
              </Stack>
            )}
          </Container>
          <Container className="pt-5">
            <Row className="mt-5 mb-4">
              {renderState === "manual" ? (
                <>
                  {context.settings?.openBanking !== "disabled" &&
                    (context.mortgage?.mortgageAmountProposed ?? 0) >=
                      (context.settings?.openBankingLowerAmount ?? 0) &&
                    (context.mortgage?.mortgageAmountProposed ?? 0) <=
                      (context.settings?.openBankingUpperAmount ?? 0) &&
                    context.mortgage?.approvalType === AIPType.HouseHunter && (
                      <>
                        <p>Switch to Open Banking</p>
                        <Stack className="justify-center">
                          <button
                            type="button"
                            className="btn btn-light bigger rad-s"
                            onClick={() => {
                              this.setState({ bankingRender: "openbanking" });
                            }}
                          >
                            Open Banking
                          </button>
                        </Stack>
                      </>
                    )}
                </>
              ) : (
                <>
                  <p>
                    If you could not retrieve an account via Open
                    <br />
                    Banking, please add the account manually.
                  </p>
                  <Stack className="justify-center">
                    <button
                      type="button"
                      className="btn btn-light bigger rad-s"
                      onClick={() => this.setState({ bankingRender: "manual" })}
                    >
                      Add Manually
                    </button>
                  </Stack>
                </>
              )}
            </Row>
          </Container>
        </>
      </Container>
    );
  }

  render() {
    let context = this.getContext();

    if (this.state.redirectTo) {
      return <Navigate to={this.state.redirectTo} />;
    }

    if (!this.state.files || context.loadingFiles) {
      this.waitForFiles();
      return <span>Loading financial details...</span>;
    }

    if (this.state.loading) {
      return <span>Loading financial details...</span>;
    }

    if (!this.state.contact) {
      return <span>Applicant information not found</span>;
    }

    var noDocs =
      this.getFilteredDocs(context).filter(
        (e) => e.statusReason !== MortgageDocumentStatusCode.Inactive
      ).length === 0;
    var title = this.state.contact.firstName + "'s Bank Details";
    var subTitle = (
      <>
        This bit is important! Please set out all current and savings accounts you hold, this includes Revolut, N26 and all other digital
        banking apps
        {/* {" "}
        {!(context.mortgage?.approvalType === AIPType.HouseHunter) &&
          "and savings"}{" "} */}        
      </>
    );
    var content = <></>;

    // Decide what content we want to display. This will
    if (
      context.settings?.openBanking === "disabled" ||
      context.mortgage?.approvalType !== AIPType.HouseHunter ||
      this.state.bankingRender === "manual" ||
      !context.mortgage?.mortgageAmountProposed ||
      (context.mortgage?.mortgageAmountProposed ?? 0) <
        (context.settings?.openBankingLowerAmount ?? 0) ||
      (context.mortgage?.mortgageAmountProposed ?? 0) >
        (context.settings?.openBankingUpperAmount ?? 0)
    ) {
      content = this.renderPills("manual");
    } else if (noDocs && this.state.bankingRender !== "openbanking") {
      // If we're rendering initial then we don't want previous and next buttons. Just return the standard portal page
      // Note that the only time we might have nodocs in this scenario is if they've activated open banking
      content = this.renderInitial();
      return (
        <PortalPage
          applicantNo={this.state.applicantNo}
          title={title}
          description={subTitle}
          next={this.next}
          prev={this.prev}
          contact={this.state.contact}
          handleSubmit={this.applicantService.handleSubmit}
          showFinishButton={false}
          percentComplete={0}
        >
          <>
            <br />
            {content}
          </>
        </PortalPage>
      );
    } else {
      content = this.renderPills();

      // Decide if we should default to OB mode or Manual mode
      // var openBankingDocs = currentAccountDocs.filter(x => x.transactionSource === TransactionSource.OpenBanking).length + savingsAccountDocs.filter(x => x.transactionSource === TransactionSource.OpenBanking).length;
      // if (openBankingDocs > 0 || this.state.bankingRender === 'openbanking') {
      //     content = this.renderOpenBanking(currentAccountDocs, savingsAccountDocs);
      // } else {
      //     content = this.renderManual(currentAccountDocs, savingsAccountDocs);
      // }
    }

    return (
      <>
        <ApplicantPage
          applicantNo={this.state.applicantNo}
          title={title}
          subTitle={subTitle}
          next={this.next}
          prev={this.prev}
          contact={this.state.contact}
          handleSubmit={this.applicantService.handleSubmit}
          showFinishButton={false}
          percentComplete={0}
        >
          <>{content}</>
        </ApplicantPage>
      </>
    );
  }
}

function isNullOrEmpty(value: any): boolean {
  return value === undefined || value === null || value === "";
}
