import { Component } from "react";
import UserContextState, { UserContext } from '../UserContext';
import { FinanceFieldsCollection } from "../../forms/FinanceFields";
import { Col, Container, Form, Row } from "react-bootstrap";
import * as Icons from "react-bootstrap";
import { FormControl } from "../FormControl";
import { ControlType, FormLookupItem } from "../../models/PortalMetadata";
import { FieldsCollection } from "../../forms/FieldsCollection";
import "./Finance.css"
import currencyFormatter from "../../helper/currencyFormatter";
import { LoanType } from "../../models/PortalMortgageDocumentModel";
import { MortgageType } from "../../models/PortalMortgageModel";
import { addressAutocomplete, addressRetrieve } from "../Data8AutoComplete";
import { Address } from "../Address";

export interface IFinanceProps {
    className?: string;
    item: any,
    institutionList: FormLookupItem[],
    helperTexts: any[],
    checkAccountNumber: any,
    isDirty?(value: boolean): void,
}
interface IFinanceState {
    fields: FieldsCollection;
    helperTexts: [] | null;
    loading: boolean;
    institutionList: any[];
    selectedPrediction: string;
    predictions: any[];
    searchValue?: string | null;
    AutoComplete: boolean;
    invalidAddress: boolean;
    sessionId: string;
}

export class Finance extends Component<IFinanceProps, IFinanceState> {
    static contextType = UserContext;

    constructor(props: any) {
        super(props);
        this.state = {
            fields: new FieldsCollection(),
            helperTexts: null,
            loading: true,
            institutionList: [],
            selectedPrediction: "",
            predictions: [],
            AutoComplete: true,
            invalidAddress: false,
            sessionId: "",
        };
        this.handleChange = this.handleChange.bind(this);
        this.updateByName = this.updateByName.bind(this);
    }

    componentDidMount() {

        let fields = new FinanceFieldsCollection();
        fields.addLookupValues("institutionId", this.props.institutionList);
        fields.loadData(this.props.item, this.props.helperTexts, true, this.handleChange);
        this.props.item.autoOpen = false;
        this.setState({ fields: fields, loading: false });
    }

    handleChange(event: any) {
        const target = event.target;
        let value = (target.type === 'checkbox' ? target.checked : target.value) || (target.textContent || '');
        const name = target.name || target.getAttribute('name');

        if (!name)
            return;
        this.updateByName(name, value);
        if (this.props.isDirty) {
            this.props.isDirty(this.state.fields.fields.filter(f => f.isDirty).length > 0);
        }
    }

    updateByName(name: string, value: any, data?: any) {
        let f = this.state.fields.getFieldByName(name);
        if (name === "accountNumber") {
            value = value.replace(/\D/g, '');
        }
        if (f.controlType === ControlType.lookup && value === "-") {
            value = null;
        }
        //// if we don't set the value here it sometimes doesn't get set and causes a bug with reverting to the original value
        let fields = this.state.fields;
        if (fields) {            
            if (f.controlType == ControlType.currency) {
                var v = value?.replaceAll(",", "").replaceAll("€", "").replaceAll(" ", "");
                if (v === "" || isNaN(v)) {
                    v = null;
                }
                fields.updateModel(this.props.item, name, v);
                fields.updateFieldValue(name, currencyFormatter(Number(v)));
            } else if (f.name === 'institutionId') {
                fields.updateModel(this.props.item, name, value);
                fields.updateFieldValue(name, value);
                fields.updateFieldValue('institution', data);
            } else {
                fields.updateModel(this.props.item, name, value);
                fields.updateFieldValue(name, value);
            }

            // This is a bandaid to fix how the insitution lookups don't pass back the name

        }
        this.setState({ fields: this.state.fields });
    }


    renderAsset() {

    }

    render() {
        var context = this.context as UserContextState;

        if (this.state.fields === null || this.state.loading === true)
            return <div><Icons.Spinner animation="border" role="status" /></div>;

        let institutionId = this.state.fields.getFieldByName("institutionId");
        var other = institutionId.lookupValues?.find(x => x.text === "Other");
        let isOtherInstitutionSelected = this.props.item.institutionId === other?.value;

        let isAsset = this.props.item.categoryUniqueCode === "PROPERTYASSETS" || this.props.item.categoryUniqueCode === "OTHERASSETS";

        let switching = context.mortgage?.type === MortgageType.RemortgagingExistingLoan;
        let sellBeforePurchasing = this.props.item.sellBeforePurchasing;

        if (isAsset) {
            return (
                <div key={this.props.item}>
                    <Container className={`finance-container`}>
                        <Row>
                            <Col>
                            {this.props.item.categoryUniqueCode === "OTHERASSETS" &&
                                <FormControl light alignLeft isRequired={true} field={this.state.fields.getFieldByName("name")} customLabel="Name" ></FormControl>
                            }
                            {this.props.item.categoryUniqueCode !== "OTHERASSETS" &&
                                <Address helperTexts={this.state.helperTexts??[]} record={this.props.item} fields={this.state.fields} handleChange={this.handleChange} address="name" letManual={false} />
                            }
                            </Col>
                            <Col>
                                <FormControl light alignLeft field={this.state.fields.getFieldByName("currentBalance")} customLabel="Value" ></FormControl>
                            </Col>
                        </Row>
                        <Row>
                            {switching !== true &&
                                <Col>
                                    <FormControl light alignLeft field={this.state.fields.getFieldByName("sellBeforePurchasing")}></FormControl>
                                </Col>
                            }
                            {(switching === true || sellBeforePurchasing !== true) &&
                                <Col>
                                    <FormControl light alignLeft field={this.state.fields.getFieldByName("recurringYearlyValue")} customLabel="Annual rental income(market rent if not yet rented)"></FormControl>
                                </Col>
                            }
                        </Row>
                        <Row>
                            {this.props.item.categoryUniqueCode === "PROPERTYASSETS" &&
                                <Col>
                                    <FormControl light alignLeft field={this.state.fields.getFieldByName("mortgageBalanceOutstanding")}></FormControl>
                                </Col>
                            }
                            <Col>
                                <FormControl light alignLeft field={this.state.fields.getFieldByName("isJoint")}></FormControl>
                            </Col>
                        </Row>
                    </Container>
                </div>
            );
        }

        if (this.state.fields.getFieldByName("termLeft").value == "")
            this.state.fields.getFieldByName("termLeft").value = 20;

        let isLoan = this.props.item.categoryUniqueCode === "LOAN" || this.props.item.categoryUniqueCode === "MORTGAGEACCOUNT";
        if (isLoan) {

            //let isOverdraft = this.props.item.loanType === LoanType.Overdraft;
            return (
                <div key={this.props.item}>
                    <Container className="finance-container">
                        <Row>
                            <Col md={isOtherInstitutionSelected ? 3 : 6}>
                                <FormControl light alignLeft isRequired={true} icon="fas fa-building-columns" field={this.state.fields.getFieldByName("institutionId")} ></FormControl>
                            </Col>
                            {isOtherInstitutionSelected === true &&
                                <Col>
                                    <FormControl light alignLeft isRequired={true} field={this.state.fields.getFieldByName("otherInstitutionName")} ></FormControl>
                                </Col>
                            }
                            {this.props.item.categoryUniqueCode === "LOAN" &&
                                <Col>
                                    <FormControl light alignLeft field={this.state.fields.getFieldByName("loanType")} isRequired={true}></FormControl>
                                </Col>
                            }
                            <Col>
                                <FormControl light alignLeft icon="fa-regular fa-credit-card" field={this.state.fields.getFieldByName("accountNumber")} isInvalid={this.props.checkAccountNumber} invalidText="Needs to be 4 digits!" ></FormControl>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <FormControl light alignLeft isRequired={true} field={this.state.fields.getFieldByName("currentBalance")}></FormControl>
                            </Col>
                            <Col>
                                <FormControl light alignLeft field={this.state.fields.getFieldByName("planOnClearingTheBalance")} ></FormControl>
                            </Col>
                            <Col>
                                <FormControl light alignLeft field={this.state.fields.getFieldByName("isJoint")}  ></FormControl>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormControl light alignLeft field={this.state.fields.getFieldByName("recurringMonthlyValue")} customLabel="Monthly Payment"></FormControl>
                            </Col>

                            {this.props.item.categoryUniqueCode === "MORTGAGEACCOUNT" &&
                                <Col>
                                    <FormControl light field={this.state.fields.getFieldByName("termLeft")} ></FormControl>
                                </Col>
                            }
                            {this.props.item.categoryUniqueCode === "LOAN" &&
                                <Col>
                                    <FormControl light alignLeft field={this.state.fields.getFieldByName("termRemaining")} ></FormControl>
                                </Col>}
                        </Row>

                        {/* <Row>
                            <Col>
                                <PlusMinusBox field={this.state.fields.getFieldByName("termLeft")}></PlusMinusBox>
                            </Col>
                        </Row> */}
                    </Container>
                </div>
            );
        }

        let isStock = this.props.item.categoryUniqueCode === "STOCK";
        if (isStock) {
            return (
                <div key={this.props.item}>
                    <Container className="finance-container">
                        <Row>
                            <Col>
                                <FormControl light alignLeft icon="fas fa-building-columns" isRequired={true} field={this.state.fields.getFieldByName("otherInstitutionName")} customLabel={"Institution"} ></FormControl>
                            </Col>
                            <Col>
                                <FormControl light alignLeft field={this.state.fields.getFieldByName("currentBalance")} ></FormControl>
                            </Col>
                            <Col md={4} sm={12}>
                                <FormControl light alignLeft field={this.state.fields.getFieldByName("usedForDeposit")} ></FormControl>
                            </Col>
                        </Row>
                    </Container>
                </div>
            );
        }

        let isCreditCard = this.props.item.categoryUniqueCode === "CREDITCARD";
        if (isCreditCard) {
            return (
                <div key={this.props.item}>
                <Container className="finance-container">
                    <Row>
                        <>
                            <Col md={isOtherInstitutionSelected ? 3 : 6}>
                                <FormControl light alignLeft icon="fas fa-building-columns" isRequired={true} field={this.state.fields.getFieldByName("institutionId")} ></FormControl>
                            </Col>
                            {isOtherInstitutionSelected === true &&
                                <Col md={3}>
                                    <FormControl light alignLeft isRequired={true} field={this.state.fields.getFieldByName("otherInstitutionName")} ></FormControl>
                                </Col>
                            }
                        </>
                        <Col>
                            <FormControl light alignLeft isRequired={true} icon="fa-regular fa-credit-card" field={this.state.fields.getFieldByName("accountNumber")} isInvalid={this.props.checkAccountNumber} invalidText="Needs to be 4 digits!"></FormControl>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <FormControl light alignLeft field={this.state.fields.getFieldByName("limit")} customLabel="Credit Limit"></FormControl>
                        </Col>
                        <Col md={3}>
                            <FormControl light alignLeft field={this.state.fields.getFieldByName("currentBalance")} ></FormControl>
                        </Col>
                        <Col md={3}>
                            <FormControl light alignLeft field={this.state.fields.getFieldByName("planOnClearingTheBalance")} ></FormControl>
                        </Col>
                        <Col md={3}>
                            <FormControl light alignLeft field={this.state.fields.getFieldByName("isJoint")}></FormControl>
                        </Col>
                    </Row>
                </Container>
            </div>
            );
        }

        let isSavings = this.props.item.categoryUniqueCode === "SAVING";
        if (isSavings) {
            return (
                <div key={this.props.item}>
                    <Container className={`finance-container ${this.props.className??''}`}>
                        <Row>
                            <Col xs={12} md={isOtherInstitutionSelected ? 3 : 6}>
                                <FormControl light alignLeft icon="fas fa-building-columns" isRequired={true} field={this.state.fields.getFieldByName("institutionId")} ></FormControl>
                            </Col>
                            {isOtherInstitutionSelected === true &&
                                <Col xs={12} md={3}>
                                    <FormControl light alignLeft isRequired={true} field={this.state.fields.getFieldByName("otherInstitutionName")} ></FormControl>
                                </Col>
                            }
                            <Col xs={12} md={6}>
                                <FormControl light alignLeft isRequired={true} icon="fa-regular fa-credit-card" field={this.state.fields.getFieldByName("accountNumber")} isInvalid={this.props.checkAccountNumber} invalidText="Needs to be 4 digits!"></FormControl>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormControl light alignLeft field={this.state.fields.getFieldByName("currentBalance")} ></FormControl>
                            </Col>
                            <Col md={3}>
                                <FormControl light alignLeft field={this.state.fields.getFieldByName("recurringMonthlyValue")} customLabel="Monthly Savings Amount"></FormControl>
                            </Col>
                            <Col md={3}>
                                <FormControl light alignLeft field={this.state.fields.getFieldByName("isJoint")}></FormControl>
                            </Col>
                        </Row>
                    </Container>
                </div>
            );
        }

        let institutionAsTextField = this.props.item.categoryUniqueCode === "FOREIGNACCOUNT";
        return (
            <div key={this.props.item}>
                <Container className={`finance-container ${this.props.className??''}`}>
                    <Row>
                        {institutionAsTextField === true &&
                            <Col xs={12} md={"auto"}>
                                <FormControl light alignLeft icon="fas fa-building-columns" isRequired={true} field={this.state.fields.getFieldByName("otherInstitutionName")} customLabel={"Institution"} ></FormControl>
                            </Col>
                        }
                        {institutionAsTextField === false &&
                            <>
                                <Col xs={12} md={"auto"}>
                                    <FormControl light alignLeft icon="fas fa-building-columns" isRequired={true} field={this.state.fields.getFieldByName("institutionId")} ></FormControl>
                                </Col>
                                {isOtherInstitutionSelected === true &&
                                    <Col xs={12} md={"auto"}>
                                        <FormControl light alignLeft isRequired={true} field={this.state.fields.getFieldByName("otherInstitutionName")} ></FormControl>
                                    </Col>
                                }
                            </>
                        }
                        <Col xs={12} md={"auto"}>
                            <FormControl light alignLeft isRequired={true} icon="fa-regular fa-credit-card" field={this.state.fields.getFieldByName("accountNumber")} isInvalid={this.props.checkAccountNumber} invalidText="Needs to be 4 digits!"></FormControl>
                        </Col>
                        <Col xs={12} md={"auto"}>
                            <FormControl light alignLeft field={this.state.fields.getFieldByName("isJoint")}></FormControl>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}