import axios from "axios";
import React from "react";
import {
  ButtonGroup,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Stack,
} from "react-bootstrap";
import { PortalMortgageModel } from "../models/PortalMortgageModel";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navigate } from "react-router-dom";
import { PortalPage } from "../components/PortalPage";
import UserContextState, { UserContext } from "../components/UserContext";
import { PortalContactModel } from "../models/PortalContactModel";
import { SolicitorFieldsCollection } from "../forms/SolicitorFields";
import { GetTooltips } from "../helper/tooltipHelper";
import { InputPill } from "../components/Pills/InputPill";
import { appInsightsHelper } from "../components/AppInsights";
import { UserContextComponent } from "../components/UserContextComponent";
import * as Icons from "react-bootstrap";
import { LinkPill } from "../components/Pills/LinkPill";
import { Address } from "../components/Address";
import { logger } from "../helper/Logger";
import { ReactComment } from "../components/ReactComment";
import { InlineWidget } from "react-calendly";

interface ISolicitorProps {
  mortgage?: PortalMortgageModel | null;
  helperTexts?: any | null;
  next?(): void | null;
  prev?(): void | null;
  step?: string | null;
  steps?: string | null;
  bumpRight?: boolean | null;
}

interface ISolicitorState {
  mortgage: PortalMortgageModel | null;
  fields: any;
  loading: boolean;
  solicitor: PortalContactModel | null;
  redirectTo?: string | null;
  helperTexts: any;
  emptyOnly: boolean;
  saving: boolean;
  addressIsValid: boolean;
  useBeam: boolean | null;
  useOwnSolicitor?: boolean;
  bookACall: boolean;
}

export class SolicitorDetails extends UserContextComponent<
  ISolicitorProps,
  ISolicitorState
> {
  static contextType = UserContext;
  static displayName = SolicitorDetails.name;
  static beamEmail = "info@letsbeam.ie";

  constructor(props: any) {
    super(props);
    this.saveAllFields = this.saveAllFields.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.prev = this.prev.bind(this);
    this.saveAllFields = this.saveAllFields.bind(this);
    this.ownSolicitorPanel = this.ownSolicitorPanel.bind(this);
    this.useBeam = this.useBeam.bind(this);
    this.continueWithBeamPanel = this.continueWithBeamPanel.bind(this);
    this.letsBeamCalendlyPanel = this.letsBeamCalendlyPanel.bind(this);
    this.useOwnSolicitor = this.useOwnSolicitor.bind(this);

    this.state = {
      loading: true,
      fields: null,
      mortgage: null,
      solicitor: null,
      helperTexts: [],
      emptyOnly: false,
      saving: false,
      addressIsValid: true,
      useBeam: null,
      bookACall: false,
    };
  }

  getfields(solicitor: any, helperTexts: any) {
    if (solicitor !== null && helperTexts !== null) {
      let fields = new SolicitorFieldsCollection();
      fields.loadData(solicitor, helperTexts, true, this.handleChange);
      this.setState({
        fields: fields,
        loading: false,
        helperTexts: helperTexts,
      });
    }
    //this.setState({ fields: getSolicitorFields(solicitor, helperTexts, true, this.handleChange), loading: false });
  }

  componentDidMount() {
    appInsightsHelper.trackPageView({ name: "Solicitor Details" });
    let context = this.getContext();
    this.setState({
      mortgage: context.mortgage,
      solicitor: context.mortgage!.solicitor ?? ({} as PortalContactModel),
      loading: true,
    });
    this.getTooltips("contact");
  }

  getTooltips(entityname: string) {
    let context = this.getContext();
    GetTooltips(entityname)
      .then((response) => {
        this.setState({ helperTexts: response.data, loading: false });
        this.getfields(
          context.mortgage!.solicitor ?? ({} as PortalContactModel),
          response.data
        );
      })
      .catch((error) => {
        if (error.response.statusCode === 401) {
          alert("Unauthorized");
        }
      });
  }

  prev() {
    if (this.props.prev) {
      this.saveAllFields(null);
      if (this.props.step === "1") this.setState({ redirectTo: "/profile" });
      else this.props.prev();
    } else {
      this.saveAllFields("/property");
    }
  }

  handleSubmit(event: any) {
    event.preventDefault();
    this.setState({ saving: true });

    if (this.props.next) {
      this.saveAllFields(null);
      this.props.next();
    } else {
      this.saveAllFields("/");
    }
  }

  handleChange(event: any) {
    const target = event.target;
    const value =
      (target.type === "checkbox" ? target.checked : target.value) ||
      target.textContent ||
      "";
    const name = target.name || target.getAttribute("name");

    if (!name) return;

    var solicitor = this.state.solicitor as any;

    //// if we don't set the value here it sometimes doesn't get set and causes a bug with reverting to the original value
    let fields = this.state.fields;
    if (fields) {
      fields.updateModel(solicitor, name, value);
      fields.updateFieldValue(name, value);
    }
    this.setState({ fields: this.state.fields, solicitor: solicitor });
  }

  getFieldCount(): number {
    return 5;
  }
  getFieldsDone(): number {
    let fieldsDone = 0;
    if (this.state.fields.getFieldByName("fullName").value) fieldsDone++;
    if (this.state.fields.getFieldByName("emailAddress2").value) fieldsDone++;
    if (this.state.fields.getFieldByName("mobile").value) fieldsDone++;
    if (this.state.fields.getFieldByName("address1Line1").value) fieldsDone++;
    if (this.state.fields.getFieldByName("companyName").value) fieldsDone++;
    return fieldsDone;
  }

  saveAllFields(redirectTo: string | null) {
    var solicitor = this.state.solicitor as any;
    var fields = this.state.fields?.fields as any;

    let dirtyFields: any[] = [];
    for (let fieldName in this.state.fields.fields) {
      let f = this.state.fields.fields[fieldName];
      if (f.isDirty) dirtyFields.push(f);
    }

    if (
      dirtyFields.length === 0 ||
      !this.state.fields.getFieldByName("emailAddress2")
    ) {
      if (redirectTo) this.setState({ redirectTo: redirectTo });
      return;
    }

    // BANDAID - THIS IS TO KEEP THE APPLICANT MENU IN SYNC AS IT USES THE CONTEXT
    let context = this.getContext();
    context.mortgage!.solicitorDetailsPercentage =
      (this.getFieldsDone() * 100) / this.getFieldCount();

    var newContact: any = {};
    Object.keys(solicitor).map((key, i) => {
      Object.keys(fields).map((f, j) => {
        var f1 = fields[j];
        if (key === f1.name && f1.isDirty) {
          newContact[key] = f1.value;
          logger.log(f1.name);
        }
        //if (key === f1.name && !f1.isDirty) {
        //    delete mortgage[key];
        //}
      });
      if (Object.keys(solicitor).length - 1 === i) {
        if (solicitor.contactId) {
          newContact["contactId"] = solicitor.contactId;
          newContact["updatedFields"] = dirtyFields
            .map((item) => {
              return item.name;
            })
            .join(",");
          axios.post("/Solicitor/update", newContact).then((response) => {
            if (redirectTo) this.setState({ redirectTo: redirectTo });
          });
        } else {
          newContact.mortgageId = this.state.mortgage?.mortgageId;
          newContact.emailAddress =
            "C-" +
            Math.floor(100000 + Math.random() * 900000).toString() +
            newContact.emailAddress2;
          axios.post("/Solicitor/create", newContact).then((response) => {
            newContact.contactId = response.data;
            context.mortgage!.solicitor = newContact;
            if (redirectTo) this.setState({ redirectTo: redirectTo });
          });
        }
      }
    });
  }

  useBeam() {
    let context = this.getContext();
    var solicitor: any = {};
    solicitor.mortgageId = context.mortgage?.mortgageId;
    solicitor.emailAddress = SolicitorDetails.beamEmail;
    axios
      .post("/Solicitor/LetsBeam", solicitor, { params: { useLetsBeam: true } })
      .then((response) => {
        var solicitor = response.data;
        context.mortgage!.solicitor = solicitor;
        context.mortgage!.useLetsBeam = true;
        let fields = new SolicitorFieldsCollection();
        fields.loadData(
          solicitor,
          this.state.helperTexts,
          true,
          this.handleChange
        );
        this.setState({
          mortgage: context.mortgage,
          solicitor: solicitor,
          fields: fields,
          useBeam: true,
          useOwnSolicitor: false,
        });
      });
  }

  useOwnSolicitor() {
    let context = this.getContext();
    context.mortgage!.useLetsBeam = false;

    let solicitor = {} as PortalContactModel;
    let fields = new SolicitorFieldsCollection();
    fields.loadData(solicitor, this.state.helperTexts, true, this.handleChange);
    this.setState({
      useBeam: false,
      useOwnSolicitor: true,
      solicitor: solicitor,
      fields: fields,
      loading: false,
    });
  }

  decidePanel(mortgage: PortalMortgageModel | null) {
    if (mortgage === null) return;

    return (
      <>
        <Row className="sub-title-box">
          <p className="subtext px-3">
            Conveyancing is the legal transfer of a property from one owner to
            another. You need to find a qualified solicitor to help you with
            this part of your home purchase or mortgage switch.
          </p>
        </Row>
        {this.beamPanel(true)}
      </>
    );
  }

  beamPanel(showOwnSolicitorOption: boolean) {
    return (
      <Container className="pill-form">
        <Stack direction="horizontal" className="justify-center">
          <div className="boxty box-beam">
            <Row className="text-start beammargin">
              <Col xs={12} md={8}>
                <Row>
                  <div className="beamlogo" />
                </Row>
                {/* <p className="l">doddl has partnered with Beam to ease customers through the conveyancing process, faster. Beam has reduced the average completion time for our customers by to 50%. <a href="https://doddl.ie/mortgage-resources/property-conveyancing/" target="_blank">Learn more</a></p>
                                    <p className="pt-2 m italic">By clicking continue with Beam you are consenting for doddl to share basic contact and mortgage details with Beam</p> */}
                <p className="l">
                  doddl has partnered with digital first legal firm Beam to
                  offer a flat professional fee and service excellence to doddl
                  mortgage clients.
                </p>
                <p className="pt-2 s italic">
                  If you click Continue with Beam below, to enable Beam to
                  contact you we will share some information you have provided
                  with them including name, email and mobile number of
                  applicant(s). So that Beam can advise you appropriately, we
                  will also share your your status such as mortgage amount, type
                  and sale agreed status. This will help to fast track your
                  application!
                </p>

                <Stack
                  direction="horizontal"
                  gap={3}
                  className="pt-3 mb-3 desktop"
                >
                  <button
                    type="button"
                    className="btn btn-secondary big btn-wide200"
                    onClick={this.useBeam}
                  >
                    Continue with Beam
                  </button>
                  {showOwnSolicitorOption && (
                    <button
                      type="button"
                      className="btn btn-standard-transparent btn-outline big btn-wide200"
                      onClick={this.useOwnSolicitor}
                    >
                      Add my own solicitor
                    </button>
                  )}
                </Stack>
                <Stack gap={3} className="pt-3 mb-3 mobile">
                  <button
                    type="button"
                    className="btn btn-secondary big btn-wide200 mb-2"
                    onClick={this.useBeam}
                  >
                    Continue with Beam
                  </button>
                  {showOwnSolicitorOption && (
                    <button
                      type="button"
                      className="btn btn-standard-transparent btn-outline big btn-wide200"
                      onClick={this.useOwnSolicitor}
                    >
                      Add my own solicitor
                    </button>
                  )}
                </Stack>
              </Col>
              <Col className="px-0 py-0 mx-0 my-0 d-xs-none d-lg-block">
                <div className="beamhero"></div>
              </Col>
            </Row>
          </div>
        </Stack>
      </Container>
    );
  }

  continueWithBeamPanel(mortgage: PortalMortgageModel | null) {
    if (mortgage === null) return;

    return (
      <>
        <Row className="sub-title-box">
          <p className="subtext px-3">Let’s discuss your legal requirements.</p>
          <p className="subtext px-3">
            The legal team at Beam will talk you through all next steps and will
            provide you with a full quote so you can budget. Doddl have
            partnered with Beam as they are as committed as we are to supporting
            you throughout your mortgage journey.{" "}
            <a
              href="https://doddl.ie/mortgage-resources/property-conveyancing/"
              target="_blank"
            >
              Learn more
            </a>
          </p>
        </Row>
        <Container className="pill-form" style={{ minHeight: 100 }}>
          <Row className="pb-5">
            <Col>
              <button
                type="button"
                className="btn bigger btn-dark"
                onClick={() => this.setState({ bookACall: true })}
              >
                <i className="fa fa-calendar-plus pe-3"></i> Book your call with
                Beam
              </button>
            </Col>
          </Row>
          <Row className="pill-row">
            <Stack gap={1} direction="horizontal" className="pill-stack">
              <InputPill
                readonly={true}
                hideIfValue={this.state.emptyOnly}
                field={this.state.fields.getFieldByName("companyName")}
              ></InputPill>
              <InputPill
                readonly={true}
                hideIfValue={this.state.emptyOnly}
                field={this.state.fields.getFieldByName("emailAddress2")}
              ></InputPill>
              <InputPill
                readonly={true}
                hideIfValue={this.state.emptyOnly}
                field={this.state.fields.getFieldByName("mobile")}
              ></InputPill>
              <InputPill
                readonly={true}
                hideIfValue={this.state.emptyOnly}
                field={this.state.fields.getFieldByName("address1Line1")}
              ></InputPill>
            </Stack>
          </Row>
        </Container>
        <Container className="pb-5 mt-5">
          <Stack
            direction="horizontal"
            gap={3}
            className="text-center justify-center"
          >
            <ButtonGroup>
              <button
                type="button"
                className="btn btn-standard-transparent btn-outline btn-wide"
                onClick={this.prev}
              >
                Back
              </button>
              {this.state.saving && (
                <button type="button" className="btn btn-standard btn-wide">
                  <Icons.Spinner size="sm" animation="border" role="status" />
                </button>
              )}
              {!this.state.saving && (
                <button
                  type="button"
                  className="btn btn-dark btn-wide"
                  onClick={this.handleSubmit}
                >
                  Finish
                </button>
              )}
            </ButtonGroup>
          </Stack>
        </Container>
        <Container>
          <Row>
            <Col>
              <button
                type="button"
                className="btn btn-transparent pointer"
                onClick={this.useOwnSolicitor}
              >
                change solicitor details
              </button>
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  letsBeamCalendlyPanel(mortgage: PortalMortgageModel | null) {
    if (mortgage === null) return;

    let context = this.getContext();

    return (
      <>
        <Row className="sub-title-box">
          <p className="subtext px-3">Let’s discuss your legal requirements.</p>
          <p className="subtext px-3">
            Provide the team at Beam with the below information and schedule a
            call to discuss the legal process and your needs. The legal team at
            Beam will talk you through all next steps and will provide you with
            a full quote so you can budget. Doddl have partnered with Beam as
            they are as committed as we are to supporting you throughout your
            mortgage journey.{" "}
          </p>
        </Row>
        {/* <Row className="sub-title-box mobile">
                    <p className="subtext px-3">Let’s discuss your legal requirements.</p>
                    <p className="subtext px-3">Provide the team at Beam with the below information and schedule a call to discuss the legal process and your needs.</p>
                </Row> */}
        <Container className="pill-form">
          <Stack direction="horizontal" className="justify-center">
            <div className="calendly-container">
              <InlineWidget
                url="https://calendly.com/d/3tm-2pp-44h?utm_source=doddl&utm_medium=platform&utm_campaign=appoint_screen&utm_content=book&hide_gdpr_banner=1&background_color=f5f0ed&text_color=1a3a40&primary_color=9bc5bb"
                styles={{ width: "100%", height: "100%" }}
                prefill={{
                  firstName: context.contact?.firstName,
                  lastName: context.contact?.lastName,
                  email: context.contact?.emailAddress,
                }}
              />
            </div>
          </Stack>
        </Container>
      </>
    );
  }

  ownSolicitorPanel(mortgage: PortalMortgageModel | null) {
    if (mortgage === null) return;

    return (
      <>
        <Row className="sub-title-box">
          <p className="subtext px-3">
            If known please complete your solicitor details, if not we can add
            later.
          </p>
        </Row>
        <Container className="pill-form" style={{ minHeight: 100 }}>
          <Row className="pill-row">
            <Stack gap={1} direction="horizontal" className="pill-stack">
              <InputPill
                hideIfValue={this.state.emptyOnly}
                field={this.state.fields.getFieldByName("companyName")}
              ></InputPill>
              <InputPill
                hideIfValue={this.state.emptyOnly}
                field={this.state.fields.getFieldByName("fullName")}
              ></InputPill>
              <InputPill
                hideIfValue={this.state.emptyOnly}
                field={this.state.fields.getFieldByName("emailAddress2")}
              ></InputPill>
              <InputPill
                hideIfValue={this.state.emptyOnly}
                field={this.state.fields.getFieldByName("mobile")}
              ></InputPill>
              <LinkPill
                hideIfValue={this.state.emptyOnly}
                field={this.state.fields.getFieldByName("address1Line1")}
                saveOnDone={this.saveAllFields}
                overlay={
                  <Address
                    helperTexts={this.state.helperTexts}
                    record={this.state.solicitor}
                    fields={this.state.fields}
                    handleChange={this.handleChange}
                    address="address1Line1"
                    eircode="eircode"
                    letManual={false}
                  />
                }
              />
            </Stack>
          </Row>
        </Container>
        <Container className="pb-5">
          <Row>
            <Col className="toggle-box">
              <Form.Check
                type="switch"
                id="empty-fields-switch"
                label="Empty Fields Only"
                reverse
                onClick={() =>
                  this.setState({ emptyOnly: !this.state.emptyOnly })
                }
              />
            </Col>
          </Row>
          <Stack
            direction="horizontal"
            gap={3}
            className="text-center justify-center"
          >
            <ButtonGroup>
              <button
                type="button"
                className="btn btn-standard-transparent btn-outline btn-wide"
                onClick={this.prev}
              >
                Back
              </button>
              {this.state.saving && (
                <button type="button" className="btn btn-standard btn-wide">
                  <Icons.Spinner size="sm" animation="border" role="status" />
                </button>
              )}
              {!this.state.saving && (
                <button
                  type="button"
                  className="btn btn-dark btn-wide"
                  onClick={this.handleSubmit}
                >
                  Finish
                </button>
              )}
            </ButtonGroup>
          </Stack>
        </Container>
        {this.beamPanel(false)}
      </>
    );
  }

  usingBeam() {
    return (
      this.state.mortgage?.solicitor &&
      (this.state.mortgage?.useLetsBeam ||
        this.state.mortgage.solicitor.emailAddress.toLowerCase() ===
          SolicitorDetails.beamEmail.toLowerCase())
    );
  }

  render() {
    if (this.state.redirectTo) {
      return <Navigate to={this.state.redirectTo} />;
    }

    var contents;
    if (this.state.loading) {
      return (
        <p>
          <em>Loading...</em>
        </p>
      );
    } else if (
      !this.state.mortgage?.solicitor &&
      this.state.mortgage?.useLetsBeam !== true &&
      !this.state.useOwnSolicitor
    ) {
      contents = this.decidePanel(this.state.mortgage);
    } else if (this.state.bookACall) {
      contents = this.letsBeamCalendlyPanel(this.state.mortgage);
    } else if (
      (this.state.mortgage?.solicitor && !this.usingBeam()) ||
      this.state.useOwnSolicitor
    ) {
      contents = this.ownSolicitorPanel(this.state.mortgage);
    } else if (this.usingBeam() || this.state.useBeam) {
      contents = this.continueWithBeamPanel(this.state.mortgage);
    } else {
      // Don't leave them with a blank page. If all else fails, drop them into the decide panel
      contents = this.decidePanel(this.state.mortgage);
    }

    //contents = this.letsBeamCalendlyPanel(this.state.mortgage);
    return (
      <PortalPage
        className={`bump-right ${
          this.state.bookACall
            ? "inprogress"
            : (this.state.useBeam || this.state.mortgage?.useLetsBeam) &&
              !this.state.useOwnSolicitor
            ? "complete"
            : ""
        }`}
        title={"Property Conveyancing"}
      >
        <Row>
          <Col className="col-12">
            <Form onSubmit={this.handleSubmit}>{contents}</Form>
          </Col>
        </Row>
        {/* <Modal centered size="xl" show={this.state.bookACall} onHide={() => {this.setState({bookACall: false})}}>
                <div className="modal-content" style={{backgroundColor:'var(--sage)', borderWidth:0}}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        {this.letsBeamCalendlyPanel(this.state.mortgage)}
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </div>
            </Modal> */}
      </PortalPage>
    );
  }

  async populateData() {
    const context = this.context as UserContextState;
    if (context.contact === null) return;

    axios
      .get(
        "/mortgages/GetMortgageByMortgageId?mortgageId=" +
          context.contact.mortgageId
      )
      .then((response) => {
        this.setState({
          mortgage: response.data,
          solicitor: response.data.solicitor ?? {},
          loading: true,
        });
        this.getTooltips("contact");
      })
      .catch((error) => {
        if (error.response.statusCode === 401) {
          alert("Unauthorized");
        }
      });
  }
}
