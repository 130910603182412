import axios, { AxiosResponse } from "axios";

var tooltips:any[] = [];
export function GetTooltips(entityname:string) {
    var value:Promise<AxiosResponse<any,any>>;
    var tooltip:any = tooltips.filter((i:any) => i.key === entityname);
    if (tooltip.length > 0) {
        value = tooltip[0].value;
    } else {
        value = axios.get("/helpertext/GetTooltips?entityname="+entityname);
        tooltips.push({key:entityname, value: value});
    }
    return value;
}