import axios from "axios";
import { PortalAIPApplicationModel } from "../models/PortalAIPApplication";
import { UserContextComponent } from "./UserContextComponent";
import { DownloadDocumentButton } from "./DownloadDocumentButton";

export interface IAIPProps {
  item: PortalAIPApplicationModel;
  buttonStyle?: "standard" | "pill" | null;
}

interface IAIPState {
  downloadError: boolean;
}

export class AIPDownloadButton extends UserContextComponent<IAIPProps, IAIPState> {
  constructor(props: IAIPProps) {
    super(props);
    this.downloadDocument = this.downloadDocument.bind(this);
    this.state = {downloadError: false};
  }

  downloadDocument() {
    const context = this.getContext();

    axios({
        url: `/AIPApplications/DownloadDocument?applicationId=${this.props.item.aipApplicationId}&mortgageId=${context.mortgage?.mortgageId}`,
        method: "GET",
        responseType: "blob"
    }).then(response => {
        if (response.data.size === 0) {
            this.setState({downloadError: true});
        } else {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", 'document.pdf');
            document.body.appendChild(link);
            link.click();
            if (link.parentNode != null)
                link.parentNode.removeChild(link);
        }
    });
}

  render() {

    return (
      <DownloadDocumentButton 
        title={"AIP doc"} 
        expiry={this.props.item.valuationExpiryDate}
        download={this.downloadDocument}
        buttonStyle={this.props.buttonStyle}
        showError={this.state.downloadError} />
    );
  }
}