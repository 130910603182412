import { FieldsCollection } from './FieldsCollection';

export class FinanceFieldsCollection extends FieldsCollection {
    constructor() {
        super();
        this.addTextField("name", "Name / Address");
        this.addLookupField("institutionId", "Institution");
        this.addTextField("institution", "Institution (label)");
        this.addTextAsNumberField("accountNumber", "Last 4 digits of Account Number", 4);
        this.addCurrencyField("currentBalance", "Balance", 0, 1000000);
        this.addCurrencyField("limit", "Limit", 0, 1000000);
        this.addNumberWithControlsField("termLeft", "Term Remaining", 0, 1000);
        this.addPicklistField("termPeriod", "Term Period", [{ text: "Months", value: 755980000 }, { text: "Years", value: 755980001 }]);

        this.addPicklistField("planOnClearingTheBalance", "Plan on clearing the balance?", [{ text: "No", value: 755980001 }, { text: "Yes", value: 755980000 }]);
        this.addCurrencyField("recurringMonthlyValue", "Recurring Monthly Value", 0, 1000000);
        this.addCurrencyField("recurringYearlyValue", "Recurring Yearly Value", 0, 1000000);
        this.addBooleanField("sellBeforePurchasing", "Sell before purchasing your new home?");
        this.addBooleanField("isJoint", "Joint with co-applicant?");
        this.addCurrencyField("mortgageBalanceOutstanding", "Mortgage Balance Outstanding", 0, 1000000);
        this.addPicklistField("loanType", "Loan Type", [{ text: "Car", value: 755980000 }, { text: "Personal", value: 755980001 },
            { text: "Home improvement", value: 755980002 }, { text: "Mortgage", value: 755980005 }, { text: "Other", value: 755980004 }]);
        this.addTextField("otherInstitutionName", "Other Institution Name", 100);
        this.addBooleanField("usedForDeposit", "Are you using part or all of the balance towards the deposit?");
        this.addPicklistField("termRemaining", "Term remaining (years)", [{ text: "1", value: 755980000 }, { text: "2", value: 755980001 },
        { text: "3", value: 755980002 }, { text: "4", value: 755980003 }, { text: "5+", value: 755980004 }]);
        this.addCurrencyField("overdraftLimit", "Overdraft Limit", -1000000, 1000000);        
    }
}