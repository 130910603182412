import axios from "axios";
import { MortgageStatus, MortgageType, PortalMortgageModel } from "../models/PortalMortgageModel";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from "react-router-dom"
import { Col, Container, Modal, Row, Stack } from "react-bootstrap";
import { PortalPage } from "../components/PortalPage";
import { UserContext } from "../components/UserContext";
import UserContextState from "../components/UserContext";
import { appInsightsHelper } from "../components/AppInsights";
import React from "react";
import { UserContextComponent } from "../components/UserContextComponent";
import { ApplicantSetup } from "../components/ApplicantSetup";
import * as Icons from "react-bootstrap";
import { ApplicantSetupDialog } from "../components/ApplicantSetupDialog";
import { AppointSolicitorTile } from "../components/Tiles/AppointSolicitorTile";
import { LifeCoverTile } from "../components/Tiles/LifeCoverTile";

interface IDashboardProps {
}

interface IDashboardState {
    mortgage: PortalMortgageModel | null;
    loading: boolean;
    isCompleted: boolean;
    showWelcomeVideo: boolean;
    startWelcomeVideo: boolean;
    missingSecondary: boolean;
}

export class Dashboard extends UserContextComponent<IDashboardProps, IDashboardState> {
    static contextType = UserContext;
    static displayName = Dashboard.name;

    constructor(props: any) {
        super(props);
        this.state = { loading: true,mortgage: null, isCompleted: false, showWelcomeVideo: false, startWelcomeVideo: false, missingSecondary: false };
    }

    componentDidMount() {
        appInsightsHelper.trackPageView({ name: "Dashboard" });

        const context = this.context as UserContextState;
        if (context.mortgage?.applicationType !== "Single" && !context.mortgage?.secondaryApplicant && this.state.missingSecondary === false) {
            this.setState({missingSecondary:true});
        }
    }

    setFirstLoginTime(): void {
        const context = this.context as UserContextState;
        axios.post('/Contacts/SetFirstLoginTime', null, { params: { contactId: context.contact?.contactId } });
    }

    onApplicantSetupFinished() {
        this.setState({missingSecondary: false});
    }


    render() {
        const context = this.context as UserContextState;
        let mortgage = context.mortgage;

        if (mortgage === null || mortgage === undefined)
            return;

        var profileOnly = false;
        if (!mortgage.status || mortgage.status === MortgageStatus.InformationGathering || mortgage.status === MortgageStatus.PortalLogin || mortgage.status === MortgageStatus.PortalSetup) {
            profileOnly = true;
        }


        var description = 
            mortgage.type === MortgageType.PurchasingaNewHome 
            ? <p><strong>Get house hunting with “Approval in Principle”</strong>; demonstrating how much you can borrow and bid.<br />Avoid delays later by getting these other requirements in order as soon as you can too.</p>
            : <p><strong>Let’s keep your mortgage switch on track</strong> by completing the application and uploading documents.<br />Avoid delays later by getting these other requirements in order as soon as you can too.</p>

        document.body.classList.add("animate-noscroll");

        return (
            <>
            <PortalPage alignTitleLeft={true} title={<>Hey {context.contact?.firstName},</>} description={description}>
                <Container>
                    <Row direction="horizontal" className="text-start">
                        <Col xs={4}>
                            {mortgage.type === MortgageType.PurchasingaNewHome 
                            ?
                            <div className="boxty tall rounder grid box-sage m-3 text-start">
                                <h3 className="pb-3">Get Approval in Principle</h3>
                                <p>You’ll need an AIP letter to bid on a property.</p>
                                <p><strong>Get your AIP within 24 hours</strong> of receipt of full docs, with your dedicated advisor by your side throughout.</p>
                                <Link to="/profile" className=" a-s-e"><button className="btn fw big btn-secondary next pe-5 mt-3">Complete application</button></Link>
                            </div>
                            :
                            <div className="text-start tall boxty rounder grid box-sage m-3">
                                <h3 className="pb-3">Complete your application</h3>
                                <p>Glide through all the mandatory information requited and upload the all the mandatory documents.</p>
                                <p>Your advisor will help you select the best loan for you.</p>
                                <Link to="/profile" className=" a-s-e"><button className="btn fw big btn-secondary next pe-5 mt-3">Complete application</button></Link>
                            </div>
                            }
                        </Col>
                        
                        {!mortgage.solicitor &&
                        <Col xs={4}>
                            <AppointSolicitorTile />
                        </Col>
                        }
                        {!mortgage.hasInsuranceQuote &&
                        <Col xs={4}>
                            <LifeCoverTile />
                        </Col>
                        }
                        {/* if we have a mortgage solicitor just pad a column at the end in for prettiness */}
                        {mortgage.solicitor && <Col />}
                        {/* if we have an insurance quote just pad a column in for prettiness */}
                        {mortgage.hasInsuranceQuote && <Col />}
                    </Row>

                    <ApplicantSetupDialog show={this.state.missingSecondary === true} centered finished={this.onApplicantSetupFinished} />

                </Container>
            </PortalPage>
            </>
        );
    }

}
