import { useMsal } from "@azure/msal-react";

export const SignOutButton = () => {
    const { instance } = useMsal();

    const handleLogout = () => {
        sessionStorage.clear();
        instance.logout();
        // instance.logoutRedirect({
        //     postLogoutRedirectUri: "/SignOutComplete",            
        // });
    };
    return <a className="signout-button" onClick={handleLogout}><i className="fa-solid fa-right-from-bracket signout-icon"></i> Sign Out</a>;
};