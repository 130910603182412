import React, { ReactNode } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ChatQuestionModel } from "./ChatQuestionModel";

export enum MessageDirection {
    Incoming = 1,
    Outgoing = 2
}

export interface ChatMessageProps {
    direction?: MessageDirection,
    initials?: string | null,
    //onMessageAdded(question: ChatQuestionModel | null): void,
    question: ChatQuestionModel | null,
    children: ReactNode,
    addclass?: string | null
}

export class ChatMessage extends React.Component<ChatMessageProps, any> {

    componentDidMount(): void {
        //this.props.onMessageAdded(this.props.question);
    }

    render() {
        return (
            <>
                {this.props.direction === MessageDirection.Incoming && <>
                    <Row style={{ marginTop: 15 }} className="align-items-center">
                        <Col md={12} style={{ padding: 0 }}>
                            <div className={`rounded-3 text-end chat-message-container chat-message-container-incoming ${this.props.addclass ?? "" }`}>
                                {this.props.children}
                            </div>
                        </Col>
                    </Row>
                </>
                }
                {this.props.direction === MessageDirection.Outgoing &&
                    <Container>
                        <Row style={{ marginTop: 15 }} className="align-items-center">
                            <Col md={1} style={{ padding: 0 }}>
                                <div className="circle-robot" style={{ width: "40px" }}>
                                    <div className="chat-avatar-circle-inner"><i className="fas fa-user"></i></div>
                                </div>
                            </Col>
                            <Col md={11} style={{ padding: 0, paddingLeft: 5 }}>
                                <div className="rounded-3 text-start chat-message-container chat-message-container-outgoing">{this.props.children}</div>
                            </Col>
                        </Row>
                    </Container>
                }
            </>
        );
    };
}