import axios from "axios";
import React from "react";
import { UserContextComponent } from "./UserContextComponent";
import { DownloadDocumentButton } from "./DownloadDocumentButton";

export interface ILoanOfferProps {
  buttonStyle?: "standard" | "pill" | null;
  className?: string;
}

interface ILoanOfferState {
  downloadError: boolean;
}

export class LoanOfferDownloadButton extends UserContextComponent<ILoanOfferProps, ILoanOfferState> {
  constructor(props: ILoanOfferProps) {
    super(props);
    this.downloadLO = this.downloadLO.bind(this);
    this.state = {downloadError: false};
  }

    downloadLO() {
      const context = this.getContext();

      axios({
          url: `/Mortgages/DownloadLoanOffer?mortgageId=${context.mortgage?.mortgageId}`,
          method: "GET",
          responseType: "blob"
      }).then(response => {
          if (response.data.size === 0) {
              this.setState({downloadError: true});
          } else {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", 'LoanOffer.pdf');
              document.body.appendChild(link);
              link.click();
              if (link.parentNode !== null)
                  link.parentNode.removeChild(link);
          }
      });
    }

  render() {
    const context = this.getContext();
    return (
      <DownloadDocumentButton 
        className={this.props.className}
        title={"Letter of Offer"} 
        download={this.downloadLO}
        buttonStyle={this.props.buttonStyle}
        showError={this.state.downloadError} />
    );
  }
}