import { FieldsCollection } from './FieldsCollection';

export class ApplicantFieldsCollection extends FieldsCollection {
    constructor() {
        super();
        this.addTextField("firstName", "First Name", 50);
        //this.addTextField("middleName", "Middle Name", 50, true);
        this.addTextField("lastName", "Last Name", 50);
        this.addTextField("placeOfBirth", "Place of Birth", 50);

        this.addLookupField("nationality", "Nationality");
        // this.addPicklistField("nationality", "Nationality",
        //     [{ text: "Irish", value: 778390000 },
        //     { text: "English", value: 778390001 },
        //     { text: "Welsh", value: 778390002 },
        //     { text: "Scottish", value: 778390003 },
        //     { text: "German", value: 778390004 },
        //     { text: "American", value: 778390005 }]);
        this.addPicklistField("sex",
            "Sex",
            [{ text: "Male", value: 1 },
            { text: "Female", value: 2 }]);
        this.addPicklistField("maritalStatus",
            "Marital Status",
            [{ text: "Single", value: 1 },
            { text: "Married", value: 2 },
            { text: "Divorced", value: 3 },
                { text: "Widowed", value: 4 },
                { text: "Separated", value: 680200001 }
            ]);
        this.addDateField("birthDate", "DOB");
        this.addLookupField("countryOfBirth", "Country Of Birth");
        //    "Country of Birth",
        //    [{ text: "Ireland", value: 778390000 },
        //    { text: "England", value: 778390001 },
        //    { text: "Scotland", value: 778390002 },
        //    { text: "Wales", value: 778390003 },
        //    { text: "Germany", value: 778390004 },
        //    { text: "USA", value: 778390005 }]);
        this.addTextField("mothersMaidenName", "Mother's Maiden Name", 100, true);
        //this.addTextField("maidenName", "Maiden Name", 50, true);
        //this.addTextField("telephone", "Telephone Number", 50);
        this.addTextField("mobile", "Mobile", 50);
        this.addTextField("emailAddress", "E-mail", 100);
        this.addTextField("emailAddress2", "E-mail 2", 100);
        this.addTextField("ppsNumber", "PPS Number", 20);
        this.addPicklistField("hasChildren", "Any children?", [{ text: "Yes", value: 1 }, { text: "No", value: 0 }]);
        //this.addBooleanField("hasChildren", "Any children?");
        this.addBooleanField("hasChildcareCost", "Any childcare cost?");
        this.addCurrencyField("monthlyChildcareCost", "Childcare monthly fees?", 0, 10000000);
        this.addCurrencyField("monthlyChildcareCostOther", "Childcare monthly fees?", 0, 10000000);
        this.addBooleanField("requireWorkPermit", "Require a work permit?");
        this.addPicklistField("livedOutsideofIreland", "Lived outside of Ireland?", [{ text: "No", value: 755980001 }, { text: "Yes", value: 755980000 }]);
        this.addTextField("visaType", "Visa Type");
        this.addDateField("visaExpiryDate", "Expiry Date", new Date(), null, false);
        this.addTextField("address1", "Address", 400);
        this.addTextField("address1Line1", "Address Line 1", 250);
        this.addTextField("address1Line2", "Address Line 2", 250);
        this.addTextField("address1Line3", "Address Line 3", 250);
        this.addTextField("address1City", "City", 50);
        this.addTextField("address1County", "County", 50);
        this.addTextField("address1Country", "Country", 80);
        this.addTextField("address1Eircode", "Eircode", 20, false);
        this.addDateField("address1MovedIn", "When did you move in?");
        this.addBooleanField("previouslyHaveMortgageLoan", "Do you have a mortgage on any other properties?");
        this.addTextField("address2", "Your previous address", 400);
        this.addTextField("address2Line1", "Address Line 1", 250);
        this.addTextField("address2Line2", "Address Line 2", 250);
        this.addTextField("address2Line3", "Address Line 3", 250);
        this.addTextField("address2City", "City", 50);
        this.addTextField("address2County", "County", 50);
        this.addTextField("address2Country", "Country", 80);
        this.addTextField("address2Eircode", "Eircode", 20, false);
        this.addDateField("address2MovedIn", "When did you move in?");
        this.addBooleanField("previouslyHaveMortgageLoan2", "Do you have a mortgage on any other properties?");
        this.addPicklistField("ownerOrRenter",
            "Current Residential Status",
            [{ text: "Owner", value: 778390000 },
            { text: "Private Rented", value: 778390001 },
            { text: "Living with Relatives", value: 778390002 }]);
        this.addTextField("currentAddressYears", "Years");
        this.addTextField("currentAddressMonths", "Months");
        this.addBooleanField("doYouPayYourRentThroughYourBankAccount", "Payment through bank?");
        this.addCurrencyField("monthlyRentAmount", "Monthly Mortgage/Rent Amount", 0, 10000000);
        this.addCurrencyField("monthlyRentAmountOther", "Monthly Mortgage/Rent Amount", 0, 10000000);
        this.addPicklistField("employmentStatus",
            "PAYE or Self-Employed?",
            [{ text: "Employed", value: 778390000 },
            { text: "Self Employed", value: 778390001 },
            { text: "Home Maker", value: 778390002 },
            { text: "Retired", value: 778390003 },
            { text: "Not Employed", value: 778390004 }]);
        this.addLookupField("occupationId", "Occupation");

        this.addNumberField("lengthOfTimeEmployerYears", "Length with your employer (years)", 0, 100000);
        this.addNumberField("lengthOfTimeEmployerMonths", "Length with your employer (months)", 0, 100000);
        this.addNumberField("lengthOfTimePrevEmployerYears", "Length with your employer (years)", 0, 100000);
        this.addNumberField("lengthOfTimePrevEmployerMonths", "Length with your employer (months)", 0, 100000);

        this.addTextField("lengthOfTimePrevEmployer", "Length with employer");
        this.addCurrencyField("grossSalary", "Gross basic income?", 0, 10000000);

        this.addBooleanField("otherCurrencyIncome", "Any income other than Euro currency?");
        this.addPicklistField("incomePaidInEuro", "Is your income paid in Euro?", [{ text: "No", value: 755980001 }, { text: "Yes", value: 755980000 }]);

        this.addCurrencyField("commission", "Commission", 0, 10000000, true);
        this.addCurrencyField("overtime", "Overtime", 0, 1000000, true);
        this.addCurrencyField("bonus", "Bonus", 0, 1000000, true);
        this.addCurrencyField("otherIncome", "Other Income", 0, 10000000, true);
        this.addTextField("companyName", "Company Name");//??
        this.addTextField("accountantsName", "Accountants Name");
        this.addBooleanField("smoker", "Are you a smoker?");
        this.addTextField("childrenAge", "Ages");
        this.addNumberField("childrenNumber", "Number of dependants", 0, 10);
        this.addTextField("employersName", "Employer Name");
        this.addTextField("employersAddress1", "Employer Address 1");
        this.addTextField("employersAddress2", "Employer Address 2");
        this.addTextField("prevEmployersName", "Previous Employer Name");
        this.addLookupField("prevOccupationId", "Previous Occupation");
        this.addTextField("prevEmployersAddress1", "Previous Employer Address 1");
        this.addTextField("prevEmployersAddress2", "Previous Employer Address 2");
        this.addPicklistField("missedPayments", "Missed Payments?", [{ text: "No", value: 755980001 }, { text: "Yes", value: 755980000 }]);
        this.addPicklistField("bankrupcyOrCourt", "Bankruptcy or Court Judgements", [{ text: "No", value: 755980001 }, { text: "Yes", value: 755980000 }]);
        this.addPicklistField("jobType", "Job Type", [{ text: "Permanent", value: 755980000 }, { text: "Temporary", value: 755980001 }, { text: "Contract", value: 755980002 }]);

        this.addTextField("businessAddress1", "Business Address Line 1");
        this.addTextField("businessAddress2", "Business Address Line 2");
        this.addTextField("natureOfBusiness", "Business Description");

        this.addDateField("businessEstablishedDate", "Business Established Date");
        this.addDecimalField("shareholdingPercentage", "% shareholding in the business", 0, 100);
        this.addCurrencyField("grossProfit", "Drawings Last Financial Year", 0, 10000000);//"Most recent year's profit"
        this.addCurrencyField("previousYearProfit", "Drawings Prev Financial Year", 0, 10000000);//"Previous year's profit"

        this.addPicklistField("salaryFrequency", "How regularly is your salary paid?",
            [{ text: "Weekly", value: 755980000 },
            { text: "Fortnightly", value: 755980001 },
            { text: "Monthly", value: 755980002 }]);
        this.addBooleanField("usCitizen", "US Citizen?");

        this.addPicklistField("hasVariableIncome", "Has variable income?",
            [{ text: "No", value: 755980001 }, { text: "Yes", value: 755980000 }]);

    }
}