import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../pages/Applicant.css';
import { UserContextComponent } from '../UserContextComponent';
import { Link } from 'react-router-dom';
import { MortgageType, YesNoBlank } from '../../models/PortalMortgageModel';
import axios from 'axios';


export class ConfirmTitleDeedsTile extends UserContextComponent<any, any> {

    constructor(props: any) {
        super(props);
        this.confirmTitleDeeds = this.confirmTitleDeeds.bind(this);
    }

    confirmTitleDeeds() {
        let context = this.getContext();
        context.mortgage!.confirmedTitleDeeds = YesNoBlank.Yes;
        axios.post('/Mortgages/ConfirmTitleDeeds', null, { params: { mortgageId: context.mortgage?.mortgageId } })
            .then(response => {
                this.props.confirmedTitleDeeds();
            });
    }

    render() {
        var context = this.getContext();

        return (
            <div className={`text-start boxty grid box-teal-outline ${this.props.className ?? ''}`}>
                <h3 className="pb-3">Confirm title deeds with solicitor</h3>
                <p>An important step that often delays closing and getting your keys.</p>
                <p className='mt-3'>Does your solicitor have the title deeds for the property?</p>
                <button className="btn fw big btn-light next-secondary pe-5 mt-3" onClick={this.confirmTitleDeeds}>Confirm</button>
            </div>
        );
    }
}




