import { Col, Container, Row } from "react-bootstrap";
import { FormControl } from "./FormControl";
import { AggregatePillOverlay, IAggregatePillOverlayProps } from "./Pills/AggregatePillOverlay";
import { UserContext } from "./UserContext";
import { Field } from "../models/PortalMetadata";

export interface IFieldWithHelptextProps extends IAggregatePillOverlayProps {
    field: Field,
}
interface IFieldWithHelptextState {
    field: Field,
}

export class FieldWithHelptext extends AggregatePillOverlay<IFieldWithHelptextProps, IFieldWithHelptextState> {
    static contextType = UserContext;

    constructor(props: any) {
        super(props);
        this.state = {
            field: this.props.field,
        };
    }

    componentDidMount() {
        this.setState({
            field: this.props.field,
        });
    }

    close(): void {
        if (this.props.closePill)
            this.props.closePill();
    }

    render() {
        return (
            <Container className="profit-container">
                <Row className='text-center'>
                    <h3>{this.state.field.tooltip?.content}</h3>
                </Row>
                <Row>
                    <Col>
                        <FormControl light field={this.state.field} customLabel=" "></FormControl>
                    </Col>
                </Row>
            </Container>
        );
    }
}