import React, { useState, useEffect } from "react"
import "./PlaceAutocomplete.css";
import { logger } from '../helper/Logger';

//import "https://webservices.data-8.co.uk/javascript/predictiveaddress.js";
//import "https://webservices.data-8.co.uk/content/predictiveaddress.css";
import axios from 'axios';
// Async google places autocomplete function
export const addressAutocomplete = async (text,sessionId) =>
    new Promise((resolve, reject) => {
        if (!text) {
            return reject("Need valid text input")
        }

        // for use in things like GatsbyJS where the html is generated first
        if (typeof window === "undefined") {
            return reject("Need valid window object")
        }
        const json = {
            "country": "IE",
            "search": text,
            "session": "",
            "options": {
                "IncludeMR": false,
                "IncludeNYB": false,
                "PreferredLanguage": "en",
            }
        };

        try {
            // axios({
            //     method: 'post',
            //     url: '/Search.json',
            //     baseURL: 'https://webservices.data-8.co.uk/PredictiveAddress',
            //     data: json,
            //     auth: {
            //         username: 'apikey-34G9-PSVP-VP6L-MBIK',
            //         password: ''
            //     },
            //     responseType: 'json', // default
            //     headers: {
            //         'Content-Type': 'application/json',
            //         //'Content-Length': json.length
            //     },
            // }).then(resolve);

            axios.get("/Address/Search?text=" + text + "&sessionId=" + sessionId)
            .then(resolve)
            .catch((error) => {
                this.setState({ loading: false });
            });


            //new window.google.maps.places.AutocompleteService().getPlacePredictions(
            //    { input: text, componentRestrictions: { country: "ie" } },
            //    resolve
            //)
        } catch (e) {
            reject(e)
        }
    });

export const addressDrilldown = async (id) =>
    new Promise((resolve, reject) => {
        if (!id) {
            return reject("Need valid id")
        }

        // for use in things like GatsbyJS where the html is generated first
        if (typeof window === "undefined") {
            return reject("Need valid window object")
        }

        try {
            axios.get("/Address/Drilldown",
            {
                params: {"id":id}
            })
            .then(resolve)
            .catch((error) => {
                this.setState({ loading: false });
            });


            //new window.google.maps.places.AutocompleteService().getPlacePredictions(
            //    { input: text, componentRestrictions: { country: "ie" } },
            //    resolve
            //)
        } catch (e) {
            reject(e)
        }
    });
    
export const addressRetrieve = async (id) =>
    new Promise((resolve, reject) => {
        if (!id) {
            return reject("Missing valid address id")
        }

        // for use in things like GatsbyJS where the html is generated first
        if (typeof window === "undefined") {
            return reject("Need valid window object")
        }
        
        try {
            axios.get("/Address/Retrieve", 
                {
                    params: {"id":id}
                })
            .then(resolve)
            .catch((error) => {
                this.setState({ loading: false });
            });
        } catch (e) {
            reject(e)
        }
    });

// React component that uses the auto complete function
export function PredictionsOnFormSubmission() {
    const [searchValue, setSearchValue] = useState("")
    const [predictions, setPredictions] = useState([])

    const handleSubmit = async e => {
        e.preventDefault()
        const results = await addressAutocomplete(searchValue)
        if (results) {
            setPredictions(results)
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <input
                    name="predictionSearch"
                    value={searchValue}
                    onChange={e => setSearchValue(e.target.value)}
                />
                <button type="submit">Search</button>
            </form>
            {/* <img
                src="https://developers.google.com/places/documentation/images/powered-by-google-on-white.png"
                alt="Powered by Google"
            /> */}
            {predictions?.map(prediction => (
                <p key={prediction?.value}>
                    {prediction?.label || "Not found"}
                </p>
            ))}
        </>
    )
}

export function usePlacesAutocomplete(text = "", debounceTimeout = 400) {
    const [predictions, setPredictions] = useState([])

    useEffect(() => {
        const handleDebounce = setTimeout(async () => {
            try {
                if (!text) {
                    return
                }

                const nextPredictions = await addressAutocomplete(text)
                setPredictions(nextPredictions)
            } catch (e) {
                logger.error(e)
            }
        }, debounceTimeout)

        return () => {
            clearTimeout(handleDebounce)
        }
    }, [text, debounceTimeout])

    return predictions
}


// Using the react hook in a function example
export function PredictionsOnInputChange() {
    const [selectedPrediction, setSelectedPrediction] = useState(null)
    const [searchValue, setSearchValue] = useState("")
    const predictions = usePlacesAutocomplete(searchValue)

    const handlePredictionSelection = (e, prediction) => {
        e.preventDefault()
        setSelectedPrediction(prediction)
    }

    return (
        <div>
            <input className="autocomplete"
                name="predictionSearch"
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
            />
            <div className="pac-container">
                <ul className="pac-item">
                    {predictions?.map(prediction => (
                        <li key={prediction?.value}>
                            <button
                                onClick={e => handlePredictionSelection(e, prediction)}
                                onKeyDown={e => handlePredictionSelection(e, prediction)}
                            >
                                {prediction?.label || "Not found"}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
            <input className="autocomplete"
                name="result"
                value={selectedPrediction?.label || "None"}
            />
            <h3>You searched for: {searchValue}</h3>
            <h3>
                You selected:{" "}
                {selectedPrediction?.label || "None"}
            </h3>
        </div>
    )
}