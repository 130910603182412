import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../pages/Applicant.css';
import { UserContextComponent } from '../UserContextComponent';
import axios from 'axios';


export class ESISTile extends UserContextComponent<any, any> {

    constructor(props: any) {
        super(props);
        this.download = this.download.bind(this);
    }

    download() {
        const context = this.getContext();

        axios({
            url: `/Mortgages/DownloadESIS?mortgageId=${context.mortgage?.mortgageId}`,
            method: "GET",
            responseType: "blob"
        }).then(response => {
            if (response.data.size === 0) {
                //this.setState({downloadError: true});
            } else {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", 'ESIS.pdf');
                document.body.appendChild(link);
                link.click();
                if (link.parentNode !== null)
                    link.parentNode.removeChild(link);
                context.mortgage!.isEsisDownloaded = true;
                this.setState({ refreshToggle: !this.state.refreshToggle });
            }
        });
    }

    render() {
        return (
            <div className={`text-start boxty grid box-teal-outline ${this.props.className ?? ''}`}>
                <h3 className="pb-3">Download ESIS</h3>
                <p>European Standard Information Sheet</p>
                <p className='mt-3'>This document confirms all of the key features and risks relating to your mortgage contract in clear language including the APRC.</p>
                <button className="btn fw big btn-light next-secondary pe-5 mt-3" onClick={this.download}>Download</button>
            </div>
        );
    }
}




