import { MortgageStatus } from '../models/PortalMortgageModel';
import { Navigate } from 'react-router-dom';
import { appInsightsHelper } from '../components/AppInsights';
import { UserContextComponent } from '../components/UserContextComponent';
import UserContextState from '../components/UserContext';
import axios from 'axios';
import { AIPDDLStatus, AIPType, PortalAIPApplicationModel } from '../models/PortalAIPApplication';

interface IAIPApplicationProps {
}

interface IAIPApplicationState {
    loading: boolean;
    aipCountLoaded: boolean;
    aipApprovedCount: number;
    hasApprovedHouseHunterAIP: boolean;
}

export class HomePage extends UserContextComponent<IAIPApplicationProps, IAIPApplicationState> {
    constructor(props: any) {
        super(props);
        this.state = { loading: false, aipCountLoaded:false, aipApprovedCount: 0, hasApprovedHouseHunterAIP: false};
    }

    componentDidMount() {
        appInsightsHelper.trackPageView({ name: "Home Page" });
    }

    setFirstLoginTime(): void {
        const context = this.context as UserContextState;
        context.contact!.portalFirstLogin = new Date();
        axios.post('/Contacts/SetFirstLoginTime', null, { params: { contactId: context.contact?.contactId } });
    }

    setMortgageFirstLoginTime(): void {
        const context = this.context as UserContextState;
        context.mortgage!.portalFirstLogin = new Date();
        axios.post('/Mortgages/SetFirstLoginTime', null, { params: { mortgageId: context.mortgage?.mortgageId } });
    }

    loadApprovedAIPCount(): void {
        const context = this.getContext();
        axios.get("/AIPApplications/GetAIPApplicationsByMortgageId?mortgageId=" + context.mortgage?.mortgageId)
            .then((response) => {
                let aips: PortalAIPApplicationModel[] = response.data;

                let houseHunterApprovedCount = (aips.filter(a => (a.aipDdlStatus === AIPDDLStatus.ApprovedByLender || a.aipDdlStatus === AIPDDLStatus.Proceeding) && a.type === AIPType.HouseHunter).length) > 0
                this.setState({
                    aipApprovedCount: aips.filter(a => a.aipDdlStatus === AIPDDLStatus.ApprovedByLender || a.aipDdlStatus === AIPDDLStatus.Proceeding).length,
                    hasApprovedHouseHunterAIP: houseHunterApprovedCount,
                    aipCountLoaded: true
                });
            }
            );
    };

    render() {
        let context = this.getContext();
        let firstLogin = false;

        if (this.state.loading || context.mortgage == null)
            return (<p><em>Loading...</em></p>);

        if (context.mortgage === null) {
            return <p>There is no mortgage record associated with your contact details.</p>
        }

        if (context.mortgage.status == MortgageStatus.Closed) {
            return <Navigate to="/completion" />;
        }

        if (context.contact?.portalFirstLogin == null) {
            firstLogin = true;
            this.setFirstLoginTime();
        }

        if (context.mortgage?.portalFirstLogin == null) {
            firstLogin = true;
            this.setMortgageFirstLoginTime();
        }

        if (firstLogin) {
            return <Navigate to="/dashboard" />;
        } else if (
            context.mortgage.status === MortgageStatus.PortalLogin) {
            return <Navigate to="/profile" />;
        }
        else if (context.mortgage.status === MortgageStatus.InformationGathering) {
            
            if (!this.state.aipCountLoaded) {
                this.loadApprovedAIPCount();
                return (<p><em>Loading...</em></p>);
            }

            if (this.state.aipApprovedCount > 0)
                return <Navigate to="/completion" />;
            else
                return <Navigate to="/profile" />;
        }
        
        else if (
                context.mortgage.status === MortgageStatus.AIPSubmitted
                || (context.mortgage.status === MortgageStatus.AIPApproved && (!context.mortgage.saleAgreed || context.mortgage.approvalType === AIPType.HouseHunter))
            || context.mortgage.status === MortgageStatus.PreAIPClarification) {

            if (!this.state.aipCountLoaded) {
                this.loadApprovedAIPCount();
                return (<p><em>Loading...</em></p>);
            }

            if (this.state.hasApprovedHouseHunterAIP && context.mortgage.saleAgreed) { //We want to keep the soft AIP customers out of the AIP page when going for full AIP
                return <Navigate to="/completion" />;
            }
            else {
                return <Navigate to="/aip-application" />;
            }

        } else if ((context.mortgage.chosenLender ?? "") !== "") {
            return <Navigate to="/completion" />;
        } else if (
                (context.mortgage.approvalType !== AIPType.HouseHunter && context.mortgage.status === MortgageStatus.AIPApproved && context.mortgage.saleAgreed)
                || context.mortgage.status === MortgageStatus.LoanOfferReceived
                || context.mortgage.status === MortgageStatus.LoanOfferRequested
                || context.mortgage.status === MortgageStatus.PreLoanOfferClarification
                || context.mortgage.status === MortgageStatus.Completions
                || context.mortgage.status === MortgageStatus.Closed) {
            return <Navigate to="/completion" />;
        } else {
            return <Navigate to="/profile" />;
        }
    }
}
