import { FieldsCollection } from './FieldsCollection';

export class SharedFieldsCollection extends FieldsCollection {
    constructor() {
        super();
        this.addCurrencyField("propertyValue", "Estimated Property Value", 0, 100000000);
        this.addCurrencyField("purchasePrice", "Estimated Purchase Price", 0, 100000000);
        this.addCurrencyField("mortgageAmountProposed", "Required Mortgage Amount", 10000, 100000000);
        this.addCurrencyField("topUpAmount", "Top-Up Amount", 0, 100000000);
        this.addPicklistField("fundoftheBalance", "Fund of the Balance",
            [{ text: "Savings", value: 755980000 },
            { text: "Gift", value: 755980001 },
            { text: "Other", value: 755980002 }]);
        this.addCurrencyField("regularSavingsAmount", "Regular Savings Amount", 0, 1000000);
        this.addCurrencyField("otherContinuedSavings", "Other Continued Savings", 0, 1000000);
        this.addCurrencyField("giftAmount", "Total Gift Amount", 0, 1000000);
        this.addCurrencyField("otherSavingsAmount", "Other Amount", 0, 1000000);
        this.addNumberField("mortgageTerm", "Proposed Mortgage Term", 0, 1000000);
        this.addPicklistField("preferenceForYourRate", "Rate Type",
            [{ text: "Variable", value: 755980000 },
            { text: "1 Year Fixed", value: 755980001 },
            { text: "2 Year Fixed", value: 755980002 },
            { text: "3 Year Fixed", value: 755980003 },
            { text: "4 Year Fixed", value: 755980004 },
            { text: "5 Year Fixed", value: 755980005 },
            { text: "7 Year Fixed", value: 755980006 },
            { text: "10 Year Fixed", value: 755980007 },
            { text: "15 Year Fixed", value: 755980008 },
            { text: "20 Year Fixed", value: 755980009 }]);
        this.addDateField("clientAdvisedClosingDate", "Updated Closing Date", new Date());
    }
}