import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Container, Stack } from "react-bootstrap";
import './Applicant.css';
import { Navigate } from "react-router-dom";
import { InputPill } from '../components/Pills/InputPill';
import { LinkPill } from "../components/Pills/LinkPill";
import { ApplicantPage } from "./ApplicantPage";
import { ApplicantService } from './ApplicantService';
import { ApplicantBaseComponent } from './ApplicantBaseComponent';
import { Employment } from '../components/Employment';
import { AggregatePill, AggregateType, IAggregateListItem, IAggregatePillProps } from '../components/Pills/AggregatePill';
import { SelfEmployment } from '../components/SelfEmployment';
import { getTooltip } from '../components/Tooltip';
import { Profit } from '../components/Applicant/Profit';
import { VariableIncome } from '../components/Applicant/VariableIncome';

export class ApplicantEmployment extends ApplicantBaseComponent {
    applicantService: ApplicantService;

    constructor(props: any) {
        super(props);
        this.prev = this.prev.bind(this);
        this.next = this.next.bind(this);
        this.visaExpiryCheck = this.visaExpiryCheck.bind(this);
    }

    componentDidMount() {
        let context = this.getContext();
        this.applicantService = new ApplicantService(context, this, this.props.applicantNo);
        this.applicantService.getOccupations().then(() => {
            this.applicantService.getApplicantData();
        });
    }

    prev() {
        // if (this.state.contact.requireWorkPermit === true && this.visaExpiryCheck(this.state.fields.getFieldByName("visaExpiryDate").value as string))
        //     return;

        this.applicantService.saveAllFields(false);
        this.setState({ redirectTo: "/residential" + this.props.applicantNo });
    }

    next() {
        // if (this.state.contact.requireWorkPermit === true && this.visaExpiryCheck(this.state.fields.getFieldByName("visaExpiryDate").value as string))
        //     return;

        this.applicantService.saveAllFields(false);
        this.setState({ redirectTo: "/financial" + this.props.applicantNo });
    }

    visaExpiryCheck(value: string) {

        let today = new Date();
        let visaExpiryDate = new Date(value);

        if (value && visaExpiryDate.toISOString() < today.toISOString()) {
            return true;
        }
        return false;
    }

    render() {
        if (this.state.loading) {
            return;
        }

        let context = this.getContext();

        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />;
        }

        if (!this.state.contact)
            return <span>Applicant information not found</span>;

        //employers
        let showPrevEmployer = (this.state.contact.lengthOfTimeEmployerYears + this.state.contact.lengthOfTimeEmployerMonths / 12) < 3 ? true : false;

        let occupation = (this.state.fields.getFieldValueByName("occupationId", this.state.contact) ?? "");
        if (occupation !== "")
            occupation = this.state.occupationList.find(o => o.value === occupation)?.text;
        let prevOccupation = (this.state.fields.getFieldValueByName("prevOccupationId", this.state.contact) ?? "");
        if (prevOccupation !== "")
            prevOccupation = this.state.occupationList.find(o => o.value === prevOccupation)?.text;

        let employersValue = this.state.fields.getFieldValueByName("employersName", this.state.contact) ?
            (this.state.fields.getFieldValueByName("employersName", this.state.contact) + " | " +
                occupation + " | " +
                (this.state.fields.getFieldValueByName("employersAddress1", this.state.contact) ?? "")) : "";
        let prevEmployersValue = this.state.fields.getFieldValueByName("prevEmployersName", this.state.contact) ?
            (this.state.fields.getFieldValueByName("prevEmployersName", this.state.contact) + " | " +
                prevOccupation + " | " +
                (this.state.fields.getFieldValueByName("prevEmployersAddress1", this.state.contact) ?? "")) : "";

        //profit pill
        let profitItems: IAggregateListItem[] = [];
        profitItems.push({ key: "Profit", values: [this.state.contact.grossProfit, this.state.contact.previousYearProfit] });
        let profitPill: IAggregatePillProps = {
            uniqueKey: "profit",
            label: "Drawings",
            showLabelCount: false,
            items: profitItems,
            aggregate1type: AggregateType.firstValue,
            aggregate1label: "Last Financial Year",
            aggregate1currency: true,
            aggregate2type: AggregateType.firstValue,
            aggregate2label: "Prev Financial Year",
            aggregate2currency: true,
            overlay: <Profit key="grossProfitPill" record={this.state.contact} fields={this.state.fields}></Profit>,
            hideIfValue: this.state.emptyOnly,
            tooltip: getTooltip(context.tooltips ?? [], "profit")
        };

        //variable income pill
        let variableIncomeItems: IAggregateListItem[] = [];
        if (this.state.contact.hasVariableIncome === 755980000)
            variableIncomeItems.push({ key: "VariableIncome", values: [this.state.contact.commission, this.state.contact.overtime, this.state.contact.bonus, this.state.contact.otherIncome] });

        let variableIncomePill: IAggregatePillProps = {
            uniqueKey: "variableIncome",
            label: "Variable Income",
            showLabelCount: false,
            items: variableIncomeItems,
            aggregate1type: AggregateType.sumAll,
            aggregate1label: "",
            aggregate1currency: true,
            overlay: <VariableIncome key="variableIncomePill" record={this.state.contact} fields={this.state.fields}></VariableIncome>,
            hideIfValue: this.state.emptyOnly,
            // setCloseModal: this.setCloseVariableIncomeModal,
            // setOpenModal: this.setOpenVariableIncomeModal,
            // itemProp: this.state.variableIncome,
            tooltip: getTooltip(context.tooltips ?? [], "variableIncome")
        };


        var title = this.state.contact.firstName + "'s Employment & Income";
        var subTitle = <>Banks calculate your loan value based on your gross income. They will often consider a percentage of any variable income too.</>;

        return (
            <>
                <ApplicantPage applicantNo={this.state.applicantNo} title={title} subTitle={subTitle} next={this.next} prev={this.prev}
                    contact={this.state.contact} handleSubmit={this.applicantService.handleSubmit} showFinishButton={false}
                    emptyOnlyClick={() => this.setState({ emptyOnly: !this.state.emptyOnly })} percentComplete={this.state.contact.employmentInfoPercentage}>
                    <Container className="pill-form">
                        <>
                            <Container>
                                <Row className="pill-row">
                                    <Stack gap={1} direction="horizontal" className="pill-stack">

                                        {/* Moved to AppolicantPersonal as per request from Grainne on 10th May 2024 */}
                                        {/* <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("employmentStatus")} ></InputPill> */}

                                        {(this.state.contact.employmentStatus === "778390000" || this.state.contact.employmentStatus === 778390000) &&
                                            <>
                                                <LinkPill
                                                    hideIfValue={this.state.emptyOnly}
                                                    field={this.state.fields.getFieldByName("employersName")}
                                                    label="Employment Details"
                                                    valueToDisplay={employersValue}
                                                    saveOnDone={this.applicantService.saveAllFields}
                                                    overlay={<Employment helperTexts={this.state.helperTexts} record={this.state.contact} fields={this.state.fields}
                                                        handleChange={this.applicantService.handleChange} employersName="employersName" occupation="occupationId"
                                                        address1="employersAddress1" address2="employersAddress2" lengthYears="lengthOfTimeEmployerYears"
                                                        lengthMonths="lengthOfTimeEmployerMonths" occupationList={this.state.occupationList} />} />
                                                {showPrevEmployer &&
                                                    <LinkPill
                                                        hideIfValue={this.state.emptyOnly}
                                                        field={this.state.fields.getFieldByName("prevEmployersName")}
                                                        label="Previous Employment Details"
                                                        valueToDisplay={prevEmployersValue}
                                                        saveOnDone={this.applicantService.saveAllFields}
                                                        overlay={<Employment helperTexts={this.state.helperTexts} record={this.state.contact} fields={this.state.fields}
                                                            handleChange={this.applicantService.handleChange} employersName="prevEmployersName" occupation="prevOccupationId"
                                                            address1="prevEmployersAddress1" address2="prevEmployersAddress2" lengthYears="lengthOfTimePrevEmployerYears"
                                                            lengthMonths="lengthOfTimePrevEmployerMonths" occupationList={this.state.occupationList} />} />
                                                }
                                                <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("jobType")} ></InputPill>
                                                <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("grossSalary")} ></InputPill>
                                                <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("salaryFrequency")} ></InputPill>
                                                <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("incomePaidInEuro")} ></InputPill>

                                                <AggregatePill size="lg" {...variableIncomePill} ></AggregatePill>
                                            </>
                                        }

                                        {(this.state.contact.employmentStatus === "778390001" || this.state.contact.employmentStatus === 778390001) &&
                                            <>
                                                <LinkPill
                                                    hideIfValue={this.state.emptyOnly}
                                                    field={this.state.fields.getFieldByName("companyName")}
                                                    valueToDisplay={this.state.fields.getFieldByName("companyName").value?.toString()}
                                                    label="Company Details"
                                                    tooltip={getTooltip(context.tooltips ?? [], "selfEmployment")}
                                                    saveOnDone={this.applicantService.saveAllFields}
                                                    overlay={<SelfEmployment handleChange={this.applicantService.handleChange} fields={this.state.fields} helperTexts={this.state.helperTexts} record={this.state.contact} ></SelfEmployment>} />


                                                <AggregatePill size="lg" {...profitPill} ></AggregatePill>
                                            </>
                                        }
                                        <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("missedPayments")} ></InputPill>
                                        <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("bankrupcyOrCourt")} ></InputPill>
                                        <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("requireWorkPermit")} ></InputPill>
                                        {this.state.contact.requireWorkPermit === true && <>
                                            <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("visaType")} ></InputPill>
                                            <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("visaExpiryDate")} invalidText="Should be a future date"
                                            //isInvalid={this.visaExpiryCheck} 
                                            ></InputPill>
                                        </>
                                        }
                                    </Stack>
                                </Row>
                            </Container>
                        </>
                    </Container>

                </ApplicantPage>
            </>
        );
    }
}