import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Modal, Row, Stack } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { ApplicantPage } from "./ApplicantPage";
import { ApplicantService } from './ApplicantService';
import { ApplicantBaseComponent } from './ApplicantBaseComponent';
import { AggregatePill, AggregateType, IAggregateListItem, IAggregatePillProps } from '../components/Pills/AggregatePill';
import { getTooltip } from '../components/Tooltip';
import { HasAny, MortgageDocumentStatusCode, ApplicableTo, PortalMortgageDocumentModel } from '../models/PortalMortgageDocumentModel';
import { FinanceManager } from '../components/Finance/FinanceManager';
import UserContextState from '../components/UserContext';
import { MortgageSubType } from '../models/PortalMortgageModel';
import { Profile } from './Profile';
import { OpenBanking } from './OpenBanking';
import axios from 'axios';
import { AIPType } from '../models/PortalAIPApplication';

export class ApplicantAssetsAndLoans extends ApplicantBaseComponent {
    applicantService: ApplicantService;

    constructor(props: any) {
        super(props);
        this.prev = this.prev.bind(this);
        this.next = this.next.bind(this);
        this.getFilteredDocs = this.getFilteredDocs.bind(this);
    }

    componentDidMount() {
        let context = this.getContext();
        this.applicantService = new ApplicantService(context, this, this.props.applicantNo);
        this.applicantService.getApplicantData();
        this.applicantService.getMortgageDocuments();
        this.applicantService.getDocumentTypes();
        //this.applicantService.getInstitutions();
        this.applicantService.getFinanceTooltips();
    }

    prev() {
        this.applicantService.saveAllFields(false);
        this.setState({ redirectTo: "/employment" + this.props.applicantNo });
    }

    next() {
        this.applicantService.saveAllFields(false);
        this.setState({ redirectTo: "/profile" });
    }

    hasActiveWithMissingData(key: string, activeDocuments: PortalMortgageDocumentModel[]): boolean {
        if (key === "PROPERTYASSETS" || key === "OTHERASSETS") {
            return activeDocuments.filter(e => isNullOrEmpty(e.name) || isNullOrEmpty(e.currentBalance) || e.currentBalance === 0).length > 0;
        }
        else if (key === "LOAN") {
            return activeDocuments.filter(e => isNullOrEmpty(e.currentBalance) || isNullOrEmpty(e.institutionId)
                || isNullOrEmpty(e.accountNumber) || e.currentBalance === 0).length > 0;
        }
        else if (key === "MORTGAGEACCOUNT") {
            return activeDocuments.filter(e => isNullOrEmpty(e.currentBalance) || isNullOrEmpty(e.institutionId)
                || isNullOrEmpty(e.accountNumber) || e.currentBalance === 0).length > 0;
        } else {
            return activeDocuments.filter(e => isNullOrEmpty(e.currentBalance) || e.currentBalance === 0).length > 0;
        }
    }

    hasMissingData(key: string, value: PortalMortgageDocumentModel[]): boolean {
        var activeDocuments = value.filter(e => e.statusReason !== MortgageDocumentStatusCode.Inactive);
        var inActiveDocuments = value.filter(e => e.statusReason === MortgageDocumentStatusCode.Inactive);
        let hasInactiveWithHasAnyEqualToNo = inActiveDocuments.filter(e => e.hasAny === HasAny.No).length > 0;
        let activeWithMissingData = this.hasActiveWithMissingData(key, activeDocuments);

        if (activeWithMissingData === true)
            return true;

        if (activeDocuments.length > 0 || hasInactiveWithHasAnyEqualToNo)
            return false;

        return true;
    }

    getFilteredDocs(context?: UserContextState | null) {
        if (!context) context = this.getContext();
        if (context.mortgageDocumentList != null) {
            let applicableTo = this.props.applicantNo === 1 ? ApplicableTo.Primary : ApplicableTo.Secondary;
            let categories = ['STATEMENT','SAVING'];
            let docs = context.mortgageDocumentList.filter(c => 
                (!categories.includes(c.categoryUniqueCode??'')) &&
                (c.applicableTo === ApplicableTo.Shared || c.applicableTo === applicableTo || c.isJoint === true)
                );

            docs.sort(
                (a: any, b: any) => {
                    // We want to primarily sort by mortgage document id, but if these are null make sure they appear first
                    // We next want to sort by the order property (if its set) as this allows to change the order before merging
                    if (
                        a.categoryOrder === b.categoryOrder) {
                        // if we have 2 null mortgage documents, or 2 matching mortgage documents, sort by the order attribute
                        if (!a.applicableTo && !b.applicableTo) return 1;
                        if (a.applicableTo < b.applicableTo) return -1;
                        if (a.applicableTo > b.applicableTo) return 1;
                    }

                    // Compare if the document needs to come before or after
                    if (a.categoryOrder < b.categoryOrder) return -1;
                    if (a.categoryOrder > b.categoryOrder) return 1;

                    // If we get here, we've probably hit equal documents and orders... 
                    // shouldn't happen, but in case paul got at the code you never know
                    return 0;
                }
            );
            return docs;
        }
        return [];
    }

    getPillList(context: UserContextState): any[] {
        var list: any[] = [];
        if (context.mortgageDocumentList === null) return [];

        let docs = this.getFilteredDocs(context);
        let catGrouped: any = this.applicantService.groupBy(docs, "categoryUniqueCode");

        Object.entries(catGrouped).forEach(([key, value]) => {
            if ((key === "MORTGAGEACCOUNT" || key === "PROPERTYASSETS") && context.mortgage?.subType === MortgageSubType.FirstTimeBuyer) {
                return;
            }
            if (key === "OTHERASSETS")
                return;

            let active = (value as PortalMortgageDocumentModel[]).filter(e => e.statusReason !== MortgageDocumentStatusCode.Inactive);
            let inActive = (value as PortalMortgageDocumentModel[]).filter(e => e.statusReason === MortgageDocumentStatusCode.Inactive);

            let hasAny = null;
            if (active.length > 0) {
                hasAny = true;
            } else {
                if (inActive.length > 0 && inActive[0].hasAny !== null)
                    hasAny = false;
            }

            // let finance = this.state.finances.find(f => f.key === key);
            // let hasAny = finance ? finance.hasAny : null;

            var label: string | null;
            var aggregate2type: AggregateType | undefined;
            var aggregate2label: string | undefined;
            var aggregate2currency: boolean | undefined;
            var items: IAggregateListItem[] | null;
            var toolTipKey = key.toLowerCase();

            var hasMissingData = this.hasMissingData(key, value as PortalMortgageDocumentModel[]);

            label = (value as PortalMortgageDocumentModel[])[0].document;
            if (key === "LOAN" || key === "MORTGAGEACCOUNT") {
                items = (value as PortalMortgageDocumentModel[])
                    .filter(e => e.statusReason !== MortgageDocumentStatusCode.Inactive)
                    .map(
                        (e: PortalMortgageDocumentModel) => {
                            return { key: e.mortgageDocumentId, values: [e.currentBalance] } as IAggregateListItem;
                        }
                    );

            } else if (key === "PROPERTYASSETS") {// || key === "OTHERASSETS") {
                items = (value as PortalMortgageDocumentModel[])
                    .filter(e => e.statusReason !== MortgageDocumentStatusCode.Inactive)
                    .map(
                        (e: PortalMortgageDocumentModel) => {
                            return { key: e.mortgageDocumentId, values: [e.recurringYearlyValue] } as IAggregateListItem;
                        }
                    );
            }
            else {
                aggregate2type = undefined;
                aggregate2label = undefined;
                aggregate2currency = undefined;
                items = (value as PortalMortgageDocumentModel[])
                    .filter(e => e.statusReason !== MortgageDocumentStatusCode.Inactive)
                    .map(
                        (e: PortalMortgageDocumentModel) => {
                            return { key: e.mortgageDocumentId, values: [e.currentBalance] } as IAggregateListItem;
                        }
                    );
            }

            var val: any[] = [];
            (value as any[])
                .filter(e => e.statusReason !== MortgageDocumentStatusCode.Inactive)
                .sort((a:any,b:any) => {
                        if (a.new && b.new) return 1;
                        if (b.new) return -1;
                        return 1;
                    }
                )
                .forEach((e, i) => { e.key = e.mortgageDocumentId; val.push(e); });

            if (items.length == 0 && hasAny == null) {
                items = null;
            }
            //logger.log(finance, hasAny);
            let item : IAggregatePillProps = {
                //key: JSON.stringify(finance) + JSON.stringify(val),
                //key: "pill-" + key,
                uniqueKey: key,
                label: label ?? "",
                showLabelCount: true,
                items: items,
                aggregate1type: AggregateType.sum,
                aggregate1label: "Balance:",
                aggregate1currency: true,
                aggregate2type: aggregate2type,
                aggregate2label: aggregate2label,
                aggregate2currency: aggregate2currency,
                overlay: <FinanceManager key={JSON.stringify(val)} uniqueKey={key} label={label} items={items} values={val} institutionList={context.institutions} 
                    helperTexts={this.state.dochelperTexts} applicantNo={this.state.applicantNo} contact={this.state.contact} setContact={(contact) => { this.setState({contact: contact});}}  hasAny={hasAny} />,
                hideIfValue: this.state.emptyOnly,
                //itemProp: null, //finance,
                isMissing: hasMissingData, //anyMissing.length > 0,
                // setCloseModal: this.setCloseModal,
                // setOpenModal: this.setOpenModal,
                tooltip: getTooltip(context.tooltips ?? [], toolTipKey)
            };


            if (key === "PROPERTYASSETS" || key === "OTHERASSETS") {
                item.aggregate1label = "Income:";
            }

            list.push(item);
        });
        return list;
    }

    refreshMortgageDocuments() {
        let context = this.getContext();
        let url = `/MortgageDocuments/GetAllDocuments?mortgageId=${context.mortgage!.mortgageId}`;
        return axios.get(url).then((mortgageDocumentsResponse) => {
            context.mortgageDocumentList = mortgageDocumentsResponse.data;
            this.setState({refreshToggle: false});
        });
    }

    render() {
        let context = this.getContext();

        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />;
        }

        if (!this.state.contact)
            return <span>Applicant information not found</span>;

        let list = this.getPillList(context);

        var title = this.state.contact.firstName + "'s Assets & Loans";
        var subTitle = <>This bit is important! Please set out all Assets and Loan accounts you hold.</>;
        
        return (
            <>
                <ApplicantPage applicantNo={this.state.applicantNo} title={title} subTitle={subTitle} next={this.next} prev={this.prev}
                    contact={this.state.contact} handleSubmit={this.applicantService.handleSubmit} showFinishButton={true}
                    emptyOnlyClick={() => this.setState({ emptyOnly: !this.state.emptyOnly })} percentComplete={this.state.contact.assetsInfoPercentage}>
                    <>
                        <Container className="pill-form">
                            <>
                                <Container>
                                    <Row className="pill-row">
                                        <Stack gap={1} direction="horizontal" className="pill-stack">
                                            {list.map((pill, idx) =>
                                                <AggregatePill key={"pill_" + idx} className="finance-modal" contentClassName="financial-modal-content" headerClassName="finance-modal-header"
                                                    {...pill}
                                                ></AggregatePill>
                                            )}
                                        </Stack>
                                    </Row>
                                </Container>
                            </>
                        </Container>
                        <Modal size="xl" fullscreen="lg-down" show={this.state.finished === true} onHide={() => this.setState({ finished: false })} centered>
                            <Modal.Body>
                                <Container className="text-center justify-center">
                                    <div className="page-title">
                                        <h1>Thanks, {this.state.contact.firstName}!</h1>
                                        <h3>Your personal details section is now <strong>{Math.round(this.state.contact.percentageOfCompletion)}% complete!</strong></h3>
                                        <h5>What would you like to do next?</h5>
                                    </div>
                                    <Profile splashScreen={true} />
                                    <br />
                                </Container>
                            </Modal.Body>
                        </Modal>
                    </>
                </ApplicantPage>
            </>
        );
    }
}

function isNullOrEmpty(value: any): boolean {
    return (value === undefined || value === null || value === "");
}
