import React from "react";
import { Accordion, Card, Col, Container, Modal, Row, Stack } from "react-bootstrap";
import './Documents.css';
import { PortalPage } from "../components/PortalPage";
import { UserContext } from '../components/UserContext';
import UserContextState from '../components/UserContext';
import axios from 'axios';
import "./LoanCompletion.css";
import { Timeline, TimelineItem, TimelineItemStatus } from '../components/Timeline';
import { MortgageDocumentStatusCode, PortalDocumentType } from '../models/PortalMortgageDocumentModel';
import { DocumentPill } from '../components/Pills/DocumentPill';
import { UserContextComponent } from '../components/UserContextComponent';
import { PortalSpecialConditionsModel, SpecialConditionsType } from '../models/PortalSpecialConditionsModel';
import { IFileItem } from "../components/DocumentManager";
import { MortgageStatus, MortgageType, YesNoBlank } from "../models/PortalMortgageModel";
import { AIPDDLStatus, AIPType, PortalAIPApplicationModel } from "../models/PortalAIPApplication";
import { ProgressTile, ProgressTileSize } from "../components/ProgressTile";
import { PortalCategoryModel } from "../models/PortalCategoryModel";
import { Navigate } from "react-router-dom";
import { FormControl } from "../components/FormControl";
import { SharedFieldsCollection } from "../forms/SharedFields";
import * as Icons from "react-bootstrap";
import { PortalClarificationModel } from "../models/PortalClarificationModel";
import { ReferralBox } from "../components/ReferralBox";
import { ApplicantService } from "./ApplicantService";
import { PortalContactModel } from "../models/PortalContactModel";
import { RateCheckerTile } from "../components/Completion/RateCheckerTile";
import { SpreadTheWordTile } from "../components/Completion/SpreadTheWordTile";
import { StayInformedTile } from "../components/Completion/StayInformedTile";
import FormattedDate from "../components/FormattedDate";
import { ValuationDownloadButton } from "../components/ValuationDownloadButton";
import { LoanOfferDownloadButton } from "../components/LoanOfferDownloadButton";
import { ConfirmTitleDeedsTile } from "../components/Tiles/ConfirmTitleDeedsTile";
import { ESISTile } from "../components/Tiles/ESISTile";
import { CostOfCreditTile } from "../components/Tiles/CostOfCreditTile";
import { LifeCoverTile } from "../components/Tiles/LifeCoverTile";

interface ICompletionProps {
}

interface ICompletionState {
    loading: boolean;
    conditions: PortalSpecialConditionsModel[] | null;
    clarifications: PortalClarificationModel[] | null;
    files: IFileItem[];
    detailsExpanded: boolean;
    refreshToggle: boolean;
    navigateToSolicitor: boolean;
    navigateToProperty: boolean;
    navigateToMortgageDetails: boolean;
    navigateToDocumentsPage: boolean;
    //fields: SharedFieldsCollection;
    sharedFields: SharedFieldsCollection;
    closeDateCollapsed: boolean;
    savingCloseDate: boolean;
    confirmingDetails: boolean;
    confirmingTitleDeeds: boolean;
    confirmingMetSolicitor: boolean;
    displayOnMobile: boolean;
    showReferral: boolean;
    contact: PortalContactModel | null;
    conditionsLoaded: boolean;
    chosenAip?: PortalAIPApplicationModel | undefined;
    hasHouseHunterApprovedAIPOnly: boolean;
}

export class LoanCompletion extends UserContextComponent<ICompletionProps, ICompletionState> {
    applicantService: ApplicantService;
    static contextType = UserContext;
    static mortgagedetails: string = "mortgagedetails";
    static loanofferdetails: string = "loanofferdetails";


    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            conditions: [],
            clarifications: [],
            files: [],
            detailsExpanded: false,
            refreshToggle: false,
            navigateToSolicitor: false,
            navigateToProperty: false,
            navigateToMortgageDetails: false,
            navigateToDocumentsPage: false,
            //fields: new SharedFieldsCollection(),
            sharedFields: new SharedFieldsCollection(),
            closeDateCollapsed: true,
            savingCloseDate: false,
            confirmingDetails: false,
            confirmingTitleDeeds: false,
            confirmingMetSolicitor: false,
            displayOnMobile: false,
            showReferral: false,
            contact: null,
            conditionsLoaded: false,
            hasHouseHunterApprovedAIPOnly: false
        };
        this.confirmTitleDeeds = this.confirmTitleDeeds.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
        let context = this.getContext();
        this.applicantService = new ApplicantService(context, this, context.applicantNo!);
        this.applicantService.getApplicantData();
        //this.state.fields.loadData(context.mortgage, [], true, null);
        this.state.sharedFields.loadData(context.mortgage, [], true, null);
        this.populateData();
    }

    refresh() {
        this.setState({refreshToggle: !this.state.refreshToggle});
    }

    formatDate(dateIn?: any): string {
        if(!dateIn) return '';
        let date = new Date(dateIn);
        return !date ? '' : date.getDate() + '/' + (date.getMonth() + 1) + '/' + (date.getFullYear());
    }

    getClosingDate() {
        let context = this.getContext();
        let mortgage = context.mortgage!;
        if (mortgage.estimatedClosingDate) {
            return this.formatDate(mortgage.estimatedClosingDate);
        } else if (mortgage.clientAdvisedClosingDate) {
            return this.formatDate(mortgage.clientAdvisedClosingDate);
        }
        return '';
    }

    getNextStepsItems(): TimelineItem[] {
        let items: TimelineItem[] = [];
        items.push({
            status: TimelineItemStatus.Inactive,
            title: <div className="timeline-text">Step 1</div>,
            content: <></>,
            key: "timeline-next1"
        });
        items.push({
            status: TimelineItemStatus.Inactive,
            title: <div className="timeline-text">Step 2</div>,
            content: <></>,
            key: "timeline-next2"
        });
        items.push({
            status: TimelineItemStatus.Inactive,
            title: <div className="timeline-text">Step 3</div>,
            content: null,
            key: "timeline-next3"
        });
        return items;
    }

    getSpecialConditionsById(id: string): any {
        if (this.state.conditions === null)
            return;

        let condition = this.state.conditions.find((c: any) => {
            return c.specialConditionsId === id;
        });
        if (condition !== null) {
            return condition;
        }
        return null;
    }

    getClarificationById(id: string): any {
        if (this.state.clarifications === null)
            return;

        let clarification = this.state.clarifications.find((c: any) => {
            return c.clarificationId === id;
        });
        if (clarification !== null) {
            return clarification;
        }
        return null;
    }

    confirmSpecialConditionsUpload(id: string): void {
        const context = this.getContext();

        let condition = this.getSpecialConditionsById(id);
        if (condition !== null) {
            axios.post('/SpecialConditions/CompleteSpecialConditions', null, { params: { specialConditionsId: id, mortgageId: context.mortgage?.mortgageId } })
                .then(response => {
                    if (condition !== null) {
                        condition.uploaded = true;
                    }
                    this.setState({ conditions: this.state.conditions });
                });
        }
    }

    confirmClarificationUpload(id: string): void {
        const context = this.getContext();

        let clarification = this.getClarificationById(id);
        if (clarification !== null) {
            axios.post('/SpecialConditions/CompleteClarification', null, { params: { clarificationId: id, mortgageId: context.mortgage?.mortgageId } })
                .then(response => {
                    if (clarification !== null) {
                        clarification.uploaded = true;
                    }
                    this.setState({ clarifications: this.state.clarifications });
                });
        }
    }

    confirmChosenLender() {
        let context = this.getContext();
        context.mortgage!.confirmedChosenLender = true;
        this.setState({ refreshToggle: !this.state.refreshToggle });

        axios.post('/Mortgages/ConfirmChosenLender', null, { params: { mortgageId: context.mortgage?.mortgageId } })
            .then(response => {
            });
    }

    confirmTitleDeeds() {
        this.setState({ refreshToggle: !this.state.refreshToggle });
    }

    metWithSolicitor() {
        let context = this.getContext();
        context.mortgage!.metWithSolicitor = true;
        this.setState({ refreshToggle: !this.state.refreshToggle });
        axios.post('/Mortgages/MetWithSolicitor', null, { params: { mortgageId: context.mortgage?.mortgageId } })
            .then(response => {
            });
    }

    setClientAdvisedClosingDate() {
        this.setState({ savingCloseDate: true });
        let date = new Date(this.state.sharedFields.getFieldByName("clientAdvisedClosingDate").value as string);
        let context = this.getContext();
        context.mortgage!.clientAdvisedClosingDate = date;

        this.setState({ refreshToggle: !this.state.refreshToggle, closeDateCollapsed: true, savingCloseDate: false });
        axios.post('/Mortgages/SetClientAdvisedClosingDate', null, { params: { mortgageId: context.mortgage?.mortgageId, date: date } })
            .then(response => {
                this.setState({ refreshToggle: !this.state.refreshToggle});
            });
    }

    onDocumentDone(mortgageDocumentId: string, status: MortgageDocumentStatusCode, conditionId: string) {
        this.confirmSpecialConditionsUpload(conditionId);
    }

    onLODocumentDone(mortgageDocumentId: string, status: MortgageDocumentStatusCode, clarificationId: string) {
        this.confirmClarificationUpload(clarificationId);
    }

    renderLOClarification(sc: PortalClarificationModel) {
        let context = this.getContext();
        let status = MortgageDocumentStatusCode.Active;
        if (sc.completed) {
            status = MortgageDocumentStatusCode.Approved;
        } else if (sc.uploaded) {
            status = MortgageDocumentStatusCode.Uploaded;
        }

        return (
            <div key={"pill-paragraph" + sc.clarificationId} style={{ marginTop: '10px' }}>
                <DocumentPill
                    key={"pill-" + sc.mortgageDocumentId}
                    status={status}
                    category="Loan Offer"
                    name={sc.title}
                    portalName={""}
                    helpText={sc.description}
                    files={this.state.files.filter(x => x.mortgageDocumentId === sc.mortgageDocumentId)}
                    contactId={context.contact?.contactId!}
                    mortgageDocumentId={sc.mortgageDocumentId}
                    onDone={(mortgageDocumentId: string, status: MortgageDocumentStatusCode) => { this.onLODocumentDone(mortgageDocumentId, status, sc.clarificationId); }}
                    mortgageId={context.contact?.mortgageId}
                    documentType={PortalDocumentType.Document}
                    metFaceToFace={false}
                    comments={""}
                />
            </div>);
    }

    renderSpecialCondition(sc: PortalSpecialConditionsModel) {
        let context = this.getContext();
        let status = MortgageDocumentStatusCode.Active;
        if (sc.completed) {
            status = MortgageDocumentStatusCode.Approved;
        } else if (sc.uploaded) {
            status = MortgageDocumentStatusCode.Uploaded;
        }


        return (
            <div key={"pill-paragraph" + sc.specialConditionsId} style={{ marginTop: '10px' }}>
                <DocumentPill
                    key={"pill-" + sc.mortgageDocumentId}
                    status={status}
                    category={sc.type === SpecialConditionsType.LoanofferCustomercondition ? "Completions" : "Loan Offer"}
                    name={sc.name}
                    portalName={""}
                    helpText={sc.description}
                    files={this.state.files.filter(x => x.mortgageDocumentId === sc.mortgageDocumentId)}
                    contactId={context.contact?.contactId!}
                    mortgageDocumentId={sc.mortgageDocumentId}
                    onDone={(mortgageDocumentId: string, status: MortgageDocumentStatusCode) => { this.onDocumentDone(mortgageDocumentId, status, sc.specialConditionsId); }}
                    mortgageId={context.contact?.mortgageId}
                    documentType={PortalDocumentType.Document}
                    metFaceToFace={false}
                    comments={""}
                />
            </div>);
    }

    getStatusClass(box: string) {
        let context = this.getContext();
        if (box === LoanCompletion.mortgagedetails) {
            if (!context.mortgage?.propertyAddress || !context.mortgage.solicitor) {
                return "box-sage";
            }
            return "box-chartreuse";
        }
        if (box === LoanCompletion.loanofferdetails) {
            if (this.atLoanOfferConditions() || this.atLoanOfferClarifications()) {
                if (this.outstandingConditions()) {
                    return "box-error";
                } else {
                    return "box-sage";
                }
            } else if (context.mortgage?.loanOfferReceived) {
                return "box-green";
            } else if (context.mortgage?.loanOfferRequested) {
                return "box-sage";
            }
        }
        return "box-gray";
    }

    getTextClass() {
        // if (this.outstandingConditions()) {
        //     return "text-red";
        // }
        return "";
        // if (this.atLoanOfferConditions() || this.atLoanOfferClarifications()) {
        //     if (this.outstandingConditions())
        //         return "text-red";
        //     else
        //         return "text-amber";
        // } else if (this.waitingForOffer()) {
        //     return "text-amber";
        // }
        // return "text-green";
    }

    atLoanOfferConditions() {
        var mortgage = this.getContext().mortgage!;
        if (!mortgage.loanOfferRequested) {
            return true;
        }
        return false;
    }

    outstandingConditions() {
        if (this.state.conditions !== null && 
            this.state.conditions != undefined && 
            this.state.conditions.filter(c => c.uploaded !== true && c.completed !== true).length > 0) {
            return true;
        }
        return false;
    }

    hasIncompleteConditions() {
        if (this.state.conditions !== null && 
            this.state.conditions != undefined && 
            this.state.conditions.filter(c => c.completed !== true).length > 0) {
            return true;
        }
        return false;
    }

    atLoanOfferClarifications() {
        var mortgage = this.getContext().mortgage!;
        if (mortgage.status === MortgageStatus.PreLoanOfferClarification) {
            return true;
        }
        return false;
    }

    outstandingClarifications() {
        if (this.state.clarifications !== null && 
            this.state.clarifications !== undefined &&
            this.state.clarifications.filter(c => c.uploaded !== true).length > 0) {
            return true;
        }
        return false;
    }

    waitingForOffer() {
        var mortgage = this.getContext().mortgage!;
        if (!this.atLoanOfferConditions() && !this.atLoanOfferClarifications() && !mortgage.loanOfferReceived) {
            return true;
        }
        return false;
    }

    loanOfferNoConditions() {
        var mortgage = this.getContext().mortgage!;
        if (mortgage.loanOfferReceived && this.state.conditionsLoaded && this.state.conditions?.length === 0) {
            return true;
        }
        return false;
    }

    loanOfferNoOutstandingConditions() {
        var mortgage = this.getContext().mortgage!;
        if (mortgage.loanOfferReceived && !this.outstandingConditions()) {
            return true;
        }
        return false;
    }

    private calcPercent(docs: any[]) {
        var completedDocs = docs.filter(i => i.uploaded).length;
        var allDocs = docs.length;
        return completedDocs / allDocs * 100;
    }

    passedLenderDetailsHoldingScreen():boolean {
        let context = this.getContext();
        let mortgage = context.mortgage!;
        if (mortgage.loanOfferRequested
            //|| mortgage.confirmedChosenLender 
            || mortgage.type === MortgageType.RemortgagingExistingLoan
            || mortgage.saleAgreed
            || mortgage.approvalType == AIPType.FullApplication
            )
            return true;
        return false;
    }

    metWithSolicitorButton() {
        let context = this.getContext();
        let mortgage = context.mortgage!;
        if (mortgage.metWithSolicitor) {
            return <div className="float-right pe-2"><i className="fa fa-circle-check fa-lg" /></div>;
        }
        return <button className="btn btn-standard float-right pull-up-3" onClick={() => this.metWithSolicitor()}>update</button>
    }

    closingDateButton() {
        let context = this.getContext();
        let mortgage = context.mortgage!;
        if (mortgage.estimatedClosingDate) {
            return <div className="float-right pe-2"><i className="fa fa-circle-check fa-lg" /></div>;
        }
        <button className="btn btn-standard float-right pull-up-3" onClick={() => this.setState({ closeDateCollapsed: false })}>update</button>
    }

    render() {
        let context = this.getContext();
        let mortgage = context.mortgage!;
        let solicitor = context.mortgage?.solicitor;
        let inprogressColor = "#fe972b";
        let doneColor = "#33cc99";
        let allDone:boolean = mortgage.fundsReleaseDate !== null && new Date(mortgage.fundsReleaseDate) <= new Date();

        let title:any;
        let description;

        if (this.state.navigateToSolicitor) {
            return <Navigate to="/solicitor-details" />
        }
        if (this.state.navigateToDocumentsPage) {
            return <Navigate to="/documents" />
        }

        

        if (this.state.navigateToProperty) {
            return <Navigate to="/property" />
        }

        if (this.state.navigateToMortgageDetails) {
            return <Navigate to="/shared" /> 
        }

        if (this.state.loading || !this.state.conditionsLoaded)
            return (<><p><em>Loading...</em></p></>);


        var names = this.getApplicantNames();
        if (allDone) {
            title = <>Welcome back, <strong>{names}</strong></>;
            description = <></>; // <>We’re delighted to have helped you secure your mortgage!<br />We’d like to help make the future a <span className="doddltext">doddl</span> too ;-)</>;
        } else if (mortgage.loanOfferReceived) {
            if (this.outstandingConditions()) {
                title = <>Hi <strong>{names}</strong>, here's your Loan offer!</>;
                description = <>It’s now time to <strong>start gathering the final documents</strong> you’ll need for closing</>
            } else{
                title = <>Hi <strong>{names}</strong>,</>;
                description = <>You’re all up to date! Your lender has all the documents they need.</>
            }
        } else if (this.waitingForOffer()) {
            title = <>{names} we have an update for you!</>;
            description = <>Your lender is working on issuing your loan offer and legal pack. You can be assured we are keeping a close eye on it and will let you know as soon as it issues.</>;
        } else if (this.outstandingConditions()) {
            title = <>Hi <strong>{names}</strong>,</>;
            description = <>Your lender requires additional documents for your Loan offer</>;
        } else if (!mortgage.loanOfferRequested && !this.outstandingConditions() && !mortgage.saleAgreed && mortgage.approvalType !== AIPType.FullApplication) {
            title = <>Congratulations <strong>{names}</strong>!</>;
            description = <>Let's work on getting you that Loan offer<br/><br/><strong>Your advisor will be in contact</strong> to confirm all of you options and request your Loan offer from your lender. In the meantime, please ensure the following information is correct:</>;
        } else if (!mortgage.loanOfferRequested && !this.outstandingConditions()) {
            title = <>Hi <strong>{names}</strong>,</>;
            description = <>We have started to work on your formal approval and loan offer from your chosen lender<br/><strong>Your advisor will be in contact</strong> once they have reviewed all the documentation and requested your Loan offer from your lender.</>;
        }

        var categories: PortalCategoryModel[] = [];
        this.state.conditions?.forEach(i => { if (!categories.find(x => x.category === i.category.category) && i.category && i.category.category) categories.push(i.category) });
        
        let conditionalDocsTile = (
            <div className={`boxty tall ${this.outstandingConditions() ? 'box-error' : (this.hasIncompleteConditions() ? 'box-sage' : 'box-chartreuse')}`} >
                <h1>Conditional Docs</h1>
                {this.outstandingConditions()
                ? <>
                    <h1 className={`pt-2`}><i className="fas fa-triangle-exclamation fa-lg needsattention" /></h1>
                    <span className={`needsattention`}><strong>Completion Docs Required</strong></span>
                    <p className={`tight`}>Please provide the following:</p>
                </>
                : this.hasIncompleteConditions() ?
                <>
                    <h1 className={`pt-2`}><i className="far fa-clock fa-lg" /></h1>
                    {categories && categories.length > 0 &&
                        <>
                            <p>Awaiting Confirmation:</p>
                            <p className="tight">that all your completion documents are in order</p>
                        </>
                    }
                </>
                :
                <>
                    <h1 className={`pt-2`}><i className="fas fa-circle-check fa-lg" /></h1>
                    {categories && categories.length > 0 &&
                        <p>Documents uploaded:</p>
                    }
                </>
                }
                <Row className="justify-center">
                {!this.waitingForOffer() && categories && categories.sort((a,b) => {return (a.order??0) > (b.order??1) ? 1 : -1;}).map((cat, idx) => (
                    <Card key={`loan-doc-${cat?.category ?? ""}`} className="mb-3">
                        <Accordion style={{ minHeight: 60, borderColor: 'var(--teal)' }}>
                            <Accordion.Item eventKey={"LO Condition"}>
                                <Accordion.Header>
                                    <Row style={{ width: '100%' }}>
                                        { !cat?.faIcon && 
                                            <>
                                                <Col xs={12} className="text-center pt-1">
                                                    <h3 className="s">Other</h3>
                                                </Col>
                                            </>
                                        }
                                        { cat?.faIcon && 
                                            <>
                                                <Col xs={12} className="text-center pt-1 nowrap">
                                                    <h3 className="s">{cat.category}</h3>
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                </Accordion.Header>
                                <Accordion.Body style={{padding: 0}}>
                                    { !cat?.faIcon && 
                                        <Stack gap={2} className="text-start">
                                            {this.state.clarifications !== null && this.state.clarifications !== undefined && this.state.clarifications!.map((sc: PortalClarificationModel, idx: number) => (
                                                <div key={`cond-container-${sc.clarificationId}`}>{this.renderLOClarification(sc)}</div>
                                            ))}
                                        </Stack>
                                    }
                                    { cat?.faIcon && 
                                        <Stack gap={2} className="text-start">
                                            {this.state.conditions !== null && this.state.clarifications !== null && this.state.conditions.filter(c => c.category.category === cat.category).map((sc: PortalSpecialConditionsModel, idx: number) => (
                                                <div key={`cond-container-${sc.specialConditionsId}`}>{this.renderSpecialCondition(sc)}</div>
                                            ))}
                                        </Stack>
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Card>
                ))}
                </Row>
            </div>);

        let loanOfferTile = (<>
            <div key={'loanoffer-' + mortgage.mortgageId} className={"boxty tall " + this.getStatusClass(LoanCompletion.loanofferdetails)}>
                <h1>Loan Offer</h1>
                {((this.outstandingConditions() && this.atLoanOfferConditions()) || (this.outstandingClarifications() && this.atLoanOfferClarifications())) &&
                    <>
                        <h1 className={`pt-2`}><i className="fas fa-triangle-exclamation fa-lg needsattention" /></h1>
                        <span className={`h5 needsattention`}><strong>Additional Docs Required</strong></span>
                        <div className="p-4"><button className='btn btn-standard-transparent btn-outline btn-fw' onClick={() => window.Intercom('showNewMessage')}>Speak to my Advisor</button></div>
                    </>
                }
                {(this.atLoanOfferConditions() || this.atLoanOfferClarifications()) && categories && categories.length > 0 &&
                    <p className={`mb-3 ${this.getTextClass()}`}><strong>UPLOAD DOCUMENTS HERE</strong></p>
                }
                {(this.waitingForOffer() || ((this.atLoanOfferConditions() || this.atLoanOfferClarifications()) && (!categories || categories.length === 0))) &&
                    <>
                        <h1 className={`pt-2`}><i className="fas fa-clock-rotate-left fa-lg" /></h1>
                        <span className={`h5`}><strong>Awaiting Loan Offer</strong></span>
                        {mortgage.loanOfferRequested &&
                        <div className="mt-3">
                            <p className="tight">Date submitted <FormattedDate excludeYear={true} monthFormat="short" date={mortgage.loanOfferRequested} /></p>
                            <p className="tight">Response expected <FormattedDate excludeYear={true} monthFormat="short" date={mortgage.estimatedLoanOfferIssueDate} /></p>
                        </div>
                        }
                        {this.state.chosenAip?.isValuationAvailable &&
                            <ValuationDownloadButton item={this.state.chosenAip} buttonStyle="pill" />
                        }
                    </>
                }
                {mortgage.loanOfferReceived &&
                    <>
                        <h1 className={`pt-2`}><i className="fa fa-circle-check fa-lg" /></h1>
                        <span className={`h5`}><strong>GRANTED</strong></span>
                        <div className="mb-4"><p className="tight">Valid until <FormattedDate excludeYear={true} date={mortgage.loanOfferExpiryDate} /></p></div>
                        
                        {/* <p className="s text-start pb-2"><em>Note: Please review to ensure terms are as per your request and if you have any queries at all please immediately let me know.</em></p> */}
                        {mortgage.isLoanOfferAvailable &&                        
                            <LoanOfferDownloadButton buttonStyle="standard" className="sage" />
                        }
                        {this.state.chosenAip?.isValuationAvailable &&
                            <ValuationDownloadButton item={this.state.chosenAip} buttonStyle="standard" className="sage" />
                        }
                    </>
                }
                <Row className="justify-center">
                {!this.waitingForOffer() && !mortgage.loanOfferReceived && categories && categories.sort((a,b) => {return (a.order??0) > (b.order??1) ? 1 : -1;}).map((cat, idx) => (
                    <Card key={`loan-doc-${cat?.category ?? ""}`} className="mb-3">
                        <Accordion style={{ minHeight: 60, borderColor: 'var(--teal)' }}>
                            <Accordion.Item eventKey={"LO Condition"}>
                                <Accordion.Header>
                                    <Row style={{ width: '100%' }}>
                                        { !cat?.faIcon && 
                                            <>
                                                <Col xs={12} className="text-center pt-1">
                                                    <h3 className="s">Other</h3>
                                                </Col>
                                            </>
                                        }
                                        { cat?.faIcon && 
                                            <>
                                                <Col xs={12} className="text-center pt-1 nowrap">
                                                    <h3 className="s">{cat.category}</h3>
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                </Accordion.Header>
                                <Accordion.Body style={{padding: 0}}>
                                    { !cat?.faIcon && 
                                        <Stack gap={2} className="text-start">
                                            {this.state.clarifications !== null && this.state.clarifications !== undefined && this.state.clarifications!.map((sc: PortalClarificationModel, idx: number) => (
                                                <div key={`cond-container-${sc.clarificationId}`}>{this.renderLOClarification(sc)}</div>
                                            ))}
                                        </Stack>
                                    }
                                    { cat?.faIcon && 
                                        <Stack gap={2} className="text-start">
                                            {this.state.conditions !== null && this.state.clarifications !== null && this.state.conditions.filter(c => c.category.category === cat.category).map((sc: PortalSpecialConditionsModel, idx: number) => (
                                                <div key={`cond-container-${sc.specialConditionsId}`}>{this.renderSpecialCondition(sc)}</div>
                                            ))}
                                        </Stack>
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Card>
                ))}
                </Row>
            </div>

        </>);

        let referralTile = (<>
            <ReferralBox horizontal style={(allDone ? {paddingBottom: 30}: {}) }></ReferralBox>
        </>);

        var mortgageDetailsLarge = (<>
            <div key={'completion-' + mortgage.mortgageId} className={`boxty wide ${this.getStatusClass(LoanCompletion.mortgagedetails)} ${this.passedLenderDetailsHoldingScreen() ? "text-start" : ""}  minheight221`} style={{paddingBottom: 25}}>
                {/* <h1>{mortgage.chosenLenderFullName}</h1> */}
                <Row className="mt-3 mb-2 text-center">
                    <Col xs={4}>
                        <p className="mb-2">Mortgage Details</p>
                        <div className="mortgage-detail-div">
                            <p className="impact tight">{mortgage.mortgageAmountProposedFormatted}</p>
                            <p className="impact tight">{mortgage.mortgageTerm} Years</p>
                            <p className="impact tight">{mortgage.fixedTermYearsProposed ? mortgage.fixedTermYearsProposed + " Years fixed*" : "Variable rate"}</p>
                        </div>
                        <div className="mt-2 mt-md-3 mb-3 mb-md-0 pointer" onClick={ () => this.setState({navigateToMortgageDetails: true})}><i className="fa fa-pencil"></i></div>
                    </Col>
                    <Col xs={4} className="ps-5 pe-5">
                        <p className={"mb-2"}>Property Details</p>
                        {!mortgage.propertyAddress &&
                            <button className='btn big btn-dark mt-3' onClick={() => { this.setState({ navigateToProperty: true }) }}>+ property details</button>
                        }
                        {mortgage.propertyAddress &&
                        <>
                            <div className="mortgage-detail-div">
                                <p className="impact">{mortgage.propertyAddress!.replace(", Ireland", "").replace(" Ireland", "")}</p>
                            </div>
                            <div className="mt-2 mt-md-3 mb-3 mb-md-0 pointer" onClick={ () => this.setState({navigateToProperty: true})}><i className="fa fa-pencil"></i></div>
                            {/* <p>Value: <strong>{mortgage.type === MortgageType.RemortgagingExistingLoan ? mortgage.propertyValueFormatted : mortgage.purchasePriceFormatted}</strong></p> */}
                        </>
                        }
                    </Col>
                    <Col xs={4}>
                        <p className={"mb-2"}>Solicitor</p>
                        {(solicitor && solicitor.address1Line1 && solicitor.companyName)
                        ?
                            <>
                                <div className="mortgage-detail-div">
                                    <p className="impact tight">{solicitor.companyName}</p>
                                    <p className="impact tight">{solicitor.address1Line1!.replace(", Ireland", "").replace(" Ireland", "")}</p>
                                    <p className="impact tight">{solicitor.mobile}</p>
                                </div>
                                <div className="mt-2 mt-md-3 mb-3 mb-md-0 pointer" onClick={ () => this.setState({navigateToSolicitor: true})}><i className="fa fa-pencil"></i></div>
                            </>
                        :
                            <button className='btn big btn-dark mt-3' onClick={() => { this.setState({ navigateToSolicitor: true }) }}>+ solicitor details</button>
                        }
                    </Col>
                </Row>
                <h6 className="pt-3">*Your rate will be confirmed at draw down (currently {mortgage.offeredInterestRate}%, subject to change)</h6>
            </div>
        </>);


        let mortgageDetailsMini = (<>
            <div key={'completion-' + mortgage.mortgageId} className={`boxty tall ${this.getStatusClass(LoanCompletion.mortgagedetails)} `}>
                <h1 className="pb-0">Chosen Lender</h1>
                {mortgage.chosenLenderLogo
                    ? <img className="mb-2" style={{width: "100%"}} src={mortgage.chosenLenderLogo}></img>
                    : <h1>{mortgage.chosenLenderFullName}</h1>
                }

                <p className="tight">Product Details</p>
                <p className="impact mb-2">{mortgage.chosenLenderProduct}</p>

                <p className="tight">Mortgage Amount Proposed</p>
                <p className="impact mb-2">{mortgage.mortgageAmountProposedFormatted}</p>

                {!this.state.detailsExpanded &&
                <div className="pointer" onClick={() => this.setState({ detailsExpanded: true })}><i className={"fa fa-chevron-down"}></i></div>
                }
                {this.state.detailsExpanded &&
                    <>
                        <div className="pointer" onClick={() => this.setState({ detailsExpanded: false })}>

                            <p className="tight">Proposed Term</p>
                            <p className="impact mb-3">{mortgage.mortgageTerm} Years</p>

                            <p className="tight">Additional Months</p>
                            <p className="impact mb-3">{`${!mortgage.termAdditionalMonths ? '–' : mortgage.termAdditionalMonths + 'Month'}${mortgage.termAdditionalMonths??0 > 1 ? 's' : ''}`}</p>

                            <p className="tight">{mortgage.type === MortgageType.PurchasingaNewHome ? 'Purchase Price' : 'Property Value'}</p>
                            <p className="impact mb-3">{mortgage.type === MortgageType.RemortgagingExistingLoan ? mortgage.propertyValueFormatted : mortgage.purchasePriceFormatted}</p>

                            <p className="tight">Fixed Term Years Proposed</p>
                            <p className="impact mb-3">{mortgage.fixedTermYearsProposed ? mortgage.fixedTermYearsProposed + " Years fixed*" : "Variable rate"}</p>

                            <p className={"tight"}>Property Details</p>
                            {!mortgage.propertyAddress &&
                                <button className='btn btn-standard s mb-5' onClick={() => { this.setState({ navigateToProperty: true }) }}>Enter Property Details</button>
                            }
                            {mortgage.propertyAddress &&
                            <>
                                <p className="impact mb-3">{mortgage.propertyAddress!.replace(", Ireland", "").replace(" Ireland", "")}</p>
                            </>
                            }
                            <p className={"tight"}>Solicitor</p>
                            {solicitor && solicitor.address1Line1 && solicitor.companyName &&
                                <>
                                    <p className="impact tight">{solicitor.companyName}</p>
                                    <p className="impact tight">{solicitor.address1Line1!.replace(", Ireland", "").replace(" Ireland", "")}</p>
                                    <p className="impact tight">{solicitor.mobile}</p>
                                </>
                            }
                            {(!solicitor || !solicitor.address1Line1 || !solicitor.companyName) && 
                                <button className='btn big btn-dark mt-3' onClick={() => { this.setState({ navigateToSolicitor: true }) }}>+ solicitor details</button>
                            }                        
                        </div>
                        <button className='btn btn-standard-transparent btn-outline btn-fw mt-2' onClick={() => window.Intercom('showNewMessage')}>Speak to my Advisor</button>
                        <h6 className="pt-3">*Your rate will be confirmed at draw down</h6> 
                        <h6>(currently {mortgage.offeredInterestRate}%, subject to change)</h6>
                    </>
                }
            </div>
        </>);

        let redirectToDocuments = (<>
            <div key={'completion-' + mortgage.mortgageId} className={`boxty tall ${this.getStatusClass(LoanCompletion.mortgagedetails)} `}>
                <h1 className="pb-0">Additional Documentation Required</h1>

                <p className="mb-3">Please upload additional documents here for formal approval and loan offer.</p>

                <div className="btn btn-dark next fw big" onClick={() => this.setState({ navigateToDocumentsPage: true })}>Documents</div>
            </div>
        </>);



        let mortgageDetailsTile = 
            (this.passedLenderDetailsHoldingScreen())
            ? mortgageDetailsMini 
            : mortgageDetailsLarge;

        let contents = (
            <>
                <PortalPage className={`bump-right ${allDone || !this.passedLenderDetailsHoldingScreen() ? 'complete' : ''}`}
                    title={title}                    
                    description={description}>
                    <>
                        {!this.passedLenderDetailsHoldingScreen() && 
                        <Row className="justify-center max-w-wide ms-5 me-5">
                            <Col>
                                {mortgageDetailsTile}
                            </Col>
                        </Row>
                        }
                        {this.passedLenderDetailsHoldingScreen() && !allDone && !mortgage.loanOfferReceived &&
                        <>
                            <Row>
                            {context.mortgage?.type === MortgageType.PurchasingaNewHome && this.state.hasHouseHunterApprovedAIPOnly &&
                            <Col xs={4} className="mb-3">
                                    {redirectToDocuments}
                            </Col>
                            }
                            <Col xs={4} className="mb-3">
                                {mortgageDetailsTile}
                            </Col>
                            <Col xs={4} className="mb-3">
                                {loanOfferTile}
                            </Col>
                            {context.mortgage?.type === MortgageType.RemortgagingExistingLoan && context.mortgage!.confirmedTitleDeeds !== YesNoBlank.Yes &&
                                <Col xs={4} className="mb-3">
                                    <ConfirmTitleDeedsTile className='tall' confirmedTitleDeeds={this.confirmTitleDeeds} />
                                </Col>
                            }
                            {!mortgage.hasInsuranceQuote &&
                                <Col xs={4}>
                                    <LifeCoverTile padding={false} onDone={this.refresh} />
                                </Col>
                            }
                            {context.mortgage?.isEsisAvailable && !context.mortgage!.isEsisDownloaded &&
                                <Col xs={4} className="mb-3">
                                    <ESISTile className='tall'/>
                                </Col>
                            }
                            {context.mortgage?.isCostOfCreditAvailable && !context.mortgage!.isEsisDownloaded &&
                                <Col xs={4} className="mb-3">
                                    <CostOfCreditTile className='tall'/> 
                                </Col>
                            }
                            </Row>
                        </>
                        }
                        {this.passedLenderDetailsHoldingScreen() && !allDone && mortgage.loanOfferReceived &&
                        <>
                        <Row>
                            <Col xs={4} className="mb-3">
                                {mortgageDetailsTile}
                            </Col>
                            <Col xs={4} className="mb-3">
                                {loanOfferTile}
                            </Col>
                            <Col xs={4} className="mb-3">
                                 {conditionalDocsTile}
                            </Col>
                            {context.mortgage?.type === MortgageType.RemortgagingExistingLoan && context.mortgage!.confirmedTitleDeeds !== YesNoBlank.Yes &&
                                <Col xs={4} className="mb-3">
                                    <ConfirmTitleDeedsTile className='tall' confirmedTitleDeeds={this.confirmTitleDeeds} />
                                </Col>
                            }
                            {!mortgage.hasInsuranceQuote &&
                                <Col xs={4}>
                                    <LifeCoverTile padding={false} />
                                </Col>
                            }
                            {context.mortgage?.isEsisAvailable && !context.mortgage!.isEsisDownloaded &&
                                <Col xs={4} className="mb-3">
                                    <ESISTile className='tall'/>
                                </Col>
                            }
                            {context.mortgage?.isCostOfCreditAvailable && !context.mortgage!.isCostOfCreditDownloaded &&
                                <Col xs={4} className="mb-3">
                                    <CostOfCreditTile className='tall'/> 
                                </Col>
                            }
                        </Row>
                        </>
                        }
                        {this.passedLenderDetailsHoldingScreen() && allDone &&
                        <>
                        <Row className="pb-3">
                            <Col lg={8}>
                                {referralTile}
                            </Col>
                            <Col lg={4}>
                                <SpreadTheWordTile />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <RateCheckerTile />
                            </Col>
                            <Col lg={4}>
                                {mortgageDetailsTile}
                            </Col>
                            <Col lg={4}>
                                <StayInformedTile />
                            </Col>
                        </Row>
                        </>
                        }

                    </>
                    <Modal fullscreen="lg-down" show={!this.state.closeDateCollapsed} onHide={() => { this.setState({ closeDateCollapsed: true }); }} centered>
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <Container>
                                <Row>
                                    <Col>
                                        <FormControl light icon="far fa-calendar" field={this.state.sharedFields.getFieldByName("clientAdvisedClosingDate")} ></FormControl>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            {this.state.savingCloseDate &&
                                <Icons.Spinner animation="border" role="status" />
                            }
                            {!this.state.savingCloseDate &&
                                <button className="btn btn-standard btn-wide" onClick={() => { this.setClientAdvisedClosingDate(); }}>
                                    Save
                                </button>
                            }
                        </Modal.Footer>
                    </Modal>
                </PortalPage>
            </>
        );

        return contents;
    }

    async populateData() {
        const context = this.context as UserContextState;
        let applicant:PortalContactModel = (context.contact?.contact1Id === context.mortgage?.primaryApplicant.contactId ? context.mortgage?.primaryApplicant : context.mortgage?.secondaryApplicant)!;

        if (context.contact === null)
            return;

            axios.get("/AIPApplications/GetAIPApplicationsByMortgageId?mortgageId=" + context.contact.mortgageId)
                .then((response) => {
                    let aips: PortalAIPApplicationModel[] = response.data;
                    let chosenAip: PortalAIPApplicationModel | undefined = aips.find(a => a.lenderChoice === context.mortgage?.chosenLender && a.type === AIPType.FullApplication);
                    if (chosenAip === undefined) aips.find(a => a.lenderChoice === context.mortgage?.chosenLender);
                    let houseHunterAIPCount: number = aips.filter(a => a.type === AIPType.HouseHunter && (a.aipDdlStatus === AIPDDLStatus.ApprovedByLender || a.aipDdlStatus === AIPDDLStatus.Proceeding)).length;
                    let fullAIPCount: number = aips.filter(a => a.type === AIPType.FullApplication && (a.aipDdlStatus === AIPDDLStatus.ApprovedByLender || a.aipDdlStatus === AIPDDLStatus.Proceeding)).length;
                    let houseHunterApprovedAIPOnly: boolean = false;
                    if (houseHunterAIPCount > 0 && fullAIPCount === 0) { 
                        this.setState({ loading: false, chosenAip: chosenAip, hasHouseHunterApprovedAIPOnly: true, conditionsLoaded: true, contact: applicant });
                    }
                    else if (this.atLoanOfferConditions()) {
                        this.loadFiles(chosenAip, chosenAip?.specialConditions, applicant);
                    } else if (this.atLoanOfferClarifications()) {
                        axios.get("/SpecialConditions/GetLoanOfferClarificationsByMortgageId?mortgageId=" + context.contact!.mortgageId)
                            .then((response) => {
                                let clarifications: PortalClarificationModel[] = response.data;
                                if (clarifications) {
                                    this.loadFiles(chosenAip, clarifications, applicant);
                                }
                            });
                    } else if (context.mortgage?.loanOfferReceived) {
                        axios.get("/SpecialConditions/GetLoanOfferConditionsByMortgageId?mortgageId=" + context.contact!.mortgageId)
                            .then((response) => {
                                this.loadFiles(chosenAip, response.data, applicant);
                            });
                    } else {
                        this.setState({ loading: false, chosenAip: chosenAip, hasHouseHunterApprovedAIPOnly: houseHunterApprovedAIPOnly, conditionsLoaded: true, contact: applicant});
                    }
        });

    }

    private loadFiles(chosenAip: PortalAIPApplicationModel | undefined, data: any, applicant: PortalContactModel): void {
        const context = this.getContext();
        let url = `/sp/GetFiles?mortgageId=${context.mortgage?.mortgageId}`;
        axios.get(url)
            .then((response) => {
                let files = response.data;
                this.setState({ loading: false, chosenAip: chosenAip, files: files, conditions: data, clarifications: data, contact: applicant, conditionsLoaded: true });
            })
            .catch((error) => {
                this.setState({ loading: false, chosenAip: chosenAip, conditionsLoaded: true, contact: applicant });
            });
    }

}