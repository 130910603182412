import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Col, Row, Container, Stack, ButtonGroup } from "react-bootstrap";
import './Applicant.css';
import { PortalPage } from "../components/PortalPage";
import { GetTooltips } from "../helper/tooltipHelper";
import UserContextState, { UserContext } from '../components/UserContext';
import { Navigate } from "react-router-dom";
import { InputPill } from "../components/Pills/InputPill";
import { appInsightsHelper } from "../components/AppInsights";
import { SharedFieldsCollection } from "../forms/SharedFields";
import { UserContextComponent } from "../components/UserContextComponent";
import { ControlType } from "../models/PortalMetadata";
import currencyFormatter from "../helper/currencyFormatter";
import { MortgageSubType, MortgageType } from "../models/PortalMortgageModel";

export default class Shared extends UserContextComponent<any, any> {
    static contextType = UserContext;

    constructor(props: any) {
        super(props);
        this.state = {
            loading: true, mortgage: null, step: 1, helperTexts: this.props.helperTexts ?? [], fields: null
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.prev = this.prev.bind(this);
        this.next = this.next.bind(this);
    }

    componentDidMount() {
        appInsightsHelper.trackPageView({ name: "Shared" });
        let context = this.getContext();
        this.setState({mortgage: context.mortgage})
        this.getTooltips("mortgage");
    }

    getTooltips(entityname: string) {
        GetTooltips(entityname).then((response) => {
            let context = this.getContext();            
            let helperTexts = response.data;
            
            if (context.mortgage !== null) {
                let fields = new SharedFieldsCollection();
                fields.loadData(context.mortgage, helperTexts, true, this.handleChange);
                this.setState({ fields: fields, loading: false, helperTexts: helperTexts });
            }
        })
            .catch((error) => {
                if (error.response.statusCode === 401) {
                    alert("Unauthorized");
                }
            });
    }

    updateDirtyFlag() {
        let dirtyFields: any[] = [];
        for (let fieldName in this.state.fields.fields) {
            let f = this.state.fields.fields[fieldName];
            if (f.isDirty)
                dirtyFields.push(f);
        }

        var isDirty = dirtyFields.length > 0;
        var context = this.getContext();

        context.setIsDirty(isDirty);
    }

    saveAllFields(redirectTo: string | null) {

        let dirtyFields: any[] = [];
        for (let fieldName in this.state.fields.fields) {
            let f = this.state.fields.fields[fieldName];
            if (f.isDirty)
                dirtyFields.push(f);
        }

        if (dirtyFields.length === 0) {
            if (!redirectTo && this.props.next) {
                this.props.next()
            } else {
                this.setState({ redirectTo: redirectTo });
            }
            return;
        }

        let newMortgage = this.state.fields.getAsEntity("mortgageId", this.state.mortgage.mortgageId, this.state.mortgage);

        axios.post('/shared/update', newMortgage)
            .then(response => {
                for (let i = 0; i < dirtyFields.length; i++) {
                    let f = dirtyFields[i];
                    f.originalValue = f.value;
                    f.isDirty = false;
                }

                this.updateDirtyFlag();
                // BANDAID - THIS IS TO KEEP THE APPLICANT MENU IN SYNC AS IT USES THE CONTEXT  
                let context = this.getContext();
                context.mortgage!.mortgageDetailsPercentage = this.getFieldsDone() * 100 / this.getFieldCount();

                if (!redirectTo && this.props.next) {
                    this.props.next()
                } else {
                    this.setState({ redirectTo: redirectTo });
                }
            })

    }

    handleSubmit(event: any) {
        event.preventDefault();
        this.saveAllFields("/property");
    }

    handleChange(event: any) {
        const target = event.target;
        const value = (target.type === 'checkbox' ? target.checked : target.value); // || (target.textContent || '');
        const name = target.name || target.getAttribute('name');

        var mortgage = this.state.mortgage;

        if (!name)
            return;

        let f = this.state.fields.getFieldByName(name);

        //// if we don't set the value here it sometimes doesn't get set and causes a bug with reverting to the original value
        let fields = this.state.fields;
        if (fields) {
            if (f.controlType === ControlType.currency) {
                fields.updateModel(this.state.mortgage, name, value?.replaceAll(",", "").replaceAll("€", ""));
                fields.updateFieldValue(name, currencyFormatter(Number(value?.replaceAll(",", "").replaceAll("€", ""))));
            } else {
                fields.updateModel(this.state.mortgage, name, value);
                fields.updateFieldValue(name, value);
            }
        }
        this.setState({ fields: this.state.fields, mortgage: mortgage });
        this.updateDirtyFlag();
    }

    prev() {
        if (this.props.prev) {
            this.props.prev();
        } else {
            this.saveAllFields("/profile")
            //this.setState({ redirectTo: "/profile" });
        }
    }

    next() {
        var step = this.state.step;

        if (step < 5)
            step++;

        this.setState({
            step: step
        });

    }

    render() {

        if (this.state.loading) {
            return <><p>
                <em>Loading...</em></p></>;
        }

        if (this.state.redirectTo) {
            //return <Navigate to="/profile" />
            // For now we are always redirecting to the "next step" which is Property Details in the flow
            return <Navigate to={this.state.redirectTo} />
        }

        return (
            <>
                <PortalPage className="bump-right" title={<>How much would you like to borrow?</>} >
                    <Row>
                        <Col className="col-12">
                            <Form onSubmit={this.handleSubmit}>
                                <Row className="sub-title-box">
                                    <p className="subtext">It’s fine if you don’t know the exact figure yet - we can work through that with you later. For now let’s work off what you think you need.</p>
                                </Row>
                                <Container className="pill-form">
                                    <Row className="pill-row">
                                        <Stack gap={1} direction="horizontal" className="pill-stack">
                                            {this.state.mortgage.type === MortgageType.PurchasingaNewHome &&
                                                <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("purchasePrice")}></InputPill>
                                            }
                                            {this.state.mortgage.type === MortgageType.RemortgagingExistingLoan &&
                                                <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("propertyValue")}></InputPill>
                                            }
                                            <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("mortgageAmountProposed")} invalidText="Min Amount is 10,000" ></InputPill>
                                            {this.state.mortgage.type === MortgageType.RemortgagingExistingLoan && [MortgageSubType.EquityReleaseTopup,MortgageSubType.EquityReleaseNoWorks,MortgageSubType.EquityReleaseWithWorks].includes(this.state.mortgage.subType) &&
                                                <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("topUpAmount")} ></InputPill>
                                            }
                                            <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("mortgageTerm")}></InputPill>
                                            <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("preferenceForYourRate")}></InputPill>
                                            {this.state.mortgage.type === MortgageType.PurchasingaNewHome &&
                                                <>
                                                    <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("giftAmount")}></InputPill>
                                                    <InputPill hideIfValue={this.state.emptyOnly} field={this.state.fields.getFieldByName("otherSavingsAmount")}></InputPill>
                                                </>
                                            }
                                        </Stack>
                                    </Row>
                                </Container>
                                <Container>
                                    <Row>
                                        <Col className="toggle-box">
                                            <Form.Check
                                                type="switch"
                                                id="empty-fields-switch"
                                                label="Empty Fields Only"
                                                reverse
                                                onClick={() => this.setState({ emptyOnly: !this.state.emptyOnly })}
                                            />
                                        </Col>
                                    </Row>
                                    <Stack direction="horizontal" gap={3} className="text-center justify-center">
                                        <ButtonGroup>
                                            <button type="button" className="btn btn-standard-transparent btn-outline btn-wide" onClick={this.prev}>Back</button>
                                            <button type="button" className="btn btn-dark btn-wide" onClick={this.handleSubmit}>Next</button>
                                        </ButtonGroup>
                                    </Stack>
                                </Container>
                            </Form>
                        </Col>
                    </Row>
                </PortalPage>
            </>
        );

    }

    getFieldCount(): number {
        let fieldCount = 4;
        if (this.state.mortgage.type === MortgageType.PurchasingaNewHome) {
            fieldCount += 2;
        }
        if ([MortgageSubType.EquityReleaseTopup,MortgageSubType.EquityReleaseNoWorks,MortgageSubType.EquityReleaseWithWorks].includes(this.state.mortgage.subType)) {
            fieldCount += 1;
        }
        return fieldCount;
    }
    getFieldsDone(): number {
        let fieldsDone = 0;
        if (this.state.mortgage.mortgageAmountProposed >= 10000) fieldsDone++;

        if (this.state.mortgage.type === MortgageType.RemortgagingExistingLoan) {
            if (this.state.mortgage.propertyValue) fieldsDone++;
        }
        if (this.state.mortgage.type === MortgageType.PurchasingaNewHome) {
            if (this.state.mortgage.purchasePrice) fieldsDone++;
            if (this.state.mortgage.giftAmount !== "" && this.state.mortgage.giftAmount !== null) fieldsDone++;
            if (this.state.mortgage.otherSavingsAmount !== "" && this.state.mortgage.otherSavingsAmount !== null) fieldsDone++;
        }
        if ([MortgageSubType.EquityReleaseTopup,MortgageSubType.EquityReleaseNoWorks,MortgageSubType.EquityReleaseWithWorks].includes(this.state.mortgage.subType)) {
            if (this.state.mortgage.topUpAmount) fieldsDone++;
        }
        if (this.state.mortgage.mortgageTerm) fieldsDone++;
        if (this.state.mortgage.preferenceForYourRate) fieldsDone++;
        return fieldsDone;
    }
}