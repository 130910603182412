import React from 'react';
import axios from 'axios';
import { useMsal, useAccount } from '@azure/msal-react';
import { loginApiRequest } from '../helper/authConfig';

interface RequestInterceptorProps {
  children: JSX.Element,
}

const RequestInterceptor: React.FC<RequestInterceptorProps> = ({ children }: RequestInterceptorProps) => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  axios.interceptors.request.use(async (config) => {
    if (!account) {
      throw Error('No active account! Verify a user has been signed in.');
    }

    const response = await instance.acquireTokenSilent({
      ...loginApiRequest,
      account,
    });

    const bearer = `Bearer ${response.accessToken}`;
    if (config.headers)
      config.headers.Authorization = bearer;

    return config;
  });

  axios.interceptors.response.use((config) => { return config; },
    (error) => {
      if (error.errorCode === 'interaction_required') {
        instance.logoutRedirect({
          postLogoutRedirectUri: "/",
        });
      }
      // if(error?.code === "ERR_NETWORK") {
      //   return Promise.reject(error);
      // }
      // else if (error.errorCode === 'interaction_required') {
      //   instance.logoutRedirect({
      //     postLogoutRedirectUri: "/",
      //   });
      // }
      // // } else {
      // //   console.error(error);
      // //   console.error(JSON.stringify(error));           

      // //   navigate("/error");
      // //   return Promise.reject(error);
      // // } 
      return Promise.reject(error);
    });

  return (
    <>
      {children}
    </>
  );
};

export default RequestInterceptor;