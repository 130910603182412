import { Component } from 'react';

export class GoToLoginPage extends Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    render() {
            return (<p><em>Redirecting to the sign in page...</em></p>);
    }
}
