import { Component } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { ApplicantFieldsCollection } from "../forms/ApplicantFields";
import "./SelfEmployment.css";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { FormControl } from "./FormControl";
import { Address } from "./Address";

interface ISelfEmploymentProps {
    helperTexts: any[];
    record: any;
    fields: ApplicantFieldsCollection;
    handleChange(event: any): void;
}

interface ISelfEmploymentState {
    helperTexts: any[];
    record: any;
    fields: ApplicantFieldsCollection;
    addressIsValid: boolean;
}

export class SelfEmployment extends Component<ISelfEmploymentProps, ISelfEmploymentState> {
    constructor(props: any) {
        super(props);
        this.state = {
            helperTexts: [],
            record: props.record,
            fields: props.fields,
            addressIsValid: true,
        }
    }

    render() {
        return (
            <Container className="selfemployment-container">
                <Row>
                    <Col>
                        <FormControl light field={this.state.fields.getFieldByName("companyName")} ></FormControl>
                    </Col>
                </Row>
                <Address helperTexts={this.state.helperTexts} record={this.state.record} fields={this.state.fields}
                    handleChange={this.props.handleChange} address="businessAddress1" letManual={false} />
                        {/* <FormControl light field={this.state.fields.getFieldByName("businessAddress1")} ></FormControl> */}
                <Row>
                    {/* <Col>
                        <FormControl light field={this.state.fields.getFieldByName("businessAddress2")} ></FormControl>
                    </Col> */}

                    <Col>
                        <FormControl light field={this.state.fields.getFieldByName("natureOfBusiness")} ></FormControl>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormControl light field={this.state.fields.getFieldByName("accountantsName")} ></FormControl>

                    </Col>

                    <Col>
                        <FormControl light field={this.state.fields.getFieldByName("shareholdingPercentage")} ></FormControl>
                    </Col>
                </Row>
                <Row>
                    <Col>

                        <FormControl light field={this.state.fields.getFieldByName("businessEstablishedDate")} ></FormControl>
                    </Col>

                    <Col>

                    </Col>
                </Row>
            </Container>
        );
    }
}