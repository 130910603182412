// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import axios from 'axios';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { logger } from '../helper/Logger';
import { Component } from 'react';

// Register the plugin
registerPlugin(FilePondPluginFileEncode);
registerPlugin(FilePondPluginFileValidateType);

interface IState {
    uploading: boolean;
}

//export function FileUploadLegacy(props) {
export class FileUploadLegacy extends Component<any, IState>{
    constructor(props: any) {
        super(props);
        this.state = {uploading: false};
        this.uploadStarted = this.uploadStarted.bind(this);
        this.uploadComplete = this.uploadComplete.bind(this);
    }
    
    uploadStarted() {
        this.setState({uploading: true});
    }

    uploadComplete() {
        this.setState({uploading: false});
    }

    render() {
        var uploadComplete = this.uploadComplete;
        return <>
            <div className='progresscontainer'>
                {this.state.uploading &&
                    <div className='progressfile'>Processing... <i className="fas fa-spinner fa-spin-pulse" style={{padding: 3}}></i></div>
                }
                <FilePond allowMultiple={true} allowFileTypeValidation={true} 
                    fileValidateTypeLabelExpectedTypes={""} labelFileTypeNotAllowed={"Only PDF, PNG or JPEG allowed"}
                    acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg', 'application/pdf']} onaddfile={(error, file) => {
                        if (error) {
                            return;
                        }
                        this.uploadStarted();
                        var form = new FormData();
                        form.append('file', file.file);

                        axios.post("/SP/UploadFile", form,
                            {
                                // "content": file.getFileEncodeBase64String(),
                                params: { "contactId": this.props.contactId, "category": this.props.category, "mortgageDocumentId": this.props.mortgageDocumentId, "mortgageId": this.props.mortgageId, "uploadId": file.id, "url": "", "fileName": file.filename },
                                maxBodyLength: 2147483647,
                                headers: { "Content-Type": "multipart/form-data" }
                            })
                            .then((response) => {
                                uploadComplete();
                                if (this.props.onUploadComplete) {
                                    this.props.onUploadComplete(response.data, file.id);
                                }
                            })
                            .catch((error) => {
                                uploadComplete();
                                logger.log(error);
                            });
                    }}
                    onremovefile={(error, file) => {
                        if (error) {
                            return;
                        }
                        this.uploadStarted();
                        axios.post("/SP/RemoveFile", null,
                            {
                                params: { "filename": file.filename, "mortgageId": this.props.mortgageId, "uploadId": file.id }
                            })
                            .then((response) => {
                                uploadComplete();
                                if (this.props.onDeleteComplete) {
                                    this.props.onDeleteComplete(response.data);
                                }
                            })
                            .catch((error) => {
                                uploadComplete();
                                logger.log(error);
                            });
                    }}
                />
            </div>
            <br />
        </>;
    }
}

export const SPCategory = Object.freeze({
    Saving: "Savings Statement",
    Current: "Current Account Statement"
});