import axios from "axios";
import React from "react";
import { Button, Col, Container, Modal, Row, Stack } from "react-bootstrap";
import * as Icons from "react-bootstrap";
import { MortgageType, PortalMortgageModel } from "../../models/PortalMortgageModel";
import { UserContextComponent } from "../UserContextComponent";
import { Link, Navigate } from "react-router-dom";

export interface IInsuranceQuoteButtonProps {
  mortgage: PortalMortgageModel;
  alreadyQuoted: boolean;
  mini?: boolean;
}

interface InsuranceQuoteState {
  showConfirm: boolean;
  confirmation: boolean;
  loader: boolean;
  missingDetails: boolean;
  missingDetailsResponse?: ProfileCheckResponse;
}

export interface ProfileCheckResponse {
  missingDetails: boolean;
  missingApplicant2: boolean;
  missingApplicant1Fields: string[];
  missingApplicant2Fields: string[];
}
export class InsuranceValidator {
  public static profileComplete(mortgage: PortalMortgageModel):ProfileCheckResponse {
    var response:ProfileCheckResponse = {missingDetails: false, missingApplicant2: false, missingApplicant1Fields: [], missingApplicant2Fields: []};

    var applicant1 = mortgage.primaryApplicant;
    if (applicant1.birthDate === null) {
      response.missingApplicant1Fields.push("Date of birth")
    }
    if (applicant1.smoker === null) {
      response.missingApplicant1Fields.push("Smoker")
    }
    if (applicant1.sex === null) {
      response.missingApplicant1Fields.push("Gender")
    }

    if (mortgage?.applicationType === "Joint") {
      if (!mortgage?.secondaryApplicant) {
        response.missingApplicant2 = true;
      } else {
        var applicant2 = mortgage.secondaryApplicant;
        if (applicant2.birthDate === null) {
          response.missingApplicant2Fields.push("Date of birth")
        }
        if (applicant2.smoker === null) {
          response.missingApplicant2Fields.push("Smoker")
        }
        if (applicant2.sex === null) {
          response.missingApplicant2Fields.push("Gender")
        }
      }
    }

    response.missingDetails = response.missingApplicant2 || (response.missingApplicant1Fields.length + response.missingApplicant2Fields.length > 0);
    return response;
  }
}

export class InsuranceQuoteButton extends React.Component<IInsuranceQuoteButtonProps, InsuranceQuoteState> {
  
  constructor(props: IInsuranceQuoteButtonProps) {
    super(props);
    this.requestAQuote = this.requestAQuote.bind(this);
    this.state = {confirmation: false, loader: false, showConfirm: false, missingDetails: false};
  }

  private requestAQuote() {
    var missingDetailsResponse = InsuranceValidator.profileComplete(this.props.mortgage!); 
    if (missingDetailsResponse.missingDetails) {
      this.setState({missingDetails: true, missingDetailsResponse: missingDetailsResponse});
      return;
    }

    this.setState({loader: true});

    axios.post('/AIPApplications/RequestAQuote', null, { params: { mortgageId: this.props.mortgage.mortgageId } })
      .then(response => {
        this.setState({confirmation: true, loader: false});
      });
  }

  render() {
    if (this.state.loader)
      return <button className="btn btn-light mini"><Icons.Spinner size="sm" animation="border" role="status" /></button>;

    if (this.state.confirmation)
      return (this.props.mini ? <div className="btn btn-light mini nohover">done</div> : <div className="doddltext">We have received your request and a quote should arrive shortly</div>)

    return (
        <>
        {!this.props.mini &&
        <div className="cta-div">
          <span className="doddl-white text-start">Simplifying insurance so that you understand what policy best suits your needs at any current stage of life, at no cost to you.</span>
        </div>
        }
        {this.props.alreadyQuoted ?
          (this.props.mini ? <button className="btn btn-standard mini" onClick={(e) => {this.setState({showConfirm: true})}}>quoted</button> : <button disabled className="btn btn-secondary" onClick={(e) => { this.requestAQuote(); }}>Quote generated</button>)
          :
          (this.props.mini ? <button className="btn btn-standard mini" onClick={(e) => { this.requestAQuote(); }}>quote</button> : <button className="btn btn-secondary next" onClick={(e) => { this.requestAQuote(); }}>Get covered</button>)
        }
        <Modal centered size="lg" fullscreen="lg-down"  show={this.state.showConfirm} onHide={() => {this.setState({showConfirm: false})}}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center mb-5 ps-5 pe-5">
                    <h1 className="impact">Life cover</h1>
                    <p className="">Your quote should arrive in your email inbox within 5 minutes, If you have not received it, or would like a new quote, please contact your advisor</p>
                </div>
                <Stack direction="horizontal"  gap={3} className="text-center justify-center">
                    <button className="btn medium btn-light" onClick={() => {this.setState({showConfirm: false})}}>Close</button>
                </Stack>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
        <Modal centered size="lg" fullscreen="lg-down"  show={this.state.missingDetails} onHide={() => {this.setState({missingDetails: false})}}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center mb-5 ps-5 pe-5">
                    <h1 className="impact">We need more details</h1>
                    <p className="">The following details are needed before we can generate a quote</p>
                </div>
                <Container>
                  <Row className="justify-center">
                      <>
                      {this.state.missingDetailsResponse && this.state.missingDetailsResponse.missingApplicant1Fields.length > 0 &&
                      <Col xs={6}>
                        <div className="boxty box-sage auto-margin">
                          <div className="box-block-80 text-start">
                            <h2>{this.props.mortgage?.primaryApplicant.firstName} </h2>
                            {this.state.missingDetailsResponse?.missingApplicant1Fields.map((item:string, idx: number) => {
                              return <p key={`app1-missing-${idx}`}><i className="fa-solid fa-circle-chevron-right"></i> {item}</p>;
                            })}
                          </div>
                          <Link to={'/personal1'} className="btn fw big btn-secondary next-secondary pe-5 mt-3 a-s-e" onClick={(e) => { this.setState({missingDetails: false}) }}>Go to profile</Link>
                        </div>
                      </Col>
                      }
                      {(this.state.missingDetailsResponse && (this.state.missingDetailsResponse.missingApplicant2 || this.state.missingDetailsResponse.missingApplicant2Fields.length > 0)) &&
                      <Col xs={6}>
                        <div className="boxty box-sage auto-margin">
                          <div className="box-block-80 text-start">
                            <h2>{this.props.mortgage?.secondaryApplicant?.firstName ?? 'Missing Applicant'} </h2>
                            {this.state.missingDetailsResponse?.missingApplicant2Fields.map((item:string, idx: number) => {
                              return <p key={`app2-missing-${idx}`}><i className="fa-solid fa-circle-chevron-right"></i> {item}</p>;
                            })}
                            </div>
                            <Link to={'/personal2'} className="btn fw big btn-secondary next-secondary pe-5 mt-3 a-s-e" onClick={(e) => { this.setState({missingDetails: false}) }}>Go to profile</Link>
                        </div>
                      </Col>
                      }
                      </>
                  </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
        </>
    );
  }
}