import './Documents.css';
import { UserContextComponent } from '../components/UserContextComponent';
import axios from 'axios';
import { Row, Col, Button } from 'react-bootstrap';
import { PortalPage } from '../components/PortalPage';
import UserContextState from '../components/UserContext';
import { PortalAIPApplicationModel } from '../models/PortalAIPApplication';
import "./AIPApplication.css";
import "./LenderConfirmation.css";
import React from 'react';
import * as Icons from "react-bootstrap";
import { ApplicantMenu } from '../components/ApplicantMenu';
import { SideNav } from '../components/SideNav';

interface ILenderConfirmationState {
    loading: boolean;
    applications: PortalAIPApplicationModel[];
}

export class LenderConfirmation extends UserContextComponent<any, ILenderConfirmationState> {
    constructor(props: any) {
        super(props);
        this.state = { loading: true, applications: []};
    }

    componentDidMount() {
        this.populateData();
    }

    confirmApplication(application: PortalAIPApplicationModel): void {
        const context = this.getContext();
        application.confirming = true;
        this.setState({ applications: this.state.applications});
        axios.post('/AIPApplications/ConfirmApplication', null, { params: { mortgageId: context.mortgage?.mortgageId, applicationId: application.aipApplicationId } })
            .then(response => {
                application.confirmed = true;
                application.confirming = false;
                this.setState({ applications: this.state.applications });
            });
    }

    render() {
        let context = this.getContext();
            
        if (this.state.loading || context.mortgage === null)
            return (<><p><em>Loading...</em></p></>);

        return (
            <>
            <PortalPage className="bump-right" title={"Lender Confirmation"} description={""} subtitle={"Please confirm you are happy for us to apply for Approval in Principle on your behalf below"}>
                <>
                    <Row>
                        <Col md={1}></Col>
                        <Col md={10}>
                            <Row className="justify-content-center">
                                {this.state.applications !== null && this.state.applications.map((item) => (
                                    <Col md={4} sm={12} key={item.aipApplicationId} style={{ marginBottom: '10px' }}>
                                        <div className={"box aip-submitted"} style={{position: "relative", paddingBottom: 80}}>
                                            <h1>{item.lender}</h1>
                                            <p>We will submit as application for an Approval in Principle to {item.lender} on your behalf for <span>{item.amount}</span></p>
                                            {item.specialOffers != null && <>
                                                <h1>Special Offers</h1>
                                                <p>{item.specialOffers}</p>
                                            </>}
                                            <h1>Happy to proceed?</h1>
                                            <p>Please confirm you are happy for us to proceed once ready.</p>
                                            <div style={{position: "absolute", width: "100%", paddingBottom: 20, left: 0, bottom: 0}}>
                                            {item.confirming === true &&
                                                <Icons.Spinner animation="border" role="status" />
                                            }
                                            {item.confirming !== true && item.confirmed === false &&
                                                <Button className="btn btn-standard" variant="secondary" onClick={e => { this.confirmApplication(item); }}>Proceed</Button>
                                            }
                                            {item.confirming !== true && item.confirmed === true &&
                                                <Button className="btn btn-standard" variant="secondary" disabled={true}>Confirmed</Button>
                                            }
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                        <Col md={1}></Col>
                    </Row><br />
                    <Row>
                        <Col md={12} sm={12}>
                            <h1>What's next</h1>
                            <p>Once we have completed your profile and all the documentation we will prepare your application form and will send it to you for signing via DocuSign.</p>
                        </Col>
                    </Row>
                </>
            </PortalPage>
            </>
        );
    }

    async populateData() {
        const context = this.context as UserContextState;
        if (context.contact === null)
            return;

        axios.get("/AIPApplications/GetAIPApplicationsByMortgageId?mortgageId=" + context.contact.mortgageId)
            .then((response) => {
                this.setState({ applications: response.data, loading: false });
            });
    }
}
