import { Component } from "react";
import { Col, Container, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { ApplicantFieldsCollection } from "../../forms/ApplicantFields";
import { DataType } from "../../models/PortalMetadata";
import { IAggregateListItem } from "../Pills/AggregatePill";
import { IAggregatePillOverlayProps, AggregatePillOverlay } from "../Pills/AggregatePillOverlay";
import { UserContext } from "../UserContext";
import "./Children.css";

export interface IChildrenProps extends IAggregatePillOverlayProps {
    items: IAggregateListItem[],
    fields: ApplicantFieldsCollection,
    save: any,
    record: any,
    hasAny: boolean
}
interface IChildrenState {
    items: IAggregateListItem[],
    fields: ApplicantFieldsCollection,
    childrenNumber: number,
    ages: { key: string, value: number }[],
    showItems: boolean,
    record: any
}

export class Children extends AggregatePillOverlay<IChildrenProps, IChildrenState> {
    static contextType = UserContext;

    constructor(props: any) {
        super(props);
        this.state = {
            items: this.props.items,
            fields: this.props.fields,
            childrenNumber: this.props.fields.getFieldValueByName("childrenNumber", this.props.record) !== undefined ? parseInt(this.props.fields.getFieldValueByName("childrenNumber", this.props.record).toString()) : 0,
            ages: [],
            showItems: this.props.hasAny,
            record: this.props.record
        };
        this.hasChildrenOnChange = this.hasChildrenOnChange.bind(this);
        this.childrenNumberOnChange = this.childrenNumberOnChange.bind(this);
        this.setAges = this.setAges.bind(this);
        this.save = this.save.bind(this);
        this.decrease = this.decrease.bind(this);
        this.increase = this.increase.bind(this);
    }

    componentDidMount() {
        this.setAges(this.state.childrenNumber);
    }

    close(): void {
        if (this.props.closePill)
            this.props.closePill();
    }

    hasChildrenOnChange(hasChildren: number) {
        let fields = this.state.fields;
        if (fields) {
            fields.updateModel(this.state.record, "hasChildren", hasChildren);
            fields.updateFieldValue("hasChildren", hasChildren);
        }
        this.setState({ fields: this.state.fields });

        if (hasChildren === 0) {
            this.props.save([], this.state.fields);
            this.close();
        }
        else {
            this.setState({ showItems: true });
        }
    }

    setAge(event: any, key: string) {
        const target = event.target;
        let value = target.value;
        if (value == "")
            value = 0;
        this.setAgeValue(key, value);
    }

    childrenNumberOnChange(event: any) {
        const target = event.target;
        let value = target.value;
        const name = target.name || target.getAttribute('name');

        if (!name) return;

        let f = this.state.fields.getFieldByName(name);
        if (f.dataType === DataType.number && value !== null) {
            value = value.replace(/\D/g, '');
        }

        let fields = this.state.fields;
        if (fields) {
            fields.updateModel(this.state.record, name, parseInt(value));
            fields.updateFieldValue(name, parseInt(value));
        }
        this.setState({ fields: this.state.fields, childrenNumber: parseInt(value) });

        if (isNaN(value))
            value = 0;

        this.setAges(parseInt(value));
    }

    setAges(childrenNumber: number) {
        let ages: { key: string, value: number }[] = [];
        if (this.state.items.length != 0 && this.state.items.length === childrenNumber) {
            this.state.items.forEach((item) => ages.push({ key: item.key, value: parseInt(item.values[0]) }));
        }
        else if (this.state.items.length < childrenNumber) {
            this.state.items.forEach((item, i) => ages.push({ key: item.key, value: parseInt(item.values[0]) }));

            for (var i = this.state.items.length; i < childrenNumber; i++) {
                ages.push({ key: i.toString(), value: 0 });
            }
        }
        else {
            for (var i = 0; i < childrenNumber; i++) {
                ages.push({ key: this.state.items[i].key, value: parseInt(this.state.items[i].values[0]) })
            }
        }
        this.setState({ ages: ages });
    }

    save() {
        let items: IAggregateListItem[] = [];
        this.state.ages.forEach((a, i) => {
            items.push({ key: a.key, values: [a.value as any] });
        })

        this.props.save(items, this.state.fields);
        this.close();
    }

    increase(a: { key: string, value: number }) {
        let newValue: number = a.value + 1;
        this.setAgeValue(a.key, newValue);
    }

    decrease(a: { key: string, value: number }) {
        let newValue: number = a.value === 0 ? 0 : a.value - 1;
        this.setAgeValue(a.key, newValue);
    }

    setAgeValue(key: string, value: number) {
        if (isNaN(value))
            value = 0;

        let items: IAggregateListItem[] = this.state.items;
        let item: IAggregateListItem | undefined = items.find(x => x.key === key);
        if (!item)
            items.push({ key: key, values: [value as any] });
        else {
            item.values = [];
            item.values.push(value as any);
        }
        this.setState({ items: items });
        this.setAges(this.state.childrenNumber);
    }

    render() {
        let ageConst: any[] = [];
        this.state.ages.forEach((a, i) => ageConst.push(
            <div key={a.key} className="justify-center" style={{ display: "flex" }}>
                <InputGroup className="mb-3" style={{ maxWidth: 200 }}>
                    <InputGroup.Text><i className="far fa-square-minus fa-2xl" onClick={(e) => this.decrease(a)}></i></InputGroup.Text>
                    <Form.Control className="children-age-control" htmlSize={3} name={"age" + i.toString()} type="text" value={this.state.ages[i].value} onChange={e => { this.setAge(e, a.key); }} />
                    <InputGroup.Text><i className="far fa-square-plus fa-2xl" onClick={(e) => this.increase(a)}></i></InputGroup.Text>
                </InputGroup>
            </div >));

        return (
            <>
                <Modal.Body>
                    {!this.state.showItems &&
                        <Container className="text-center">
                            <h3>Do you have any dependants?</h3>
                            <br />
                            <button className="btn btn-standard btn-outline btn-wide" onClick={e => this.hasChildrenOnChange(1)}>Yes</button>&nbsp;
                            <button className="btn btn-standard btn-outline btn-wide" onClick={e => this.hasChildrenOnChange(0)}>No</button>
                        </Container>
                    }
                    {this.state.showItems &&
                        <Container className="text-center">
                            <h3>Number of dependants</h3>
                            <Row>
                                <Col></Col>
                                <Col className="col-10 children-container">
                                    <Form.Control name="childrenNumber" placeholder={this.state.fields.getFieldByName("childrenNumber").placeholder ?? ""} type="number" value={this.state.fields.getFieldValueByName("childrenNumber", this.state.record)}
                                        onChange={e => { this.childrenNumberOnChange(e); }} />
                                </Col>
                                <Col></Col></Row>
                            <br />
                            <Row className="text-center">
                                {this.state.ages.length > 0 &&
                                    <h3 className="children-ages-title">Ages</h3>
                                }
                                {ageConst.map((a) => a)}
                            </Row>
                        </Container>
                    }
                </Modal.Body>
                {this.state.showItems && <Modal.Footer>
                    <Container>
                        <Row className="justify-center">
                            <button className="btn btn-standard btn-wide" onClick={() => { this.save(); }}>Done</button>
                        </Row>
                    </Container>
                </Modal.Footer>}
            </>
        );
    }
}