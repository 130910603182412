
import React from "react";
import { Component } from "react";
import { ControlType, Field } from "../../models/PortalMetadata";
import { FormControl } from "../FormControl";
import { Tooltip } from "../Tooltip";
import { Pill } from "./Pill";
import { IPillElementModel } from "./PillElement";

interface IInputPillProps {
  field: Field;
  hideIfValue: boolean | null;
  isInvalid?(value:string|number|undefined):boolean;
  invalidText?:string;
  extraClasses?:string;
  customLabel?:string;
  disableCollapse?: boolean;
  usePlaceHolderForMinWidth?: boolean;
  readonly?: boolean;
}

interface IInputPillState {
  hideIfValue: boolean | null;
  toggleState?: boolean;
}


export class InputPill extends Component<IInputPillProps, IInputPillState> {

  constructor(props: IInputPillProps) {
    super(props);
    this.focus = this.focus.bind(this);
    this.state = { hideIfValue: false, toggleState: false };
  }

  componentDidUpdate() {
    if (this.state.hideIfValue !== this.props.hideIfValue)
      this.setState({ hideIfValue: this.props.hideIfValue });
  }

  componentDidMount() {
    if (this.state.hideIfValue !== this.props.hideIfValue)
      this.setState({ hideIfValue: this.props.hideIfValue });
  }

  getPillClass() {
    return "notstarted";
  }

  focus(field: Field) {
    if (field.controlType === ControlType.text || field.controlType === ControlType.textarea || field.controlType === ControlType.number || field.controlType === ControlType.decimal
      || field.controlType === ControlType.currency) {
      let inputs = document.getElementsByName(field.name);
      if (inputs !== null && inputs[0]) {
        inputs[0].focus();
      }
    }
  }

  isFocused():boolean {
    let focused = false;
    let inputs = document.getElementsByName(this.props.field.name);
    if (inputs !== null && inputs[0] && inputs[0].id === document.activeElement!.id) {
      focused = true;
    }
    return focused;
  }

  render() {
    if (this.state.hideIfValue && (this.props.field.value || this.props.field.optional) && !this.isFocused()) {
      return "";
    }
      
    var components: IPillElementModel[] = [];
    components.push({
      leftBorder: true,
      rightBorder: ((this.props.customLabel ?? this.props.field.label) !== ""),
      focus: () => {this.focus(this.props.field);},
      content: <Tooltip tooltip={this.props.field.tooltip}><div onClick={() => this.focus(this.props.field)}>{this.props.customLabel ?? this.props.field.label}</div></Tooltip>
    });

    var f = this.props.field;
    let text = f.controlType === ControlType.date ? "" : f.value;
    if (f.controlType === ControlType.lookup) {
      let item = f.lookupValues?.find(x => { return x.value === f.value });
      if (item)
        text = item.text;  
    }

    components.push({
      leftBorder: false,
      rightBorder: true,
      content: <div onBlur={() => {this.setState({toggleState: !this.state.toggleState})}} className={this.props.field.controlType === ControlType.date ? 'date-popup-override' : ''}>
        <FormControl 
          readOnly={this.props.readonly === true}
          fieldOnly={true}
          field={this.props.field} 
          isInvalid={this.props.isInvalid}
          invalidText={this.props.invalidText}
          ></FormControl>
        <span style={{ visibility: "hidden", height: "1px" }}>{text}</span>
        {this.props.usePlaceHolderForMinWidth && 
            <span style={{ visibility: "hidden", height: "1px" }}>{this.props.field.placeholder}</span>
        }
      </div>
    });


    return (
      <Pill disableCollapse={this.props.disableCollapse ?? (f.controlType===ControlType.date ? true : false)} components={components} empty={!f.value} extraClass={`${(!this.props.field.optional && !this.props.field.value ? "pill-missing" : "")} ${(this.props.extraClasses ?? "")}`}></Pill>
    );
  }
}
