import React, { Component } from 'react';
import { Button, Col, Container, Row } from "react-bootstrap";
import { HelpBox } from "../components/HelpBox";
import './Documents.css';
import { PortalPage } from "../components/PortalPage";
import { UserContext } from '../components/UserContext';
import UserContextState from '../components/UserContext';
import axios from 'axios';
import { MortgageStatus, PortalMortgageModel } from '../models/PortalMortgageModel';
import { FileUploadLegacy } from '../components/FileUploadLegacy';
import { appInsightsHelper } from '../components/AppInsights';

interface IDocumentReviewProps {
}

interface IDocumentReviewState {
    loading: boolean;
    mortgage: PortalMortgageModel | null;
    selectedDocument: string | null;
    specialConditionsId: string | null;
    documents: any[]
}

export class DocumentReview extends Component<IDocumentReviewProps, IDocumentReviewState> {
    static contextType = UserContext;
    
    constructor(props: any) {
        super(props);
        this.state = { loading: false, mortgage: null, selectedDocument: null, specialConditionsId: null, documents: [] };
        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this.onUploadComplete = this.onUploadComplete.bind(this);
    }
    componentDidMount() {
        appInsightsHelper.trackPageView({ name: "Document Review" });
        this.populateData();
    }

    onUploadComplete(data: any) {
        axios.post('/SpecialConditions/UpdateSpecialConditionsFileUrl',null, { params: { specialConditionsId: this.state.specialConditionsId, url: data }})
            .then(response => {
                var filteredDocuments = this.state.documents.filter(x => {
                    return x.specialConditionsId !== this.state.specialConditionsId;                    
                });
                this.setState({ documents: filteredDocuments, selectedDocument: null, specialConditionsId: null });
            });
    }

    handleDocumentClick(event: any) {
        event.preventDefault();
        const target = event.target;
        this.setState({ selectedDocument: target.getAttribute("data-category"), specialConditionsId: target.getAttribute("data-id")});
        //logger.log(this.state);
    }

    render() {
        if(this.state.loading)
            return (<p><em>Loading...</em></p>);

         let contents = (
                <PortalPage title={"Document Review"} description={""}>
                        <>
                        <Row>
                            <Col className="col-1"></Col>
                            <Col className="col-6">
                                <div className="list-group">
                                    <div className=" list-group-item list-group-item-action flex-column align-items-start">
                                        <Container>
                                            <Row>
                                                <Col className="col-11 text-start">
                                                    Our team are currently reviewing your documentation and an advisor will be back to you soon!
                                                </Col>
                                            </Row>
                                            <Row><Col>&nbsp;</Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </div>
                            </Col>
                            <Col className="col-1"></Col>
                            <Col className="col-3">
                                    <HelpBox title={"What's next?"} text={"Once your documents have been reviewed, we can start to choose a lender to continue your journey"}></HelpBox>
                            </Col>
                        </Row>    
                        <br /><br /><br /><br /></>
                </PortalPage>
                );
        return contents;
    }

    async populateData() {
        const context = this.context as UserContextState;
        if(context.contact === null)
            return;

        axios.get("/mortgages/GetMortgageByMortgageId?mortgageId="+context.contact.mortgageId)
            .then((response) => {
                
                let mortgage = response.data;
                if(mortgage.status === MortgageStatus.AIPApproved || mortgage.status === MortgageStatus.AIPSubmitted) {
                    window.location.href = "/aip-application";
                }
            })
    }
}