import axios from "axios";
import AnimateHeight from "react-animate-height";
import { Stack, Container, Row, Col, Modal, Form } from "react-bootstrap";
import { ApplicableTo, MortgageDocumentStatusCode, PortalDocumentType } from "../../models/PortalMortgageDocumentModel";
import { IFileItem } from "../DocumentManager";
import { FileUpload } from "../FileUpload";
import * as Icons from "react-bootstrap";
import { UserContextComponent } from "../UserContextComponent";
import { Tooltip } from "../Tooltip";
import { Navigate } from "react-router-dom";
import { OpenBanking } from "../../pages/OpenBanking";
import { ChevronUp } from "react-bootstrap-icons";
import { UploadResponse } from "../../models/UploadResponse";
import { logger } from "../../helper/Logger";
import { AIPType } from "../../models/PortalAIPApplication";
import { FileUploadLegacy } from "../FileUploadLegacy";
import { TransactionComments } from "./TransactionComments";
import DraggableModal from "../DraggableModal";

interface IDocumentPillProps {
    //key: number;
    category: string | null;
    name: string;
    files: IFileItem[];
    status?: number | null;
    helpText: string | null;
    comments: string | null;
    contactId: string | null;
    mortgageDocumentId: string | null;
    onDone(mortgageDocumentId: string, status: MortgageDocumentStatusCode): void;
    mortgageId: string | undefined;
    documentType: PortalDocumentType | null;
    metFaceToFace: boolean | null;
    portalName: string | null;
    onUploadComplete?(url: string): void;
    houseHunterDocument?: boolean | null;
    //allowDocUpload?: boolean | null;
}

interface IDocumentPillState {
    isCollapsed: boolean;
    isHelpTextCollapsed: boolean;
    files: IFileItem[];
    showPinDialog: boolean;
    customerComment: string;
    originalPin: string;
    pin: string;
    selectedFileName: string;
    idPalLoader: boolean;
    selectedFileUploadId: string;
    useOpenBanking: boolean;
    navigateTo: string | null;
    uploadcollapsed: boolean;
    showTransactionComments: boolean;
}

export class DocumentPill extends UserContextComponent<IDocumentPillProps, IDocumentPillState> {
    private documentType: PortalDocumentType | null;

    constructor(props: IDocumentPillProps) {
        super(props);
        this.state = { 
            isCollapsed: true, 
            files: this.props.files, 
            uploadcollapsed: this.props.files.length > 0, 
            showPinDialog: false, 
            customerComment: "", 
            originalPin: "", 
            pin: "", 
            selectedFileName: "", 
            idPalLoader: false, 
            selectedFileUploadId: "", 
            navigateTo: null, 
            useOpenBanking: true, 
            isHelpTextCollapsed: true,
            showTransactionComments: false
        };
        this.downloadFile = this.downloadFile.bind(this);

        this.uploadComplete = this.uploadComplete.bind(this);
        this.deleteComplete = this.deleteComplete.bind(this);
        this.confirmUpload = this.confirmUpload.bind(this);
        this.getPillClass = this.getPillClass.bind(this);


        this.documentType = this.props.documentType;

        this.closePinDialog = this.closePinDialog.bind(this);
        this.showCommentAndPinDialog = this.showCommentAndPinDialog.bind(this);
        this.saveCommentAndPin = this.saveCommentAndPin.bind(this);

        this.onSuccess = this.onSuccess.bind(this);
        this.collapsePill = this.collapsePill.bind(this);
    }

    closePinDialog() {
        this.setState({ showPinDialog: false });
    }

    showCommentAndPinDialog(filename: string, uploadId: string, custComment: string, pin: string) {
        this.setState({ selectedFileName: filename, customerComment: custComment, originalPin: pin, pin: pin, showPinDialog: true, selectedFileUploadId: uploadId });
    }

    saveCommentAndPin() {
        // Send in a null for the pin if not updated. Sending in a pin will trigger the automatic file unlock which clears the Comments on success, and we only want that when a pin changes.
        axios.post('/SP/UpdateCommentPin', null, { params: { mortgageId: this.props.mortgageId, filename: this.state.selectedFileName, uploadId: this.state.selectedFileUploadId, custComment: this.state.customerComment, pin: (this.state.pin == this.state.originalPin ? null : this.state.pin), mortgageDocumentId: this.props.mortgageDocumentId } })
            .then(response => {
                this.setState({ showPinDialog: false });
                let items: IFileItem[] = this.state.files;
                let item = items.find(x => x.name === this.state.selectedFileName);
                if (item) {
                    item.customerComment = this.state.customerComment;
                    item.pin = this.state.pin;
                    item.status = "Awaiting advisor review";
                    if (this.state.pin != this.state.originalPin) {
                        item.comment = "";
                    }
                }
            })
            .catch((e) => {
                logger.log(e);
            });
    }

    getPillClass() {
        if (this.needsAction() && this.props.status !== MortgageDocumentStatusCode.Approved)
            return "needsattention";
        if (this.props.status === MortgageDocumentStatusCode.Active)
            return this.state.files?.length > 0 ? "inprogress" : "notstarted";
        if (this.props.status === MortgageDocumentStatusCode.Uploaded)
            return "done";
        if (this.props.status === MortgageDocumentStatusCode.InProgress)
            return "inprogress"
        if (this.props.status === MortgageDocumentStatusCode.Approved)
            return "approved"

        return "notstarted";
    }

    needsAction() {
        var filesWithComments = this.props.files.filter(f => (
                (f.comment??"")!=="" || 
                (f.transactionComments??[]).filter(t => !t.customerComment).length>0
            ));
        if (this.props.comments ||
            filesWithComments.length > 0 ||
            this.props.status === MortgageDocumentStatusCode.NeedsAction)
            return true;
        return false;
    }

    private uploadComplete(data: UploadResponse, category: string, mortgageDocumentId: string, contactId: string, uploadId: any) {
        if (this.props.onUploadComplete)
            this.props.onUploadComplete(data.url);
        let items: IFileItem[] = this.state.files;
        let mortgageDocument = items.find(x => x.mortgageDocumentId === mortgageDocumentId)?.mortgageDocument;
        let item: IFileItem = {
            id: items.length + 100000,
            category: category,
            mortgageDocument: mortgageDocument,
            mortgageDocumentId: mortgageDocumentId,
            name: data.url.split('/').pop()!,
            status: (data.accepted ? "For advisor review" : "Rejected"),
            comment: data.message || "",
            customerComment: "",
            pin: "",
            uploadId: uploadId
        };
        items.push(item);
        this.setState({ files: items });
    }

    private deleteComplete(data: any, mortgageDocumentId: string) {
        let items: IFileItem[] = this.state.files;
        let itemIndex = items.findIndex(x => x.mortgageDocumentId === mortgageDocumentId && x.name === data.split('/').pop());
        if (itemIndex > -1)
            items.splice(itemIndex, 1);
        this.setState({ files: items });
    }

    confirmUpload(mortgageDocumentId: string): void {
        const context = this.getContext();
        axios.post('/MortgageDocuments/MarkMortgageDocumentAsUploaded', null, { params: { mortgageDocumentId: mortgageDocumentId, mortgageId: context.mortgage?.mortgageId } })
            .then(response => {
                this.props.onDone(mortgageDocumentId, MortgageDocumentStatusCode.Uploaded);
            });
    }

    onSuccess(mortgageDocumentId: string): void {
        this.props.onDone(mortgageDocumentId, MortgageDocumentStatusCode.Uploaded);
    }

    downloadFile(mortgageId: string | undefined, fileName: string, uploadId: string): void {
        if (mortgageId) {
            axios({
                url: `/SP/DownloadFile?mortgageId=${mortgageId}&fileName=${encodeURIComponent(fileName)}&uploadId=${uploadId}`,
                method: "GET",
                responseType: "blob"
            }).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                if (response.headers["content-type"] === "application/pdf" && fileName.indexOf(".pdf") === -1) {
                    fileName = fileName + ".pdf";
                }
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
                if (link.parentNode != null)
                    link.parentNode.removeChild(link);
            });
        }
    }

    useIDPal(mortgageDocumentId: string): void {
        const context = this.getContext();
        this.setState({ idPalLoader: true });
        axios.post('/MortgageDocuments/MarkIDPalMortgageDocumentsAsInProgress', null, { params: { mortgageDocumentId: mortgageDocumentId, mortgageId: context.mortgage?.mortgageId } })
            .then((response) => {
                axios.post('/Contacts/SendIDPalLink', null, { params: { contactId: this.props.contactId } }).then(() => {
                    if (response.data) {
                        for (let i = 0; i < response.data.length; i++) {
                            let id = response.data[i];
                            this.props.onDone(id, MortgageDocumentStatusCode.InProgress);
                        }
                        //this.props.onDone(mortgageDocumentId, MortgageDocumentStatusCode.InProgress);    
                    }
                });
            });
    }
    
    getHouseHunterClass() {
        const context = this.getContext();
        if (context.mortgage?.approvalType === AIPType.HouseHunter) {
            if (!this.props.houseHunterDocument && this.state.isCollapsed) {
                return 'greyedpill';
            }
            return 'highlightpill';
        }
        return '';
    }

    collapsePill() {
        if (this.state.files.length > 0 && this.props.status !== MortgageDocumentStatusCode.Approved) {
            this.confirmUpload(this.props.mortgageDocumentId!);
        }
        this.setState({ isCollapsed: true });
    }

    render() {
        const context = this.getContext();
        var pillStatus = this.getPillClass();
        var comments = this.state.files.filter(f => f.comment || (f.transactionComments && f.transactionComments.length > 0));

        if (this.state.navigateTo) {
            return <Navigate to={this.state.navigateTo} />;
        }

        // This section displays on a different place depending on whether you are on mobile or desktop
        var commentsAndFiles = <>
            {!this.props.comments && comments.length === 0 && this.state.files.length === 1 &&
                <>
                    <div className="pb-1"><span><strong>{this.state.files.length} File</strong> added</span></div>
                </>
            }
            {!this.props.comments && comments.length === 0 && this.state.files.length !== 1 && 
                <>
                    <div className="pb-1"><span><strong>{this.state.files.length} Files</strong> added</span></div>
                </>
            }
            {(this.props.comments || comments.length > 0) &&
                <div className="box box-travertine">
                    <i className="fas fa-triangle-exclamation pill-big needsattention"></i>
                {this.props.comments &&
                    <div className="pt-2">
                        <Row>
                            <Col>
                                <h6 className="mb-0"><strong>For review</strong></h6>
                                <h6 className="render-linebreaks">{this.props.comments}</h6>
                            </Col>
                        </Row>
                    </div>
                }
                
                {comments.length > 0 && comments.map((item, idx) => {
                    return <div key={"comment-"+item.id} className="pt-2">
                        <Row>
                            <Col>
                                <h6 className="mb-0 filename"><strong>{item.name}</strong></h6>
                                {item.comment &&
                                    <h6 className="render-linebreaks">{item.comment} <span className="pointer doddltext" style={{marginLeft: 4}} onClick={e => this.showCommentAndPinDialog(item.name, item.uploadId, item.customerComment, item.pin)}><u><i className="fa-solid fa-reply"></i> reply</u></span></h6>
                                }
                                <TransactionComments item={item} mortgageId={this.props.mortgageId!} />
                            </Col>
                        </Row>
                    </div>;
                    }
                )}
                </div>
            }

            {this.state.files.map((item, idx) => {
                return <div key={"file-" + item.id} className={`document-file ${item.status === 'Approved' ? 'complete' : ''}`}>
                    <Row>
                        <Col xs="1">
                            {item.status === 'Approved' &&
                                <Tooltip tooltip={{ "fieldname": "", "title": "", "content": "doddl will review your documents and get back to you soon!" }}>
                                    <i className="fa fa-circle-check file-icon" style={{color: 'var(--teal)'}}></i>
                                </Tooltip>
                            }
                            {item.status === 'Rejected' &&
                                <Tooltip tooltip={{ "fieldname": "", "title": "", "content": "There is an issue with this document" }}>
                                    <i className="fas fa-triangle-exclamation pill-big pill-icon needsattention"></i>
                                </Tooltip>
                            }
                            {item.status !== 'Approved' && item.status !== 'Rejected' &&
                                <i className="far fa-clock file-icon"></i>
                            }
                        </Col>
                        <Col xs="8">
                            <h6 className="filename">
                                <strong><span style={{ paddingLeft: 10, cursor: "pointer" }} onClick={e => { this.downloadFile(this.props.mortgageId, item.name, item.uploadId); }}>{item.name}</span></strong>
                            </h6>
                        </Col>
                        <Col xs="3" className="text-end">
                            <Tooltip tooltip={{ "fieldname": "", "title": "", "content": "Download file" }}>
                                <i className="fas fa-cloud-download pointer pe-2" onClick={e => { this.downloadFile(this.props.mortgageId, item.name, item.uploadId); }}></i>
                            </Tooltip>
                            <Tooltip tooltip={{ "fieldname": "", "title": "", "content": "Add a comment or let us know if a pin is required to unlock this file" }}>
                                <i className="fas fa-pencil pointer pe-2" onClick={e => this.showCommentAndPinDialog(item.name, item.uploadId, item.customerComment, item.pin)}></i>
                            </Tooltip>
                            {/* <Tooltip tooltip={{ "fieldname": "", "title": "", "content": "Delete this file" }}>
                                <i className="fa fa-trash pointer pe-2" onClick={e => this.showCommentAndPinDialog(item.name, item.uploadId, item.customerComment, item.pin)}></i>
                            </Tooltip> */}

                        </Col>
                    </Row>
                </div>
            }                                
            )}

        </>;

        return (
            // reverting the savings back again! ((context.mortgage?.approvalType === AIPType.HouseHunter && this.props.portalName !== "Savings Accounts") || (context.mortgage?.approvalType !== AIPType.HouseHunter) || (context.mortgage?.approvalType === AIPType.HouseHunter && this.props.portalName === "Savings Accounts" && this.props?.allowDocUpload === true)) && 
            <Container className={`pill-document ${pillStatus} ${this.getHouseHunterClass()}` } >
                <div title={this.props.name} className={`pill-header ${!this.state.isCollapsed && 'nowrap'}`} onClick={() => this.setState({ isCollapsed: !this.state.isCollapsed })}>
                    <Row>
                        <Col xs={this.state.isCollapsed && pillStatus !== "notstarted" ? 10 : 12}>
                            <div className={`pill-text ${this.props.name.length <= 25 ? 'overflow' : ''}`}>{this.props.name}</div>
                        </Col>
                        {this.state.isCollapsed && pillStatus !== "notstarted" &&
                            <Col xs={2} className="pill-count-col ">
                                {this.props.status === MortgageDocumentStatusCode.Approved && !this.needsAction() &&
                                    // <div className="pill-count ">✔</div>
                                    <Tooltip tooltip={{ "fieldname": "", "title": "", "content": "Your documents have been reviewed and approved!" }}>
                                        <i className={`fas fa-circle-check pill-big ${this.props.status === MortgageDocumentStatusCode.Approved ? 'pill-icon-app': 'pill-icon'}`}></i>
                                    </Tooltip>
                                }

                                {this.props.status === MortgageDocumentStatusCode.Uploaded && !this.needsAction() &&
                                    <Tooltip tooltip={{ "fieldname": "", "title": "", "content": "doddl will review your documents and get back to you soon!" }}>
                                        <i className="far fa-clock pill-big pill-icon"></i>
                                    </Tooltip>
                                }
                                {this.needsAction() &&
                                    <Tooltip tooltip={{ "fieldname": "", "title": "", "content": "There are some issues that need to be addressed on your documents" }}>
                                        <i className="fa-solid fa-triangle-exclamation pill-big pill-icon needsattention"></i>
                                    </Tooltip>
                                }
                                {this.state.files.length > 0 && (this.props.status === MortgageDocumentStatusCode.Active || this.props.status === MortgageDocumentStatusCode.InProgress) && !this.needsAction() &&
                                    <Tooltip tooltip={{ "fieldname": "", "title": "", "content": "The number of documents uploaded" }}>
                                        <div className="pill-count ">{this.state.files.length}</div>
                                    </Tooltip>
                                }
                                {this.state.files.length > 0 && (this.props.status === MortgageDocumentStatusCode.Uploaded && !this.needsAction()) &&
                                    <Tooltip tooltip={{ "fieldname": "", "title": "", "content": "The number of documents uploaded" }}>
                                        <div className="pill-count">{this.state.files.length}</div>
                                    </Tooltip>
                                }
                            </Col>
                        }
                    </Row>

                </div>
                {/* reverting the savings back again!{(!this.state.isCollapsed && this.props.portalName === "Savings Accounts" && context.mortgage?.approvalType === AIPType.HouseHunter && this.props?.allowDocUpload === false) ? "" :*/}
                    <Modal size="xl" show={!this.state.isCollapsed} onHide={this.collapsePill} centered>
                        <Modal.Header>
                        </Modal.Header>
                        <Modal.Body className="px-4 py-4">
                            <Row>
                                <Col xs={12} md={8} className="pe-4 document-upload">
                                    <h1 className="pb-3">{this.props.name}</h1>
                                    <h4 style={this.props.status === MortgageDocumentStatusCode.Approved ? { paddingBottom: 10 } : { paddingBottom: 10 }} dangerouslySetInnerHTML={{ __html: this.props.helpText ?? "" }}></h4>
                                    {(this.needsAction() && (this.props.comments === "" || this.props.comments === null)) &&
                                        <br />
                                    }
                                    <div className="mobile-only">
                                        {commentsAndFiles}
                                    </div>
                                    <div style={{ paddingTop: 10 }} />
                                    {this.props.status !== MortgageDocumentStatusCode.Approved &&
                                        <>
                                            {this.documentType === PortalDocumentType.OpenBanking && this.props.status !== MortgageDocumentStatusCode.Uploaded &&
                                                <Stack direction="horizontal" className="justify-center pb-2" gap={2}>
                                                    <OpenBanking mortgageDocumentId={this.props.mortgageDocumentId!} onSuccess={this.onSuccess} applicableTo={this.props.contactId === context.mortgage?.primaryApplicant.contactId ? ApplicableTo.Primary : ApplicableTo.Secondary} />
                                                    {this.state.useOpenBanking && <button className="btn big btn-light" onClick={() => this.setState({ useOpenBanking: !this.state.useOpenBanking })}>Upload Statements</button>}
                                                    {!this.state.useOpenBanking && <div className="amber pointer text-end" style={{ fontSize: 30 }} onClick={() => this.setState({ useOpenBanking: !this.state.useOpenBanking })}><ChevronUp /></div>}
                                                </Stack>
                                            }
                                            {this.props.metFaceToFace !== true && this.documentType === PortalDocumentType.IDPal && this.props.status !== MortgageDocumentStatusCode.InProgress && this.state.idPalLoader &&
                                                <Stack direction="horizontal" className="justify-center">
                                                    <Icons.Spinner animation="border" role="status" style={{ marginBottom: 15 }} />
                                                </Stack>}

                                            {this.documentType === PortalDocumentType.IDPal && this.props.status !== MortgageDocumentStatusCode.InProgress && this.props.status !== MortgageDocumentStatusCode.Uploaded && !this.state.idPalLoader &&
                                                <Stack direction="horizontal" className="justify-center">
                                                    <button className='btn big btn-dark' style={{ marginBottom: 15 }} onClick={e => { this.useIDPal(this.props.mortgageDocumentId!); }}>Use ID-Pal</button>
                                                </Stack>
                                            }
                                            {this.documentType === PortalDocumentType.IDPal && this.props.status === MortgageDocumentStatusCode.InProgress &&
                                                <h4 className='inprogress' style={{ marginLeft: 5, marginBottom: 15 }}>Check your SMS messages for your ID-Pal link</h4>
                                            }
                                            {this.documentType === PortalDocumentType.OpenBanking && this.props.status === MortgageDocumentStatusCode.Uploaded &&
                                                <h3 className='done' style={{ marginLeft: 5, marginBottom: 15 }}>Open Banking Connected</h3>
                                            }

                                            {(this.documentType !== PortalDocumentType.IDPal || this.props.metFaceToFace === true) &&
                                                !(this.documentType === PortalDocumentType.IDPal && this.props.metFaceToFace === false && (this.props.status === MortgageDocumentStatusCode.InProgress || this.props.status === MortgageDocumentStatusCode.Uploaded)) &&
                                                (this.documentType !== PortalDocumentType.OpenBanking || !this.state.useOpenBanking) &&

                                                <>
                                                    {context.mortgage?.useLegacyUploader

                                                        ?
                                                        <FileUploadLegacy
                                                            mortgageId={context.mortgage?.mortgageId}
                                                            contactId={this.props.contactId}
                                                            category={this.props.category}
                                                            mortgageDocumentId={this.props.mortgageDocumentId}
                                                            onUploadComplete={(d: UploadResponse, uploadId: any) => { this.uploadComplete(d, this.props.category!, this.props.mortgageDocumentId!, this.props.contactId!, uploadId); }}
                                                            onDeleteComplete={(d: any) => { this.deleteComplete(d, this.props.mortgageDocumentId!); }} />

                                                        :
                                                        <FileUpload
                                                            mortgageId={context.mortgage?.mortgageId}
                                                            contactId={this.props.contactId}
                                                            category={this.props.category}
                                                            mortgageDocumentId={this.props.mortgageDocumentId}
                                                            onUploadComplete={(d: UploadResponse, uploadId: any) => { this.uploadComplete(d, this.props.category!, this.props.mortgageDocumentId!, this.props.contactId!, uploadId); }}
                                                            onDeleteComplete={(d: any) => { this.deleteComplete(d, this.props.mortgageDocumentId!); }} />
                                                    }
                                                </>
                                            }

                                        </>
                                    }
                                </Col>
                                <Col xs={4}>
                                    <Stack direction="horizontal" gap={2} className="pill-buttons-right">
                                        <button className='btn btn-light big' onClick={this.collapsePill}>Close</button>
                                    </Stack>

                                    <div className="desktop-only">
                                        {commentsAndFiles}
                                    </div>
                                </Col>
                            </Row>

                        </Modal.Body>
                        <DraggableModal
                            show={this.state.showPinDialog}
                            onHide={() => { this.closePinDialog(); }}
                            title={""}
                            body={
                                <Form>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Comment</Form.Label>
                                        <Form.Control onMouseDown={(e) => { e.stopPropagation() }} disabled={this.props.status === MortgageDocumentStatusCode.Approved && !this.needsAction()} as="textarea" rows={3} defaultValue={this.state.customerComment} onChange={e => this.setState({ customerComment: e.target.value })} autoFocus />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Document Password (if applicable)</Form.Label>
                                        <Form.Control onMouseDown={(e) => { e.stopPropagation() }} disabled={this.props.status === MortgageDocumentStatusCode.Approved} type="password" defaultValue={this.state.pin} onChange={e => this.setState({ pin: e.target.value })} />
                                    </Form.Group>
                                </Form>
                            }
                            footer={
                                <>
                                    <button className="btn btn-light" onClick={this.closePinDialog}>
                                        Close
                                    </button>
                                    {this.props.status !== MortgageDocumentStatusCode.Approved &&
                                        <button className="btn btn-standard" onClick={this.saveCommentAndPin}>
                                            Save
                                        </button>
                                    }
                                </>
                            }
                        />
                    </Modal>{/* } */}
            </Container >
        );
    }
}