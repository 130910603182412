import React from "react";
import { Col, Row, Stack } from "react-bootstrap";

export class SpreadTheWordTile extends React.Component {

    render() {
        return (
            <div key={'spreadthewordtile'} className={"text-start boxty box-teal-outline"}>
                <>
                    <p className={`text-gray`}>Help us to help others</p>
                    <h1>Spread the word</h1>
                    <p className={`m`}>If you have been happy with the impartial, expert service we’ve provided, please consider reviewing us on Google!</p>
                    <Stack gap={2}>
                    <div className="btn btn-gray-outline btn-fw text-start" onClick={() => window.open('https://search.google.com/local/reviews?placeid=ChIJtfmJFqwPZ0gRUpfpE-qI-fA', '_blank')}>
                        <Row>
                            <Col xs={9} className="text-start ps-4 nowrap">Pen a Google review</Col><Col xs={3} className="text-end pe-4"><i className="fab fa-google" /></Col>
                        </Row>
                    </div>
                    </Stack>
                </>
            </div>
            );
    }
}