import React from 'react';
import axios from 'axios';
import { Button, Row, Col, Modal, Stack } from 'react-bootstrap';
import { PlaidLinkOnSuccess, PlaidLinkOnEvent, PlaidLinkOnExit } from 'react-plaid-link';
import { PlaidLinkCustom } from '../components/PlaidLinkCustom';
import { PortalPage } from '../components/PortalPage';
import { FileUploadLegacy, SPCategory} from '../components/FileUploadLegacy'
import { UserContextComponent } from '../components/UserContextComponent';
import { ApplicableTo, PortalMortgageDocumentModel } from '../models/PortalMortgageDocumentModel';
import { logger } from '../helper/Logger';

interface Props {
  className?: string;
  label?: any;
  mortgageDocumentId?: string;
  applicableTo:ApplicableTo;
  onSuccess(mortgageDocumentId?:string, openBankingRequestId?:string): void;
}
interface State {
  linkToken: string | null;
  publicToken: string | null;
  reportToken: string | null;
  transactions: any[] | null;
  step: number;
}

export class OpenBanking extends UserContextComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { linkToken: null, reportToken: null, publicToken: null, transactions: null, step: 1 };
    
  }

  async createLinkToken() {
    const response = await axios.get('/OpenBanking/CreateLinkToken');
    return response.data;
  }

  async componentDidMount() {
    const linkToken = await this.createLinkToken();
    this.setState({ linkToken: linkToken });
  }

  
  async LinkOpenBanking(publicToken?: string) {
    const context = this.getContext();
    const response = await axios.get(`/OpenBanking/LinkOpenBanking?mortgageId=${context.mortgage?.mortgageId}&applicableTo=${this.props.applicableTo}&publicToken=${publicToken}`)
    return response.data;
  }

  onSuccess: PlaidLinkOnSuccess = async (publicToken, metadata) => {
    // We should instead create perhaps a transaction header and update it with this token...
    // We should also see what metadata we receive... as this might have useful info like account name...
    this.setState({ publicToken: publicToken });
    var openBankingRequest = await this.LinkOpenBanking(publicToken);
    this.props.onSuccess(this.props.mortgageDocumentId, openBankingRequest.id);
  };

  onEvent: PlaidLinkOnEvent = (eventName, metadata) => {
    logger.log(eventName, metadata);
  };

  onExit: PlaidLinkOnExit = (error, metadata) => {
    logger.log(error, metadata);
  };

    render() {
      return (
        <PlaidLinkCustom className={this.props.className ?? 'btn big btn-dark'} token={this.state.linkToken} onSuccess={this.onSuccess}>{this.props.label ?? 'Continue with open banking'}</PlaidLinkCustom>
      );
  }
}